import { NewestLastEvaluatedKey } from '../../../interface/listEbook';
import { HtiResponse } from '../../../interface';
import { checkLastEvaluatedKey } from '../../Utilities/validateText';
import queryString from 'query-string';
import axios from 'axios';

export const getPublisher = async () => {
    const {
        data: { data }
    } = await axios.get<HtiResponse>(`/catalogs/publishers`);
    return data;
};

export const getPublisherById = async (publisherID: string, LastEvaluatedKey?: any) => {
    let path = `/catalogs/publishers/${publisherID}`;
    const qString: any = checkLastEvaluatedKey(LastEvaluatedKey);
    if (LastEvaluatedKey) {
        path += `?lastEvaluatedKey=${LastEvaluatedKey}`;
    }
    const {
        data: { data }
    } = await axios.get(
        queryString.stringifyUrl({
            url: path,
            query: qString
        })
    );
    return data;
};

export const getNewest = async (lastEvaluatedKey?: NewestLastEvaluatedKey) => {
    let path = `/catalogs/newest`;
    const qString: any = checkLastEvaluatedKey(lastEvaluatedKey);

    if (lastEvaluatedKey) {
        path += `?lastEvaluatedKey=${lastEvaluatedKey}`;
    }
    const {
        data: { data }
    } = await axios.get(
        queryString.stringifyUrl({
            url: path,
            query: qString
        })
    );
    return data;
};

export const getCategories = async () => {
    const {
        data: { data }
    } = await axios.get('/catalogs/categories');
    return data;
};

export const getSubCategories = async (categoryId: number) => {
    const {
        data: { data }
    } = await axios.get(`/catalogs/categories/${categoryId}`);
    return data;
};

export const getCatalogBySubCategoryId = async (
    categoryId: number,
    subCategoryId: number,
    LastEvaluatedKey?: any
) => {
    let path = `/catalogs/categories/${categoryId}/subcategories/${subCategoryId}`;
    const qString: any = checkLastEvaluatedKey(LastEvaluatedKey);

    if (LastEvaluatedKey) {
        path += `?lastEvaluatedKey=${LastEvaluatedKey}`;
    }
    const {
        data: { data }
    } = await axios.get(
        queryString.stringifyUrl({
            url: path,
            query: qString
        })
    );
    return data;
};

export const getCatalogDetail = async (contentUID: string) => {
    const path = `/catalogs/contents/${contentUID}`;
    const {
        data: { data }
    } = await axios.get(path);
    return data;
};
