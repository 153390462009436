import { Swiper, SwiperSlide } from 'swiper/react';
import { ContentSetView } from '../store';
import { LicenseType, SubscriptionMetaData } from '../../../interface';

interface ContentSetViewProps {
    contents: SubscriptionMetaData[];
    licenseType: LicenseType;
}

const SubScriptionView = (props: ContentSetViewProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 1.5,
            slidesPerGroup: 1.5
        },
        576.98: {
            slidesPerView: 3.5,
            slidesPerGroup: 3.5
        },

        1024.98: {
            slidesPerView: 5.5,
            slidesPerGroup: 5.5
        }
    };

    return (
        <Swiper breakpoints={breakpoint} spaceBetween={16}>
            {props.contents.map((el, index) => (
                <SwiperSlide key={index}>
                    <ContentSetView key={index} set={el} licenseType={props.licenseType} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SubScriptionView;
