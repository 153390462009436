import { Col } from 'antd';

interface EbookDetailNumberProp {
    title: string;
    number?: string;
    disable?: boolean;
}

const EbookDetailNumber = (props: EbookDetailNumberProp) => {
    return (
        <Col className={`detailNumber ${props.disable === true ? 'disable' : ''}`}>
            <div>{props.title}</div> <div>{props.number !== 'undefined' ? props.number : '0'}</div>
        </Col>
    );
};

export default EbookDetailNumber;
