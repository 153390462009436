const NotificationMeasures = () => {
    return (
        <div className='notification-measures'>
            <div>
                <h1>ขอใช้สิทธิสำหรับเจ้าของข้อมูลส่วนบุคคล</h1>
                <p>
                    ตาม ประกาศกระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม เรื่อง ขั้นตอนการแจ้งเตือน
                    การระงับการทําให้แพร่หลายของข้อมูลคอมพิวเตอร์และการนําข้อมูลคอมพิวเตอร์ออกจากระบบคอมพิวเตอร์
                    พ.ศ. 2560 ผู้ที่ประสงค์จะให้สำนักงานในฐานะผู้ให้บริการ การระงับการทำให้แพร่หลาย
                    และการนําข้อมูลออกจากระบบคอมพิวเตอร์
                    สำหรับกรณีที่มีข้อมูลใดที่อาจเป็นความผิดตามมาตรา 14
                    แห่งพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ.2550
                    และที่แก้ไขเพิ่มเติม
                    สามารถดำเนินการแจ้งสำนักงานได้พร้อมเอกสารหลักฐานประกอบแล้วส่งมาที่
                </p>
            </div>
            <div>
                <h4>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด (Hytexts)</h4>
                <p>
                    3 อาคารพร้อมพันธุ์ 3 ชั้น 7 ห้อง 701-702 ซอย ลาดพร้าว 3 แขวงจอมพล เขตจตุจักร
                    กรุงเทพมหานคร 10900 หรือติดต่ออีเมล : contact@hytexts.com
                </p>
            </div>
            <div>
                <h4>เอกสารหลักฐานประกอบ</h4>
                <ul>
                    <li>
                        <a
                            className='link'
                            target='_blank'
                            href='https://s3.ap-southeast-1.amazonaws.com/hti.elibrary/public/form/complaintform_Hibrary.pdf'
                        >
                            แบบฟอร์มข้อร้องเรียนเพื่อระงับหรือลบการแพร่หลายของข้อมูลคอมพิวเตอร์ (Com
                            plaint Form)
                        </a>
                    </li>
                    <li>
                        ลงบันทึกประจําวัน
                        หรือแจ้งความร้องทุกข์เพื่อเป็นหลักฐานต่อพนักงานสอบสวนหรือเจ้าหน้าที่ตํารวจ
                    </li>
                    <li>
                        เอกสารหลักฐานที่แสดงให้เห็นถึงการกระทําความผิดหรือหลักฐานอื่นที่เกี่ยวข้องแก่ผู้ให้บริการ
                    </li>
                    <li>หนังสือมอบอำนาจ (กรณีมอบอำนาจ)</li>
                </ul>
            </div>
        </div>
    );
};

export default NotificationMeasures;
