import React, { useState, useEffect, useMemo } from 'react';
import { removeSplash } from '../../helpers/removeSplash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { BreadcrumbLink, BreadCrumbView } from '../../components/breadcrumb';
import { Newest } from './newest';
import { Publisher } from './publisher';
import { Category } from './catagory';
import { Ebook } from './ebook';
import { useHistory } from 'react-router-dom';
import { SubCatData, contentBySubCat } from '../../../interface/listEbook';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { HibraryRootState } from '../../../redux/rootReducer';
import {
    getPublisherById,
    getSubCategories,
    getCatalogBySubCategoryId
} from '../../redux/catalog/catalogCrud';
import './index.scss';
import { useIntl } from 'react-intl';
import CardListEbook from './card';

interface ListEbookViewProps {
    type?: string;
    id?: string;
    subId?: string;
}
export const ListEbook = (props: ListEbookViewProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const pageId = 'catalogEbookPage';
    const intl = useIntl();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
    const [publisherById, setPublisherById] = useState<any>();
    const [subCategory, setSubCategory] = useState<SubCatData>();
    const [catalogBySubcat, setCatalogBySubcat] = useState<contentBySubCat>();
    const [renderPage, setRenderPage] = useState<string>(props.type ? props.type : 'card');
    const [header, setHeader] = useState('');

    const { userinfo } = useSelector(
        (state: HibraryRootState) => ({
            userinfo: state.auth.userInfo
        }),
        shallowEqual
    );

    useEffect(() => {
        if (userinfo?.role !== 'admin') history.push('/error/not-found');
        if (renderPage == 'card') removeSplash(dispatch);
    }, []);

    useEffect(() => {
        dispatch(action.setProcess(true, pageId));
        setPublisherById(undefined);
        setSubCategory(undefined);
        setCatalogBySubcat(undefined);

        if (props.subId !== undefined && props.id !== undefined && props.type !== undefined) {
            fetchCatalogBySubCategoryId(props.id, props.type, props.subId);
        } else {
            if (props.id !== undefined && props.type !== undefined) {
                if (props.type === 'publisher') {
                    fetchPublisherById(props.id);
                }
                if (props.type === 'cat') {
                    fetchSubCategory(props.id);
                }
            } else {
                setRenderPage(props.type ? props.type : 'card');
                updateBreadCrumb();
                dispatch(action.setProcess(false, pageId));
            }
        }
    }, [props.type, props.id, props.subId]);

    const fetchCatalogBySubCategoryId = (id: string, type: string, subId: string) => {
        getCatalogBySubCategoryId(parseInt(id), parseInt(subId)).then((res) => {
            setCatalogBySubcat(res);
            setHeader(res.subcatName);
            setRenderPage(`${props.type}/subCat/catalog`);
            dispatch(action.setProcess(false, pageId));
            setBreadCrumbs([
                {
                    title: getHeaderLabel(type),
                    path: `/listEbook/${props.type}`
                },
                {
                    title: res.catName,
                    path: `/listEbook/${props.type}/${props.id}`
                },
                {
                    title: res.subcatName,
                    path: `/listEbook/${props.type}/${props.id}/${props.subId}`
                }
            ]);
        });
    };

    const fetchSubCategory = (id: string) => {
        getSubCategories(parseInt(id)).then((res) => {
            setSubCategory(res);
            setHeader(res.catName);
            setRenderPage(`${props.type}/subCat`);
            updateBreadCrumb(res.catName);
            dispatch(action.setProcess(false, pageId));
        });
    };

    const fetchPublisherById = (id: string) => {
        getPublisherById(id).then((res) => {
            setPublisherById(res);
            setHeader(res.publisherName);
            setRenderPage(`${props.type}/publisher/catalog`);
            updateBreadCrumb(res.publisherName);
            dispatch(action.setProcess(false, pageId));
        });
    };

    const updateBreadCrumb = (name1?: string) => {
        if (props.type && props.id) {
            setBreadCrumbs([
                {
                    title: getHeaderLabel(props.type),
                    path: `/listEbook/${props.type}`
                },
                {
                    title: name1 ? name1 : '',
                    path: `/listEbook/${props.type}/${props.id}`
                }
            ]);
        } else {
            if (props.type !== undefined) {
                setHeader(getHeaderLabel(props.type));
                setBreadCrumbs([
                    {
                        title: getHeaderLabel(props.type),
                        path: `/listEbook/${props.type}`
                    }
                ]);
            } else {
                setHeader(getHeaderLabel('รายการหนังสือ'));
            }
        }
    };

    const getHeaderLabel = (type: string) => {
        switch (type) {
            case 'newest':
                return intl.formatMessage({ id: 'Catalogue.NewestTitle' });
            case 'cat':
                return intl.formatMessage({ id: 'Catalogue.CategoryTitle' });
            case 'publisher':
                return intl.formatMessage({ id: 'Catalogue.PublisherTitle' });
            default:
                return intl.formatMessage({ id: 'Catalogue.Title' });
        }
    };

    const selectPage = () => {
        switch (renderPage) {
            case 'card':
                return <CardListEbook />;
            case 'newest':
                return <Newest />;
            case 'cat':
                return <Category />;
            case 'cat/subCat':
                return <Category subCategory={subCategory} />;
            case 'cat/subCat/catalog':
                return catalogBySubcat ? (
                    <Ebook
                        contents={catalogBySubcat?.contents}
                        publisherName={catalogBySubcat?.subcatName}
                        LastEvaluatedKey={catalogBySubcat?.LastEvaluatedKey}
                        categoryId={props.id}
                        subCategoryId={props.subId}
                        receivedFrom='catagory'
                    />
                ) : null;
            case 'publisher':
                return <Publisher />;
            case 'publisher/publisher/catalog':
                return (
                    <Ebook
                        contents={publisherById?.contents}
                        publisherName={publisherById?.publisherName}
                        LastEvaluatedKey={publisherById?.LastEvaluatedKey}
                        publisherID={props.id}
                        receivedFrom='publisher'
                    />
                );
        }
    };

    const breadcrumbsMemo = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    const pageMemo = useMemo(() => {
        return selectPage();
    }, [renderPage, publisherById]);

    return (
        <>
            {breadcrumbsMemo}
            <div>
                <h1 className='header-title' style={{ marginBottom: '2rem' }}>
                    {header}
                </h1>
            </div>
            <div style={{ marginBottom: '3rem' }}>
                {pageMemo}
                <Loading id={pageId} />
            </div>
        </>
    );
};
