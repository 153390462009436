import { Button } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import './index.scss';

interface OpenAppTabProps {
    url: string;
}

const OpenAppTab: FC<OpenAppTabProps> = ({ url }) => {
    const intl = useIntl();
    const { environment, authen } = useSelector(
        (state: HibraryRootState) => ({
            environment: state.environment,
            authen: state.auth
        }),
        shallowEqual
    );

    return (
        <div className={environment.openAppTab ? 'openAppTab' : 'openAppTab fadeOut'}>
            <div className='appTitleContainer'>
                <img
                    src={
                        authen.isCuDomain
                            ? ' /images/icon/cuAppIcon.svg'
                            : '/images/icon/hibraryAppIcon.svg'
                    }
                    className='appIcon'
                />
                <div>
                    <p className='appName'>
                        {authen.isCuDomain
                            ? intl.formatMessage({ id: 'OpenAppTab.CUTitle' })
                            : intl.formatMessage({
                                  id: 'OpenAppTab.HibraryTitle'
                              })}
                    </p>
                    <p className='description'>
                        {intl.formatMessage(
                            { id: 'OpenAppTab.SubtTitle' },
                            {
                                0: authen.isCuDomain ? `‘CU-eLibrary‘` : `‘Hibrary’`
                            }
                        )}
                    </p>
                </div>
            </div>
            <Button shape='round' href={url}>
                {intl.formatMessage({ id: 'OpenAppTab.Button' })}
            </Button>
        </div>
    );
};

export default OpenAppTab;
