import { Checkbox, Space } from 'antd';
import { QuestionComponentProps } from '../../../../interface/questionForm';

const QuestionCheckBox = (props: QuestionComponentProps) => {
   return (
      <Checkbox.Group>
         <Space size={8} direction='vertical' className='radio-button'>
            {props.data.answerPreset!.map((el, index) => (
               <Checkbox value={el.value} key={index}>
                  {el.display}
               </Checkbox>
            ))}
         </Space>
      </Checkbox.Group>
   );
};

export default QuestionCheckBox;
