import { Space } from 'antd';
import { ReadingTimePerDay } from '../../../../interface';
import SVG from 'react-inlinesvg';
import { useIntl } from 'react-intl';

interface VerticalProgressProps {
    data: ReadingTimePerDay[];
    totalReadingTime: number;
}

const ReadingTimeBox = (props: VerticalProgressProps) => {
    const intl = useIntl();
    const getDays = () => {
        const second = props.totalReadingTime;
        return Math.floor(second / 86400);
    };

    const getHours = () => {
        const second = props.totalReadingTime;
        return Math.floor((second % 86400) / 3600);
    };

    const getMinute = () => {
        const second = props.totalReadingTime;
        return Math.ceil(((second % 86400) % 3600) / 60);
    };

    return (
        <div>
            <div className='flex gap-8' style={{ marginBottom: '0.5rem' }}>
                <p>{intl.formatMessage({ id: 'ReadingStat.ReadTime' })}</p>
                <SVG src='/images/reading-stat/stop-watch.svg' />
            </div>
            <div>
                <p className='float-text'>{intl.formatMessage({ id: 'ReadingStat.Last7Days' })}</p>
                <Space
                    size={8}
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        marginBottom: '0.5rem'
                    }}
                >
                    {props.data.map((el, index) => (
                        <div className='custom-progress flex' key={index}>
                            <div
                                style={{
                                    height: `${el.readingTimePercent * 100}%`
                                }}
                            ></div>
                        </div>
                    ))}
                </Space>
            </div>

            <div>
                <p className='float-text'>
                    {intl.formatMessage({ id: 'ReadingStat.TotalReadTime' })}
                </p>
                <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                    <div className='flex flex-col align-center justify-content day-time'>
                        <p className='time'>{getDays()}</p>
                        <p className='day'>{intl.formatMessage({ id: 'Unit.Day' })}</p>
                    </div>

                    <div className='flex flex-col align-center justify-content day-time'>
                        <p className='time'>{getHours()}</p>
                        <p className='day'>{intl.formatMessage({ id: 'Unit.Hour' })}</p>
                    </div>

                    <div className='flex flex-col align-center justify-content day-time'>
                        <p className='time'>{getMinute()}</p>
                        <p className='day'> {intl.formatMessage({ id: 'Unit.Min' })}</p>
                    </div>
                </Space>
            </div>
        </div>
    );
};

export default ReadingTimeBox;
