import { Swiper, SwiperSlide } from 'swiper/react';
import { Content } from '../../../interface';
import { ImageCover } from '../contentCover/imageCover';

import './index.scss';
import { getDetailRoute } from '../../routes/generatePath';

interface ContentProps {
    contents: Content[];
}

const ContentsView = (props: ContentProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 3.2,
            slidesPerGroup: 3.2
        },
        576.98: {
            slidesPerView: 5.2,
            slidesPerGroup: 5.2
        },
        768.98: {
            slidesPerView: 7.2,
            slidesPerGroup: 7.2
        }
    };

    return (
        <Swiper
            className='contents-container swiper-contents-view'
            spaceBetween={16}
            breakpoints={breakpoint}
            slidesPerColumn={2}
        >
            {props.contents.map((el, index) => {
                return (
                    <SwiperSlide
                        key={index}
                        style={index < props.contents.length / 2 ? { marginBottom: '1rem' } : {}}
                    >
                        <div className='content-cover'>
                            <ImageCover
                                coverImage={el.coverImage}
                                fileType={el.fileType}
                                path={getDetailRoute(el.contentUID, el.contentType, el.licenseType)}
                            />
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default ContentsView;
