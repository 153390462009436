/* eslint-disable @typescript-eslint/no-unused-vars */

import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useLang, setLanguage } from '../../i18n';
import { ClientInfo } from '../../../interface/client';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Switch } from 'antd';
import { setDarkTheme } from '../../redux/theme/action';
import { LoginButton } from './LoginButton';
import { useIntl } from 'react-intl';
import UserInfo from './UserInfo';
import TabBox from './TabBox';
import SearchBox from './Search';
import './index.scss';

export const NavBar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { authState, clientInfo, theme } = useSelector(
    (state: HibraryRootState) => ({
      authState: state.auth,
      clientInfo: state.clientInfo as ClientInfo,
      theme: state.theme
    }),
    shallowEqual
  );

  const darkModeEnabled = (enable: boolean) => {
    dispatch(setDarkTheme(enable));
  };

  const LangBox = () => {
    const lang = useLang();
    const selectedLangClassName = (l: string) => {
      if (l === lang) {
        return 'selected-lang';
      } else {
        return 'unselected-lang';
      }
    };

    return (
      <div className="lang-box">
        <span
          className={selectedLangClassName('en')}
          onClick={() => setLanguage('en')}
        >
          EN
        </span>{' '}
        |{' '}
        <span
          className={selectedLangClassName('th')}
          onClick={() => setLanguage('th')}
        >
          TH
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="navbar">
        <div className="flexContainer">
          <UserInfo />
          <div className="navMiddle">
            <SearchBox />
            <TabBox
              isPrivateLib={clientInfo.isPrivateLibrary}
              isAuthToken={authState.authToken ? true : false}
              role={
                authState?.userInfo?.role === undefined
                  ? ''
                  : authState.userInfo.role
              }
            />
          </div>
          <div className="hamburger">
            <div className="themeBtn">
              <span>{intl.formatMessage({ id: 'Action.DarkMode' })}</span>
              <Switch
                className="switch"
                checkedChildren={intl.formatMessage({
                  id: 'Action.On'
                })}
                unCheckedChildren={intl.formatMessage({
                  id: 'Action.Off'
                })}
                defaultChecked={theme.darkMode}
                onChange={darkModeEnabled}
              />
              <LangBox />
              {/* <ThemeSwitch /> */}
            </div>
            <LoginButton />
          </div>
        </div>

        <div className="nav-inner-mobile">
          <SearchBox />
          <TabBox
            isPrivateLib={clientInfo.isPrivateLibrary}
            isAuthToken={authState.authToken ? true : false}
            role={
              authState?.userInfo?.role === undefined
                ? ''
                : authState.userInfo.role
            }
          />
        </div>
      </div>
    </>
  );
};
