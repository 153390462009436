import { Swiper, SwiperSlide } from 'swiper/react';
import { Content, LicenseType } from '../../../interface';
import { breakpointPodcast } from '../../Utilities/swiperBreakpoint';
import { ContentCollectionView } from '../contentCover';

interface PodcastSlideViewProps {
    podcasts: Content[];
    licenseType: LicenseType;
}

const PodcastSlideView = (props: PodcastSlideViewProps) => {
    return (
        <Swiper breakpoints={breakpointPodcast} spaceBetween={16}>
            {props.podcasts.map((el, index) => (
                <SwiperSlide key={index} className='podcast-swiper'>
                    <ContentCollectionView
                        content={el as Content}
                        licenseType={props.licenseType}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default PodcastSlideView;
