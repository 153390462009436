import './tag.scss';

interface TagProps {
  fileType: string;
}

const TagFileType = (props: TagProps) => {
  const renderTag = () => {
    switch (props.fileType) {
      case 'pdf':
        return <div className="tag pdf">{`pdf`}</div>;
      case 'epub':
        return <div className="tag epub">{`e-pub`}</div>;
    }
  };
  return <>{renderTag()}</>;
};

export default TagFileType;
