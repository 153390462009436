import { Row } from 'antd';
import { LicenseType } from '../../../interface';
import EbookDetailNumber from './EbookDetailNumber';
import { useIntl } from 'react-intl';
import { ContentDetail } from '../../../interface/detailPage';

export interface ConcurrentViewParam {
    content: ContentDetail;
    remainConcurrent: number;
    waitingAmount: number;
}

export const ConcurrentView = (props: ConcurrentViewParam) => {
    const content = props.content;
    const intl = useIntl();

    // content.licenseType === LicenseType.Free ||
    // (!content.remainConcurrent && content.remainConcurrent !== 0)

    return (
        <Row gutter={[0, 0]} justify='center'>
            {content.licenseType === LicenseType.Free || content.concurrent === undefined ? (
                <>
                    <EbookDetailNumber
                        title={intl.formatMessage({ id: 'Book.Balance' })}
                        number={intl.formatMessage({ id: 'Book.Unlimited' })}
                    />
                    <EbookDetailNumber
                        title={intl.formatMessage({ id: 'Book.BorrowedText' })}
                        number={`${content.totalRent}`}
                    />
                </>
            ) : (
                <>
                    <EbookDetailNumber
                        title={intl.formatMessage({ id: 'Book.Balance' })}
                        number={`${content.remainConcurrent}`}
                        disable={content.remainConcurrent === 0 ? true : false}
                    />
                    <EbookDetailNumber
                        title={intl.formatMessage({ id: 'Book.Total' })}
                        number={`${content.concurrent}`}
                    />
                    <EbookDetailNumber
                        title={intl.formatMessage({ id: 'Book.Queue' })}
                        number={`${content.waitingAmount}`}
                    />
                    <EbookDetailNumber
                        title={intl.formatMessage({ id: 'Book.BorrowedBtn' })}
                        number={`${content.totalRent}`}
                    />
                </>
            )}
        </Row>
    );
};
