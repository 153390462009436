import { useEffect, useState, useRef } from 'react';
import { Button, Slider } from 'antd';
import { PodcastContent, PodcastEpisode, RentalStatus } from '../../../interface/home';
import { RentBtn } from '../../customUI/RentButton';
import SVG from 'react-inlinesvg';
import Hls from 'hls.js';

interface PodcastPlayerParam {
    podcast: PodcastContent;
    episode?: PodcastEpisode;
    playlist?: string;
    autoPlaying: boolean;
    isPlaying: boolean;
    playDidChange: (isPlaying: boolean) => void;
    goBack: (e: PodcastEpisode) => void;
    goNext: (e: PodcastEpisode) => void;
}

export const PodcastPlayerView = (props: PodcastPlayerParam) => {
    const audioPlayerRef = useRef(null);
    const [audioPlayer, setAudioPlayer] = useState<any>();
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [speedRate, setSpeedRate] = useState(0);

    useEffect(() => {
        setCurrentTime(0);
    }, []);

    useEffect(() => {
        if (audioPlayer && props.playlist) {
            if (props.isPlaying) {
                audioPlayer.play();
            } else {
                audioPlayer.pause();
            }
        }
    }, [props]);

    useEffect(() => {
        setAudioPlayer(audioPlayerRef.current);
    }, [audioPlayerRef]);

    useEffect(() => {
        if (audioPlayer) {
            audioPlayer.onloadeddata = () => {
                setDuration(audioPlayer.duration);
            };
            audioPlayer.ontimeupdate = () => {
                setCurrentTime(audioPlayer.currentTime);
            };
            // audioPlayer.onprogress = () => {};
            // audioPlayer.onwaiting = () => {};
        }
    }, [audioPlayer]);

    useEffect(() => {
        if (audioPlayer) {
            audioPlayer.playbackRate = speedRatePreset[speedRate].rate;
        }
    }, [speedRate]);

    const prevDidClick = (episode: PodcastEpisode) => {
        props.goBack(episode);
    };

    const nextDidClick = (episode: PodcastEpisode) => {
        props.goNext(episode);
    };

    const speedRateBtnAction = () => {
        if (speedRate < speedRatePreset.length - 1) {
            setSpeedRate(speedRate + 1);
        } else {
            setSpeedRate(0);
        }
    };

    const sliderOnChange = (value: number) => {
        setCurrentTime(value);
        audioPlayer.currentTime = value;
    };

    return (
        <div className='podcast-player'>
            <div>
                <h2>{props.episode?.title ?? props.podcast.title}</h2>
            </div>
            <div className='media-button-container'>
                <div className='list-button-left'>
                    {props.podcast.rentalStatus === RentalStatus.Rented ? (
                        <Button
                            className='player-button'
                            onClick={() => props.playDidChange(!props.isPlaying)}
                            icon={
                                <SVG
                                    src={
                                        props.isPlaying
                                            ? '/images/player/pause.svg'
                                            : '/images/player/play.svg'
                                    }
                                />
                            }
                        />
                    ) : (
                        <RentBtn
                            customBtn={
                                <Button
                                    className='player-button'
                                    icon={<SVG src='/images/player/play.svg' />}
                                />
                            }
                            content={props.podcast}
                            size='small'
                            contentType={props.podcast.contentType}
                            status={props.podcast.rentalStatus!}
                            licenseType={props.podcast.licenseType}
                        />
                    )}
                </div>
                <div className='list-button-right'>
                    <Button
                        className='player-button'
                        onClick={speedRateBtnAction}
                        disabled={props.podcast.rentalStatus !== RentalStatus.Rented}
                    >
                        {speedRatePreset[speedRate].title}
                    </Button>
                    <Button
                        className='player-button'
                        disabled={props.podcast.rentalStatus !== RentalStatus.Rented}
                        icon={<SVG src='/images/player/backTrack.svg' />}
                        onClick={() => {
                            if (props.episode) prevDidClick(props.episode);
                        }}
                    />
                    <Button
                        className='player-button'
                        disabled={props.podcast.rentalStatus !== RentalStatus.Rented}
                        icon={<SVG src='/images/player/nextTrack.svg' />}
                        onClick={() => {
                            if (props.episode) nextDidClick(props.episode);
                        }}
                    />
                </div>
            </div>

            <div className='media-slider-container'>
                <Slider
                    className='player-slider'
                    defaultValue={0}
                    value={currentTime}
                    tooltipVisible={false}
                    max={duration}
                    min={0}
                    onChange={sliderOnChange}
                />
                <div className='media-time'>
                    <p className='time'>{dateConvert(currentTime)}</p>
                    <p className='time'>-{dateConvert(duration - currentTime)}</p>
                </div>
            </div>
            <audio ref={audioPlayerRef}>
                {props.playlist ? (
                    <HLSSource type='audio/mpeg' source={audioPlayer} src={props.playlist} />
                ) : null}
            </audio>
        </div>
    );
};

const HLSSource = (props: any) => {
    useEffect(() => {
        const hls = new Hls();
        const { src, source } = props;
        if (Hls.isSupported()) {
            hls.loadSource(src);
            hls.attachMedia(source);
        }
    }, [props.source, props.src]);
    return <source src={props.src} type={props.type || 'application/x-mpegURL'} />;
};

const speedRatePreset = [
    { title: '1X', rate: 1 },
    { title: '1.5X', rate: 1.5 },
    { title: '2X', rate: 2 },
    { title: '2.5X', rate: 2.5 },
    { title: '3X', rate: 3 }
];

const dateConvert = (second: number) => {
    return new Date(second * 1000).toISOString().substr(11, 8).replace(/^00:/, '');
};
