const CookiePolicy = () => {
    return (
        <div style={{ color: 'var(--title)' }}>
            <h1>นโยบายการใช้คุกกี้</h1>

            <p>
                บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด ใช้คุกกี้บนเว็บไซต์ www.hytexts.com
                (“เว็บไซต์”) เพื่อเป็นเครื่องมือช่วยให้บริการแก่ท่านโดยคุกกี้ประเภทนี้มีความจำเป็น
                ซึ่งจะทำให้ท่านสามารถเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของเราได้ทุกส่วน
                ในขณะที่คุกกี้บางประเภทใช้สำหรับจัดเก็บข้อมูล (สถิติ)
                เพื่อนำผลลัพธ์ดังกล่าวไปใช้ในการปรับปรุงเว็บไซต์ให้ตอบสนองความต้องการของทุกท่าน
                คุกกี้บางประเภทเป็นคุกกี้ที่อยู่แค่ช่วงเวลาหนึ่งจะถูกลบโดยอัตโนมัติเมื่อคุณปิดเบราว์เซอร์ในขณะที่คุกกี้บางตัวจะคงอยู่ในคอมพิวเตอร์ของท่านไปอีกระยะหนึ่ง
                นอกจากนี้ บริษัทยังมีการใช้งาน Local
                คุกกี้ซึ่งเป็นคุกกี้ที่มาพร้อมกับโฆษณาซึ่งจะจดจำการตั้งค่าของท่านในการเข้าใช้งานหน้าเว็บไซต์
                เพื่อนำเสนอโฆษณาที่เหมาะสมกับทุกท่านมากที่สุด
            </p>

            <p>
                บริษัทมีความจำเป็นต้องใช้คุกกี้บางประเภทเพื่อให้เว็บไซต์สามารถใช้การได้
                และมีคุกกี้บางส่วนที่จะเพื่อนำไปปรับปรุงการทำงานของเว็บไซต์
                เพื่อให้สามารถบริหารจัดการสิ่งต่าง ๆ ที่คุณสนใจได้
            </p>

            <div>
                <h3>คุกกี้ที่จำเป็นต้องมี จะถูกใช้งานเพื่อ</h3>
                <ul>
                    <li>จดจำสิ่งที่ท่านเลือกซื้อในตะกร้าสินค้า</li>
                    <li>จดจำระยะเวลาการสั่งซื้อสินค้าของท่าน</li>
                </ul>
            </div>

            <div>
                <h3>คุกกี้สำหรับการใช้งาน จะถูกใช้งานเพื่อ</h3>
                <ul>
                    <li>จดจำรายละเอียดการเข้าสู่ระบบของท่าน</li>
                    <li>รักษาความปลอดภัยให้ท่านเมื่อเข้าสู่ระบบ</li>
                    <li>เพิ่มความทำให้มั่นใจในการเข้าใช้งานเว็บไซต์</li>
                    <li>ทำให้มั่นใจว่าเว็บไซต์มีความต่อเนื่อง</li>
                </ul>
            </div>

            <div>
                <h3>คุกกี้ปฏิบัติการ จะถูกใช้งานเพื่อ</h3>
                <ul>
                    <li>ปรับปรุงการทำงานของเว็บไซต์</li>
                    <li> ปรับปรุงการให้บริการแก่ผู้ใช้งาน</li>
                </ul>
            </div>

            <div>
                <h3>คุกกี้เป้าหมาย จะถูกใช้งานเพื่อ</h3>
                <ul>
                    <li>ให้ท่านแสดงความคิดเห็นต่อข้อมูลที่อยู่บนเว็บไซต์</li>
                    <li>
                        สามารถส่งต่อข้อมูลให้แก่เว็บไซต์อื่นๆ เพื่อปรับปรุงโฆษณา
                        ตามความต้องการของเว็บไซต์
                    </li>
                </ul>
            </div>

            <div>
                <h3>คุกกี้จะถูกใช้งานเพื่อพัฒนาการให้บริการแก่ท่าน โดยมีตัวอย่างดังต่อไปนี้</h3>
                <ul>
                    <li>
                        เพื่อให้บริการบางประเภท เช่น การชำระเงินได้
                        หากไม่มีคุกกี้บริการนี้ก็ไม่สามารถใช้งานได้
                    </li>
                    <li>เพื่อทำให้เว็บไซต์สามารถจดจำอุปกรณ์ของผู้ใช้งานได้</li>
                    <li>
                        จดจำเมื่อคุณเข้าใช้งานเว็บไซต์
                        โดยไม่ต้องใส่รหัสผ่านซ้ำอีกครั้งเมื่อเข้าใช้งานเว็บไซต์อีกครั้ง
                    </li>
                    <li>
                        คอยประมวลผลทุกคนที่เข้ามาเยี่ยมชมเว็บไซต์
                        โดยนำข้อมูลดังกล่าวมาไปเพื่อพัฒนาเว็บไซต์ให้ตอบสนองความต้องการของผู้เยี่ยมชม
                    </li>
                    <li>
                        วิเคราะห์รูปแบบพฤติกรรมของผู้เยี่ยมชมเพื่อพัฒนาเว็บไซต์ให้ตอบสนองความต้องการของผู้เยี่ยมชมต่อไป
                    </li>
                </ul>
            </div>

            <p>
                เรียนทุกท่านที่เข้ามาใช้งานเว็บไซต์ หากไม่ใช้คุกกี้ระหว่างที่ท่านเข้าใช้งาน
                บริการหรือหน้าต่างๆบนเว็บไซต์อาจทำงานได้ไม่เต็มประสิทธิภาพตามที่ท่านต้องการ เช่น
                ท่านจะไม่สามารถใช้บริการการเลือกสินค้าได้และบริษัทจะไม่สามารถแจ้งเตือนท่านเกี่ยวกับสินค้าที่ท่านอาจสนใจตามประสบการณ์การเลือกซื้อสินค้าที่ผ่านมาของท่านได้
            </p>

            <p>
                หากต้องการลบคุกกี้ในคอมพิวเตอร์ ท่านใช้คำสั่งโดยกดที่คำว่า “ช่วยเหลือ”
                ในรายการเบราว์เซอร์ของท่าน
            </p>

            <p>
                นอกจากนี้ ยังสามารถศึกษาเกี่ยวกับคุกกี้เพิ่มเติม
                รวมถึงวิธีการลบและควบคุมคุกกี้ได้ที่{' '}
                <a href='https://www.aboutcookies.org/'>www.aboutcookies.org</a> หรือโดยกดที่คำว่า
                “ช่วยเหลือ” ในหน้ารายการเบราว์เซอร์ของท่าน
            </p>
        </div>
    );
};

export default CookiePolicy;
