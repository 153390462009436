import { Modal, ModalProps } from 'antd';
import SVG from 'react-inlinesvg';
import './rightSubjectModal.scss';

interface SuccessModalProps {
    visible: boolean;
    onClose: () => void;
    onClearForm: () => void;
}

const SuccessModal = (props: SuccessModalProps) => {
    const modalProps: ModalProps = {
        open: props.visible,
        onCancel: props.onClose,
        footer: null,
        title: null,
        closeIcon: <SVG src='/images/icon/subjectRights/closeIcon.svg' />,
        className: 'successSubjectRightModal',
        width: 311,
        centered: true,
        afterClose: props.onClearForm
    };

    return (
        <Modal {...modalProps}>
            <SVG src='/images/icon/subjectRights/successIcon.svg' />
            <h1>คุณส่งข้อมูลเรียบร้อย!</h1>
        </Modal>
    );
};

export default SuccessModal;
