import { Tooltip } from 'antd';
import { Content } from '../../../interface';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getDetailRoute, getWriterRoute } from '../../routes/generatePath';
import { ImageCover } from '../contentCover/imageCover';
import { Link } from 'react-router-dom';
import './index.scss';

interface ContentWithDetailProps {
    contents: Content[];
}

const ContentWithDetailView = (props: ContentWithDetailProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 3.2,
            slidesPerGroup: 3.2
        },
        576.98: {
            slidesPerView: 5.2,
            slidesPerGroup: 5.2
        },
        768.98: {
            slidesPerView: 7.2,
            slidesPerGroup: 7.2
        }
    };

    return (
        <Swiper className='contents-container' spaceBetween={16} breakpoints={breakpoint}>
            {props.contents.map((el, index) => (
                <SwiperSlide className='content-cover' key={index}>
                    <ImageCover
                        coverImage={el.coverImage}
                        fileType={el.fileType}
                        path={getDetailRoute(el.contentUID, el.contentType, el.licenseType)}
                    />
                    <div className='detail'>
                        <Tooltip title={el.title}>
                            <Link
                                className='title-text'
                                to={getDetailRoute(el.contentUID, el.contentType, el.licenseType)}
                            >
                                {el.title}
                            </Link>
                        </Tooltip>
                        <Tooltip title={el.writerName || el.authorName}>
                            {el.writerUID ? (
                                <Link
                                    to={getWriterRoute(
                                        el.writerUID,
                                        undefined,
                                        el.licenseType,
                                        el.contentType
                                    )}
                                    className='sub-text'
                                >
                                    {el.writerName || el.authorName}
                                </Link>
                            ) : (
                                <span className='sub-text'>{el.writerName || el.authorName}</span>
                            )}
                        </Tooltip>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ContentWithDetailView;
