import { useDispatch } from 'react-redux';
import { AuthPageType, ClientInfo, LoginResponse } from '../../../../interface';
import { SetStateAction, useEffect } from 'react';
import { actions } from '../../../redux/auth/authRedux';
import { Button } from 'antd';
import { checkRedirect } from '../../../Utilities/validateText';
import { useIntl } from 'react-intl';
import Lottie from 'lottie-react';
import successLoading from '../../../assets/lotties/1127-success.json';

interface SuccessProps {
  pageType?: AuthPageType;
  clientInfo: ClientInfo;
  userTokenRegistered?: LoginResponse;
  setPageType?: React.Dispatch<SetStateAction<AuthPageType>>;
  onCloseModal?: () => void;
}

const Success = (props: SuccessProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    return () => {
      dispatch(actions.setPrefixRegisterToken(undefined));
    };
  }, []);

  const handleClick = () => {
    switch (props.pageType) {
      case AuthPageType.SuccessChangeEmail:
      case AuthPageType.SuccessChangePassword:
        if (props.userTokenRegistered) {
          dispatch(actions.login(props.userTokenRegistered.userToken));
        }
        if (props.onCloseModal) props.onCloseModal();
        break;
      case AuthPageType.SuccessForgotPassword:
        if (props.setPageType) props.setPageType(AuthPageType.Login);
        break;
      default:
        if (props.userTokenRegistered) {
          checkRedirect(
            props.userTokenRegistered.userToken,
            props.userTokenRegistered.code
          );
          dispatch(actions.login(props.userTokenRegistered.userToken));
          if (props.onCloseModal) props.onCloseModal();
        }
        break;
    }
  };

  const buttonTitle = () => {
    switch (props.pageType) {
      case AuthPageType.SuccessChangeEmail:
      case AuthPageType.SuccessChangePassword:
      case AuthPageType.SuccessForgotPassword:
        return intl.formatMessage({ id: 'Action.ConfirmBtn' });
      case AuthPageType.SuccessRegister:
        return intl.formatMessage({ id: 'SignIn.SignInBtn' });
      default:
        return intl.formatMessage({ id: 'Action.ConfirmBtn' });
    }
  };

  return (
    <div className="div-success">
      <Lottie animationData={successLoading} className="lottie" />
      <div className="div-text">ดำเนินการสำเร็จ</div>
      {/* {props.verifyOtpType !== undefined &&
            [
                SendEmailForOtpType.ChangeEmail,
                SendEmailForOtpType.ChangePassword,
                SendEmailForOtpType.ForgotPassword
            ].includes(props.verifyOtpType) ? (
                <Button
                    className='success-button'
                    onClick={() => {
                        if (props.userTokenRegistered) {
                            dispatch(actions.login(props.userTokenRegistered.userToken));
                        }
                        if (props.onCloseModal) props.onCloseModal();
                    }}
                >
                    ตกลง
                </Button>
            ) : (
                <Button
                    className='success-button'
                    onClick={() => {
                        if (props.userTokenRegistered) {
                            checkRedirect(props.userTokenRegistered.userToken);
                            dispatch(actions.login(props.userTokenRegistered.userToken));
                            if (props.onCloseModal) props.onCloseModal();
                        }
                    }}
                >
                    ลงชื่อเข้าใช้งาน
                </Button>
            )} */}

      <Button className="success-button" onClick={() => handleClick()}>
        {buttonTitle()}
      </Button>
    </div>
  );
};

export default Success;
