import { Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { EmptyDataView } from '../../components/nodata';
import { ComponentLoading, Loading } from '../../customUI/loading';
import { removeSplash } from '../../helpers/removeSplash';
import { action } from '../../redux/request';
import { getCatalogContents, voteEbook } from '../../redux/voteEbook/request';
import { onErrorImage } from '../../Utilities/imageUtilities';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
    CatalogContentResponse,
    CatalogVotePayload,
    VoteAction
} from '../../../interface/voteEbook';
import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';
import VoteEbookDetailModal from '../../components/modal/voteEbookModal/DetailModal';
import AlertVoteContentModal from '../../components/modal/voteEbookModal/AlertVoteModal';
import './index.scss';
import { FormattedMessage, useIntl } from 'react-intl';

interface CatalogContentsProps {
    catalogUID: string;
}

interface CatalogsContentState {
    contentUID: string;
    concurrent: number;
    selectedContent: number;
    buttonLoading: boolean;
    detailModal: boolean;
    limitModal: boolean;
    alertModal: boolean;
    successModal: boolean;
}

const CatalogContents = (props: CatalogContentsProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const intl = useIntl();
    const queryParams = new URLSearchParams(location.search);
    const [content, setContent] = useState<CatalogContentResponse>();
    const [componentState, setComponentState] = useState<CatalogsContentState>({
        contentUID: '',
        concurrent: 0,
        selectedContent: 0,
        buttonLoading: false,
        detailModal: false,
        limitModal: false,
        alertModal: false,
        successModal: false
    });
    const [votePayload, setVotePayload] = useState<CatalogVotePayload>({
        catalogUID: props.catalogUID,
        listContents: []
    });

    require('dayjs/locale/th');
    const isEdit = queryParams.get('edit') === 'true';
    const dateFormat = 'DD MMMM YYYY';
    const dateTimeFormat = `DD MMMM YYYY HH:mm ${intl.locale === 'en' ? '' : 'น.'}`;
    const pageID = 'catalogContents';

    const { request, environment } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch,
            environment: state.environment
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchContentList(false);
    }, []);

    useEffect(() => {
        if (environment.endOfScreen && !request.loading) {
            if (content?.lastEvaluatedKey !== undefined) {
                if (!request.loading) {
                    fetchContentList(true);
                }
               
            }
        }
    }, [environment]);

    const fetchContentList = async (isEndScroll: boolean) => {
        try {
            dispatch(action.setProcess(true, pageID));
            const response = await getCatalogContents(
                props.catalogUID,
                isEndScroll ? content?.lastEvaluatedKey : undefined
            );
            if (response) {
                if (isEndScroll && content) {
                    setContent({
                        ...content,
                        contents: content.contents.concat(response.contents),
                        lastEvaluatedKey: response.lastEvaluatedKey
                    });
                } else {
                    setContent(response);
                    setComponentState({
                        ...componentState,
                        concurrent: response.concurrent - response.activeConcurrent,
                        selectedContent: response.activeConcurrent,
                        alertModal: true
                    });
                }
            }
        } finally {
            dispatch(action.setProcess(false, pageID));
            dispatch(removeSplash);
        }
    };

    const onVote = (contentUID: string) => {
        if (content) {
            const contentIndex = content.contents.findIndex((el) => el.contentUID === contentUID);
            const payloadIndex = votePayload.listContents.findIndex(
                (el) => el.contentUID === contentUID
            );
            setComponentState({
                ...componentState,
                concurrent: componentState.concurrent - 1,
                selectedContent: componentState.selectedContent + 1
            });
            if (contentIndex >= 0) {
                const newListContent = content.contents;
                newListContent[contentIndex] = {
                    ...newListContent[contentIndex],
                    isVoted: true
                };
                setContent({
                    ...content,
                    contents: newListContent
                });
            }

            if (payloadIndex >= 0) {
                const newListPayload = votePayload.listContents;
                newListPayload[payloadIndex] = {
                    ...newListPayload[payloadIndex],
                    voteAction: VoteAction.Vote
                };
                setVotePayload({
                    ...votePayload,
                    listContents: newListPayload
                });
            } else {
                setVotePayload({
                    ...votePayload,
                    listContents: [
                        ...votePayload.listContents,
                        {
                            contentUID: contentUID,
                            voteAction: VoteAction.Vote
                        }
                    ]
                });
            }
        }
    };

    const onUnVote = (contentUID: string) => {
        if (content) {
            const contentIndex = content.contents.findIndex((el) => el.contentUID === contentUID);
            const payloadIndex = votePayload.listContents.findIndex(
                (el) => el.contentUID === contentUID
            );
            setComponentState({
                ...componentState,
                concurrent: componentState.concurrent + 1,
                selectedContent: componentState.selectedContent - 1
            });

            if (contentIndex >= 0) {
                const newListContent = content.contents;
                newListContent[contentIndex] = {
                    ...newListContent[contentIndex],
                    isVoted: false
                };
                setContent({
                    ...content,
                    contents: newListContent
                });
            }

            if (payloadIndex >= 0) {
                const newListPayload = votePayload.listContents;
                newListPayload[payloadIndex] = {
                    ...newListPayload[payloadIndex],
                    voteAction: VoteAction.UnVote
                };
                setVotePayload({
                    ...votePayload,
                    listContents: newListPayload
                });
            } else {
                setVotePayload({
                    ...votePayload,
                    listContents: [
                        ...votePayload.listContents,
                        {
                            contentUID: contentUID,
                            voteAction: VoteAction.UnVote
                        }
                    ]
                });
            }
        }
    };

    const VoteContent = async () => {
        if (votePayload && votePayload.listContents.length > 0) {
            try {
                setComponentState({ ...componentState, buttonLoading: true });
                const response = await voteEbook(votePayload);
                if (response) {
                    setComponentState({
                        ...componentState,
                        successModal: true,
                        buttonLoading: false
                    });
                }
            } catch {
                setComponentState({ ...componentState, buttonLoading: false });
            }
        }
    };

    return (
        <div className='catalogEbookContainer'>
            {content ? (
                <>
                    <div className='titleSection'>
                        <div>
                            <h1>{content?.title}</h1>
                            <h4>
                                {dayjs
                                    .unix(content?.startDate ?? 0)
                                    .locale(intl.locale)
                                    .format(dateFormat) +
                                    ' - ' +
                                    dayjs
                                        .unix(content?.endDate ?? 0)
                                        .locale(intl.locale)
                                        .format(dateFormat)}
                            </h4>
                        </div>
                        <p>
                            {intl.formatMessage({
                                id: 'VoteActivity.BalanceVote'
                            })}{' '}
                            : <span>{componentState.concurrent}</span>
                        </p>
                    </div>
                    <div className='contentContainer'>
                        {content?.contents.map((el) => (
                            <div key={el.contentUID} className='contentItem'>
                                <div>
                                    <div className='coverImage'>
                                        <img
                                            src={el.coverImageWebp ?? el.coverImage}
                                            onError={onErrorImage}
                                            onClick={() =>
                                                setComponentState({
                                                    ...componentState,
                                                    contentUID: el.contentUID,
                                                    detailModal: true
                                                })
                                            }
                                        />
                                        {/* {el.isVoted && ( */}
                                        <div
                                            className={
                                                el.isVoted
                                                    ? 'floatItem bounceIn'
                                                    : 'floatItem bounceOut'
                                            }
                                        >
                                            <SVG src='/images/icon/votedIcon.svg' />
                                            <p>
                                                {intl.formatMessage({
                                                    id: 'VoteActivity.Voted'
                                                })}{' '}
                                            </p>
                                        </div>
                                        {/* )} */}
                                    </div>
                                    <div className='detail'>
                                        <Tooltip placement='top' title={el.title}>
                                            <p>{el.title}</p>
                                        </Tooltip>
                                        <p className='authorName'>{el.authorName}</p>
                                    </div>
                                </div>
                                {!el.isVoted ? (
                                    <Button
                                        className='vote'
                                        shape='round'
                                        disabled={componentState.concurrent === 0 ? true : false}
                                        onClick={() => {
                                            if (componentState.concurrent < 1) {
                                                setComponentState({
                                                    ...componentState,
                                                    limitModal: true
                                                });
                                            } else {
                                                onVote(el.contentUID);
                                            }
                                        }}
                                    >
                                        {intl.formatMessage({
                                            id: 'VoteActivity.VoteBtn'
                                        })}
                                    </Button>
                                ) : (
                                    <Button
                                        className='unVote'
                                        shape='round'
                                        onClick={() => onUnVote(el.contentUID)}
                                    >
                                        {intl.formatMessage({
                                            id: 'VoteActivity.CancelVoteBtn'
                                        })}
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                    <VoteEbookDetailModal
                        key={componentState.contentUID}
                        open={componentState.detailModal}
                        contentUID={componentState.contentUID}
                        onClose={() =>
                            setComponentState({
                                ...componentState,
                                detailModal: false
                            })
                        }
                    />
                    <AlertVoteContentModal
                        open={componentState.limitModal}
                        title={intl.formatMessage({
                            id: 'VoteActivity.HaveNotRemain'
                        })}
                        description={
                            <>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: intl
                                            .formatMessage(
                                                {
                                                    id: 'VoteActivity.Description'
                                                },
                                                { 0: content.concurrent ?? 0 }
                                            )
                                            .replace(/\n/g, '<br/>')
                                    }}
                                />
                            </>
                        }
                        onClose={() =>
                            setComponentState({
                                ...componentState,
                                limitModal: false
                            })
                        }
                    />
                    <AlertVoteContentModal
                        open={componentState.alertModal}
                        title={content.title}
                        description={
                            <>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: intl
                                            .formatMessage(
                                                { id: 'VoteActivity.Date' },
                                                {
                                                    0: `<span>${
                                                        dayjs
                                                            .unix(content?.startDate ?? 0)
                                                            .locale(intl.locale)
                                                            .format(dateTimeFormat) +
                                                        ' - ' +
                                                        dayjs
                                                            .unix(content?.endDate ?? 0)
                                                            .locale(intl.locale)
                                                            .format(dateTimeFormat)
                                                    }<span/>`
                                                }
                                            )
                                            .replace(/\n/g, '<br/>')
                                    }}
                                />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: intl
                                            .formatMessage(
                                                {
                                                    id: 'VoteActivity.Description'
                                                },
                                                {
                                                    0: content.concurrent ?? 0
                                                }
                                            )
                                            .replace(/\n/g, '<br/>')
                                    }}
                                />
                            </>
                        }
                        onClose={() =>
                            setComponentState({
                                ...componentState,
                                alertModal: false
                            })
                        }
                    />
                    <AlertVoteContentModal
                        open={componentState.successModal}
                        width={400}
                        description={
                            <>
                                <SVG
                                    src='/images/icon/checkIcon.svg'
                                    width={75}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <h1>
                                    {isEdit
                                        ? intl.formatMessage({
                                              id: 'VoteActivity.ModalVotedSubTitle'
                                          })
                                        : intl.formatMessage({
                                              id: 'VoteActivity.ModalVotedTitle'
                                          })}
                                </h1>
                                <p>
                                    {intl.formatMessage({
                                        id: 'VoteActivity.ModalEditedSubTitle'
                                    })}
                                </p>
                            </>
                        }
                        onClose={() => {
                            setComponentState({
                                ...componentState,
                                successModal: false
                            });
                            history.push('/voteEbook');
                        }}
                    />
                </>
            ) : null}

            <div
                className={
                    componentState.selectedContent > 0 || isEdit
                        ? 'floatMenu fadeIn'
                        : 'floatMenu fadeOut'
                }
            >
                <div className='flexContainer'>
                    <SVG src='/images/icon/vote.svg' />
                    <p>
                        {intl.formatMessage(
                            { id: 'VoteActivity.TotalVote' },
                            { 0: componentState.selectedContent }
                        )}
                    </p>
                </div>
                <Button
                    shape='round'
                    onClick={() => VoteContent()}
                    disabled={votePayload.listContents.length === 0}
                >
                    {componentState.buttonLoading ? (
                        <LoadingOutlined color='#000' />
                    ) : (
                        <FormattedMessage id={'VoteActivity.VoteBtn'} />
                    )}
                </Button>
            </div>

            {request.loading && <ComponentLoading id={pageID} absolute={true} />}
            {(!content || (content && content.contents.length === 0)) && !request.loading ? (
                <EmptyDataView />
            ) : null}
        </div>
    );
};

export default CatalogContents;
