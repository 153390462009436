import { Button } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CatalogMetadata } from '../../../interface/voteEbook';
import { HibraryRootState } from '../../../redux/rootReducer';
import { BreadcrumbLink, BreadCrumbView } from '../../components/breadcrumb';
import { EmptyDataView } from '../../components/nodata';
import { Loading } from '../../customUI/loading';
import { removeSplash } from '../../helpers/removeSplash';
import { action } from '../../redux/request';
import { getCatalogList } from '../../redux/voteEbook/request';
import { onErrorImage } from '../../Utilities/imageUtilities';
import './index.scss';

const VoteEbook = () => {
    require('dayjs/locale/th');
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();
    const [catalogList, setCatalogList] = useState<CatalogMetadata[]>();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
    const { request } = useSelector((state: HibraryRootState) => ({
        request: state.fetch
    }));

    const dateFormat = 'DD MMMM YYYY';
    const pageID = 'voteEbook';

    useEffect(() => {
        setBreadCrumbs([
            { title: intl.formatMessage({ id: 'VoteActivity.Title' }) }
        ]);
        fetchCatalogList();
    }, []);

    const breadcrumbs = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    const fetchCatalogList = async () => {
        try {
            dispatch(action.setProcess(true, pageID));
            const response = await getCatalogList();
            if (response) {
                setCatalogList(response.catalogsPoll);
            }
        } finally {
            dispatch(action.setProcess(false, pageID));
            dispatch(removeSplash);
        }
    };

    return (
        <>
            {breadcrumbs}
            <div className='voteEbookContainer'>
                <h1>{intl.formatMessage({ id: 'VoteActivity.Title' })}</h1>
                {catalogList && !request.loading ? (
                    <div className='contentContainer'>
                        {catalogList.map((el) => (
                            <div className='catalogItem' key={el.uid}>
                                <div
                                    className='coverImage'
                                    style={
                                        el.bgColor
                                            ? { background: el.bgColor }
                                            : { background: 'var(--grey5)' }
                                    }
                                >
                                    <img
                                        src={el.coverImageWebp ?? el.coverImage}
                                        onError={onErrorImage}
                                    />
                                </div>
                                <div className='catalogDetail'>
                                    <h4>{el.title}</h4>
                                    <p>
                                        {' '}
                                        {dayjs
                                            .unix(el.voteStartDate ?? 0)
                                            .locale(intl.locale)
                                            .format(dateFormat) +
                                            ' - ' +
                                            dayjs
                                                .unix(el.voteEndDate ?? 0)
                                                .locale(intl.locale)
                                                .format(dateFormat)}
                                    </p>
                                    <Button
                                        shape='round'
                                        onClick={() => {
                                            if (el.isVoted) {
                                                history.push(
                                                    `/voteEbook/${
                                                        el.uid
                                                    }?edit=${true}`
                                                );
                                            } else {
                                                history.push(
                                                    `/voteEbook/${el.uid}`
                                                );
                                            }
                                        }}
                                        className={
                                            el.isVoted ? `isVoted` : `isVote`
                                        }
                                    >
                                        {el.isVoted
                                            ? intl.formatMessage({
                                                  id: 'VoteActivity.EditVoteBtn'
                                              })
                                            : intl.formatMessage({
                                                  id: 'VoteActivity.JoinVoteBtn'
                                              })}
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}

                {request.loading && <Loading id={pageID} absolute={true} />}
                {(!catalogList || (catalogList && catalogList.length <= 0)) &&
                !request.loading ? (
                    <EmptyDataView />
                ) : null}
            </div>
        </>
    );
};

export default VoteEbook;
