import { shallowEqual, useSelector } from 'react-redux';
import { SplashLoadingState, SplashLoadingStatus } from '../../redux/splashLoading';
import { HibraryRootState } from '../../../redux/rootReducer';

import Lottie from 'lottie-react';
import htiLoading from './hibraryLoading.json';
import cuLoading from './cu-elibraryLoading.json';
import './index.scss';

export const SplashLoading = () => {
   const { splashLoadingState, isCuDomain } = useSelector(
      (state: HibraryRootState) => ({
         splashLoadingState: state.splashLoading as SplashLoadingState,
         isCuDomain: state.auth.isCuDomain
      }),
      shallowEqual
   );

   return (
      <div
         className={`splashLoading${
            splashLoadingState.status === SplashLoadingStatus.AnimateHide
               ? ' animateHideLoading'
               : ''
         }${splashLoadingState.status === SplashLoadingStatus.Hide ? ' hideLoading' : ''}`}
      >
         {splashLoadingState.status === SplashLoadingStatus.AnimateHide ? null : (
            <>
               {isCuDomain ? (
                  <Lottie animationData={cuLoading} className='lottie' />
               ) : (
                  <Lottie animationData={htiLoading} className='lottie' />
               )}
            </>
         )}
      </div>
   );
};
