/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HibraryRootState } from '../../../redux/rootReducer';

interface TabBoxProps {
    isPrivateLib: boolean;
    isAuthToken: boolean;
    role: string;
}

const TabBox = (props: TabBoxProps) => {
    const { isPrivateLib, isAuthToken, role } = props;
    const history = useHistory();
    const intl = useIntl();

    const { isCuDomain } = useSelector(
        (state: HibraryRootState) => ({
            isCuDomain: state.auth.isCuDomain
        }),
        shallowEqual
    );

    const tabData = [
        {
            label: intl.formatMessage({
                id: 'Library.Title'
            }),
            key: '/'
        },
        {
            label: intl.formatMessage({
                id: 'Library.FreePageBtn'
            }),
            key: '/free'
        },
        {
            label: intl.formatMessage({
                id: 'OwnedMedia.Title'
            }),
            key: '/private'
        },
        {
            label: intl.formatMessage({
                id: 'Catalogue.Title'
            }),
            key: '/listEbook'
        },
        {
            label: intl.formatMessage({
                id: 'BookShelf.Title'
            }),
            key: '/bookshelf'
        }
    ];

    const filterTabMenu = () => {
        const tabMenu = tabData.filter((el) => {
            if (
                (!isPrivateLib && el.key === '/private') ||
                (!isAuthToken && el.key === '/bookshelf') ||
                ((role !== 'admin' || isCuDomain) && el.key === '/listEbook')
            ) {
                return false;
            } else {
                return true;
            }
        });

        return tabMenu;
    };

    const handleChangeTab = (path: string) => {
        history.push(path);
    };

    return (
        <div className='tabMenu'>
            <Tabs defaultActiveKey='/' items={filterTabMenu()} onTabClick={handleChangeTab} />
        </div>
    );
};

export default TabBox;
