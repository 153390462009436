import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BreadCrumbView, BreadcrumbLink } from '../../components/breadcrumb';
import { action } from '../../redux/request';
import { removeSplash } from '../../helpers/removeSplash';
import { getListCategoryRoute, getListSubInCategoryRoute } from '../../routes/generatePath';
import { MemoContentCollectionView } from '../../components/contentCover';
import { EmptyDataView } from '../../components/nodata';
import { Loading } from '../../customUI/loading';
import { Helmet } from 'react-helmet-async';
import {
    ContentType,
    LicenseType,
    ListAllContentResponse,
    TitleResponse
} from '../../../interface';
import { HibraryRootState } from '../../../redux/rootReducer';
import {
    getAllEbook,
    getPublisherContent,
    getSubcategoryContent,
    getWriterContent
} from '../../redux/store/storeCrud';
import { useLocation } from 'react-router-dom';

export interface ViewAllContentProps {
    contentType: ContentType;
    licenseType: LicenseType;
    categoryUID?: string;
    subcategoryUID?: string;
    creatorUID?: string;
    publisherUID?: string;
    writerUID?: string;
}

export const ViewAllContent = (props: ViewAllContentProps) => {
    const pageId = 'ViewAllContent';
    const dispatch = useDispatch();
    const location = useLocation();
    const [contentList, setContentList] = useState<ListAllContentResponse>();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
    const [title, setTitle] = useState<string>();

    const { environment, clientInfo, authen, request } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchContent(false);
    }, [location.pathname]);

    useEffect(() => {
        if (
            environment.endOfScreen &&
            contentList?.lastEvaluatedKey !== undefined &&
            !request.loading
        ) {
            fetchContent(true);
        }
    }, [environment]);

    const fetchContent = (appendData = true) => {
        if (props.categoryUID) {
            fetchContentBySubcategory(appendData);
        } else if (props.publisherUID && !props.writerUID) {
            fetchContentByPublisher(appendData);
        } else if (props.writerUID) {
            fetchListContentByWriter(appendData);
        } else {
            fetchAllContent(appendData);
        }
    };

    const fetchAllContent = async (appendData: boolean) => {
        try {
            setTitle('ดูทั้งหมด');
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;
            const response = await getAllEbook(
                authen.isLogin,
                clientInfo.companyPrefix,
                props.licenseType,
                props.contentType,
                lastEvaluatedKey
            );

            if (response) {
                if (appendData && contentList) {
                    if (props.licenseType === LicenseType.Private) {
                        if (props.contentType === ContentType.video) {
                            setContentList({
                                ...contentList,
                                ebooks: contentList?.ebooks?.concat(response.ebooks ?? []) ?? [],
                                lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                            });
                        } else if (props.contentType === ContentType.podcast) {
                            setContentList({
                                ...contentList,
                                podcasts:
                                    contentList?.podcasts?.concat(response.ebooks ?? []) ?? [],
                                lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                            });
                        } else {
                            setContentList({
                                ...contentList,
                                videos: contentList?.videos?.concat(response.ebooks ?? []) ?? [],
                                lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                            });
                        }
                    } else {
                        setContentList({
                            ...contentList,
                            contents: contentList?.contents.concat(response.contents) ?? [],
                            lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                        });
                    }
                } else {
                    setContentList(response);
                }
            }
        } finally {
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        }
    };

    const fetchContentBySubcategory = async (appendData: boolean) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;
            const response = await getSubcategoryContent(
                authen.isLogin,
                clientInfo.companyPrefix,
                props.licenseType,
                props.contentType,
                props.categoryUID ?? '',
                props.subcategoryUID ?? '',
                lastEvaluatedKey as string
            );

            if (response) {
                if (appendData && contentList) {
                    setContentList({
                        ...contentList,
                        contents: contentList.contents.concat(response.contents),
                        lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                    });
                } else {
                    if (props.licenseType === LicenseType.Private) {
                        setBreadCrumbs([
                            {
                                title: 'หมวดหมู่',
                                path: getListCategoryRoute(props.licenseType, props.contentType)
                            },
                            {
                                title: (response.title as TitleResponse).catName,
                                path: getListSubInCategoryRoute(
                                    props.categoryUID ?? '',
                                    props.licenseType,
                                    props.contentType
                                )
                            },
                            {
                                title: (response.title as TitleResponse).subcatName
                            }
                        ]);
                        setTitle((response.title as TitleResponse).subcatName);
                    } else {
                        setBreadCrumbs([
                            {
                                title: 'หมวดหมู่',
                                path: getListCategoryRoute(props.licenseType, props.contentType)
                            },
                            {
                                title: response.title as string,
                                path: getListSubInCategoryRoute(
                                    props.categoryUID ?? '',
                                    props.licenseType,
                                    props.contentType
                                )
                            },
                            {
                                title: response.subtitle as string
                            }
                        ]);
                        setTitle(response.subtitle as string);
                    }
                    setContentList(response);
                }
            }
        } finally {
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        }
    };

    const fetchListContentByWriter = async (appendData: boolean) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;
            const response = await getWriterContent(
                authen.isLogin,
                clientInfo.companyPrefix,
                props.licenseType,
                props.writerUID ?? '',
                props.creatorUID ?? '',
                lastEvaluatedKey as string
            );

            if (response) {
                if (appendData && contentList) {
                    setContentList({
                        ...contentList,
                        contents: contentList.contents.concat(response.contents),
                        lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                    });
                } else {
                    setBreadCrumbs([{ title: response.title as string }]);
                    setTitle(`อีบุ๊กของ ${response.title}`);
                    setContentList(response);
                }
            }
        } finally {
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        }
    };

    const fetchContentByPublisher = async (appendData: boolean) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData ? contentList?.lastEvaluatedKey : undefined;
            const response = await getPublisherContent(
                authen.isLogin,
                clientInfo.companyPrefix,
                props.licenseType,
                props.publisherUID ?? '',
                props.contentType,
                lastEvaluatedKey as string
            );

            if (response) {
                if (appendData && contentList) {
                    setContentList({
                        ...contentList,
                        contents: contentList.contents.concat(response.contents),
                        lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                    });
                } else {
                    setTitle(response.title as string);
                    setBreadCrumbs([
                        {
                            title: response.title as string
                        }
                    ]);
                    setContentList(response);
                }
            }
        } finally {
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        }
    };

    const generateClassName = () => {
        switch (props.contentType) {
            case ContentType.ebook:
                return `content-list-container-ebook`;
            case ContentType.video:
                return `content-list-container-video`;
            case ContentType.podcast:
                return `content-list-container-podcast`;
            default:
                return `content-list-container-ebook`;
        }
    };

    const breadcrumbs = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    return (
        <>
            {breadcrumbs}
            <div className='store view-all-content'>
                <Helmet>
                    <title>
                        {window.location.hostname.match(/cu-elibrary.com/)
                            ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                            : 'Hibrary'}{' '}
                        | {title || 'All E-book'}
                    </title>
                </Helmet>
                <h1 className='viewAllTitle'>{title || 'All E-book'}</h1>
                {contentList && contentList.contents?.length > 0 && (
                    <div className='content-list'>
                        <div className={generateClassName()}>
                            {contentList.contents?.map((el, index) => (
                                <div key={index}>
                                    <MemoContentCollectionView
                                        content={el}
                                        licenseType={props.licenseType}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {contentList && (contentList.ebooks?.length || 0) > 0 && (
                    <div className='content-list'>
                        <div className={generateClassName()}>
                            {contentList.ebooks?.map((el, index) => (
                                <div key={index}>
                                    <MemoContentCollectionView
                                        content={el}
                                        licenseType={props.licenseType}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {contentList && (contentList.videos?.length || 0) > 0 && (
                    <div className='content-list'>
                        <div className={generateClassName()}>
                            {contentList.videos?.map((el, index) => (
                                <div key={index}>
                                    <MemoContentCollectionView
                                        content={el}
                                        licenseType={props.licenseType}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {contentList && (contentList.podcasts?.length || 0) > 0 && (
                    <div className='content-list'>
                        <div className={generateClassName()}>
                            {contentList.podcasts?.map((el, index) => (
                                <div key={index}>
                                    <MemoContentCollectionView
                                        content={el}
                                        licenseType={props.licenseType}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <Loading id={pageId} />
                {!request.loading && !contentList && <EmptyDataView />}
            </div>
        </>
    );
};
