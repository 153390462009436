import { Progress } from 'antd';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useIntl } from 'react-intl';

interface PercentReadingProgressProps {
    percent: number;
}

const ReadingPercentBox = (props: PercentReadingProgressProps) => {
    const intl = useIntl();
    return (
        <div>
            <div className='flex align-center gap-8'>
                <p>
                    {intl.formatMessage({ id: 'ReadingStat.ReadingPercent' })}
                </p>
                <SVG src='/images/reading-stat/check.svg' />
            </div>
            <div className='flex progress-percent-reading justify-center align-center'>
                <Progress
                    type='circle'
                    percent={Math.floor(props.percent * 100)}
                    strokeWidth={8}
                    width={150}
                    trailColor='#fff'
                    format={(percent) =>
                        (percent ?? 0) < 100 ? `${percent}%` : '100%'
                    }
                />
            </div>
        </div>
    );
};

export default ReadingPercentBox;
