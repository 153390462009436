import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import './index.scss';
import { onErrorImage } from '../../Utilities/imageUtilities';
import { SponsorBannerMetaData } from '../../../interface/sponsor';

interface BannerProps {
    banners: SponsorBannerMetaData[];
}

const SponsorBanner = (props: BannerProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 1
        },
        576.98: {
            slidesPerView: 3
        }
    };

    return (
        <div className='sponsorBanner'>
            <Swiper
                breakpoints={breakpoint}
                spaceBetween={16}
                centeredSlides={true}
                loop={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
            >
                {props.banners.map((el, index) => (
                    <SwiperSlide key={index} className='itemContainer'>
                        <Link to={`/sponsor/sets/${el.setUID}`}>
                            <img src={el.coverImage} onError={onErrorImage} />
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default SponsorBanner;
