import { Helmet } from 'react-helmet-async';
import { action } from '../../redux/request';
import { Loading } from '../../customUI/loading';
import { CategoryView } from '../../components/category';
import { removeSplash } from '../../helpers/removeSplash';
import { HibraryRootState } from '../../../redux/rootReducer';
import { BreadCrumbView, BreadcrumbLink } from '../../components/breadcrumb';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ContentType, LicenseType } from '../../../interface/home';
import { AllCategoryResponse } from '../../../interface/request';
import { EmptyDataView } from '../../components/nodata';
import { getAllCategory } from '../../redux/store/storeCrud';
import './index.scss';

export interface CategoriesPageProps {
    licenseType: LicenseType;
    contentType: ContentType;
}

const CategoryPage = (props: CategoriesPageProps) => {
    const pageId = 'CategoriesPage';
    const dispatch = useDispatch();
    const [categories, setCategories] = useState<AllCategoryResponse>();
    const [breadcrumb, setBreadCrumb] = useState<BreadcrumbLink[]>();
    const { clientInfo, authen, request } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchCategories();
        setBreadCrumb([
            {
                title: 'หมวดหมู่'
            }
        ]);
    }, []);

    const fetchCategories = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const data = await getAllCategory(
                authen.isLogin,
                clientInfo.companyPrefix,
                props.licenseType,
                props.contentType
            );
            setCategories(data);
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } finally {
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        }
    };

    return (
        <>
            <BreadCrumbView addLinks={breadcrumb} />
            <div className='store view-all-content'>
                <Helmet>
                    <title>
                        {window.location.hostname.match(/cu-elibrary.com/)
                            ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                            : 'Hibrary'}{' '}
                        | หมวดหมู่
                    </title>
                </Helmet>

                <h1 className='viewAllTitle'>{'หมวดหมู่'}</h1>
                <div className='content-list list-row-categories'>
                    {categories?.categories.map((e, index) => (
                        <CategoryView
                            key={index}
                            category={e}
                            licenseType={props.licenseType}
                            contentType={props.contentType}
                        />
                    ))}
                </div>
                <Loading id={pageId} />
                {!request.loading && !categories ? <EmptyDataView /> : null}
            </div>
        </>
    );
};

export default CategoryPage;
