import { Link } from 'react-router-dom';
import { DetailViewParam } from '../../../interface/detailPage';
import { useIntl } from 'react-intl';
import { getPublisherRoute } from '../../routes/generatePath';
import { ContentType, LicenseType } from '../../../interface';

const PublisherView = (props: DetailViewParam) => {
    const content = props.content;
    const intl = useIntl();

    const handleLinkPublisher = () => {
        switch (content.licenseType) {
            case LicenseType.Private:
                return getPublisherRoute(
                    content.creatorUID,
                    content.licenseType,
                    content.contentType
                );

            default:
                return getPublisherRoute(
                    content.publisherUID,
                    content.licenseType,
                    content.contentType
                );
        }
    };

    const renderLink = () => {
        if (
            content.contentType === ContentType.newspaper ||
            content.contentType === ContentType.magazine
        )
            return <span>{content.publisherName}</span>;
        else return <Link to={handleLinkPublisher()}>{content.publisherName}</Link>;
    };

    return (
        <>
            {intl.formatMessage({ id: 'Book.Publisher' }) + ' : '}
            {renderLink()}
        </>
    );
};

export default PublisherView;
