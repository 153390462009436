import { OtpPassword, OtpRegister } from '../../../interface';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

enum VerifyOtpActionType {
    InitRegisterOTP = '[OTP] initRegisterOTP',
    ResetRegisterOTP = '[OTP] resetRegisterOTP',
    IntiForgotPasswordOTP = '[OTP] initForgotPasswordOTP',
    ResetForgotPasswordOTP = '[OTP] ResetForgotPasswordOTP',
    IntiChangePasswordOTP = '[OTP] initChangePasswordOTP',
    ResetChangePasswordOTP = '[OTP] ResetChangePasswordOTP',
    IntiChangeEmailOTP = '[OTP] initChangeOTP',
    ResetChangeEmailOTP = '[OTP] ResetChangeEmailOTP'
}

export interface VerifyOTPState {
    registerOTP?: OtpRegister;
    forgotPasswordOTP?: OtpPassword;
    changePasswordOTP?: OtpPassword;
}

const initialState: VerifyOTPState = {
    registerOTP: undefined,
    forgotPasswordOTP: undefined,
    changePasswordOTP: undefined
};

interface VerifyOTPAction {
    type: VerifyOtpActionType;
    payload?: OtpRegister | OtpPassword;
}

export const reducer = persistReducer(
    {
        storage,
        key: 'hibrary-otp',
        whitelist: ['forgotPasswordOTP', 'registerOTP', 'changePasswordOTP']
    },
    (state: VerifyOTPState = initialState, action: VerifyOTPAction): VerifyOTPState => {
        switch (action.type) {
            case VerifyOtpActionType.InitRegisterOTP: {
                const otp = action.payload as OtpRegister;
                return {
                    ...state,
                    registerOTP: otp
                };
            }
            case VerifyOtpActionType.ResetRegisterOTP:
                return {
                    ...state,
                    registerOTP: undefined
                };
            case VerifyOtpActionType.IntiForgotPasswordOTP: {
                const otp = action.payload as OtpPassword;
                return {
                    ...state,
                    forgotPasswordOTP: otp
                };
            }
            case VerifyOtpActionType.ResetForgotPasswordOTP:
                return {
                    ...state,
                    forgotPasswordOTP: undefined
                };
            case VerifyOtpActionType.IntiChangePasswordOTP: {
                const otp = action.payload as OtpPassword;
                return {
                    ...state,
                    changePasswordOTP: otp
                };
            }
            case VerifyOtpActionType.ResetChangePasswordOTP:
                return {
                    ...state,
                    changePasswordOTP: undefined
                };
            default:
                return state;
        }
    }
);

export const VerifyOTPAction = {
    initRegisterOTP: (payload: OtpRegister): VerifyOTPAction => ({
        type: VerifyOtpActionType.InitRegisterOTP,
        payload: payload
    }),
    resetRegisterOTP: (): VerifyOTPAction => ({
        type: VerifyOtpActionType.ResetRegisterOTP
    }),
    intiForgotPasswordOTP: (payload: OtpPassword): VerifyOTPAction => ({
        type: VerifyOtpActionType.IntiForgotPasswordOTP,
        payload: payload
    }),
    resetForgotPasswordOTP: (): VerifyOTPAction => ({
        type: VerifyOtpActionType.ResetForgotPasswordOTP
    }),
    initChangePasswordOTP: (payload: OtpPassword): VerifyOTPAction => ({
        type: VerifyOtpActionType.IntiChangePasswordOTP,
        payload: payload
    }),
    resetChangePasswordOTP: (): VerifyOTPAction => ({
        type: VerifyOtpActionType.ResetChangePasswordOTP
    })
};
