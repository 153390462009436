import { Radio, Space } from 'antd';
import { QuestionComponentProps } from '../../../../interface/questionForm';

const QuestionRadioGroup = (props: QuestionComponentProps) => {
   return (
      <Radio.Group id='ages' name='ages'>
         <Space direction='vertical' size={16} className='radio-button'>
            {props.data.answerPreset!.map((el, index) => (
               <Radio value={el.value} key={index}>
                  {el.display}
               </Radio>
            ))}
         </Space>
      </Radio.Group>
   );
};

export default QuestionRadioGroup;
