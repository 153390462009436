import { Link } from 'react-router-dom';
import { onErrorImage } from '../../Utilities/imageUtilities';
import TagFileType from '../tag/TagFileType';
import './index.scss';

interface CoverProps {
    coverImage: string;
    fileType?: string;
    path?: string;
}

export const ImageCover = (props: CoverProps) => {
    return (
        <Link to={props.path ?? '/'}>
            <div className='image-cover'>
                <img
                    src={props.coverImage + ''}
                    alt={props.coverImage}
                    id={props.coverImage}
                    onError={onErrorImage}
                />
                {props.fileType && <TagFileType fileType={props.fileType ?? ''} />}
            </div>
        </Link>
    );
};
