import { Swiper, SwiperSlide } from 'swiper/react';
import { Content, LicenseType } from '../../../interface';
import { breakpointVideo } from '../../Utilities/swiperBreakpoint';
import { ContentCollectionView } from '../contentCover';

interface VideoSlideViewProps {
    videos: Content[];
    licenseType: LicenseType;
}

const VideoSlideView = (props: VideoSlideViewProps) => {
    return (
        <Swiper breakpoints={breakpointVideo} spaceBetween={16}>
            {props.videos.map((el, index) => (
                <SwiperSlide key={index} className='video-swiper'>
                    <ContentCollectionView
                        content={el as Content}
                        licenseType={props.licenseType}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default VideoSlideView;
