import { ErrFullDevice } from '../../../interface';
import { NotificationType } from '../../../interface/notification';

export interface NotificationState {
  notification?: NotificationType[];
  userSurveyUrl?: string;
  isSponsoredClient?: boolean;
  deviceErr?: ErrFullDevice;
}

const initialState: NotificationState = {
  notification: [],
  deviceErr: {
    devices: []
  }
};

export enum NotificationActionType {
  fetchNotificationData = '[fetch]NotificationData',
  setDevicesNotification = '[devices]setDevicesNotification'
}

export interface NotificationAction {
  type: NotificationActionType;
  payload?: NotificationState;
}

export const reducer = (
  state = initialState,
  action: NotificationAction
): NotificationState => {
  switch (action.type) {
    case NotificationActionType.fetchNotificationData: {
      const payload = action.payload;
      return {
        ...state,
        notification: payload?.notification ?? [],
        userSurveyUrl: payload?.userSurveyUrl,
        isSponsoredClient: payload?.isSponsoredClient
      };
    }
    case NotificationActionType.setDevicesNotification: {
      const payload = action.payload;
      return {
        ...state,
        deviceErr: payload?.deviceErr
      };
    }
    default:
      return state;
  }
};
