import { Space, Tooltip } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Content } from '../../../interface';
import { RentBtn } from '../../customUI/RentButton';
import { ImageCover } from '../contentCover/imageCover';
import { getDetailRoute, getWriterRoute } from '../../routes/generatePath';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import './index.scss';

interface ContentListWithBackgroundViewProps {
    contents: Content[];
}

const ContentListWithRankView = (props: ContentListWithBackgroundViewProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 1.2
        },
        576.98: {
            slidesPerView: 2.2
        },
        768.98: {
            slidesPerView: 3.2
        }
    };

    return (
        <Swiper className='content-list-with-rank' breakpoints={breakpoint} spaceBetween={16}>
            {props.contents.map((el, index) => {
                if (index % 2 === 0 && index <= props.contents.length - 1) {
                    return (
                        <SwiperSlide key={index} className='container'>
                            <ContentListWithRankCover
                                content={props.contents[index]}
                                index={index}
                            />
                            {props.contents[index + 1] && (
                                <ContentListWithRankCover
                                    content={props.contents[index + 1]}
                                    index={index + 1}
                                />
                            )}
                        </SwiperSlide>
                    );
                } else {
                    return <></>;
                }
            })}
        </Swiper>
    );
};

export default ContentListWithRankView;

interface ContentListWithRankCoverProps {
    content: Content;
    index: number;
}

export const ContentListWithRankCover = (props: ContentListWithRankCoverProps) => {
    const { content, index } = props;

    const intl = useIntl();
    return (
        <div className='cover-container'>
            <ImageCover
                coverImage={content.coverImage + '' || ''}
                fileType={content.fileType}
                path={getDetailRoute(content.contentUID, content.contentType, content.licenseType)}
            />

            <div className='detail'>
                <Space size={16} align='start'>
                    <div className='rank-title'>{index + 1}</div>
                    <div>
                        <Tooltip title={content.title}>
                            <Link
                                className='title-text'
                                to={getDetailRoute(
                                    content.contentUID,
                                    content.contentType,
                                    content.licenseType
                                )}
                            >
                                {content.title}
                            </Link>
                        </Tooltip>
                        <Tooltip title={content.writerName || content.authorName}>
                            {props.content.writerUID ? (
                                <Link
                                    className='sub-text'
                                    to={getWriterRoute(
                                        content.writerUID,
                                        undefined,
                                        content.licenseType,
                                        content.contentType
                                    )}
                                >
                                    {content.writerName || content.authorName}
                                </Link>
                            ) : (
                                <span className='sub-text'>
                                    {content.writerName || content.authorName}
                                </span>
                            )}
                        </Tooltip>
                    </div>
                </Space>

                <div className='queue-title'>
                    <div>{intl.formatMessage({ id: 'Book.Queue' })}</div>
                    <div>{content.waitingAmount ?? 0}</div>
                </div>

                <RentBtn
                    content={content}
                    contentType={content.contentType}
                    licenseType={content.licenseType}
                    status={content.rentalStatus!}
                    size='middle'
                />
            </div>
        </div>
    );
};
