import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UseFetchType } from '../../../interface';
import { RecommendResponse } from '../../../interface/sponsor';
import { HibraryRootState } from '../../../redux/rootReducer';
import { EmptyDataView } from '../../components/nodata';
import { ComponentLoading } from '../../customUI/loading';
import { removeSplash } from '../../helpers/removeSplash';
import { action } from '../../redux/request';
import { getAllBannerSponsor } from '../../redux/sponsor/request';
import { onErrorImage } from '../../Utilities/imageUtilities';
import './index.scss';

const useFetchAllBannerSponsor = (
   pageId: string
): UseFetchType<RecommendResponse> => {
   const [data, setData] = useState<RecommendResponse>();
   const [error, setError] = useState<any>();
   const dispatch = useDispatch();

   const { loading, authen, clientInfo } = useSelector(
      (state: HibraryRootState) => ({
         loading: state.fetch.loading,
         authen: state.auth,
         clientInfo: state.clientInfo
      }),
      shallowEqual
   );

   useEffect(() => {
      const fetchSponsor = async () => {
         try {
            dispatch(action.setProcess(true, pageId));
            const response = await getAllBannerSponsor(
               !authen.isLogin,
               clientInfo.companyPrefix
            );
            if (response) {
               setData(response);
            }
         } catch (error) {
            setError(error);
         } finally {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
         }
      };

      fetchSponsor();
   }, []);

   return { data, loading, error };
};

const ViewAllSponsorBanner = () => {
   const pageId = 'viewAllSponsorBanner';
   const { data, loading, error } = useFetchAllBannerSponsor(pageId);
   return (
      <div className='viewAllSponsorBanner'>
         <h1>รวมหนังสือแนะนำ</h1>
         <div className='contentContainer'>
            {!loading && data && data.recommends.length > 0 ? (
               <>
                  {data.recommends.map((el, index) => (
                     <div className='sponsorBannerCover' key={index}>
                        <Link to={`/sponsor/sets/${el.setUID}`}>
                           <img
                              src={el.coverImageWebp ?? el.coverImage}
                              onError={onErrorImage}
                           />
                        </Link>
                     </div>
                  ))}
               </>
            ) : null}
            {loading && <ComponentLoading id={pageId} />}
            {(error || (data && data?.recommends.length <= 0)) && (
               <EmptyDataView />
            )}
         </div>
      </div>
   );
};

export default ViewAllSponsorBanner;
