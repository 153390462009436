import { Progress } from 'antd';
import dayjs from 'dayjs';
import { ReadingTimePerDay } from '../../../../interface';

interface ReadingTimePerDayProps {
  data: ReadingTimePerDay[];
}

const ReadTimePerDay = (props: ReadingTimePerDayProps) => {
  const TimeFormat = (time: number) => {
    let timeString = '';

    if (time / 3600 >= 1) {
      return (timeString += `${(time / 3600).toFixed(2)} ชม.`);
    } else {
      return (timeString += `${Math.ceil(time / 60)} น.`);
    }
  };
  return (
    <>
      {props.data.map((el, index) => (
        <div
          className="flex flex-col progress-day-reading justify-center"
          key={index}
        >
          <label
            className={el.readingTime > 0 ? 'active-reading' : ''}
            style={{ color: 'black' }}
          >
            {dayjs.unix(el.date).format('DD')}
          </label>
          <Progress
            type="circle"
            size="small"
            percent={Math.ceil(el.readingTimePercent * 100)}
            strokeWidth={12}
            trailColor="#C4C4C4"
            width={48}
            showInfo={el.readingTime > 0 ? true : false}
            format={(percent) => (percent ?? 0 < 100 ? `${percent}%` : '100%')}
          />
          {el.readingTime > 0 && (
            <label className="time-reading">{TimeFormat(el.readingTime)}</label>
          )}
        </div>
      ))}
    </>
  );
};

export default ReadTimePerDay;

// format={(minute) => (
//   <>
//     {el.readingTime > 0 && (
//       <>
//         <p
//           style={{
//             marginBottom: '2px',
//             marginTop: '0.5rem',
//             color: '#012876'
//           }}
//         >
//           {addTwoDigits(Math.floor(el.readingTime / 60), 2) +
//             ' : ' +
//             addTwoDigits(Math.floor(el.readingTime % 60), 2)}
//         </p>
//         <p
//           style={{
//             color: '#012876'
//           }}
//         >
//           นาที
//         </p>
//       </>
//     )}
//   </>
// )}
