import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useState, useEffect } from 'react';
import { ContentType } from '../../../interface';
import { FormattedMessage, useIntl } from 'react-intl';
import './index.scss';
import 'antd/dist/antd.min.css';
export interface BreadcrumbLink {
    title: string | JSX.Element;
    path?: string;
}

export interface BreadcrumbProps {
    addLinks?: BreadcrumbLink[];
}

export interface BreadcrumbList {
    title?: string | JSX.Element;
    path?: string;
    param?: any;
}

export const BreadcrumbPath = {
    Home: {
        title: <FormattedMessage id='Library.HomePageBtn' />,
        path: '/'
    },
    Hibrary: {
        title: <FormattedMessage id='Library.Title' />,
        path: '/'
    },
    Free: {
        title: <FormattedMessage id='Library.FreePageBtn' />,
        path: '/free'
    },
    Private: {
        title: <FormattedMessage id='OwnedMedia.Title' />,
        path: '/private'
    },
    Categories: {
        title: <FormattedMessage id='OwnedMedia.CategoryPageBtn' />,
        path: '/categories'
    },
    History: {
        title: <FormattedMessage id='BorrowingHistory.Title' />,
        path: '/history'
    },
    Queue: {
        title: <FormattedMessage id='Booking.Title' />,
        path: '/queue'
    },
    Profile: {
        title: <FormattedMessage id='Profile.Title' />,
        path: '/profile'
    },
    PrivateAllEbook: {
        title: <FormattedMessage id='ContentType.Ebook' />,
        path: '/private/all/ebook'
    },
    PrivateAllVideo: {
        title: <FormattedMessage id='ContentType.Video' />,
        path: '/private/all/video'
    },
    PrivateAllPodcast: {
        title: <FormattedMessage id='ContentType.Podcast' />,
        path: '/private/all/podcast'
    },
    ListEbook: {
        title: <FormattedMessage id='Catalogue.Title' />,
        path: '/listEbook'
    }
};

export const BreadCrumbView = (props: BreadcrumbProps) => {
    const intl = useIntl();
    const location = useLocation();
    const [links, setLink] = useState<BreadcrumbLink[]>([BreadcrumbPath.Home]);

    useEffect(() => {
        const newPath = createPath(location.pathname);
        setLink(([BreadcrumbPath.Home] as BreadcrumbLink[]).concat(newPath));
    }, [location]);

    useEffect(() => {
        setLink(links.concat(props.addLinks ?? []));
    }, [props.addLinks]);

    const createPath = (path: string): BreadcrumbLink[] => {
        const data: BreadcrumbLink[] = [];
        const slitPath = path.split('/');
        data.push(getMainPathData(slitPath![1]));
        if (slitPath[2]) {
            const link = getSubPathData(
                slitPath![2],
                slitPath![1],
                slitPath[slitPath.length - 2] ?? ''
            );
            if (link) data.push(link);
        }
        if (slitPath[2] == 'home' && slitPath[3]) {
            data.push(getContentTypeLink(slitPath[1], slitPath[3] as ContentType));
        }
        if (slitPath[2] == 'sets' && slitPath[3]) {
            data.push(getContentSetContent(slitPath![1], slitPath[3]));
        }
        return data;
    };

    const getContentSetContent = (licenseType: string, setType: string): BreadcrumbLink => {
        let title: string;
        switch (setType) {
            case 'magazine':
                title = intl.formatMessage({ id: 'ContentType.Magazine' });
                break;
            case 'newspaper':
                title = intl.formatMessage({ id: 'ContentType.Newpaper' });
                break;

            default:
                title = intl.formatMessage({ id: 'ContentType.Magazine' });
                break;
        }
        return {
            title: title,
            path: `/${licenseType}/sets/${setType}`
        };
    };

    const getContentTypeLink = (licenseType: string, contentType: ContentType): BreadcrumbLink => {
        let title: string;
        switch (contentType) {
            case ContentType.ebook:
                title = intl.formatMessage({ id: 'ContentType.Ebook' });
                break;
            case ContentType.podcast:
                title = intl.formatMessage({ id: 'ContentType.Podcast' });
                break;
            case ContentType.video:
                title = intl.formatMessage({ id: 'ContentType.Video' });
                break;
            default:
                title = intl.formatMessage({ id: 'ContentType.Ebook' });
                break;
        }
        return {
            title: title,
            path: `/${licenseType}/home/${contentType}`
        };
    };

    const getSubPathData = (path: string, mainPath: string, contentType: string) => {
        switch (path) {
            case 'all':
                return {
                    title: intl.formatMessage({ id: 'Library.SeeAllBtn' }),
                    path: `/${mainPath}/${path}/${contentType}`
                };
            case 'categories':
            case 'subcategories':
                return {
                    title: intl.formatMessage({
                        id: 'Library.CategoryPageBtn'
                    }),
                    path: `/${mainPath}/categories/${contentType}/all`
                };
            default:
                return undefined;
        }
    };

    const getMainPathData = (path: string) => {
        switch (path) {
            case 'rent':
                return BreadcrumbPath.Hibrary;
            case 'free':
                return BreadcrumbPath.Free;
            case 'private':
                return BreadcrumbPath.Private;
            case 'categories':
                return BreadcrumbPath.Categories;
            case 'history':
                return BreadcrumbPath.History;
            case 'queue':
                return BreadcrumbPath.Queue;
            case 'profile':
                return BreadcrumbPath.Profile;
            case 'listEbook':
                return BreadcrumbPath.ListEbook;
            default:
                return BreadcrumbPath.Hibrary;
        }
    };

    return (
        <div className='breadcrumb'>
            <Breadcrumb separator='>'>
                {links.length > 1 && (
                    <>
                        {links.map((e: BreadcrumbLink, index: number) => (
                            <Breadcrumb.Item key={index}>
                                {index !== links.length - 1 ? (
                                    <Link
                                        to={{
                                            pathname: e.path
                                        }}
                                    >
                                        {e.title}
                                    </Link>
                                ) : (
                                    e.title
                                )}
                            </Breadcrumb.Item>
                        ))}
                    </>
                )}
            </Breadcrumb>
        </div>
    );
};
