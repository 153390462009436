import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CategoryContentResponse, ContentType, LicenseType } from '../../../interface';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { BreadCrumbView, BreadcrumbLink } from '../../components/breadcrumb';
import { HibraryRootState } from '../../../redux/rootReducer';
import { action } from '../../redux/request';
import { getListCategoryRoute, getListSubCategoryRoute } from '../../routes/generatePath';
import { removeSplash } from '../../helpers/removeSplash';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../../customUI/loading';
import { SegmentHeader } from '../home/segmentHeader';
import ContentWithDetailView from '../../components/segment/contentWithDetailView';
import { getCategoryContents } from '../../redux/store/storeCrud';

interface CategoryContentViewProps {
    categoryUID: string;
    licenseType: LicenseType;
    contentType: ContentType;
}

const CategoryContentPage = (props: CategoryContentViewProps) => {
    const pageId = 'CategoryContentView';
    const dispatch = useDispatch();
    const history = useHistory();
    const [categoryContents, setCategoryContents] = useState<CategoryContentResponse>();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
    const { clientInfo, authen } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            environment: state.environment
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchData();
    }, []);

    const breadcrumbMemo = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    const fetchData = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getCategoryContents(
                authen.isLogin,
                clientInfo.companyPrefix,
                props.licenseType,
                props.categoryUID,
                props.contentType
            );

            if (response) {
                if (response.subcategories.length > 1) setCategoryContents(response);
                else
                    history.push(
                        getListSubCategoryRoute(
                            props.categoryUID,
                            response?.subcategories[0].subcategoryUID,
                            props.licenseType,
                            props.contentType
                        )
                    );

                setBreadCrumbs([
                    {
                        title: 'หมวดหมู่',
                        path: getListCategoryRoute(props.licenseType, props.contentType)
                    },
                    { title: response?.title ?? '' }
                ]);
                dispatch(action.setProcess(false, pageId));
                dispatch(removeSplash);
            }
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    return (
        <div className='store'>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}{' '}
                    | หมวดหมู่ : {`${categoryContents?.title ?? ''}`}
                </title>
            </Helmet>
            {breadcrumbMemo}
            <h1 className='viewAllTitle'>{`${categoryContents?.title ?? ''}`}</h1>
            <div className='segment-list'>
                {categoryContents?.subcategories.map((el, index) => (
                    <div key={index}>
                        <SegmentHeader
                            title={el.subcategoryName}
                            path={getListSubCategoryRoute(
                                props.categoryUID,
                                el.subcategoryUID,
                                props.licenseType,
                                props.contentType
                            )}
                            right={(el.contents?.length ?? 0) > 7 ? true : false}
                        />
                        <ContentWithDetailView contents={el.contents ?? []} />
                    </div>
                ))}
            </div>
            <Loading id={pageId} />
        </div>
    );
};

export default CategoryContentPage;
