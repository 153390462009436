import { Modal, Button } from 'antd';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { HibraryRootState } from '../../../redux/rootReducer';
import { RentalStatus } from '../../../interface/home';
import errorLoading from '../../assets/lotties/4386-connection-error.json';
import successLoading from '../../assets/lotties/1127-success.json';
import Lottie from 'lottie-react';
// import dayjs from 'dayjs';
import './index.scss';
import { RequestStage } from '../../redux/rental';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { rentCancel, rentReset } from '../../redux/rental/action';
import { useIntl } from 'react-intl';
import { formatDate } from '../../helpers/changeFormatDateTime';
import dayjs from 'dayjs';

interface RentalModalInformation {
    title?: string;
    description?: string;
    dateInformation?: string;
    confirmTitle?: string;
    isError?: boolean;
}
export const RentalModal = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [visible, setVisible] = useState(false);
    const [info, setInfo] = useState<RentalModalInformation>({});

    const { rental } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            clientInfo: state.clientInfo,
            rental: state.rental
        }),
        shallowEqual
    );

    const getTitleModal = () => {
        switch (rental.rentalStatus) {
            case RentalStatus.Rent:
                return intl.formatMessage({ id: 'Book.ModalMediaAdded' });
            case RentalStatus.WaitingListed:
                return intl.formatMessage({ id: 'Book.ModalCancelled' });
            case RentalStatus.Rented:
                return intl.formatMessage({ id: 'BorrowingHistory.ModalReturned' });
            default:
                return undefined;
        }
    };

    useEffect(() => {
        console.log(rental.stage);

        if (rental.stage === RequestStage.Error) {
            setInfo({
                title: intl.formatMessage({ id: 'Alert.SomethingWrong' }),
                description: `${rental.error ? rental.error.message : ''}`,
                isError: true
            });
        } else if (rental.stage === RequestStage.Complete) {
            // const date = rental.license?.expireDate
            //     ? dayjs.unix(rental.license?.expireDate ?? 0)
            //     : dayjs().add(rental.content?.rentalPeriod ?? 0, 's');
            setInfo({
                title: getTitleModal(),
                description: rental.content?.title,
                dateInformation:
                    rental.rentalStatus === RentalStatus.Rent
                        ? intl.formatMessage(
                              { id: 'Book.ModalBorrowBookSubtitle' },
                              {
                                  0: formatDate(
                                      rental.license?.expireDate ?? 0,
                                      intl.locale === 'th' ? 'th' : 'en',
                                      'DD MMM YYYY'
                                  )
                              }
                          )
                        : '',
                isError: true
            });
            // dispatch(rentReset());
        } else {
            setModalInformation(rental.rentalStatus ?? RentalStatus.Rent);
        }
        if (rental.showConfirmModal) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [rental]);

    const setModalInformation = (status: RentalStatus) => {
        switch (status) {
            case RentalStatus.Rent:
                setInfo({
                    title: intl.formatMessage({
                        id: 'Book.ModalBorrowBookTitle'
                    }),
                    description: rental.content?.title ?? '',
                    dateInformation: intl.formatMessage(
                        {
                            id: 'Book.ModalBorrowBookSubtitle'
                        },
                        {
                            0: ` ${dayjs()
                                .add(rental.content?.rentalPeriod ?? 0, 'seconds')
                                .locale(intl.locale)
                                .format('DD MMM YYYY HH:mm')} ${intl.locale === 'th' ? 'น.' : ''}`
                        }
                    ),
                    confirmTitle: intl.formatMessage({ id: 'Action.BorrowBtn' })
                });

                break;
            case RentalStatus.Rented:
                setInfo({
                    title: intl.formatMessage({ id: 'BookShelf.ModalReturn' }),
                    description: `${rental.content?.title}`,
                    confirmTitle: intl.formatMessage({
                        id: 'BookShelf.ReturnBtn'
                    })
                });
                break;
            case RentalStatus.WaitingList:
                setInfo({
                    title: intl.formatMessage({
                        id: 'Book.ModalBookTitle'
                    }),
                    description: rental.content?.title ?? '',
                    confirmTitle: intl.formatMessage({
                        id: 'Action.BookBtn'
                    })
                });
                break;
            case RentalStatus.WaitingListed:
                setInfo({
                    title: intl.formatMessage({
                        id: 'Booking.ModalCancelBookingTitle'
                    }),
                    description: rental.content?.title ?? '',
                    confirmTitle: intl.formatMessage({
                        id: 'Action.CancelBookingBtn'
                    })
                });
                break;
            default:
                break;
        }
    };

    const getModalDetail = (stage: RequestStage) => {
        switch (stage) {
            case RequestStage.Complete:
                return (
                    <div className='modal-detail'>
                        <Lottie animationData={successLoading} className='modal-lottie' />
                        <h3>{info.description}</h3>
                        <p>{info.dateInformation}</p>
                    </div>
                );
            case RequestStage.Error:
                return (
                    <div className='modal-detail'>
                        <Lottie animationData={errorLoading} className='modal-lottie' />
                        <h3>{info.description}</h3>
                    </div>
                );
            default:
                return (
                    <div>
                        <h3>{info.description}</h3>
                        <p>{info.dateInformation}</p>
                    </div>
                );
        }
    };

    const onCancel = () => {
        dispatch(rentCancel());
    };

    const onConfirm = () => {
        if (
            ![RequestStage.Complete, RequestStage.Error, RequestStage.Init].includes(rental.stage)
        ) {
            rental.onConfirm!(rental.content!);
        } else {
            setVisible(false);
        }
    };

    const confirmBtn = (
        <Button
            size='middle'
            loading={rental.stage === RequestStage.Process ? true : false}
            type='primary'
            shape='round'
            key='confirm'
            onClick={onConfirm}
        >
            {!info.isError ? info.confirmTitle : intl.formatMessage({ id: 'Action.OKBtn' })}{' '}
        </Button>
    );

    const cancelBtn = (
        <Button size='middle' type='ghost' shape='round' key='back' onClick={onCancel}>
            {intl.formatMessage({ id: 'Action.CancelBtn' })}
        </Button>
    );

    const buttonSet = [cancelBtn, confirmBtn];
    const buttonConfirm = [confirmBtn];

    return (
        <Modal
            className='rental-modal'
            onCancel={onCancel}
            visible={visible}
            title={info.title ?? null}
            footer={
                [RequestStage.Complete, RequestStage.Error].includes(rental.stage)
                    ? buttonConfirm
                    : buttonSet
            }
        >
            {getModalDetail(rental.stage)}
        </Modal>
    );
};
