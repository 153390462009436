import { SegmentHeader } from './segmentHeader';
import { generateHeaderLinkPath } from '../../redux/utilities';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    Category,
    Content,
    ContentType,
    DisplayType,
    PrivateSegmentMetaData,
    RenderHomeSegmentParam
    // Recommend
} from '../../../interface';
import { CategoryView } from '../../components/category';
import {
    ContentCollectionView,
    HomeSwiperCategory,
    SponsorBannerBreakPoint,
    SponsorBreakpoint,
    SwiperBestseller
} from '../../components/contentCover';
import {
    breakpointEbook,
    breakpointPodcast,
    breakpointVideo
} from '../../Utilities/swiperBreakpoint';
import { ContentListWithRankCover } from '../../components/segment/contentListWithRankView';
// import ContentWithDetailView from '../../components/segment/contentWithDetailView';
// import ContentBestsellerView from '../../components/segment/contentBestSeller';

export const PrivateHomeSegment = (props: RenderHomeSegmentParam) => {
    const getSwiperClass = (setType: DisplayType, contentType: ContentType) => {
        switch (setType) {
            // case DisplayType.ContentSet:
            //     return 'contentSet-swiper';
            case DisplayType.CategoryEbook:
            case DisplayType.SubCategoryEbook:
            case DisplayType.AllContent:
            case DisplayType.NewContent:
            case DisplayType.RentAbleContent:
                return `${contentType}-swiper`;
            case DisplayType.BestSeller:
                return 'content-list-with-rank';
            case DisplayType.Categories:
                return 'category-swiper';
            case DisplayType.ContentMainSponsored:
            case DisplayType.SecondarySponsor:
            case DisplayType.RecommendContent:
                return ``;
            default:
                return 'contentSet-swiper';
        }
    };

    const getSwiperItem = (setType: DisplayType, data: Content | Category, index?: number) => {
        switch (setType) {
            case DisplayType.CategoryEbook:
            case DisplayType.AllContent:
            case DisplayType.NewContent:
            case DisplayType.RentAbleContent:
                // case DisplayType.SubCategoryEbook:
                return (
                    <ContentCollectionView
                        key={index}
                        content={data as Content}
                        licenseType={props.licenseType}
                    />
                );
            case DisplayType.Categories:
                return (
                    <CategoryView
                        key={index}
                        category={data as Category}
                        licenseType={props.licenseType}
                        contentType={(data as Content).contentType}
                    />
                );
            case DisplayType.BestSeller:
                return (
                    <ContentListWithRankCover
                        key={index}
                        index={index ?? 0}
                        content={data as Content}
                    />
                );
            default:
                return <></>;
        }
    };

    const getViewAll = (home: PrivateSegmentMetaData) => {
        switch (home.type) {
            case DisplayType.CategoryEbook:
            case DisplayType.SubCategoryEbook:
            case DisplayType.AllContent:
            case DisplayType.RentAbleContent:
                if (home.contents && home.contents.length <= 8) {
                    return false;
                } else {
                    return true;
                }
            case DisplayType.NewContent:
                return true;
            case DisplayType.BestSeller:
                return false;
            case DisplayType.Categories:
                if (home.category!.length > 8) {
                    return true;
                } else {
                    return false;
                }
            default:
                return false;
        }
    };

    const getBreakpointOfCollection = (home: PrivateSegmentMetaData) => {
        switch (home.type) {
            case DisplayType.ContentSet:
            case DisplayType.Categories:
                return HomeSwiperCategory;
            case DisplayType.AllContent:
            case DisplayType.NewContent:
                if (home.contentType === ContentType.video) return breakpointVideo;
                if (home.contentType === ContentType.podcast) return breakpointPodcast;
                return breakpointEbook;
            case DisplayType.BestSeller:
                return SwiperBestseller;
            case DisplayType.ContentMainSponsored:
            case DisplayType.SecondarySponsor:
                return SponsorBreakpoint;
            case DisplayType.RecommendContent:
                return SponsorBannerBreakPoint;
            default:
                if (home.contentType === ContentType.video) return breakpointVideo;
                if (home.contentType === ContentType.podcast) return breakpointPodcast;
                return breakpointEbook;
        }
    };

    return (
        <div key={`segment_${props.indexSegment}_${props.segment.contentType}`}>
            <SegmentHeader
                title={props.segment.title}
                right={getViewAll(props.segment)}
                path={generateHeaderLinkPath(props)}
                description={props.segment.subtitle}
            />

            <div
                className={`categorySwiper${
                    (props.segment.type as DisplayType) === DisplayType.BestSeller
                        ? ' bestSeller'
                        : ''
                }`}
            >
                <Swiper
                    className='segment-swiper'
                    breakpoints={getBreakpointOfCollection(props.segment)}
                    spaceBetween={16}
                    centeredSlides={false}
                    loop={false}
                >
                    {props.segment.videos?.map((e, index) => (
                        <div key={`div${index}`}>
                            <SwiperSlide
                                key={`col_${index}`}
                                className={getSwiperClass(
                                    (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                    props.segment.contentType ?? ContentType.video
                                )}
                            >
                                {getSwiperItem(
                                    (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                    e,
                                    index
                                )}
                            </SwiperSlide>
                        </div>
                    ))}

                    {props.segment.podcasts?.map((el, index) => (
                        <div key={`div${index}`}>
                            <SwiperSlide
                                key={`col_${index}`}
                                className={getSwiperClass(
                                    (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                    props.segment.contentType!
                                )}
                            >
                                {getSwiperItem(
                                    (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                    el,
                                    index
                                )}
                            </SwiperSlide>
                        </div>
                    ))}

                    {props.segment.contents?.map((e, index) => (
                        <div key={`div${index}`}>
                            {(props.segment.type as DisplayType) === DisplayType.BestSeller ? (
                                <>
                                    {index <= props.segment.contents!.length / 2 - 1 ||
                                    props.segment.contents!.length == 1 ? (
                                        <SwiperSlide
                                            key={`col_${index}`}
                                            className={getSwiperClass(
                                                (props.segment.type as DisplayType) ??
                                                    DisplayType.AllContent,
                                                props.segment.contentType!
                                            )}
                                        >
                                            <div className='list-bestSeller'>
                                                <div>
                                                    {getSwiperItem(
                                                        props.segment.type as DisplayType,
                                                        props.segment.contents![index * 2],
                                                        index * 2
                                                    )}
                                                    {props.segment.contents!.length > 1
                                                        ? getSwiperItem(
                                                              props.segment.type as DisplayType,
                                                              props.segment.contents![
                                                                  index * 2 + 1
                                                              ],
                                                              index * 2 + 1
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ) : (
                                <SwiperSlide
                                    key={`col_${index}`}
                                    className={getSwiperClass(
                                        (props.segment.type as DisplayType) ??
                                            DisplayType.AllContent,
                                        props.segment.contentType!
                                    )}
                                >
                                    {getSwiperItem(
                                        (props.segment.type as DisplayType) ??
                                            DisplayType.AllContent,
                                        e,
                                        index
                                    )}
                                </SwiperSlide>
                            )}
                        </div>
                    ))}

                    {props.segment.category?.map((e, index) => (
                        <SwiperSlide
                            key={`col_Cat_${index}`}
                            className={getSwiperClass(
                                (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                props.segment.contentType!
                            )}
                        >
                            {getSwiperItem(
                                (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                e
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

{
    /* {props.segment.recommends?.map((e, index) => (
                        <SwiperSlide
                            key={`col_set_${index}`}
                            className={getSwiperClass(
                                (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                props.segment.contentType!
                            )}
                        >
                            {getSwiperItem(
                                (props.segment.type as DisplayType) ?? DisplayType.RecommendContent,
                                e
                            )}
                        </SwiperSlide>
                    ))} */
}

{
    /* {props.segment.sponsors?.map((e, index) => (
                        <SwiperSlide
                            key={`col_set_${index}`}
                            className={getSwiperClass(
                                (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                props.segment.contentType!
                            )}
                        >
                            {getSwiperItem(
                                (props.segment.type as DisplayType) ?? DisplayType.ContentMainSponsored,
                                e
                            )}
                        </SwiperSlide>
                    ))} */
}

{
    /* {props.segment.sets?.map((e, index) => (
                        <SwiperSlide
                            key={`col_set_${index}`}
                            className={getSwiperClass(
                                (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                props.segment.contentType!
                            )}
                        >
                            {getSwiperItem(
                                (props.segment.type as DisplayType) ?? DisplayType.AllContent,
                                e
                            )}
                        </SwiperSlide>
                    ))} */
}
