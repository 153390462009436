import { Link } from 'react-router-dom';
import { getDetailRoute } from '../../routes/generatePath';
import { Image } from 'antd';
import { CoverViewParam } from '../../../interface/detailPage';
import { onErrorImage } from '../../Utilities/imageUtilities';

const CoverView = (props: CoverViewParam) => {
    const { licenseType, contentType } = props;
    const hasLink = props.hasLink !== undefined ? props.hasLink : true;

    return licenseType && hasLink ? (
        <Link
            to={{
                pathname: getDetailRoute(props.contentUID, contentType, licenseType)
            }}
        >
            <div className='cover'>
                <div className={`filetype ${props.fileType}`}>{props.fileType}</div>
                <Image preview={false} src={props.coverImage} onError={onErrorImage} />
            </div>
        </Link>
    ) : (
        <div className='cover'>
            <div className={`filetype ${props.fileType}`}>{props.fileType}</div>
            <Image preview={false} src={props.coverImage} onError={onErrorImage} />
        </div>
    );
};

export default CoverView;
