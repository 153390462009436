import { Sponsor, SponsorBannerMetaData } from './sponsor';

export enum DisplayType {
  Content = 'Content',
  ContentWithTitle = 'ContentWithTitle',
  ContentWithRankTitle = 'ContentWithRankTitle',
  ContentWithTitleButton = 'ContentWithTitleButton',
  ContentWithButton = 'ContentWithButton',
  ContentListWithBG = 'ContentListWithBG',
  ContentListWithRank = 'ContentListWithRank',
  ContentList = 'ContentList',
  ContentListWithCoverBG = 'ContentListWithCoverBG',
  BannerSquare = 'BannerSquare',
  BannerRectangle = 'BannerRectangle',
  ContentCategory = 'ContentCategory',
  CategoryContent = 'CategoryContent',
  AllEbook = 'AllEbook',
  CategoryEbook = 'CategoryEbook',
  Banner = 'Banner',
  VideoList = 'VideList',
  VideoSlide = 'VideoSlide',
  ContentMainSponsored = 'ContentMainSponsored',
  ContentSecondarySponsored = 'ContentSecondarySponsored',
  //?old
  ContentSet = 'ContentSet',
  MagazineSet = 'MagazineSet',
  NewspaperSet = 'NewspaperSet',
  SubCategoryEbook = 'SubCategoryEbook',
  AllContent = 'AllContent',
  Categories = 'Categories',
  BestSeller = 'BestSeller',
  NewContent = 'NewContent',
  SecondarySponsor = 'SecondarySponsor',
  RecommendContent = 'RecommendContent',
  RentAbleContent = 'RentAbleContent'
}

export enum SegmentType {
  CategoryContent = 'CategoryContent',
  PopularContent = 'PopularContent',
  RentableContent = 'RentableContent',
  AllContent = 'AllContent',
  CustomContent = 'CustomContent',
  RecommendContent = 'RecommendContent',
  SubscriptionContent = 'SubscriptionContent',
  Category = 'Category',
  CustomBanner = 'CustomBanner',
  ContentMainSponsored = 'ContentMainSponsored',
  SecondarySponsor = 'SecondarySponsor'
}

export enum ContentType {
  content = 'content',
  ebook = 'ebook',
  podcast = 'podcast',
  video = 'video',
  newspaper = 'newspaper',
  magazine = 'magazine'
}

export enum SubscriptionType {
  Magazine = 'Magazine',
  Newspaper = 'Newspaper',
  eBook = 'eBook'
}

export enum BannerType {
  normal = 'normal',
  ebook = 'ebook',
  podcast = 'podcast',
  video = 'video',
  link = 'link',
  creator = 'creator',
  author = 'author',
  category = 'category',
  subcategory = 'subcategory',
  search = 'search'
}

export enum LicenseType {
  Sample = 'Sample',
  Rent = 'Rent',
  Buffet = 'Buffet',
  Private = 'Private',
  Free = 'Free'
}

export enum RentalStatus {
  Rent = 'Rent',
  Rented = 'Rented',
  WaitingList = 'WaitingList',
  WaitingListed = 'WaitingListed',
  Returned = 'Returned',
  ReturnAble = 'ReturnAble'
}

export enum RentBtnTitle {
  Rent = 'ยืมสื่อ',
  Rented = 'ยืมแล้ว',
  WaitingList = 'จองคิว',
  WaitingListed = 'ยกเลิก'
}

export enum ContentViewType {
  List, // 0
  Collection, // 1
  BestSeller, // 2
  History, //3
  Queue, //4
  BookShelf, //5
  Detail
}

type HomeContent =
  | VideoContent
  | Content
  | PodcastContent
  | SubscriptionMetaData
  | Sponsor
  | SponsorBannerMetaData
  | Category
  | CustomHomeBanner;

export interface HomeResponse {
  home: SegmentMetaData[];
  banners: Banner[];
}

export interface RenderHomeSegmentParam {
  segment: PrivateSegmentMetaData;
  indexSegment: number;
  contentType: ContentType;
  licenseType: LicenseType;
}

export interface SegmentMetaData {
  index: number;
  title: string;
  description?: string;
  segmentUID: string;
  displayType: DisplayType;
  segmentType: SegmentType;
  contentType: ContentType;
  segmentContents: HomeContent[];
  categoryUID?: string;
  subscriptionType?: SubscriptionType;
}

export interface PrivateSegmentMetaData {
  title: string;
  subtitle?: string;
  type: DisplayType;
  contents: Content[];
  contentType?: ContentType;
  sets?: SubscriptionMetaData[];
  setType?: SubscriptionType;
  ebooks?: Content[];
  videos?: VideoContent[];
  podcasts?: PodcastContent[];
  sponsors?: Sponsor[];
  recommends?: Recommend[];
  catId?: string | number;
  subcatId?: string | number;
  category?: Category[];
}

export interface Category {
  categoryUID: string;
  categoryName: string;
  contentType: ContentType;
  licenseType: LicenseType;
  coverImage: string;
  coverImageWebp?: string;
  bgColor?: string;
  subcategoryAmount: number;

  //? private
  catName?: string;
  catId?: string;
  subcatId?: string;
}

export interface CustomHomeBanner {
  uid: string;
  bannerType: BannerType;
  imageUrl: string;
  value?: string;
  subValue?: string;
  status: number;
  licenseType?: LicenseType;
}

export const getLicenseType = (licenseType: string): LicenseType => {
  switch (licenseType) {
    case 'sample':
      return LicenseType.Sample;
    case 'rent':
      return LicenseType.Rent;
    case 'buffet':
      return LicenseType.Buffet;
    case 'private':
      return LicenseType.Private;
    case 'free':
      return LicenseType.Free;
    default:
      return LicenseType.Rent;
  }
};
export interface SubCategory {
  subcatId: number;
  status: number;
  subcatName: string;
  coverImage: string;
}

export interface Recommend {
  coverImage: string;
  coverImageWebp?: string;
  setUID: string;
  sponsorUID: string;
  string: number;
  title: string;
}

export interface Content {
  contentUID: string;
  title: string;
  categoryUID: string;
  categoryName: string;
  subcategoryUID: string;
  subcategoryName: string;
  writerUID: string;
  writerName: string;
  publisherUID: string;
  publisherName: string;
  contentType: ContentType;
  coverImage: string;
  coverImageWebp?: string;
  publicationDate: string;
  description: string;
  tags: string[];
  licenseType: LicenseType;
  fileType: string;
  fileSize: number;
  samplePath: string;
  remainConcurrent: number;
  concurrent: number;
  waitingAmount: number;
  totalRent: number;
  status: number;
  startDate: number;
  expireDate: number;
  rentalPeriod: number;
  rentalStatus: RentalStatus;
  view?: number;
  uploadDate: number;
  duration?: string;

  //? ของเก่า
  subcatId: string;
  catId: string;
  pageCount: number;
  catName: string;
  subcatName: string;
  creatorUID: string;
  authorName: string;
  channelName: string;
}

export interface PrivateContent {
  contentUID: string;
  subcatId?: string;
  concurrent: number;
  catId?: string;
  creatorUID: string;
  title: string;
  authorName: string;
  coverImage: string;
  fileType: string;
  licenseType: LicenseType;
  contentType: ContentType;
  writerID?: number;
  status?: number;
  pageCount?: number;
  fileSize?: number;
  description?: string;
  publisherName?: string;
  catName?: string;
  subcatName?: string;
  rentalStatus?: RentalStatus;
  rentalPeriod?: number;
  channelName?: string;
  view?: number;
  uploadDate?: number;
  duration?: string;
  writerUID?: string;
  bgColor?: string;
  expireDate?: number;
}

export interface SubscriptionMetaData {
  coverImage: string;
  coverImageWebp?: string;
  subscriptionType: SubscriptionType;
  title: string;
  uid: string;

  //? old
  bgColor?: string;
  imagePath: string;
}

export interface Banner {
  bannerType: BannerType;
  licenseType?: LicenseType;
  value: string;
  subValue?: string;
  imageUrl: string;
}

// export interface Ebook {
//     contentUID: string;
//     subcatId: number;
//     activeLicense: number;
//     concurrent: number;
//     catId: number;
//     creatorUID: string;
//     title: string;
//     authorName: string;
//     isNew: boolean;
//     isBestSeller: boolean;
//     isPromotions: boolean;
//     coverImage: string;
//     fileType: string;
//     channelName?: string;
// }

export interface VideoContent extends Content {
  // contentUID: string;
  // subcatId: string;
  // catId: string;
  // creatorUID: string;
  // title: string;
  // status: number;
  // description: string;
  // rentalPeriod: number;
  // coverImage: string;
  // totalRent?: number;
  // waitingAmount?: number;
  // remainConcurrent?: number;
  // licenseType: LicenseType;
  channelName: string;
  channelImage: string;
  view: number;
  uploadDate: number;
  playlist: string;
  language: string;
  duration: string;
  relatedContents?: Content[];
}

export interface PodcastContent extends Content {
  // contentUID: string;
  // subcatId: string;
  // catId: string;
  // creatorUID: string;
  // title: string;
  // coverImage: string;
  // status: number;
  // description: string;
  // rentalPeriod: number;
  // channelName: string;
  // uploadDate: number;
  // totalRent?: number;
  // waitingAmount?: number;
  // remainConcurrent?: number;
  // licenseType: LicenseType;
  channelName: string;
  relatedContents?: Content[];
  episodes?: PodcastEpisode[];
  duration: string;
}

export interface PodcastEpisode {
  title: string;
  contentUID: string;
  description: string;
  uploadDate: number;
  duration: string;
}
export interface PodcastPlaylist {
  playlist: string;
}

export interface PrivateCategoryResponse {
  catId: string;
  catName: string;
  status: number;
  subcats: PrivateSubcategoryContent[];
}

export interface PrivateSubcategoryContent {
  ebooks?: Content[];
  podcasts?: Content[];
  videos?: Content[];
  status: number;
  subcatId: string;
  subcatName: string;
}

export interface CategoryContentResponse {
  title: string;
  subcategories: SubCategoryContent[];
}

export interface SubCategoryContent {
  subcategoryName: string;
  subcategoryUID: string;
  contents?: Content[];
}

export interface SubscriptionResponse {
  title: string;
  subscriptions: SubscriptionMetaData[];
}
