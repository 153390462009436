import { Tooltip } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { getDetailRoute, getWriterRoute } from '../../routes/generatePath';
import { Content } from '../../../interface';
import { ImageCover } from '../contentCover/imageCover';
import './index.scss';

interface ContentWithRankTitleViewProps {
    contents: Content[];
}

const ContentWithRankTitleView = (props: ContentWithRankTitleViewProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 3.2,
            slidesPerGroup: 3.2
        },
        576.98: {
            slidesPerView: 5.2,
            slidesPerGroup: 5.2
        },
        768.98: {
            slidesPerView: 7.2,
            slidesPerGroup: 7.2
        }
    };

    return (
        <Swiper
            className='content-with-rankTitle-container'
            spaceBetween={16}
            breakpoints={breakpoint}
        >
            {props.contents.map((el, index) => (
                <SwiperSlide className='content-cover' key={index}>
                    <ImageCover
                        coverImage={el.coverImage}
                        fileType={el.fileType}
                        path={getDetailRoute(el.contentUID, el.contentType, el.licenseType)}
                    />
                    <div className='number-tag'>#{index + 1}</div>
                    <div className='detail'>
                        <Tooltip title={el.title}>
                            <Link
                                className='title-text'
                                to={getDetailRoute(el.contentUID, el.contentType, el.licenseType)}
                            >
                                {el.title}
                            </Link>
                        </Tooltip>
                        <Tooltip title={el.writerName}>
                            <Link
                                className='sub-text'
                                to={getWriterRoute(
                                    el.writerUID,
                                    undefined,
                                    el.licenseType,
                                    el.contentType
                                )}
                            >
                                {el.writerName}
                            </Link>
                        </Tooltip>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ContentWithRankTitleView;
