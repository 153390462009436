import { useState, useEffect } from 'react';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';

import { Ebook } from './ebook';
import { getNewest } from '../../redux/catalog/catalogCrud';
import { NewestInterface, NewestLastEvaluatedKey } from '../../../interface/listEbook';
import { useDispatch } from 'react-redux';

export const Newest = () => {
    const pageId = 'catalogNewest';
    const dispatch = useDispatch();
    const [newest, setNewest] = useState<NewestInterface[]>();
    const [lastEvaluate, setLastEvaluate] = useState<NewestLastEvaluatedKey>();

    useEffect(() => {
        dispatch(action.setProcess(true, pageId));
        getNewest().then((res) => {
            setLastEvaluate(res.LastEvaluatedKey);
            setNewest(res.contents);
            dispatch(action.setProcess(false, pageId));
        });
    }, []);

    return (
        <>
            <div className='list-Ebook-wrap'>
                {newest && (
                    <Ebook
                        contents={newest}
                        LastEvaluatedKey={lastEvaluate}
                        receivedFrom='newest'
                    />
                )}
                <Loading id={pageId} />
            </div>
        </>
    );
};
