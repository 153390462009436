import { Radio, Space } from 'antd';
import { QuestionComponentProps } from '../../../../interface/questionForm';

const QuestionRating = (props: QuestionComponentProps) => {
   return (
      <Radio.Group
         id={props.data.questionUID}
         name={props.data.questionUID}
         onChange={props.onChange}
         value={props.formikValue}
      >
         <Space size={32}>
            {props.data.answerPreset?.map((el) => (
               <Radio value={el.value} key={el.answerUID}>
                  {el.display}
               </Radio>
            ))}
         </Space>
      </Radio.Group>
   );
};

export default QuestionRating;
