import axios from 'axios';
import { HtiResponse } from '../../../interface';
import { QRCodeResponse } from '../../../interface/qrCode';

export const getQRCode = async (prefix: string, qrCodeUID: string) => {
   try {
      const path = `/public/${prefix}/qrcode/${qrCodeUID}`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<QRCodeResponse>>(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};
