import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { removeSplash } from '../../helpers/removeSplash';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Content, ContentType, LicenseType } from '../../../interface/home';
import { ContentCollectionView } from '../../components/contentCover';
import { EmptyDataView } from '../../components/nodata';
import { SegmentButton } from '../../components/bookShelfSegmented';
import { ListAllContentResponse } from '../../../interface';
import { getSearchContent } from '../../redux/store/storeCrud';

interface SearchResultProps {
    keyword: string;
}

export const SearchResult = (props: SearchResultProps) => {
    const pageId = 'SearchResult';
    const location = useLocation();
    const dispatch = useDispatch();
    const [currentLicenseType, setCurrentLicenseType] = useState<LicenseType>(LicenseType.Rent);
    const [resultList, setResultList] = useState<ListAllContentResponse>();

    const { clientInfo, authen, environment, request } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchResult(false);
    }, [location, currentLicenseType]);

    useEffect(() => {
        if (
            environment.endOfScreen &&
            resultList?.lastEvaluatedKey !== undefined &&
            !request.loading
        ) {
            fetchResult(true);
        }
    }, [environment]);

    const fetchResult = async (appendData: boolean) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getSearchContent(
                authen.isLogin,
                clientInfo.companyPrefix,
                currentLicenseType,
                props.keyword.trim(),
                resultList?.lastEvaluatedKey as string
            );
            if (response) {
                if (appendData && resultList) {
                    setResultList({
                        ...resultList,
                        contents: resultList.contents.concat(response.contents),
                        lastEvaluatedKey: response.lastEvaluatedKey ?? undefined
                    });
                } else {
                    setResultList(response);
                }
            }
        } finally {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const onSelectedSegment = (index: number) => {
        switch (index) {
            case 0:
                setCurrentLicenseType(LicenseType.Rent);
                break;
            case 1:
                setCurrentLicenseType(LicenseType.Free);
                break;
            case 2:
                setCurrentLicenseType(LicenseType.Private);
                break;
            default:
                setCurrentLicenseType(LicenseType.Rent);
                break;
        }
    };

    return (
        <div className='store view-all-content'>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}{' '}
                    | ผลการค้นหา "{props.keyword}"
                </title>
            </Helmet>
            <h1 className='viewAllTitle'>ผลการค้นหา "{props.keyword}"</h1>
            <SegmentButton
                buttonTitles={['ห้องสมุด', 'เผยแพร่ฟรี', 'สื่อองค์กร']}
                defaultIndex={0}
                onSelected={onSelectedSegment}
            />

            <SearchResultListView contents={resultList?.contents} contentType={ContentType.ebook} />
            <Loading id={pageId} />
        </div>
    );
};

interface SearchResultListViewProps {
    contents?: Content[];
    contentType: ContentType;
}

const SearchResultListView = (props: SearchResultListViewProps) => {
    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch
        }),
        shallowEqual
    );

    const generateClassName = () => {
        switch (props.contentType) {
            case ContentType.ebook:
                return `content-list-container-ebook`;
            case ContentType.video:
                return `content-list-container-video`;
            case ContentType.podcast:
                return `content-list-container-podcast`;
            default:
                return `content-list-container-ebook`;
        }
    };

    const renderContent = () => {
        if (props.contents && props.contents.length > 0) {
            return (
                <div className='content-list'>
                    <div className={generateClassName()}>
                        {props.contents?.map((content, index: number) => (
                            <ContentCollectionView
                                key={`${content.contentUID}_${index}`}
                                content={content}
                                licenseType={content.licenseType}
                            />
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                !request.loading && (
                    <div className='content-list flex-center'>
                        <EmptyDataView />
                    </div>
                )
            );
        }
    };

    return <>{renderContent()}</>;
};
