import './tag.scss';

interface TagProps {
    text: string;
    path: string;
}

const TagStyle = (props: TagProps) => {
    return (
        <a className='tagStyle' href={props.path}>
            {props.text}
        </a>
    );
};

export default TagStyle;
