import { ClientInfoAction, ClientInfoActionType } from '.';
import { ClientInfo } from '../../../interface/client';

export const setClientInfo = (clientInfo: ClientInfo): ClientInfoAction => {
    return {
        type: ClientInfoActionType.SetClient,
        payload: clientInfo
    };
};
export const setReadingGoal = (readingGoal: number): ClientInfoAction => {
    return {
        type: ClientInfoActionType.SetReadingGoal,
        payload: readingGoal
    };
};
