export enum SplashLoadingStatus {
   Show,
   Hide,
   AnimateHide
}
export interface SplashLoadingState {
   status: SplashLoadingStatus;
}
const initialState: SplashLoadingState = {
   status: SplashLoadingStatus.Show
};
export enum SplashLoadingActionType {
   Show = 'ShowSplashLoading',
   Hide = 'HideSplashLoading',
   AnimateHide = 'AnimateHideSplashLoading'
}
export interface SplashLoadingAction {
   type: SplashLoadingActionType;
}
export const reducer = (state = initialState, action: SplashLoadingAction): SplashLoadingState => {
   switch (action.type) {
      case SplashLoadingActionType.Show:
         return {
            status: SplashLoadingStatus.Show
         };
      case SplashLoadingActionType.Hide:
         return {
            status: SplashLoadingStatus.Hide
         };
      case SplashLoadingActionType.AnimateHide:
         if (state.status !== SplashLoadingStatus.Hide) {
            return {
               status: SplashLoadingStatus.AnimateHide
            };
         } else {
            return state;
         }
      default:
         return state;
   }
};
