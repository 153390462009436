import { memo } from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { getPublisherRoute, getDetailRoute, getWriterRoute } from '../../routes/generatePath';
import { UploadDateView } from '../../helpers/changeFormatDateTime';
import { ImageCover } from './imageCover';
import {
    Content,
    ContentType,
    ContentViewType,
    LicenseType,
    VideoContent
} from '../../../interface/home';
import dayjs from 'dayjs';
import './index.scss';

export interface ContentViewProps {
    content: Content;
    licenseType: LicenseType;
    viewType?: ContentViewType;
    children?: JSX.Element | JSX.Element[] | string | string[];
}

export const ContentCollectionView = (props: ContentViewProps) => {
    return (
        <div className='content-collection'>
            <ContentCover {...props} />
            <ContentMetaView {...props} />
        </div>
    );
};
export const MemoContentCollectionView = memo(ContentCollectionView);

export const ContentCover = (props: ContentViewProps) => {
    const checkIsExpire = () => {
        if (props.content?.expireDate) return dayjs().unix() > props.content?.expireDate;
        else return false;
    };

    // const getContentTypeClass = (contentType: ContentType) => {
    //     switch (contentType) {
    //         case ContentType.video:
    //             return 'cover video';
    //         case ContentType.podcast:
    //             return 'cover podcast';
    //         default:
    //             return 'cover';
    //     }
    // };

    // const getCoverClassName = (
    //     viewType: ContentViewType = ContentViewType.Collection,
    //     contentType: ContentType,
    //     isSafari = false
    // ) => {
    //     let className = '';
    //     switch (viewType) {
    //         case ContentViewType.List:
    //         case ContentViewType.Collection:
    //         case ContentViewType.BestSeller:
    //         case ContentViewType.History:
    //         case ContentViewType.Queue:
    //         case ContentViewType.BookShelf:
    //             // className = `${getContentTypeClass(contentType)} bestseller`;
    //             className = `${getContentTypeClass(contentType)}`;
    //             if (isSafari) className += ' safariFix';
    //             break;
    //         case ContentViewType.Detail:
    //             className = `${getContentTypeClass(contentType)} ebookdetail`;
    //             if (isSafari) className += ' safariFixShadow';
    //             break;
    //         default:
    //             className = `${getContentTypeClass(contentType)}`;
    //             break;
    //     }
    //     return className;
    // };

    return (
        <div className='content-cover'>
            <ImageCover
                coverImage={props.content.coverImage}
                fileType={props.content.fileType}
                path={
                    checkIsExpire()
                        ? '#'
                        : getDetailRoute(
                              props.content.contentUID,
                              props.content.contentType,
                              props.licenseType
                          )
                }
            />
            {props.content.contentType === ContentType.video ? (
                <div className='total-time'>
                    {(props.content as VideoContent).duration
                        ? (props.content as VideoContent).duration
                        : 'ไม่มีข้อมูล'}
                </div>
            ) : null}
        </div>
    );
};

export const ContentMetaView = (props: ContentViewProps) => {
    const checkIsExpire = () => {
        if (props.content?.expireDate) {
            return dayjs().unix() > props.content?.expireDate;
        } else {
            return false;
        }
    };

    const renderDetail = () => {
        switch (props.content.licenseType) {
            case LicenseType.Private: {
                return (
                    <>
                        {props.content.channelName ? (
                            <Tooltip title={props.content.channelName}>
                                <Link
                                    style={
                                        props.content.title === 'ไม่มีข้อมูล'
                                            ? { pointerEvents: 'none' }
                                            : {}
                                    }
                                    to={{
                                        pathname: getPublisherRoute(
                                            props.content.creatorUID,
                                            props.licenseType,
                                            props.content.contentType
                                        )
                                    }}
                                >
                                    <span className='spanLink'>{props.content.channelName}</span>
                                </Link>
                            </Tooltip>
                        ) : (
                            <Tooltip title={props.content.authorName}>
                                <span className='spanLink'>{props.content.authorName}</span>
                            </Tooltip>
                        )}
                        {(props.content.contentType === ContentType.ebook ||
                            props.content.contentType === ContentType.podcast) && (
                            <UploadDateView {...props} />
                        )}
                    </>
                );
            }
            default:
                return (
                    <Tooltip title={props.content.writerName}>
                        <Link
                            to={getWriterRoute(
                                props.content.writerUID,
                                undefined,
                                props.licenseType,
                                props.content.contentType
                            )}
                        >
                            {props.content.writerName}
                        </Link>
                    </Tooltip>
                );
        }
    };

    return (
        <div className='bookTitleContainer'>
            <Tooltip title={props.content.title}>
                <Link
                    to={
                        checkIsExpire()
                            ? '#'
                            : getDetailRoute(
                                  props.content.contentUID,
                                  props.content.contentType,
                                  props.content.licenseType
                              )
                    }
                    className={checkIsExpire() ? ' bookTitle cursor-not-allowed' : 'bookTitle'}
                >
                    {props.content.title}
                </Link>
            </Tooltip>

            <div className='detail'>{renderDetail()}</div>
            {props.children}
        </div>
    );
};

//! ไม่ใช่ลบด้วย
export const HomeSwiperEbook = {
    1: {
        slidesPerView: 3.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    376: {
        slidesPerView: 3.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    541: {
        slidesPerView: 4.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    769: {
        slidesPerView: 5.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    1025: {
        slidesPerView: 7.5,
        allowSlidePrev: true,
        allowSlideNext: true
    }
};

export const HomeSwiperVideo = {
    1: {
        slidesPerView: 1.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    376: {
        slidesPerView: 1.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    541: {
        slidesPerView: 2.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    769: {
        slidesPerView: 3.2,
        allowSlidePrev: true,
        allowSlideNext: true
    }
};

export const HomeSwiperPodcast = {
    1: {
        slidesPerView: 1.8,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    376: {
        slidesPerView: 1.8,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    541: {
        slidesPerView: 2.8,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    769: {
        slidesPerView: 3.8,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    1025: {
        slidesPerView: 4.8,
        allowSlidePrev: true,
        allowSlideNext: true
    }
};

export const HomeSwiperCategory = {
    1: {
        slidesPerView: 2.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    376: {
        slidesPerView: 2.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    541: {
        slidesPerView: 3.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    769: {
        slidesPerView: 4.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    1025: {
        slidesPerView: 5.2,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    1281: {
        slidesPerView: 6.2,
        allowSlidePrev: true,
        allowSlideNext: true
    }
};

export const SwiperBestseller = {
    1: {
        slidesPerView: 1.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },

    541: {
        slidesPerView: 1.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    769: {
        slidesPerView: 2.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    1025: {
        slidesPerView: 3.5,
        allowSlidePrev: true,
        allowSlideNext: true
    },
    1281: {
        slidesPerView: 3.5,
        allowSlidePrev: true,
        allowSlideNext: true
    }
};

export const SponsorBreakpoint = {
    1: {
        slidesPerView: 2.5
    },
    576.98: {
        slidesPerView: 3.5
    },
    768.98: {
        slidesPerView: 4.5
    },
    1024.98: {
        slidesPerView: 7
    }
};

export const SponsorBannerBreakPoint = {
    1: {
        slidesPerView: 2
    },
    576.98: {
        slidesPerView: 2
    },
    768.98: {
        slidesPerView: 3
    }
};
