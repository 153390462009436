import { persistReducer } from 'redux-persist';
import { Themes } from '../../../interface/client';
import storage from 'redux-persist/lib/storage';

export interface ThemeState {
    normalTheme: Themes;
    darkTheme: Themes;
    darkMode: boolean;
}

export const normalTheme: Themes = {
    Primary: '#012875',
    PrimaryVariant: '#001B51',
    Secondary: '#FD6B53',
    SecondaryVariant: '#FF5337',
    Background: '#f2f2f2',
    Surface: '#ffffff',
    Surface2: '#fafafa',
    Error: '#d7042b',
    OnPrimary: '#ffffff',
    OnSecondary: '#ffffff',
    OnBackground: '#012875',
    OnSurface: '#012875',
    OnError: '#ffffff',
    Title: '#012875',
    Header: '#012875',
    SubHeader: '#333333',
    Body: '#ffffff',
    grey1: '#1c1c1e',
    grey2: '#333333',
    grey3: '#4f4f4f',
    grey4: '#828282',
    grey5: '#bdbdbd',
    grey6: '#f2f2f2',
    grey7: '#ffffff',
    PrimaryDark: '#012875',
    OnPrimaryDark: '#ffffff',
    htiPrimaryLight: '#012875',
    htiPrimaryDark: '#012875',
    htiPrimaryVariant: '#001b51',
    htiSecondary: '#fd6b53',
    htiSecondaryVariant: '#ff5337',
    modalBackground: '#ffffff'
};

export const darkTheme: Themes = {
    Primary: '#012875',
    PrimaryVariant: '#001B51',
    Secondary: '#FD6B53',
    SecondaryVariant: '#FF5337',
    Background: '#000000',
    Surface: '#000000',
    Surface2: '#232323',
    Error: '#d7042b',
    OnPrimary: '#ffffff',
    OnSecondary: '#ffffff',
    OnBackground: '#ffffff',
    OnSurface: '#ffffff',
    OnError: '#ffffff',
    Title: '#ffffff',
    Header: '#f2f2f2',
    SubHeader: '#f2f2f2',
    Body: '#1c1c1e',
    grey1: '#ffffff',
    grey2: '#f2f2f2',
    grey3: '#bdbdbd',
    grey4: '#828282',
    grey5: '#4f4f4f',
    grey6: '#333333',
    grey7: '#1c1c1e',
    PrimaryDark: '#1c1c1e',
    OnPrimaryDark: '#ffffff',
    htiPrimaryLight: '#ffffff',
    htiPrimaryDark: '#000000',
    htiPrimaryVariant: '#ffffff',
    htiSecondary: '#ffffff',
    htiSecondaryVariant: '#ffffff',
    modalBackground: '#333333'
};

export const initialState: ThemeState = {
    normalTheme: normalTheme,
    darkTheme: darkTheme,
    darkMode: false
};

export enum ThemeActionType {
    SetTheme = 'SetTheme[THEME]',
    SetDarkTheme = 'SetDarkTheme[THEME]'
}
export interface ThemeAction {
    type: ThemeActionType;
    payload: Themes | boolean;
}

export const reducer = persistReducer(
    {
        storage,
        key: 'hibrary-themes',
        whitelist: ['normalTheme', 'darkTheme', 'darkMode']
    },
    (state: ThemeState = initialState, action: ThemeAction): ThemeState => {
        switch (action.type) {
            case ThemeActionType.SetTheme:
                return { ...state, normalTheme: action.payload as Themes };
            case ThemeActionType.SetDarkTheme:
                return { ...state, darkMode: action.payload as boolean };
            default:
                return state;
        }
    }
);
