import { Swiper, SwiperSlide } from 'swiper/react';
import { CustomHomeBanner } from '../../../interface';
import { onErrorImage } from '../../Utilities/imageUtilities';

interface BannerProps {
    banners: CustomHomeBanner[];
}

const BannerRectangleView = (props: BannerProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 2.5
        },
        576.98: {
            slidesPerView: 3.5
        }
    };

    return (
        <Swiper
            centeredSlides={true}
            breakpoints={breakpoint}
            spaceBetween={16}
            loop={true}
            // autoplay={{ delay: 5000, disableOnInteraction: false }}
            className='banner-rectangle'
        >
            {props.banners.map((el, index) => (
                <SwiperSlide key={index}>
                    {el.value ? (
                        <a href={el.value} target='_blank'>
                            <img src={el.imageUrl} loading='lazy' onError={onErrorImage} />
                        </a>
                    ) : (
                        <img src={el.imageUrl} loading='lazy' onError={onErrorImage} />
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default BannerRectangleView;
