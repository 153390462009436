export enum AnswerType {
  Radio = 'radio',
  CheckBox = 'checkBox',
  Input = 'input',
  Rating = 'rating',
  MostCategoryRent = 'mostCategoryRent'
}

export enum formType {
  Question = 'question',
  Answer = 'answer'
}

export interface FormTypeResponse {
  question: FormDataType;
}

export interface FormDataType {
  section: SectionType[];
  subtitle: string;
  title: string;
}

export interface SectionType {
  question: QuestionType[];
  sectionUID: string;
  title: string;
}

export interface QuestionType {
  answerPreset?: AnswerPresetType[];
  answerType: AnswerType;
  questionUID: string;
  title: string;
}

export interface AnswerPresetType {
  answerUID: string;
  display: string;
  value: string | number;
}

export interface QuestionComponentProps {
  data: QuestionType;
  onChange: (value: any) => void;
  formikValue: any;
}
