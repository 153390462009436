import { Empty } from 'antd';
import './index.scss';
import { useIntl } from 'react-intl';

interface EmptyDataViewProps {
    description?: string;
}

export const EmptyDataView = (props: EmptyDataViewProps) => {
    const intl = useIntl();
    return (
        <div className='nodata-view'>
            <Empty
                description={
                    props.description ??
                    intl.formatMessage({ id: 'Action.NoDataFound' })
                }
            />
        </div>
    );
};
