import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CarouselEle } from '../banner';
import { Helmet } from 'react-helmet-async';
import { HibraryRootState } from '../../../redux/rootReducer';
import { HomeState } from '../../redux/home';
import { ContentType, LicenseType } from '../../../interface/home';
import StoreHomeList from './storeHomeList';
import * as actions from '../../redux/store/storeActions';
import 'swiper/swiper.scss';
import './index.scss';

interface StoreHomePageParam {
    licenseType?: LicenseType;
    contentType?: ContentType;
}

export const StoreHomePage = (props: StoreHomePageParam) => {
    const pageId = 'StoreHomeList';
    const dispatch = useDispatch();

    const { clientInfo, home, authState, request } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            clientInfo: state.clientInfo,
            home: state.home,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        if (checkReloadContent() && !request.loading)
            fetchStore(authState.authToken === undefined ? false : true);
    }, [authState]);

    useEffect(() => {
        if (home.isReset) fetchStore(authState.authToken === undefined ? false : true);
    }, [home]);

    const fetchStore = (isLogin: boolean) => {
        dispatch(
            actions.fetchStoreHome(
                pageId,
                isLogin,
                clientInfo.companyPrefix,
                props.licenseType ?? LicenseType.Rent,
                props.contentType
            )
        );
    };

    const checkReloadContent = () => {
        switch (props.licenseType) {
            case LicenseType.Rent: {
                if (!home.listHomeHibrary) {
                    return true;
                } else {
                    return false;
                }
            }
            case LicenseType.Free:
                if (!home.listHomeFree) {
                    return true;
                } else {
                    return false;
                }
            case LicenseType.Private:
                if (props.contentType) {
                    return true;
                }
                if (!home.listHomePrivate) {
                    return true;
                } else {
                    return false;
                }
            default:
                return true;
        }
    };

    const MainHome = useMemo(() => {
        const getHomeModel = (type: LicenseType, home: HomeState) => {
            if (props.contentType === undefined || props.contentType === ContentType.content) {
                switch (type) {
                    case LicenseType.Rent:
                        return home.listHomeHibrary;
                    case LicenseType.Free:
                        return home.listHomeFree;
                    case LicenseType.Private:
                        return home.listHomePrivate;
                    default:
                        return [];
                }
            } else {
                return home.listHomeContent;
            }
        };

        return (
            <StoreHomeList
                homes={getHomeModel(props.licenseType!, home)}
                licenseType={props.licenseType ?? LicenseType.Rent}
                contentType={props.contentType ?? ContentType.ebook}
            />
        );
    }, [home]);

    const MainBanner = useMemo(() => {
        return <CarouselEle banners={home.banners ?? []} />;
    }, [home]);

    return (
        <div className='store'>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}{' '}
                    | ห้องสมุดออนไลน์ (E-library) {clientInfo.displayName}
                </title>
            </Helmet>
            {home.banners!.length > 0 ? MainBanner : null}
            {MainHome}
        </div>
    );
};
