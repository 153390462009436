import { useHistory } from 'react-router-dom';
import { Button, Divider, Tag } from 'antd';
import { LicenseType, RentalStatus } from '../../../interface/home';
import { RentBtn } from '../../customUI/RentButton';
import { useSelector, shallowEqual } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { getSearchRoute } from '../../routes/generatePath';
import { SegmentHeader } from '../home/segmentHeader';
import { WriterView } from '../../components/detailPage/WriterView';
import { ConcurrentView } from '../../components/detailPage/ConcurrentView';
import { FileDetailView } from '../../components/detailPage/FileDetailView';
import { TagsView } from '../../components/detailPage/TagsView';
import { RelatedView } from '../../components/detailPage/RelateView';
import { useIntl } from 'react-intl';
import { ContentDetail } from '../../../interface/detailPage';
import CoverView from '../../components/detailPage/CoverView';
import PublisherView from '../../components/detailPage/PublisherView';
import parse from 'html-react-parser';
import './_store.scss';

export interface EbookDetailViewParam {
    content: ContentDetail;
    remainConcurrent: number;
    waitingAmount: number;
    sampleDidClick: React.MouseEventHandler<HTMLElement>;
}

export const EbookDetailView = (props: EbookDetailViewParam) => {
    const history = useHistory();
    const intl = useIntl();
    const book = props.content;
    const { clientInfo } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo
        }),
        shallowEqual
    );

    const checkSampleBtn =
        book.licenseType === LicenseType.Free ||
        book.licenseType === LicenseType.Private ||
        (!props.remainConcurrent && props.remainConcurrent !== 0)
            ? 'noSample'
            : '';

    return (
        <>
            <div>
                <div className='title'>{book.title}</div>
                <div className='column'>
                    <div className='left-detail'>
                        <CoverView {...book} hasLink={false} />
                    </div>
                    <div className='right-detail'>
                        <div className='detail-ebook'>
                            <WriterView content={book} />
                        </div>

                        <div className='detail-ebook'>
                            {clientInfo.storeStatus === 'trial' ? (
                                `${intl.formatMessage({
                                    id: 'Book.Publisher'
                                })} : ${book.publisherName}`
                            ) : (
                                <PublisherView content={book} licenseType={book.licenseType} />
                            )}
                        </div>
                        <div className={`columnButton ${checkSampleBtn}`}>
                            <div className='boxSampleButton'>
                                <SampleBtn
                                    content={book}
                                    remainConcurrent={props.remainConcurrent}
                                    waitingAmount={props.waitingAmount}
                                    didClick={(e) => props.sampleDidClick(e)}
                                />
                            </div>
                            <div className='boxRentButton'>
                                <RentBtn
                                    content={book}
                                    size='small'
                                    contentType={book.contentType}
                                    status={book.rentalStatus!}
                                    style={{ height: 40 }}
                                    licenseType={book.licenseType}
                                />
                            </div>
                        </div>
                        <Divider />
                        <div className='columnNumber'>
                            <ConcurrentView
                                content={book}
                                remainConcurrent={props.remainConcurrent}
                                waitingAmount={props.waitingAmount}
                            />
                        </div>
                        <Divider />
                        <div className='columnFile'>
                            <FileDetailView content={book} />
                        </div>
                        <Divider />
                        <TagsView content={book} />
                        <Divider />
                    </div>
                </div>
            </div>

            {book.description ? (
                <div>
                    <SegmentHeader
                        title={intl.formatMessage({ id: 'Book.Summary' })}
                        right={false}
                    />
                    <div className='description'>{parse(book.description)}</div>
                </div>
            ) : null}
            {book.tags && book.tags.length > 0 ? (
                <div className='flex  tagContainer'>
                    {book.tags.map((el) => (
                        <Tag
                            key={el}
                            onClick={() => history.push(getSearchRoute(el))}
                            style={{ cursor: 'pointer' }}
                        >
                            {el}
                        </Tag>
                    ))}
                </div>
            ) : null}
            {book.relatedContents && book.relatedContents.length > 0 ? (
                <div>
                    <SegmentHeader
                        title={intl.formatMessage({ id: 'Book.RelatedBooks' })}
                        right={false}
                    />
                    <RelatedView content={book} />
                </div>
            ) : null}
        </>
    );
};

export interface SampleBtnParam {
    content: ContentDetail;
    remainConcurrent: number;
    waitingAmount: number;
    didClick: React.MouseEventHandler<HTMLElement>;
}

export const SampleBtn = (props: SampleBtnParam) => {
    const intl = useIntl();
    return (
        <Button
            type='primary'
            shape='round'
            size='large'
            onClick={props.didClick}
            disabled={props.content.rentalStatus === RentalStatus.Rented}
        >
            {intl.formatMessage({ id: 'Book.SampleBtn' })}
        </Button>
    );
};
