import {
    LicenseType,
    ContentType,
    DisplayType,
    RenderHomeSegmentParam
} from '../../../interface/home';
import {
    getAllContentRoute,
    getHomeRoute,
    getListCategoryRoute,
    getListSubCategoryRoute,
    getListSubInCategoryRoute
} from '../../routes/generatePath';

export const generatePath = (
    route: string,
    url?: string,
    prefix?: string,
    licenseType?: LicenseType,
    contentType?: ContentType,
    isPublic = true
) => {
    let path = '';
    if (isPublic) {
        path = `/public`;
    }

    if (prefix && isPublic) {
        if (path === '/') {
            path += `${prefix}`;
        } else {
            path += `/${prefix}`;
        }
    }
    path += `/${route}`;

    if (licenseType) {
        if (licenseType !== LicenseType.Rent) {
            path += `/${licenseType!.toLowerCase()}`;
        } else if (!isPublic) {
            path += `/${licenseType!.toLowerCase()}`;
        }
    }
    if (url) {
        path += `/${url}`;
    }
    if (contentType) {
        path += `?contentType=${contentType}`;
    }

    return path;
};

//! ถ้าแก้ private แล้วลบด้วย
export const generateCreatorPath = (
    route: string,
    model?: string,
    creatorUID?: string,
    prefix?: string,
    licenseType?: LicenseType,
    contentType?: ContentType,
    isPublic = true
) => {
    let path = '';
    if (isPublic) {
        path = `/public`;
        if (prefix) {
            path += `/${prefix}`;
        }
    }
    path += `/${route}`;
    if (licenseType && licenseType !== LicenseType.Rent) {
        path += `/${licenseType.toLowerCase()}`;
    }
    if (model) {
        path += `/${model}`;
    }
    if (creatorUID) {
        path += `/${creatorUID}`;
    }
    if (contentType) {
        path += `?contentType=${contentType}`;
    }

    return path;
};

//! ถ้าแก้ private แล้วลบด้วย
export const generateCategoryApiPath = (
    route: string,
    queryPram?: string,
    prefix?: string,
    licenseType?: LicenseType,
    contentType?: ContentType,
    isPublic = true
) => {
    let path = '';

    if (isPublic) path = `/public`;
    if (prefix && isPublic) path += `/${prefix}`;
    path += `/${route}`;
    if (licenseType && licenseType !== LicenseType.Rent) path += `/${licenseType.toLowerCase()}`;
    if (queryPram) path += `/${queryPram}`;
    if (contentType) path += `?contentType=${contentType}`;

    return path;
};

export const addTwoDigits = (num: number, places: any) => {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
};

export const errorWord = (err: any) => {
    if (err && err.response && err.response.data && err.response.data.errorCode) {
        switch (err.response.data.errorCode) {
            case '9001':
                return 'AUTH.VALIDATION.INVALID_LOGIN_DEVICE';
            case '919':
                return 'AUTH.VALIDATION.INVALID_LOGIN_EMAIL';
            case '005':
                return 'AUTH.VALIDATION.INVALID_LOGIN_PASSWORD';
            case '909':
                return 'AUTH.VALIDATION.INVALID_LOGIN_EXPIRE';
            case '004':
                return 'AUTH.VALIDATION.FULL_DEVICES';
            default:
                return 'AUTH.VALIDATION.INVALID_LOGIN';
        }
    } else {
        return 'AUTH.VALIDATION.INVALID_LOGIN';
    }
};

export const generateHeaderLinkPath = (data: RenderHomeSegmentParam) => {
    switch (data.segment.type) {
        // case DisplayType.ContentSet:
        //     return getSetContentRoute(data.segment!.setType!, data.licenseType);
        case DisplayType.CategoryEbook:
            return getListSubInCategoryRoute(
                data.segment.catId!,
                data.licenseType,
                data.segment.contentType
            );
        case DisplayType.SubCategoryEbook:
            return getListSubCategoryRoute(
                data.segment.catId!,
                data.segment.subcatId!,
                data.licenseType,
                data.segment.contentType
            );
        case DisplayType.AllContent:
            return getAllContentRoute(data.licenseType, data.segment.contentType);
        case DisplayType.NewContent:
            return getHomeRoute(data.licenseType, data.segment.contentType);
        case DisplayType.Categories:
            return getListCategoryRoute(data.licenseType, data.contentType);
        case DisplayType.RentAbleContent:
            return `/ebook/all/RentableContent`;
        default:
            return '/';
    }
};
