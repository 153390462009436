import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useIntl } from 'react-intl';

dayjs.extend(utc);
dayjs.extend(timezone);

export const changeTimeStampToTextOld = (timestamp: number) => {
    if (timestamp) {
        if (timestamp - dayjs().tz('Asia/Bangkok').unix() >= 86400) {
            return Math.floor((timestamp - dayjs().tz('Asia/Bangkok').unix()) / 86400) + ' วัน';
        } else if (timestamp - dayjs().tz('Asia/Bangkok').unix() >= 3600) {
            if (
                Math.floor(((timestamp - dayjs().tz('Asia/Bangkok').unix()) / 86400) * 24 * 60) %
                    60 ===
                0
            ) {
                return (
                    Math.floor(((timestamp - dayjs().tz('Asia/Bangkok').unix()) / 86400) * 24) +
                    ' ชั่วโมง'
                );
            } else {
                return (
                    Math.floor(((timestamp - dayjs().tz('Asia/Bangkok').unix()) / 86400) * 24) +
                    ' ชม. ' +
                    (Math.floor(
                        ((timestamp - dayjs().tz('Asia/Bangkok').unix()) / 86400) * 24 * 60
                    ) %
                        60) +
                    ' นาที'
                );
            }
        } else if (timestamp - dayjs().tz('Asia/Bangkok').unix() >= 60) {
            return (
                Math.floor(((timestamp - dayjs().tz('Asia/Bangkok').unix()) / 86400) * 24 * 60) +
                ' นาที'
            );
        } else {
            return '1 นาที';
        }
    }
};

export const changeTimeStampToText = (timestamp: number) => {
    const intl = useIntl();
    let timeString = '';

    if (timestamp) {
        const day = Math.floor((timestamp - dayjs().unix()) / 86400);
        const hour = Math.floor(((timestamp - dayjs().unix()) / 3600) % 24);
        const minus = Math.floor(((timestamp - dayjs().unix()) / 86400) * 24 * 60) % 60;

        if (day > 0) {
            timeString += day + ` ${intl.formatMessage({ id: 'Unit.Day' })} `;
        }
        if (hour > 0) {
            timeString += hour + ` ${intl.formatMessage({ id: 'Unit.Hour' })} `;
        }
        if (minus > 0 && day <= 0) {
            timeString += minus + ` ${intl.formatMessage({ id: 'Unit.Min' })} `;
        }
    }

    return timeString;
};
