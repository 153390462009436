import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Sponsor } from '../../../interface/sponsor';
import SponsorModal from '../modal/sponsorModal/sponsorModal';
import './index.scss';

interface SponsorProps {
    contents: Sponsor[];
}

const SponsorView = (props: SponsorProps) => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const breakpoint = {
        1: {
            slidesPerView: 2.2
        },
        576.98: {
            slidesPerView: 4.2
        },
        768.98: {
            slidesPerView: 7.2
        }
    };

    return (
        <>
            <div className='sponsorContainer'>
                <Swiper spaceBetween={16} breakpoints={breakpoint}>
                    {props.contents.map((el, index) => (
                        <SwiperSlide className='itemContainer' key={index + 1}>
                            <div
                                className='coverImage'
                                onClick={() => {
                                    history.push({ search: `?uid=${el.sponsorUID}` });
                                    setModalVisible(true);
                                }}
                            >
                                <img src={el.coverImage} />
                            </div>
                            <p className='sponsor-name'>{el.name}</p>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <SponsorModal visible={modalVisible} onCancel={() => setModalVisible(false)} />
        </>
    );
};

export default SponsorView;
