import { useMemo } from 'react';
import { HibraryRootState } from '../../redux/rootReducer';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { NavBar } from '../components/navbar/NavBar';
import { MobileView } from 'react-device-detect';
import BasePage from '../pages/base/BasePage';
import Footer from '../components/footer/Footer';

const MobileViewPage = () => {
   const { authen } = useSelector(
      (state: HibraryRootState) => ({
         authen: state.auth
      }),
      shallowEqual
   );

   const basePageMemoAuth = useMemo(() => {
      return <BasePage />;
   }, [authen]);

   return (
      <MobileView>
         <Switch>
            <Route path='/'>
               <NavBar />
               {basePageMemoAuth}
               <Footer />
            </Route>
         </Switch>
      </MobileView>
   );
};

export default MobileViewPage;
