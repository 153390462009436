import { useIntl } from 'react-intl';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  AuthPageType,
  ClientInfo,
  LoginResponse,
  OtpRegister
} from '../../../../../interface';
import { registerForm } from '../../../../redux/auth/authCrud';
import { Button, Input, Tooltip } from 'antd';
import { SetStateAction, useState } from 'react';
import * as Yup from 'yup';
import SVG from 'react-inlinesvg';
import { VerifyOTPAction } from '../../../../redux/verifyOTP';

interface RegisterFormProps {
  otp: OtpRegister;
  verifyOTP: string;
  referenceCode: string;
  location: GeolocationCoordinates;
  setUserTokenRegister: React.Dispatch<
    SetStateAction<LoginResponse | undefined>
  >;
  setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
}

const RegisterForm = (props: RegisterFormProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [seePassword, setSeePassword] = useState<boolean>(false);

  const { authState, clientInfo } = useSelector(
    (state: HibraryRootState) => ({
      authState: state.auth,
      clientInfo: state.clientInfo as ClientInfo
    }),
    shallowEqual
  );

  const eyeIcon = (
    <Tooltip title={seePassword ? 'ซ่อนรหัสผ่าน' : 'แสดงรหัสผ่าน'}>
      <SVG
        src={seePassword ? `/images/eye-slash.svg` : `/images/eye.svg`}
        onClick={() => setSeePassword(!seePassword)}
      />
    </Tooltip>
  );

  const PasswordSchemaRequired = Yup.object().shape({
    password: Yup.string()
      .min(6, 'อย่างน้อย 6 ตัวอักษร')
      .max(50, 'ไม่เกิน 50 ตัวอักษร')
      .matches(
        /([A-Za-z0-9@$!%*?&_.]{6,20})/,
        'ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น'
      )
      .required(),
    staffID: Yup.string().max(50, 'ไม่เกิน 50 ตัวอักษร').required(),
    surname: Yup.string().max(50, 'ไม่เกิน 50 ตัวอักษร').required(),
    name: Yup.string().max(50, 'ไม่เกิน 50 ตัวอักษร').required()
  });

  const PasswordSchemaNotRequired = Yup.object().shape({
    password: Yup.string()
      .min(6, 'อย่างน้อย 6 ตัวอักษร')
      .max(50, 'ไม่เกิน 50 ตัวอักษร')
      .matches(
        /([A-Za-z0-9@$!%*?&_.]{6,20})/,
        'ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น'
      ),
    surname: Yup.string().max(50, 'ไม่เกิน 50 ตัวอักษร').required(),
    name: Yup.string().max(50, 'ไม่เกิน 50 ตัวอักษร').required()
  });

  const formik = useFormik({
    initialValues: {
      name: authState.userInfoForRegister?.name
        ? authState.userInfoForRegister?.name
        : '',
      surname: authState.userInfoForRegister?.surname
        ? authState.userInfoForRegister.surname
        : '',
      userID: props.otp.userID,
      password: undefined,
      staffID: authState.userInfoForRegister?.staffID
        ? authState.userInfoForRegister.staffID
        : ''
    },
    validationSchema: clientInfo.requireEmployeeNumber
      ? PasswordSchemaRequired
      : PasswordSchemaNotRequired,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await registerForm(
          authState.prefixRegister
            ? authState.prefixRegister
            : clientInfo.companyPrefix,
          values.userID,
          values.name,
          values.password,
          values.surname,
          props.verifyOTP,
          values.staffID,
          props.location?.latitude,
          props.location?.longitude,
          props.referenceCode
        );

        if (response) {
          props.setUserTokenRegister(response);
          props.setPageType(AuthPageType.SuccessRegister);
          dispatch(VerifyOTPAction.resetRegisterOTP());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <div className="registerFormContainer">
      <h1>
        {intl.formatMessage({
          id: 'Register.Title'
        })}
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="inputItem">
          <Input
            value={formik.values.name}
            placeholder={intl.formatMessage({
              id: 'Register.FirstNamePlaceholder'
            })}
            className="formInput"
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
            // disabled={authState.userInfoForRegister?.name !== undefined}
          />
          {formik.errors.name && formik.touched.name && (
            <div className="error-text">{formik.errors.name}</div>
          )}
        </div>
        <div className="inputItem">
          <Input
            value={formik.values.surname}
            placeholder={intl.formatMessage({
              id: 'Register.LastNamePlaceholder'
            })}
            className="formInput"
            onChange={(e) => formik.setFieldValue('surname', e.target.value)}
            // disabled={authState.userInfoForRegister?.surname !== undefined}
          />
          {formik.errors.surname && formik.touched.surname && (
            <div className="error-text">{formik.errors.surname}</div>
          )}
        </div>
        {clientInfo.requireEmployeeNumber ? (
          <div className="formItem">
            <Input
              value={formik.values.staffID}
              placeholder={intl.formatMessage({
                id: 'Register.IDPlaceholder'
              })}
              className="formInput"
              autoComplete="new-password"
              onChange={(e) => formik.setFieldValue('staffID', e.target.value)}
              disabled={authState.userInfoForRegister?.staffID !== undefined}
            />
            {formik.errors.staffID && formik.touched.staffID && (
              <div className="error-text">{formik.errors.staffID}</div>
            )}
          </div>
        ) : null}
        {!authState.isLogin && (
          <div className="formItem">
            <Input
              value={formik.values.password}
              type={seePassword ? 'text' : 'password'}
              placeholder={intl.formatMessage({
                id: 'Register.PasswordPlaceholder'
              })}
              className="formInput"
              autoComplete="new-password"
              onChange={(e) => formik.setFieldValue('password', e.target.value)}
              suffix={eyeIcon}
            />
            {formik.errors.password && formik.touched.password && (
              <div className="error-text">{formik.errors.password}</div>
            )}
          </div>
        )}
        <Input
          disabled
          type="userID"
          placeholder="example@gmail.com"
          className="formInput"
          value={formik.values.userID}
          onChange={(e) => formik.setFieldValue('userID', e.target.value)}
        />

        <div className="buttonContain">
          <Button className="acceptButton" loading={loading} htmlType="submit">
            {loading
              ? intl.formatMessage({ id: 'Action.JustAMoment' })
              : intl.formatMessage({
                  id: 'Action.ConfirmBtn'
                })}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
