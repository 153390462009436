import { Content, ContentType, LicenseType } from '../../../interface';
import { ContentDetail, DetailViewParam } from '../../../interface/detailPage';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContentCollectionView } from '../contentCover';
import {
    breakpointEbook,
    breakpointPodcast,
    breakpointVideo
} from '../../Utilities/swiperBreakpoint';

export const RelatedView = (props: DetailViewParam) => {
    const content = props.content as ContentDetail;
    let sw;
    switch (content.contentType) {
        case ContentType.video:
            sw = breakpointVideo;
            break;
        case ContentType.podcast:
            sw = breakpointPodcast;
            break;
        default:
            sw = breakpointEbook;
            break;
    }

    return (
        <div className={`relate-view-container`}>
            <Swiper breakpoints={sw} spaceBetween={16} centeredSlides={false} loop={false}>
                {content.relatedContents
                    ? content.relatedContents.map((e: Content) => (
                          <SwiperSlide key={`col_${e.contentUID}`}>
                              <ContentCollectionView
                                  content={e}
                                  licenseType={e.licenseType as LicenseType}
                              />
                          </SwiperSlide>
                      ))
                    : null}
                {content.relatedContents
                    ? content.relatedContents.map((e: Content) => (
                          <SwiperSlide key={`col_${e.contentUID}`}>
                              <ContentCollectionView
                                  content={e}
                                  licenseType={e.licenseType as LicenseType}
                              />
                          </SwiperSlide>
                      ))
                    : null}
            </Swiper>
        </div>
    );
};
