import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { removeSplash } from '../../helpers/removeSplash';
import { HibraryRootState } from '../../../redux/rootReducer';
import { useParams } from 'react-router-dom';
import { action } from '../../redux/request';
import { getQRCode } from '../../redux/qrCode/qrCode';
import { QRCodeResponse } from '../../../interface/qrCode';
import { Loading } from '../../customUI/loading';
import { getDynamicLink } from '../../Utilities/dynamicLink';
import { Platform } from '../../../interface/dynamicLink';
import { isMobile } from 'react-device-detect';
import './index.scss';

interface Params {
   qrCodeUID: string;
}

export const QrCode = () => {
   const pageId = 'QRCode';
   const dispatch = useDispatch();
   const { qrCodeUID } = useParams<Params>();

   const [qrCode, setQrCode] = useState<QRCodeResponse>();

   const { clientInfo, request, authen } = useSelector(
      (state: HibraryRootState) => ({
         clientInfo: state.clientInfo,
         request: state.fetch,
         authen: state.auth
      })
   );

   useEffect(() => {
      if (authen.isCuDomain !== undefined) {
         fetchQRCode();
      }
   }, [authen.isCuDomain]);

   const fetchQRCode = async () => {
      try {
         dispatch(action.setProcess(true));
         const response = await getQRCode(clientInfo.companyPrefix, qrCodeUID);
         if (response) {
            setQrCode(response);
            const url = getDynamicLink(
               window.location.href,
               authen.isCuDomain ? Platform.CuElibrary : Platform.Hibrary
            );

            if (isMobile) window.location.href = url;
         }
      } finally {
         dispatch(action.setProcess(false));
         dispatch(removeSplash);
      }

      // setTimeout(() => {
      //    const url = getDynamicLink(
      //       window.location.href,
      //       authen.isCuDomain ? Platform.CuElibrary : Platform.Hibrary
      //    );
      //    if (isMobile) window.location.href = url;
      // }, 500);
   };

   return (
      <div className='qrCodeContainer'>
         {!request.loading && qrCode && (
            <div className='contentContainer'>
               <div>
                  <h1>{qrCode.name}</h1>
                  <h4>
                     สามารถสแกน QR Code ได้จากกล้องโทรศัพท์มือถือ
                     และบนแอปพลิเคชัน Hibrary
                  </h4>
               </div>
               <div className='qrCodeCover'>
                  <img src={qrCode.QRcode} />
               </div>
            </div>
         )}
         <Loading id={pageId} />
      </div>
   );
};

export default QrCode;
