import { combineReducers } from 'redux';
// import { categoriesSlice } from '../app/redux/categories/categoriesSlice';
import { HomeState } from '../app/redux/home';
import { LoginState } from '../app/redux/login';
import * as auth from '../app/redux/auth/authRedux';
import * as rental from '../app/redux/rental';
import * as splashLoading from '../app/redux/splashLoading';
import * as theme from '../app/redux/theme';
import * as clientInfo from '../app/redux/clientInfo';
import * as home from '../app/redux/home';
import * as login from '../app/redux/login';
import * as Fetch from '../app/redux/request';
import * as environment from '../app/redux/environment';
import * as notification from '../app/redux/notification';
import * as verifyOTP from '../app/redux/verifyOTP';

export interface HibraryRootState {
  auth: auth.UserAuthentication;
  //   categories: any;
  //   ebooks: any;
  rental: rental.RentalState;
  splashLoading: splashLoading.SplashLoadingState;
  theme: theme.ThemeState;
  darkmode: boolean;
  clientInfo: clientInfo.ClientInfoState;
  home: HomeState;
  login: LoginState;
  fetch: Fetch.FetchState;
  environment: environment.EnvState;
  notification: notification.NotificationState;
  verifyOTP: verifyOTP.VerifyOTPState;
}

export const rootReducer = combineReducers({
  auth: auth.reducer,
  //   categories: categoriesSlice.reducer,
  //   ebooks: ebooksSlice.reducer,
  rental: rental.reducer,
  splashLoading: splashLoading.reducer,
  theme: theme.reducer,
  clientInfo: clientInfo.reducer,
  home: home.reducer,
  login: login.reducer,
  fetch: Fetch.reducer,
  environment: environment.reducer,
  notification: notification.reducer,
  verifyOTP: verifyOTP.reducer
});
