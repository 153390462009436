import { Platform } from '../../interface/dynamicLink';

const AppID = {
  Hibrary: {
    IOSBundleID: 'hti.elibrary.ios',
    IOSAppID: '1519192143',
    AndroidPackageName: 'com.hti.elibrary.android'
  },
  CuElibrary: {
    IOSBundleID: 'hti.cu.e-library.ios',
    IOSAppID: '1607050195',
    AndroidPackageName: 'hti.cu.elibrary.android'
  }
};

export const getDynamicLink = (
  url: string,
  platform: Platform = Platform.Hibrary
) => {
  let dynamicUrl = ``;
  switch (platform) {
    case Platform.Hibrary:
      dynamicUrl += `${process.env.REACT_APP_DYNAMIC_LINK_HOST}?link=${url}&apn=${AppID.Hibrary.AndroidPackageName}&ibi=${AppID.Hibrary.IOSBundleID}&ipbi=${AppID.Hibrary.IOSBundleID}&isi=${AppID.Hibrary.IOSAppID}`;
      break;
    case Platform.CuElibrary:
      dynamicUrl += `${process.env.REACT_APP_CU_DYNAMIC_LINK_HOST}?link=${url}&apn=${AppID.CuElibrary.AndroidPackageName}&ibi=${AppID.CuElibrary.IOSBundleID}&ipbi=${AppID.CuElibrary.IOSBundleID}&isi=${AppID.CuElibrary.IOSAppID}`;
      break;
    default:
      break;
  }
  return dynamicUrl;
};
