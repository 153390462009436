/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getPublisher } from '../../redux/catalog/catalogCrud';
import { removeSplash } from '../../helpers/removeSplash';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { HibraryRootState } from '../../../redux/rootReducer';
import { EmptyDataView } from '../../components/nodata';
import './index.scss';
interface Publisher {
    uid: string;
    name: string;
    profileImage: string;
}

export const Publisher = () => {
    const pageId = 'catalogPublisherPage';
    const dispatch = useDispatch();
    const history = useHistory();
    const [publisher, setPublisher] = useState<Publisher[]>([]);

    const { request } = useSelector((state: HibraryRootState) => ({
        request: state.fetch
    }));

    useEffect(() => {
        fetchPublisher();
    }, []);

    const fetchPublisher = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getPublisher();
            if (response) setPublisher(response.publishers);
        } finally {
            dispatch(action.setProcess(false, pageId));
            removeSplash(dispatch);
        }
    };

    return (
        <>
            <div className='publisherContainer'>
                {publisher &&
                    publisher.map((el) => (
                        <div
                            className='col-publisher'
                            onClick={() => history.push(`/listEbook/publisher/${el.uid}`)}
                        >
                            <img src={el.profileImage} />
                            <span className='publisher-list-title'>{el.name}</span>
                        </div>
                    ))}
            </div>
            <Loading id={pageId} />
            {publisher.length < 1 && !request.loading && <EmptyDataView />}
        </>
    );
};
