import { Button, message, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReadingGoalPresetType } from '../../../../interface';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { getReadingGoalPreset, setReading } from '../../../redux/bookshelf/request';
import { setReadingGoal } from '../../../redux/clientInfo/action';

const { Option } = Select;

import './index.scss';

interface GoalModalProps {
   visible: boolean;
   onClose: () => void;
}

// interface GoalsPresetType {
//    key: string;
//    title: string;
//    second: number;
// }

const GoalModal = (props: GoalModalProps) => {
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [readingGoalPreset, setReadingGoalPreset] = useState<ReadingGoalPresetType[]>();
   const [goalsTime, setGoalTime] = useState<number>();

   const { readingGoal } = useSelector(
      (state: HibraryRootState) => ({
         readingGoal: state.clientInfo.readingGoal
      }),
      shallowEqual
   );

   useEffect(() => {
      setGoalTime((readingGoal ?? 600) / 60);
      fetchReadingGoal();
   }, []);

   const fetchReadingGoal = async () => {
      try {
         const response = await getReadingGoalPreset();
         if (response?.readingGoalPreset) {
            setReadingGoalPreset(response.readingGoalPreset);
         }
      } catch (error) {
         console.log(error);
      }
   };

   const onChange = (time: number) => {
      setGoalTime(time);
   };

   const saveReadingGoal = async () => {
      try {
         setLoading(true);
         const response = await setReading(goalsTime ?? 600);
         if (response.status) {
            setLoading(false);
            dispatch(setReadingGoal(goalsTime ? goalsTime : 600));
            message.success('บันทึกเป้าการอ่านรายวันของคุณแล้ว!');
            props.onClose();
         }
      } catch (error) {
         setLoading(false);
      }
   };

   return (
      <Modal title={null} footer={null} visible={props.visible} onCancel={props.onClose}>
         <div className='goal-modal'>
            <h1>เป้าหมายการอ่านรายวัน</h1>
            <div className='container flex-col'>
               {readingGoalPreset && (
                  <Select
                     onChange={onChange}
                     size='large'
                     virtual={false}
                     defaultValue={readingGoal}
                     style={{ width: '100%', maxWidth: '200px' }}
                  >
                     {readingGoalPreset?.map((el) => (
                        <Option value={el.value} key={el.key}>
                           {el.key}
                        </Option>
                     ))}
                  </Select>
               )}
            </div>

            <div className='container'>
               <Button
                  size='large'
                  shape='round'
                  loading={loading}
                  className='custom-button'
                  onClick={saveReadingGoal}
               >
                  บันทึก
               </Button>
            </div>
         </div>
      </Modal>
   );
};

export default GoalModal;
