import axios from 'axios';
import { HtiResponse } from '../../../interface';
import { FormTypeResponse } from '../../../interface/questionForm';

export const fetchQuestionForm = async () => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<FormTypeResponse>>(`/form/questionnaire`);
   return data;
};
