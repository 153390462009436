import { useSelector, shallowEqual } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { useMemo } from 'react';
import loading from '../../assets/lotties/315-loader-ring.json';
import Lottie from 'lottie-react';
import './index.scss';

interface LoadingProps {
    id: string;
    absolute?: boolean;
}
export const Loading = (props: LoadingProps) => {
    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch
        }),
        shallowEqual
    );

    const loadingMemo = useMemo(
        () => (
            <>
                {request.loading && request.id === props.id ? (
                    <div
                        className={
                            props.absolute ? 'component-loading-absolute' : 'component-loading'
                        }
                    >
                        <Lottie animationData={loading} className='loading' />
                    </div>
                ) : null}
            </>
        ),
        [request]
    );
    return <>{loadingMemo}</>;
};

export const ComponentLoading = (props: LoadingProps) => {
    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch
        }),
        (a) => {
            if (a.request.id === props.id) {
                return false;
            } else {
                return true;
            }
        }
    );

    return (
        <>
            {request.loading ? (
                <div className='in-component-loading'>
                    <Lottie animationData={loading} className='loading' />
                </div>
            ) : null}
        </>
    );
};

export const ModalLoading = () => {
    return (
        <div className='in-component-loading'>
            <Lottie animationData={loading} className='loading' />
        </div>
    );
};
