import { SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AuthPageType, AxiosError, OtpPassword, OtpRegister } from '../../../../interface';
import { useFormik } from 'formik';
import { Button, Input, Modal } from 'antd';
import { requestPassword } from '../../../redux/auth/authCrud';
import * as Yup from 'yup';
import './index.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { checkOTPExpire, checkValueIsPhoneNumber } from '../../../Utilities/validateText';
import { VerifyOTPAction } from '../../../redux/verifyOTP';

interface ForgotPasswordProps {
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
    setOTPData: React.Dispatch<SetStateAction<OtpPassword | OtpRegister | undefined>>;
    onBack: () => void;
}

interface ForgotPasswordPayload {
    userID?: string;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const regex = /^((?:\+|\d)[0-9])+|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$/;
    const [status, setStatus] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const { verifyOTP } = useSelector(
        (state: HibraryRootState) => ({
            verifyOTP: state.verifyOTP
        }),
        shallowEqual
    );

    const schema = Yup.object().shape({
        userID: Yup.string()
            .trim()
            .matches(regex, intl.formatMessage({ id: 'Register.PleaseEnterValidEmailOrTel' }))
            .required()
    });

    const formik = useFormik<ForgotPasswordPayload>({
        initialValues: {
            userID: ''
        },
        validationSchema: schema,
        onSubmit: () => showConfirm()
    });

    useEffect(() => {
        if (status) setStatus(undefined);
    }, [formik.values]);

    useEffect(() => {
        if (verifyOTP.forgotPasswordOTP) {
            if (checkOTPExpire(verifyOTP.forgotPasswordOTP.expireDate ?? 0)) {
                dispatch(VerifyOTPAction.resetForgotPasswordOTP());
            } else {
                props.setOTPData(verifyOTP.forgotPasswordOTP);
                props.setPageType(AuthPageType.ForgotPasswordOTP);
            }
        }
    }, []);

    const handleClickRequestOTP = () => {
        if (verifyOTP.forgotPasswordOTP && checkValueIsPhoneNumber(formik.values.userID ?? '')) {
            props.setPageType(AuthPageType.ForgotPasswordOTP);
            props.setOTPData(verifyOTP.forgotPasswordOTP);
        } else {
            requestOTP();
        }
    };

    const requestOTP = async () => {
        try {
            setLoading(true);
            const response = await requestPassword(formik.values.userID!);
            if (response) {
                props.setPageType(AuthPageType.ForgotPasswordOTP);
                props.setOTPData(response);

                if (checkValueIsPhoneNumber(formik.values.userID ?? ''))
                    dispatch(VerifyOTPAction.intiForgotPasswordOTP(response));
            }
        } catch (error) {
            const getError = error as AxiosError;
            setStatus(getError.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const showConfirm = () => {
        Modal.confirm({
            title: intl.formatMessage({
                id: 'ChangePassword.ModalChangePassword'
            }),
            content: (
                <p
                    dangerouslySetInnerHTML={{
                        __html: intl
                            .formatMessage(
                                { id: 'RegisterModal.Description' },
                                {
                                    0: formik.values.userID
                                }
                            )
                            .replace(/\n/g, '<br/>')
                    }}
                />
            ),
            okText: intl.formatMessage({ id: 'RegisterModal.AgreeBtn' }),
            cancelText: intl.formatMessage({ id: 'RegisterModal.CancelBtn' }),
            onOk: () => handleClickRequestOTP()
        });
    };

    return (
        <div className='forgotPasswordContainer'>
            <h1>{intl.formatMessage({ id: 'ForgotPassword.Title' })}</h1>
            <form onSubmit={formik.handleSubmit}>
                <div className='inputItem'>
                    <Input
                        className='formInput'
                        value={formik.values.userID}
                        placeholder={intl.formatMessage({
                            id: 'ForgotPassword.PlaceHolder'
                        })}
                        onChange={(e) => formik.setFieldValue('userID', e.target.value)}
                    />
                    {formik.touched.userID && formik.errors.userID ? (
                        <div className='error-text'>{formik.errors.userID}</div>
                    ) : null}
                </div>
                <div className='errorField'>{status && <div>{status}</div>}</div>
                <div className='buttonContain'>
                    <Button className='acceptButton' htmlType='submit' loading={loading}>
                        {loading
                            ? intl.formatMessage({ id: 'Action.JustAMoment' })
                            : intl.formatMessage({ id: 'Action.ConfirmBtn' })}
                    </Button>
                    <span className='back' onClick={() => props.setPageType(AuthPageType.Login)}>
                        {intl.formatMessage({ id: 'Action.Back' })}
                    </span>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
