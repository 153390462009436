/* eslint-disable @typescript-eslint/no-unused-vars */
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeSplash } from '../../helpers/removeSplash';
import { UseFetchType } from '../../../interface';
import { SponsorResponse } from '../../../interface/sponsor';
import { getSponsorList } from '../../redux/sponsor/request';
import { EmptyDataView } from '../../components/nodata';
import { onErrorImage } from '../../Utilities/imageUtilities';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { HibraryRootState } from '../../../redux/rootReducer';
import SponsorModal from '../../components/modal/sponsorModal/sponsorModal';
import './index.scss';
import { useIntl } from 'react-intl';

const useFetchSponsorListData = (
  pageId: string
): UseFetchType<SponsorResponse> => {
  const [data, setData] = useState<SponsorResponse>();
  const [error, setError] = useState<any>();
  const dispatch = useDispatch();

  const { loading, authen, clientInfo } = useSelector(
    (state: HibraryRootState) => ({
      loading: state.fetch.loading,
      authen: state.auth,
      clientInfo: state.clientInfo
    }),
    shallowEqual
  );

  useEffect(() => {
    const fetchSponsor = async () => {
      try {
        dispatch(action.setProcess(true, pageId));
        const response = await getSponsorList(
          !authen.isLogin,
          clientInfo.companyPrefix
        );
        if (response) {
          setData(response);
        }
      } catch (error) {
        setError(error);
      } finally {
        dispatch(action.setProcess(false, pageId));
        dispatch(removeSplash);
      }
    };

    fetchSponsor();
  }, []);

  return { data, loading, error };
};

const ViewAllSponsor = () => {
  const pageId = 'viewAllSponsor';
  const history = useHistory();
  const intl = useIntl();
  const { data, loading, error } = useFetchSponsorListData(pageId);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const renderItem = () => {
    if (data && !loading) {
      return (
        <>
          <div className="segmentContainer">
            {data.mainSponsors?.length > 0 && (
              <div>
                <h1 className="titleHeader">
                  {intl.formatMessage({ id: 'Sponsor.MainSponsorTitle' })}
                </h1>
                <div className="gridContainer">
                  {data!.mainSponsors?.map((el, index) => (
                    <div
                      className="contentBox"
                      key={index + 1}
                      onClick={() => {
                        history.replace({
                          search: `?uid=${el.sponsorUID}`
                        });
                        setModalVisible(true);
                      }}
                    >
                      <div className="coverImage">
                        <img
                          src={el.coverImage}
                          alt="Image"
                          onError={onErrorImage}
                        />
                      </div>
                      <p>{el.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {data.secondarySponsors?.length > 0 && (
              <div>
                <h1 className="titleHeader">
                  {intl.formatMessage({ id: 'Sponsor.SecondarySponsorTitle' })}
                </h1>
                <div className="gridContainer">
                  {data!.secondarySponsors?.map((el, index) => (
                    <div
                      className="contentBox"
                      key={index + 1}
                      onClick={() => {
                        history.replace({
                          search: `?uid=${el.sponsorUID}`
                        });
                        setModalVisible(true);
                      }}
                    >
                      <div className="coverImage">
                        <img src={el.coverImage} alt="" />
                      </div>
                      <p>{el.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      );
    }
  };
  return (
    <div className="viewAllSponsor">
      <h1 className="pageTitle">
        {intl.formatMessage({ id: 'Sponsor.Title' })}
      </h1>
      {renderItem()}
      {loading && <Loading id={pageId} />}
      {error && <EmptyDataView />}
      {modalVisible && (
        <SponsorModal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </div>
  );
};

export default ViewAllSponsor;
