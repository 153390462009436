import { Themes } from '../../../interface/client';
import { ThemeAction, ThemeActionType, initialState } from './index';

export const setTheme = (theme: Themes): ThemeAction => {
    const newTheme: Themes = Object.assign(initialState.normalTheme, theme);
    newTheme.PrimaryDark = theme.Primary;
    newTheme.OnPrimaryDark = theme.OnPrimary;
    return {
        type: ThemeActionType.SetTheme,
        payload: newTheme
    };
};

export const setDarkTheme = (enable: boolean): ThemeAction => {
    return {
        type: ThemeActionType.SetDarkTheme,
        payload: enable
    };
};
