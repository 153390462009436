import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import jwtDecode from 'jwt-decode';
import { eraseCookie } from '../../helpers/cookie';
import { setupAxiosByToken } from '../../../request';
import { UserInfoForRegister } from '../../../interface';
export enum AuthenActionTypes {
    Login = '[Login] Authen',
    RefreshToken = '[RefreshToken] Authen',
    UpdateToken = '[UpdateToken] Authen',
    Logout = '[Logout] Authen',
    setIsCuDomain = '[setIsCuDomain] Authen',
    setPrefixRegisterToken = '[setPrefixRegisterToken] Authen',
    SetClientForRegister = '[SetClientForRegister] Authen'
}

export interface UserAuthentication {
    authToken?: string;
    pathToGo?: string;
    userInfo?: UserTokenInfo;
    isLogin: boolean;
    isCuDomain?: boolean;
    isSsoLogin: boolean;
    prefixRegister?: string;
    userInfoForRegister?: UserInfoForRegister;
}

export const getUserInfo = (userToken: string): UserTokenInfo => {
    const jwtObject: UserTokenInfo = jwtDecode(userToken);
    return jwtObject;
};

const initialAuthState: UserAuthentication = {
    authToken: undefined,
    pathToGo: undefined,
    userInfo: undefined,
    isLogin: false,
    isCuDomain: undefined,
    isSsoLogin: false,
    prefixRegister: undefined,
    userInfoForRegister: undefined
};
export interface UserTokenInfo {
    uid: string;
    role: string;
    expireDate: number;
    startDate: number;
    name: string;
    surname: string;
    staffID: string;
    email: string;
    phoneNumber?: string;
    storeType: string;
    storeStatus: string;
    packageUID: string;
    clientUID: string;
    clientName: string;
    companyName: string;
    clientLogo: string;
    clientPrefix: string;
    showClientLogo: boolean;
    isPrivateLibrary: boolean;
    isAnonymous: boolean;
    isSsoLogin: boolean;
    iat: number;
    exp: number;
}

interface AuthenAction {
    type: AuthenActionTypes;
    payload?: { userToken: string };
    isCuDomain?: boolean;
    prefixRegister?: string;
    userInfoForRegister?: UserInfoForRegister;
}
//setupAxiosByToken(state.authToken)
export const reducer = persistReducer(
    {
        storage,
        key: 'hibrary-web-auth',
        whitelist: ['authToken', 'userInfo', 'isLogin']
    },
    (state: UserAuthentication = initialAuthState, action: AuthenAction): UserAuthentication => {
        switch (action.type) {
            case AuthenActionTypes.Login: {
                const { userToken } = action.payload!;
                setupAxiosByToken(userToken);
                return {
                    ...state,
                    authToken: userToken,
                    userInfo: getUserInfo(userToken),
                    isLogin: true
                };
            }
            case AuthenActionTypes.Logout: {
                // TODO: Change this code. Actions in reducer aren't allowed.
                eraseCookie('HTIReaderToken');
                eraseCookie('HTIReaderReturnUrl');
                const newState: UserAuthentication = Object.assign(state, {});
                newState.authToken = undefined;
                newState.userInfo = undefined;
                newState.isLogin = false;
                setupAxiosByToken(undefined);
                return { ...newState };
            }
            case AuthenActionTypes.setIsCuDomain: {
                return {
                    ...state,
                    isCuDomain: action.isCuDomain
                };
            }
            case AuthenActionTypes.setPrefixRegisterToken: {
                if (action.prefixRegister) {
                    return {
                        ...state,
                        prefixRegister: action.prefixRegister
                    };
                } else {
                    return {
                        ...state
                    };
                }
            }
            case AuthenActionTypes.UpdateToken: {
                const { userToken } = action.payload!;
                setupAxiosByToken(userToken);
                return {
                    ...state,
                    authToken: userToken,
                    userInfo: getUserInfo(userToken),
                    isLogin: true
                };
            }
            case AuthenActionTypes.SetClientForRegister: {
                return {
                    ...state,
                    userInfoForRegister: action.userInfoForRegister
                };
            }
            default:
                return state;
        }
    }
);

export const actions = {
    login: (userToken: string): AuthenAction => ({
        type: AuthenActionTypes.Login,
        payload: { userToken }
    }),
    updateToken: (userToken: string): AuthenAction => ({
        type: AuthenActionTypes.UpdateToken,
        payload: { userToken }
    }),
    setIsCuDomain: (isCu: boolean) => ({
        type: AuthenActionTypes.setIsCuDomain,
        isCuDomain: isCu
    }),
    setPrefixRegisterToken: (prefix?: string) => ({
        type: AuthenActionTypes.setPrefixRegisterToken,
        prefixRegister: prefix
    }),
    setUserInfoForRegister: (userInfo?: UserInfoForRegister) => ({
        type: AuthenActionTypes.SetClientForRegister,
        userInfoForRegister: userInfo
    }),
    logout: (): AuthenAction => ({ type: AuthenActionTypes.Logout })
};
