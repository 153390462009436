import axios from 'axios';
import { HtiResponse } from '../../../interface';
import {
   Sponsor,
   SponsorContentSet,
   SponsorResponse
} from '../../../interface/sponsor';

export const getSponsorList = async (
   isPublic = true,
   clientPrefix?: string
) => {
   try {
      const path = isPublic
         ? `/public/${clientPrefix}/sponsor/sponsorList`
         : `/sponsor/sponsorList`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<SponsorResponse>>(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const getSponsorDetail = async (
   uid: string,
   isPublic = true,
   clientPrefix?: string
) => {
   try {
      if (isPublic) {
         const {
            data: { data }
         } = await axios.get<HtiResponse<Sponsor>>(
            `/public/${clientPrefix}/sponsor/detail/${uid}`
         );
         return data;
      } else {
         const {
            data: { data }
         } = await axios.get<HtiResponse<Sponsor>>(`/sponsor/detail/${uid}`);
         return data;
      }
   } catch (error) {
      return Promise.reject(error);
   }
};

export const getSponsorContentSet = async (
   setUID: string,
   isPublic: boolean,
   clientPrefix?: string
) => {
   try {
      const path = isPublic
         ? `/public/${clientPrefix}/sponsor/recommendContents/${setUID}`
         : `/sponsor/recommendContents/${setUID}`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<SponsorContentSet>>(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const getAllBannerSponsor = async (
   isPublic: boolean,
   clientPrefix?: string
) => {
   try {
      const path = isPublic
         ? `/public/${clientPrefix}/sponsor/recommendContents`
         : `/sponsor/recommendContents`;

      const {
         data: { data }
      } = await axios.get(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};
