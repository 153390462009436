export enum FetchStatus {
   Init,
   Process,
   Complete,
   Error
}
const initialData: FetchState = {
   status: FetchStatus.Init,
   loading: false
};
export interface FetchState<T = any> {
   id?: string;
   status: FetchStatus;
   loading: boolean;
   response?: T;
}

export enum FetchActionType {
   Process = 'FetchActionType[Process]',
   Complete = 'FetchActionType[Complete]',
   Error = 'FetchActionType[Error]'
}
interface FetchActionPayload {
   loading: boolean;
   id?: string;
}
export interface FetchAction {
   payload: FetchActionPayload;
   type: FetchActionType;
}
export const reducer = (
   state = initialData,
   action: FetchAction
): FetchState => {
   switch (action.type) {
      case FetchActionType.Process:
         return {
            status: FetchStatus.Process,
            loading: action.payload.loading,
            id: action.payload.id
         };
      case FetchActionType.Complete:
         return {
            status: FetchStatus.Complete,
            response: action.payload,
            loading: false,
            id: action.payload.id
         };

      case FetchActionType.Error:
         return {
            status: FetchStatus.Error,
            response: action.payload,
            loading: false,
            id: action.payload.id
         };
      default:
         return state;
   }
};

const setProcess = (loading: boolean, id?: string): FetchAction => {
   return {
      type: FetchActionType.Process,
      payload: { loading, id }
   };
};

const setComplete = (response?: any, id?: string): FetchAction => {
   return {
      type: FetchActionType.Complete,
      payload: { loading: false, id }
   };
};

const setError = (response: any, id?: string): FetchAction => {
   return {
      type: FetchActionType.Complete,
      payload: { loading: false, id }
   };
};
export const action = { setProcess, setComplete, setError };
