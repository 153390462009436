import axios from 'axios';
import { HtiResponse } from '../../../interface/request';
import { ContentType, LicenseType } from '../../../interface';

export const fetchAutocomplete = async (
    keyword: string,
    clientPrefix: string,
    isLogin: boolean
) => {
    // const url = isLogin
    //     ? `/store/autoComplete?keyword=${keyword.trim().toLowerCase()}`
    //     : `/public/${clientPrefix}/store/autoComplete?keyword=${keyword.trim().toLowerCase()}`;
    const path = isLogin
        ? `/store/contents/search/autocomplete`
        : `/public/${clientPrefix}/contents/search/autocomplete`;

    const {
        data: { data }
    } = await axios.post<HtiResponse<AutoCompleteResult>>(path, {
        keyword: keyword.trim().toLocaleLowerCase()
    });
    return data;
};

export const saveSearchAutoComplete = async (keyword: string) => {
    const {
        data: { status }
    } = await axios.post<HtiResponse<saveSearchAutoCompleteResult>>(
        `/statistics/search?keyword=${keyword}`
    );

    return status;
};

export const saveSearchAutoCompletePublic = async (keyword: string, clientPrefix: string) => {
    const {
        data: { status }
    } = await axios.post<HtiResponse<saveSearchAutoCompleteResult>>(
        `/public/${clientPrefix}/search?keyword=${keyword}`
    );

    return status;
};

export enum SearchType {
    TITLE = 'TITLE',
    PUBLISHER = 'PUBLISHER',
    AUTHOR = 'AUTHOR',
    KEYWORD = 'KEYWORD',
    CATEGORY = 'CATEGORY'
}

export type AutoCompleteKey = AutoCompleteMetaData | CategoryAutoCompleteMetaData;

export interface AutoCompleteResult {
    author: AutoCompleteMetaData[];
    publisher: AutoCompleteMetaData[];
    keyword: AutoCompleteMetaData[];
    category: AutoCompleteMetaData[];
}

export interface saveSearchAutoCompleteResult {
    status: boolean;
}

export interface AutoCompleteMetaData {
    uid: string;
    keyword: string;
    subKeyword?: string;
    type: SearchType;
    licenseType?: LicenseType;
    contentType?: ContentType;
}

export interface CategoryAutoCompleteMetaData extends AutoCompleteMetaData {
    subcategoryUID: string;
}
