const CUTerms = () => {
    return (
        <div>
            <p style={{ marginBottom: '1.25rem' }}>
                เงื่อนไขการใช้บริการ cu-elibrary การใช้เว็บไซต์ www.cu-elibrary.com นี้ (เว็บไซต์)
                หรือ แอปพลิเคชั่น (ตามคำนิยามด้านล่างนี้) อยู่ภายใต้การควบคุมดูแลของ
                ศูนย์หนังสือแห่งลงกรณ์มหาวิทยาลัย รวมเรียกว่า chulabook cu-elibrary หรือ
                ศูนย์หนังสือจุฬาฯ) หมายถึงท่านยอมรับเงื่อนไขเหล่านี้ที่ปรากฏบนเว็บไซต์ และ/หรือ
                แอปพลิเคชั่นว่ามีผลผูกพันตามกฎหมาย (เงื่อนไข)
                และท่านยังยอมรับนโยบายความเป็นส่วนตัวของเราและยอมรับที่จะปฏิบัติตามกฎระเบียบอื่น ๆ
                บนเว็บไซต์ และ/หรือ แอปพลิเคชัน เช่น วิธีการใช้ วิธีปฏิบัติและขั้นตอน
                หรือเงื่อนไขสำหรับการใช้บริการ ทั้งนี้ศูนย์หนังสือ
                ขอสงวนสิทธิ์ในการเปลี่ยนแปลงกฎและเงื่อนไขต่างๆเพื่อความความเหมาะสม
            </p>

            <ol>
                <li>
                    <p>
                        คำจำกัดความของบริการและแอปพลิเคชั่น cu-elibrary
                        เป็นการบริการสำหรับหน่วยงานหรือองค์กรที่ต้องการให้สมาชิกในองค์กรได้แสวงหาความรู้ในรูปแบบหนังสืออิเล็กทรอนิกส์
                        ที่หน่วยงานหรือองค์กรจัดหาให้ โดยสมาชิกสามารถสมัครเป็นผู้ใช้บริการเพื่ออ่าน
                        หนังสือ วารสาร นิตยสาร หนังสือพิมพ์
                        หนังสือเสียงประเภทอิเล็กทรอนิกส์สิ่งตีพิมพ์ดิจิทัล สิ่งตีพิมพ์อิเล็กทรอนิกส์
                        หรือสิ่งตีพิมพ์ประเภทอื่น ๆ ผ่านเว็บไซต์หรือแอปพลิเคชั่นของ cu-elibrary
                    </p>
                    <br />
                    <p>
                        หนังสืออิเล็กทรอนิกส์ : ที่อยู่ใน cu-elibrary จะ
                        ผ่านการตรวจสอบโดยเจ้าหน้าที่ก่อนขึ้นประกาศในเว็บไซต์แล้ว
                        ทั้งนี้เนื่องจากปริมาณของข้อมูลนั้นมีจำนวนมาก
                        โดยเฉพาะส่วนของตัวเลขหรือรูปภาพทางคณิตศาสตร์
                        ทางเราจึงขอสงวนสิทธิ์ในการยกเลิกหรือปฏิเสธรายการที่มีข้อผิดพลาดหากตรวจพบ
                        และหนังสืออิเล็กทรอนิกส์ : ที่อยู่ใน cu-elibrary
                        ถูกเข้ารหัสเพื่อจัดการลิขสิทธ์ (DRM: Digital Right Management)
                        ทุกรายการเพื่อป้อวกันการทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ จัดจำหน่าย
                        โดยไม่ได้รับอนุญาตจาก cu-elibrary
                    </p>
                    <br />
                    <p>
                        ผู้ใช้บริการ : สามารถดาวน์โหลดแอปพลิเคชั่น หรือเว็บไซต์ผ่าน ระบบปฏิบัติการ
                        IOS, Android และเว็บเบราว์เซอร์เพื่อ อ่าน
                        หรือฟังสื่ออิเล็กทรอนิกส์ได้เฉพาะทางแอปพลิเคชั่น
                    </p>
                    <br />
                    <p>
                        อุปกรณ์ที่รองรับการอ่าน : รองรับการอ่านผ่าน Tablet , IPad , IPhone , Androi
                        และ Computer Pc
                    </p>
                </li>
                <br />
                <li>
                    การสร้างบัญชีผู้ใช้บริการ
                    การใช้บริการท่านจะต้องลงทะเบียนชื่อบัญชีและตั้งรหัสผ่าน ให้รายละเอียดต่าง ๆ
                    ที่เกี่ยวข้องกับตนเองที่ถูกต้อง สมบูรณ์
                    และตรงต่อความเป็นจริงผ่านการลงทะเบียนบนเว็บไซต์ และ/หรือ แอปพลิเคชั่น
                    (ข้อมูลบัญชี)
                    และแก้ไขข้อมูลบัญชีให้ถูกต้องและตรงต่อความเป็นจริงในปัจจุบันอยู่เสมอกรณีที่ศูนย์หนังสือจุฬาฯ
                    ตรวจสอบและพบว่าท่านให้ข้อมูลที่เป็นเท็จ ทางศูนย์หนังสือจุฬาฯ
                    ขอสงวนสิทธิ์ที่จะระงับหรือเพิกถอนบัญชีหรือระงับการใช้บริการของท่านได้ทันที
                </li>
                <br />
                <li>
                    การจัดการสิทธิของผู้ใช้บริการ รองรับการบริการ “ยืม”
                    หนังสืออิเล็กทรอนิกส์ที่มีในระบบได้ ทั้งนี้จำนวนสิทธิและระยะเวลาการยืม
                    ตามที่กําหนดไว้ในระเบียบการยืม-คืน รองรับการบริการ “คืน”
                    หนังสืออิเล็กทรอนิกส์ก่อนกําหนดได้ ทั้งนี้ต้องตรวจสอบสิทธิการจองและ
                    ดําเนินการตามเงื่อนไขที่กําหนดก่อน รองรับการบริการ “จอง”
                    หนังสืออิเล็กทรอนิกส์ที่มีในระบบได้ รองรับการแสดงประวัติการอ่าน, การยืม-คืน,
                    การจองหนังสืออิเล็กทรอนิกส์ของสมาชิกได้
                </li>
                <br />
                <li>
                    <p>
                        เงื่อนไขการใช้งาน เว็บไซต์และแอปพลิเคชั่นโดย cu-elibrary
                        ประกอบด้วยเนื้อหาต่างๆ ทั้งที่เป็นตัวอักษร รูปภาพและเสียง
                        ซึ่งได้รับความคุ้มครองในด้านลิขสิทธิ์ และทรัพย์สินทางปัญญาประเภทอื่นๆ
                        สิขสิทธิ์และทรัพย์สินทางปัญญา
                        ทั้งหลายที่บรรจุในเว็บไซต์และแอปพลิเคชั่นเป็นกรรมสิทธิ์ของ cu-elibrary
                        ได้รับอนุญาตจากผู้มีสิทธิเหล่านั้นให้สามารถใช้เป็นส่วนหนึ่งของเว็บไซต์และแอปพลิเคชั่นได้
                    </p>
                    <br />
                    <p>
                        ผู้ใช้บริการสามารถทำการใช้ข้อมูลหรือดาวน์โหลดไฟล์ที่เป็นซอฟต์แวร์ หรือรูปภาพ
                        จากเว็บไซต์และแอปพลิเคชั่นของ cu-elibrary เพื่อใช้ส่วนตัวเท่านั้น
                        และบริษัทไม่อนุญาตให้นำไปใช้ในกิจกรรมใด ๆ
                        ที่เกี่ยวข้องกับเชิงพาณิชย์ทั้งสิ้น
                        และท่านต้องรับรองว่าจะไม่ดำเนินการตามเนื้อหาด้านล่างดังต่อไป
                    </p>
                    <br />
                    <p>
                        ผู้ใช้บริการสามารถทำการใช้ข้อมูลหรือดาวน์โหลดไฟล์ที่เป็นซอฟต์แวร์ หรือรูปภาพ
                        จากเว็บไซต์และแอปพลิเคชั่นของ cu-elibrary เพื่อใช้ส่วนตัวเท่านั้น
                        และบริษัทไม่อนุญาตให้นำไปใช้ในกิจกรรมใด ๆ
                        ที่เกี่ยวข้องกับเชิงพาณิชย์ทั้งสิ้น
                        และท่านต้องรับรองว่าจะไม่ดำเนินการตามเนื้อหาด้านล่างดังต่อไป
                    </p>
                    <br />
                    <p>
                        ละเมิดลิขสิทธิ์หรือละเมิดทรัพย์สินทางปัญญา และสิทธิต่าง ๆ กระทำการใด ๆ
                        เพื่อให้มีขึ้นซึ่งสื่อที่มีไวรัส หรือรหัสคอมพิวเตอร์
                        แฟ้มข้อมูลหรือโปรแกรมอื่นใดซึ่งออกแบบมาเพื่อทำลาย
                        แทรกแซงหรือจำกัดการใช้งานตามปกติของบริการหรือเว็บไซต์ และ/หรือ แอปพลิเคชั่น
                        (หรือส่วนหนึ่งส่วนใดของบริการ) หรือซอฟต์แวร์หรือฮาร์ดแวร์คอมพิวเตอร์
                        ดำเนินการใด ๆ ที่ไม่ชอบด้วยกฎหมาย ถอดรหัสโปรแกรมแบบย้อนกลับ, ถอด, แยก
                        หรือพยายามที่จะค้นหารหัสหรือจะเข้าถึงแหล่งที่มาของความคิด
                        พื้นฐานหรือขั้นตอนวิธีการโครงสร้างของบริการของบริษัทฯ
                        ไม่ว่าจะทั้งหมดหรือบางส่วน หรือใช้ซอฟต์แวร์หรืออุปกรณ์ใด ๆ
                        (ไม่ว่าจะเป็นกระทำการเองหรือโดยอัตโนมัติ) เพื่อรวบรวมข้อมูลหรือเผยแพร่
                        ส่วนหนึ่งส่วนใดของเว็บไซต์ และ/หรือ แอปพลิเคชั่น
                    </p>
                </li>
            </ol>
        </div>
    );
};

export default CUTerms;
