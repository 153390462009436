import { shallowEqual, useSelector } from 'react-redux';
import { Space } from 'antd';
import { HibraryRootState } from '../../../redux/rootReducer';
import dayjs from 'dayjs';
import validator from 'validator';
import './index.scss';
import { useIntl } from 'react-intl';

export const Footer = () => {
    const intl = useIntl();
    const { clientInfo, isCuDomain } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            isCuDomain: state.auth.isCuDomain
        }),
        shallowEqual
    );

    const lineIdLink = (lineID: string) => {
        if (lineID) {
            if (validator.isURL(lineID)) {
                return lineID;
            } else if (lineID.length && lineID[0] === '@') {
                return 'https://line.me/ti/p/%40' + lineID;
            } else {
                return 'https://line.me/ti/p/~' + lineID;
            }
        }
    };

    return (
        <div className='footer'>
            <div className='flex-container'>
                <div className='col'>
                    <h4>Address</h4>
                    <p>{clientInfo.contactList?.address}</p>
                    <div className='social-container'>
                        {clientInfo.contactList?.Facebook && (
                            <a
                                href={clientInfo.contactList.Facebook}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src='/images/social/FacebookIcon.svg' alt='icon twitter' />
                            </a>
                        )}
                        {clientInfo.contactList?.LineID && (
                            <a
                                href={lineIdLink(clientInfo.contactList.LineID)}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src='/images/social/LineIcon.svg' alt='icon line' />
                            </a>
                        )}
                        {clientInfo.contactList?.Twitter && (
                            <a
                                href={clientInfo.contactList.Twitter}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src='/images/social/XIcon.svg' alt='icon twitter' />
                            </a>
                        )}

                        {clientInfo.contactList?.Instagram && (
                            <a
                                href={clientInfo.contactList.Instagram}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src='/images/social/InstagramIcon.svg' alt='icon twitter' />
                            </a>
                        )}
                        {clientInfo.contactList?.Tiktok && (
                            <a
                                href={clientInfo.contactList.Tiktok}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src='/images/social/TiktokIcon.svg' alt='icon twitter' />
                            </a>
                        )}

                        {clientInfo.contactList?.YouTube && (
                            <a
                                href={clientInfo.contactList.YouTube}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src='/images/social/YoutubeIcon.svg' alt='icon twitter' />
                            </a>
                        )}
                        {clientInfo.contactList?.website && (
                            <a
                                href={clientInfo.contactList.website}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src='/images/social/WebsiteIcon.svg' alt='icon twitter' />
                            </a>
                        )}
                    </div>
                </div>
                <div className='col'>
                    <h1>{clientInfo.clientName}</h1>
                    <h4>{intl.formatMessage({ id: 'Footer.DownloadApp' })}</h4>
                    <Space>
                        <a
                            href={
                                isCuDomain
                                    ? 'https://apps.apple.com/uy/app/hibrary/id1607050195'
                                    : 'https://apps.apple.com/us/app/hibrary-%E0%B8%AB-%E0%B8%AD%E0%B8%87%E0%B8%AA%E0%B8%A1-%E0%B8%94%E0%B8%AD%E0%B8%AD%E0%B8%99%E0%B9%84%E0%B8%A5%E0%B8%99/id1519192143'
                            }
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img src='/images/appstore-footer.png' className='app-icon' alt='' />
                        </a>
                        <a
                            href={
                                isCuDomain
                                    ? 'https://play.google.com/store/apps/details?id=hti.cu.elibrary.android&hl=th&gl=US'
                                    : 'https://play.google.com/store/apps/details?id=com.hti.elibrary.android&hl=th&gl=US'
                            }
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img src='/images/playstore-footer.png' className='app-icon' alt='' />
                        </a>
                    </Space>
                </div>
                <div className='col'>
                    <h4>Contact Us</h4>
                    {clientInfo.contactList?.phoneNumber ? (
                        <p>
                            {intl.formatMessage(
                                { id: 'ContactUs.PhoneNumber' },
                                { 0: clientInfo.contactList.phoneNumber }
                            )}
                        </p>
                    ) : null}
                    {clientInfo.contactList?.mobilePhone ? (
                        <p>
                            {intl.formatMessage(
                                { id: 'ContactUs.TelephoneNumber' },
                                { 0: clientInfo.contactList.mobilePhone }
                            )}
                        </p>
                    ) : null}
                    {clientInfo.contactList?.email ? (
                        <p>
                            {intl.formatMessage(
                                { id: 'ContactUs.Email' },
                                { 0: clientInfo.contactList.email }
                            )}
                        </p>
                    ) : null}
                </div>
            </div>
            <div className='footer-bottom'>
                <div>
                    v.{process.env.REACT_APP_VERSION} Powered by{' '}
                    <a
                        href='https://www.hibrary.me'
                        target='_blank'
                        rel='noreferrer'
                        className='link'
                    >
                        Hytexts Interactive Limited
                    </a>
                </div>
                <div>
                    Copyright ©{dayjs().get('year')} All Right Reserved.{' | '}
                    <a
                        href='/terms-of-service/terms-and-conditions'
                        target='_blank'
                        rel='noreferrer'
                        className='link'
                    >
                        {intl.formatMessage({ id: 'Footer.TermCon' })}
                    </a>
                    {' | '}
                    <a
                        href='/terms-of-service/privacy-policy'
                        target='_blank'
                        rel='noreferrer'
                        className='link'
                    >
                        {intl.formatMessage({ id: 'Footer.Privacy' })}
                    </a>
                </div>
                <div>Customer Service : 089-134-7470, 02-024-6690</div>
            </div>
        </div>
    );
};

export default Footer;
