import { Modal, ModalProps } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CatalogContentMetaData } from '../../../../interface/voteEbook';
import { ModalLoading } from '../../../customUI/loading';
import { getCatalogContentDetail } from '../../../redux/voteEbook/request';
import { ImageCover } from '../../contentCover/imageCover';
import { EmptyDataView } from '../../nodata';
import './index.scss';

interface VoteEbookDetailModalProps {
    open: boolean;
    contentUID: string;
    onClose: () => void;
}

const VoteEbookDetailModal = (props: VoteEbookDetailModalProps) => {
    const intl = useIntl();
    const [content, setContent] = useState<CatalogContentMetaData>();
    const [loading, setLoading] = useState<boolean>(false);

    const modalProps: ModalProps = {
        open: props.open,
        className: `voteEbookDetailModal`,
        onCancel: props.onClose,
        footer: null,
        width: 800
    };

    useEffect(() => {
        if (props.open && props.contentUID) fetchCatalogContentDetail();
    }, [props.open, props.contentUID]);

    const fetchCatalogContentDetail = async () => {
        try {
            setLoading(true);
            const response = await getCatalogContentDetail(props.contentUID);
            if (response) {
                setContent(response);
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal {...modalProps}>
            {!loading && content ? (
                <>
                    <h1>{content.title}</h1>
                    <div className='contentContainer'>
                        <div className='coverImage'>
                            <ImageCover coverImage={content.coverImage} />
                        </div>
                        <div className='detailContainer'>
                            <p>
                                {intl.formatMessage({ id: 'ReadingStat.By' })} :{' '}
                                {content.authorName ?? '-'}
                            </p>
                            <p>
                                {intl.formatMessage({ id: 'ReadingStat.Publisher' })} :{' '}
                                {content.publisherName ?? '-'}
                            </p>
                            <div className='tagContainer'>
                                <div className='tagStyle'>{content.catName}</div>
                                <div className='tagStyle'>{content.catName}</div>
                            </div>
                            <div>
                                <h1 className='paragraphTitle'>
                                    {intl.formatMessage({ id: 'VoteActivity.Synopsis' })}
                                </h1>
                                <p>{content.description}</p>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {loading && <ModalLoading />}
            {!loading && !content ? <EmptyDataView /> : null}
        </Modal>
    );
};

export default VoteEbookDetailModal;
