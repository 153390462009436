import { SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LeftOutlined } from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    AddMemberStore,
    AddMemberStorePayload,
    AuthPageType,
    PublicRegisterClient
} from '../../../../../interface';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { AddMemberClientStore, getPublicRegisterClient } from '../../../../redux/auth/authCrud';
import { ComponentLoading } from '../../../../customUI/loading';
import { action } from '../../../../redux/request';
import { Empty, Modal, Space } from 'antd';
import SVG from 'react-inlinesvg';

interface SelectLibraryToAddProps {
    // onSelectClient: (client: PublicRegisterClient) => void;
    // text?: string
    setCurrentClient: React.Dispatch<SetStateAction<PublicRegisterClient | undefined>>;
    setUserToken: React.Dispatch<SetStateAction<AddMemberStore | undefined>>;
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
}

const AddLibrary = (props: SelectLibraryToAddProps) => {
    const pageID = 'AddNewLibrary';
    const intl = useIntl();
    const dispatch = useDispatch();
    const [displayEmpty, setDisplayEmpty] = useState(false);
    const [library, setLibrary] = useState<PublicRegisterClient[]>();
    const { authState } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(action.setProcess(true, pageID));
        navigator.geolocation.getCurrentPosition(function (position) {
            getPublicRegisterClient(
                authState.userInfo?.uid,
                position.coords.latitude,
                position.coords.longitude
            )
                .then((res) => {
                    setLibrary(res.data?.clients);
                    dispatch(action.setProcess(false, pageID));
                })
                .catch((error) => {
                    if (error.response.data.errorCode === '933') {
                        dispatch(action.setProcess(false, pageID));
                        setDisplayEmpty(true);
                    }
                });
        });
    }, []);

    const onAddClientStore = async (clientInfo: PublicRegisterClient) => {
        try {
            const payload: AddMemberStorePayload = {
                clientPrefix: clientInfo.companyPrefix,
                memberUID: authState.userInfo?.uid
            };
            const response = await AddMemberClientStore(payload);
            if (response) {
                props.setUserToken(response);
                if (response.requireEmployeeNumber === false) {
                    props.setPageType(AuthPageType.Success);
                } else if (response.requireEmployeeNumber === true) {
                    if (response.success) {
                        props.setPageType(AuthPageType.Success);
                    } else {
                        props.setPageType(AuthPageType.AddNewLibraryForm);
                    }
                }
            }
        } finally {
            console.log('fetch');
        }
    };

    const handleClick = (currentClient: PublicRegisterClient) => {
        props.setCurrentClient(currentClient);
        if (currentClient.requireEmployeeNumber) {
            onAddClientStore(currentClient);
        } else {
            confirmAddLibrary(currentClient);
        }
    };

    const confirmAddLibrary = (clientInfo: PublicRegisterClient) => {
        Modal.confirm({
            title:
                intl.formatMessage({
                    id: 'AddLibrary.AddBtn'
                }) + '?',
            wrapClassName: 'confirmAddLibraryModal',
            content: <p>ต้องการเพิ่มห้องสมุด {clientInfo?.displayName} หรือไม่?</p>,
            okText: 'เพิ่ม',
            cancelText: intl.formatMessage({ id: 'RegisterModal.CancelBtn' }),
            onOk: () => onAddClientStore(clientInfo)
        });
    };

    // const onSelectedLibrary = (client: PublicRegisterClient) => {
    //     const isPrivateLibrary = !client.isPublic;
    //     const isPublicRegister = client.isPublic;
    //     const register: ClientInfo = Object.assign(client, {
    //         isPrivateLibrary,
    //         isPublicRegister
    //     });
    //     dispatch(authenActions.setPrefixRegisterToken(client.companyPrefix));
    //     history.replace({
    //         search: `?addLibrary=true`
    //     });
    //     setRegisterable(register);
    //     setVerifyOtpType(SendEmailForOtpType.RegisterByEmail);
    //        props.setPageType(AuthPageType.SelectLibrary);
    // };

    return (
        <div className='addLibraryContainer'>
            <div className='titleSection'>
                <Space
                    size={4}
                    className='backButton'
                    onClick={() => props.setPageType(AuthPageType.SelectLibrary)}
                >
                    <LeftOutlined />
                    <span>{intl.formatMessage({ id: 'Action.Back' })}</span>
                </Space>
                <h1>{intl.formatMessage({ id: 'AddLibrary.Title' })}</h1>
            </div>
            <div className='clientListContainer'>
                {displayEmpty && <Empty style={{ margin: '2rem 0' }} />}
                {library?.map((el) => (
                    <div onClick={() => handleClick(el)} className='clientItem'>
                        <Space size={16}>
                            <div className='coverImage'>
                                <img src={el.logoImages} />
                            </div>
                            <div>
                                <div className='clientName'>{el.displayName}</div>
                                <div className='clientDetail'>{el.clientName}</div>
                            </div>
                        </Space>
                        <SVG
                            src={
                                el.isPublic
                                    ? '/images/icon/public-client.svg'
                                    : '/images/icon/private-client.svg'
                            }
                        />
                    </div>
                ))}
                <ComponentLoading id={pageID} absolute={true} />
            </div>
        </div>
    );
};

export default AddLibrary;
