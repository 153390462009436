import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FastAverageColor from 'fast-average-color';
import * as model from '../../redux/store/storeModel';
import {
    Content,
    SubscriptionType,
    LicenseType,
    SubscriptionMetaData
} from '../../../interface/home';
import { getListSetRoute } from '../../routes/generatePath';
import { onErrorImage } from '../../Utilities/imageUtilities';

export enum StoreViewType {
    List,
    Collection,
    BestSeller,
    History,
    Queue,
    BookShelf
}

interface IColor {
    value?: any;
    isLight?: any;
    hex: string;
}

export interface StoreBookViewParam {
    index?: number;
    book: Content;
    type?: StoreViewType;
    licenseType?: LicenseType;
}
export interface StoreVideoViewParam {
    index?: number;
    video: model.StoreBookDetail;
    type?: StoreViewType;
    licenseType?: model.LicenseType;
    setReturnedUID?: any;
    search?: string;
    authToken?: string;
    prefix?: string;
    isEbookDetail?: boolean;
    related?: boolean;
}

export interface StorePodcastViewParam {
    index?: number;
    podcast: model.StoreBookDetail;
    type?: StoreViewType;
    licenseType?: model.LicenseType;
    setReturnedUID?: any;
    search?: string;
    authToken?: string;
    prefix?: string;
    isEbookDetail?: boolean;
}

export interface BookDetailParam {
    book: model.StoreBookDetail;
}

interface ContentSetViewParam {
    set: SubscriptionMetaData;
    subscriptionName?: string;
    licenseType?: LicenseType;
    // setType?: SubscriptionType;
    // setTypeName?: string;
    // prefix?: string;
    // clientInfo?: ClientInfo;
}

export const ContentSetView = (props: ContentSetViewParam) => {
    const [color, setColor] = useState<IColor | undefined>(
        props.set.bgColor ? { hex: props.set.bgColor } : undefined
    );
    const [fontColor, setFontColor] = useState('');
    const licenseType = props.licenseType;

    const root = document.documentElement;
    const style = getComputedStyle(root);

    const adjustBrightness = (hexCode: string, adjustPercent: number): string => {
        hexCode = hexCode.replace('#', '');

        if (hexCode.length === 3) {
            hexCode = hexCode[0] + hexCode[0] + hexCode[1] + hexCode[1] + hexCode[2] + hexCode[2];
        }

        const hexArray = hexCode.match(/.{2}/g);
        if (!hexArray) {
            throw new Error('Invalid hex code');
        }

        const adjustedHexArray = hexArray.map((hex) => {
            const color = parseInt(hex, 16);
            const adjustableLimit = adjustPercent < 0 ? color : 255 - color;
            const adjustAmount = Math.ceil(adjustableLimit * adjustPercent);
            return ('0' + Math.min(255, Math.max(0, color + adjustAmount)).toString(16)).slice(-2);
        });

        return '#' + adjustedHexArray.join('');
    };

    const primaryColor = adjustBrightness(style.getPropertyValue('--primary'), 0.8);

    const isDarkColor = (hexCode: string): boolean => {
        hexCode = hexCode.replace('#', '');
        const r = parseInt(hexCode.substring(0, 2), 16);
        const g = parseInt(hexCode.substring(2, 4), 16);
        const b = parseInt(hexCode.substring(4, 6), 16);
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        return luminance < 128;
    };

    useEffect(() => {
        if (props.set && !props.set.bgColor) {
            const img = document.getElementById(props.set.uid.toString()) as HTMLImageElement;

            if (typeof img !== 'undefined' && img !== null) {
                const loadHandler = () => {
                    const fac = new FastAverageColor();
                    if (img) {
                        const googleProxyURL =
                            'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
                        const finalScr =
                            googleProxyURL +
                            encodeURIComponent(props.set.coverImage || props.set.imagePath);
                        fac.getColorAsync(finalScr)
                            .then(function (c: any) {
                                setColor(c);
                            })
                            .catch(() => {
                                setColor({ hex: primaryColor });
                            });
                    }
                };
                img.addEventListener('load', loadHandler, false);
                return () => {
                    img.removeEventListener('load', loadHandler, false);
                };
            }
        }
    }, [props.set]);

    useEffect(() => {
        if (color) {
            const bgEle = color;
            setFontColor(bgEle ? (!isDarkColor(color.hex) ? '#000000' : '#ffffff') : '#000000');
        }
    }, [color]);

    return (
        <Link
            to={{
                pathname: getListSetRoute(
                    props.set.uid,
                    props.set.subscriptionType ?? SubscriptionType.Magazine,
                    props.licenseType
                ),
                state: {
                    licenseType: licenseType,
                    setId: props.set.uid,
                    setName: props.set.title,
                    setType: props.set.subscriptionType ?? SubscriptionType.Magazine,
                    setTypeName: props.set.title
                    // prefix: props.clientInfo?.companyPrefix ?? ''
                }
            }}
        >
            <div
                className={color ? 'category  fadeIn' : 'category'}
                style={{ backgroundColor: color?.hex }}
            >
                <div className='title' style={{ color: fontColor }}>
                    {props.set.title}
                </div>

                <div className={`cover`}>
                    <img
                        id={props.set.uid}
                        src={
                            props.set.coverImageWebp || props.set.coverImage || props.set.imagePath
                        }
                        className='ebook'
                        alt={props.set.title}
                        onError={onErrorImage}
                    />
                </div>
            </div>
        </Link>
    );
};
