import { Modal, ModalProps } from 'antd';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  AuthPageType,
  LoginResponse,
  OtpPassword,
  OtpRegister
} from '../../../../../interface';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { checkOTPExpire } from '../../../../Utilities/validateText';
import { VerifyOTPAction } from '../../../../redux/verifyOTP';
import ChangePassword from './changePassword';
import NewPassword from '../NewPassword';
import VerifyOTP from '../VerifyOTP';
import Success from '../Success';
import './changePasswordModal.scss';

interface ChangePasswordModalProps {
  visible: boolean;
  onClose: () => void;
}

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const dispatch = useDispatch();
  const [pageType, setPageType] = useState<AuthPageType>(
    AuthPageType.ChangeEmail
  );
  const [otpData, setOTPData] = useState<OtpPassword | OtpRegister>();
  const [userTokenRegister, setUserTokenRegister] = useState<LoginResponse>();

  const { clientInfo, verifyOTP } = useSelector(
    (state: HibraryRootState) => ({
      clientInfo: state.clientInfo,
      verifyOTP: state.verifyOTP
    }),
    shallowEqual
  );

  const modalProps: ModalProps = {
    open: props.visible,
    onCancel: props.onClose,
    className: 'changeEmailModal',
    footer: null,
    width: 450,
    afterClose: () => setPageType(AuthPageType.ChangeEmail)
  };

  useEffect(() => {
    if (
      verifyOTP.changePasswordOTP &&
      checkOTPExpire(verifyOTP.changePasswordOTP.expireDate ?? 0)
    ) {
      dispatch(VerifyOTPAction.resetChangePasswordOTP());
    }
  }, [props.visible]);

  const renderPage = () => {
    switch (pageType) {
      case AuthPageType.ChangeEmail:
        return (
          <ChangePassword
            clientInfo={clientInfo}
            setPageType={setPageType}
            setOTPData={setOTPData}
          />
        );
      case AuthPageType.ChangePasswordOTP:
        return (
          <VerifyOTP
            pageType={pageType}
            otp={otpData!}
            setPageType={setPageType}
            setOTPData={setOTPData}
            onBack={() => setPageType(AuthPageType.ChangeEmail)}
            setUserTokenRegistered={setUserTokenRegister}
            onClose={props.onClose}
          />
        );
      case AuthPageType.NewPassword:
        return (
          <NewPassword
            clientInfo={clientInfo}
            otp={otpData!}
            verifyOTP={otpData?.verifyOtp ?? ''}
            setPageType={setPageType}
            onBack={() => setPageType(AuthPageType.ChangeEmail)}
          />
        );
      case AuthPageType.SuccessChangePassword:
        return (
          <Success
            clientInfo={clientInfo}
            userTokenRegistered={userTokenRegister}
            onCloseModal={props.onClose}
          />
        );
    }
  };

  return (
    <Modal {...modalProps}>
      <img src={clientInfo.logoImages ?? 'images/login-sticker.svg'} alt="" />
      {renderPage()}
    </Modal>
  );
};
export default ChangePasswordModal;
