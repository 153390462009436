import { Button, Checkbox, Modal, ModalProps } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import PrivacyPolicyModal from '../../privacyPolicyModal/PrivacyPolicyModal';
import TermsOfUseModal from '../../termsOfUseModal/TermsOfUseModal';
import './index.scss';
import { getSignInSsoUrl } from '../../../../redux/auth/authCrud';

interface SignInSSOModalProps {
    visible: boolean;
    onClose: () => void;
}

const SignInSSOModal = (props: SignInSSOModalProps) => {
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [checkPrivacy, setCheckPrivacy] = useState<boolean>(false);
    const [privacyPolicyModal, setPrivacyModal] = useState<boolean>(false);
    const [checkTermOfUse, setCheckTermOfuse] = useState<boolean>(false);
    const [termsOfUseModal, setTermsOfUseModal] = useState<boolean>(false);

    const { clientInfo } = useSelector((state: HibraryRootState) => ({
        clientInfo: state.clientInfo
    }));

    const modalProps: ModalProps = {
        open: props.visible,
        onCancel: props.onClose,
        className: 'sign-in-sso-modal',
        footer: null,
        width: 450
    };

    const checkTerms = () => {
        if (checkPrivacy && checkTermOfUse) return false;
        else return true;
    };

    const handleSignIn = async () => {
        try {
            setLoading(true);
            window.localStorage.setItem('accepted-policy', 'true');
            const response = await getSignInSsoUrl(clientInfo.companyPrefix);
            if (response.data) window.location.href = response.data?.ssoUrl;
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal {...modalProps}>
            <img src={clientInfo.logoImages ?? 'images/login-sticker.svg'} alt='' />
            <h1>{intl.formatMessage({ id: 'SignIn.SSOBtn' })}</h1>
            <div className='clientCard'>
                <div className='coverImage'>
                    <img src={clientInfo?.logoImages} />
                </div>
                <div>
                    <p>{clientInfo?.displayName}</p>
                    <p>{clientInfo?.clientName}</p>
                </div>
            </div>

            <div className='check-box-container'>
                <Checkbox
                    checked={checkPrivacy}
                    onClick={() => {
                        if (checkPrivacy) {
                            setCheckPrivacy(false);
                        } else {
                            setPrivacyModal(true);
                        }
                    }}
                >
                    ฉันได้อ่านและยอมรับ นโยบายความเป็นส่วนตัว
                </Checkbox>
                <Checkbox
                    checked={checkTermOfUse}
                    onClick={() => {
                        if (checkTermOfUse) {
                            setCheckTermOfuse(false);
                        } else {
                            setTermsOfUseModal(true);
                        }
                    }}
                >
                    ฉันได้อ่านและยอมรับ เงื่อนไขการใช้บริการ
                </Checkbox>
            </div>

            <Button
                className='acceptButton'
                htmlType='submit'
                loading={loading}
                disabled={checkTerms()}
                onClick={() => handleSignIn()}
            >
                {loading
                    ? intl.formatMessage({ id: 'Action.JustAMoment' })
                    : intl.formatMessage({ id: 'SignIn.SignInBtn' })}
            </Button>

            <PrivacyPolicyModal
                visible={privacyPolicyModal}
                handleCheckPrivacy={() => setCheckPrivacy(true)}
                handleVisible={(visible) => setPrivacyModal(visible)}
            />
            <TermsOfUseModal
                visible={termsOfUseModal}
                handleCheckTermsOfUse={() => setCheckTermOfuse(true)}
                handleVisible={(visible) => setTermsOfUseModal(visible)}
            />
        </Modal>
    );
};

export default SignInSSOModal;
