import { AddMemberStore, PublicRegisterClient } from '../../../../../interface';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { getDomainName } from '../../../../Utilities/validateText';

interface SuccessAddLibraryProps {
    currentClient?: PublicRegisterClient;
    userToken?: AddMemberStore;
}

const SuccessAddLibrary = (props: SuccessAddLibraryProps) => {
    const { auth } = useSelector((state: HibraryRootState) => ({
        auth: state.auth
    }));

    const onRedirect = () => {
        const reg = new RegExp(
            `((elibrary-([A-z0-9]+))|bkk)\.${getDomainName(window.location.hostname)}`
        );

        if (props.currentClient && props.userToken) {
            if (props.currentClient.companyPrefix === 'bkk') {
                window.location.href = `bkk.hibrary.me?ref=${
                    auth.userInfo?.uid
                }&&authenUserToken=${encodeURIComponent(props.userToken.token.code)}`;
            }

            if (window.location.origin.match(reg)) {
                const newLocation = window.location.origin.replace(
                    reg,
                    `elibrary-${props.currentClient.companyPrefix}.${getDomainName(
                        window.location.hostname
                    )}`
                );
                window.location.href = `${newLocation}?ref=${
                    auth.userInfo?.uid
                }&&authenUserToken=${encodeURIComponent(props.userToken.token.code)}`;
            } else {
                window.location.href = `https://elibrary-${
                    props.currentClient.companyPrefix
                }.${getDomainName(window.location.hostname)}?ref=${
                    auth.userInfo?.uid
                }&&authenUserToken=${encodeURIComponent(props.userToken.token.code)}`;
            }
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            onRedirect();
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className='successAddLibrary'>
            <img src='/images/icon/successAddLibrary.svg' />
            <div>
                <h1>เพิ่มห้องสมุดเรียบร้อย</h1>
                <h4>เปลี่ยนไปใช้ห้องสมุดที่เพิ่มใหม่ได้เลย</h4>
            </div>
            {/* <Button shape='round'>เปลี่ยนห้องสมุด</Button> */}
        </div>
    );
};

export default SuccessAddLibrary;
