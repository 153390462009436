import axios from 'axios';
import { HtiResponse } from '../../../interface';
import {
   CatalogContentMetaData,
   CatalogContentResponse,
   CatalogsResponse,
   CatalogVotePayload
} from '../../../interface/voteEbook';

export const getCatalogList = async () => {
   try {
      const path = `/catalogs/catalogsPoll`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<CatalogsResponse>>(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const getCatalogContents = async (
   catalogUID: string,
   lastEvaluatedKey?: string
) => {
   try {
      const path = `/catalogs/${catalogUID}/contents`;
      if (lastEvaluatedKey) {
         const {
            data: { data }
         } = await axios.get<HtiResponse<CatalogContentResponse>>(path, {
            params: { lastEvaluatedKey: lastEvaluatedKey, limit: 36 }
         });
         return data;
      } else {
         const {
            data: { data }
         } = await axios.get<HtiResponse<CatalogContentResponse>>(path);
         return data;
      }
   } catch (error) {
      return Promise.reject(error);
   }
};

export const voteEbook = async (payload: CatalogVotePayload) => {
   try {
      const path = `/catalogs/vote`;
      const {
         data: { data }
      } = await axios.post<HtiResponse>(path, payload);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const getCatalogContentDetail = async (contentUID: string) => {
   try {
      const path = `/catalogs/contents/${contentUID}`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<CatalogContentMetaData>>(path);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};
