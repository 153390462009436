import { AxiosError, HtiErrorResponse } from '../../../../interface';
import { Modal } from 'antd';

export const showErrorModal = (error: AxiosError | HtiErrorResponse, onConfirm?: () => void) => {
    let htiError: HtiErrorResponse;
    const checkError = error as AxiosError;
    if (checkError && checkError.response && checkError.response.data) {
        htiError = checkError.response.data;
    } else {
        htiError = error as HtiErrorResponse;
    }
    Modal.error({
        title:
            htiError.errorCode === '905'
                ? 'สื่อหมดอายุหรือถูกคืนจากอุปกรณ์อื่นแล้ว'
                : htiError.message,
        okText: 'ตกลง',
        onOk: onConfirm
    });
};
