import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Banner, BannerType, ContentType } from '../../../interface/home';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import {
    getPublisherRoute,
    getDetailRoute,
    getListSubCategoryRoute,
    getListSubInCategoryRoute,
    getSearchRoute
} from '../../routes/generatePath';

SwiperCore.use([Autoplay]);

const SwiperBanner = {
    1: {
        slidesPerView: 1
    },
    576.98: {
        slidesPerView: 1.2
    },
    768.98: {
        slidesPerView: 2.5
    },
    1024.98: {
        slidesPerView: 3.5
    },
    2049: {
        slidesPerView: 4.5
    }
};

interface CarouselEleProps {
    banners: Banner[];
}
export const CarouselEle = (props: CarouselEleProps) => {
    const slideDiv = useRef<HTMLDivElement>(null);

    const convertBannerTypeToContentType = (bannerType: BannerType): ContentType => {
        switch (bannerType) {
            case BannerType.ebook:
                return ContentType.ebook;
            case BannerType.podcast:
                return ContentType.podcast;
            case BannerType.video:
                return ContentType.video;
            default:
                return ContentType.ebook;
        }
    };

    const convertedLink = (banner: Banner) => {
        switch (banner.bannerType) {
            case BannerType.ebook || BannerType.podcast || BannerType.video:
                return banner.licenseType
                    ? getDetailRoute(
                          banner.value as string,
                          convertBannerTypeToContentType(banner.bannerType),
                          banner.licenseType
                      )
                    : '#';
            case BannerType.link:
                return banner.value;
            case BannerType.creator:
                return getPublisherRoute(banner.value as string, banner.licenseType);
            case BannerType.author:
                return getPublisherRoute(banner.value as string, banner.licenseType);
            case BannerType.category:
                return getListSubInCategoryRoute(banner.value, banner.licenseType);
            case BannerType.subcategory:
                return getListSubCategoryRoute(banner.value, banner.subValue!, banner.licenseType);
            case BannerType.search:
                return getSearchRoute(banner.value);
            default:
                return '#';
        }
    };

    return (
        <div ref={slideDiv}>
            <Swiper
                breakpoints={SwiperBanner}
                spaceBetween={4}
                centeredSlides={true}
                loop={true}
                pagination={{ clickable: true }}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                className='bannerSwiper'
            >
                {props.banners?.map((e, index) => (
                    <SwiperSlide key={`banner_${index}`} className='bannerHome'>
                        <Link
                            to={{
                                pathname: e.value === '' ? undefined : convertedLink(e)
                            }}
                            target={e.bannerType === BannerType.link ? '_blank' : '_self'}
                        >
                            <div className='slide' key={index}>
                                <img src={e.imageUrl} className='link' alt='Banner' />
                            </div>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
