import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { action } from '../../redux/request';
import { useFormik } from 'formik';
import { removeSplash } from '../../helpers/removeSplash';
import { Helmet } from 'react-helmet-async';
import { Divider, Row, Col, Button, Modal, Space } from 'antd';
import { BreadCrumbView } from '../../components/breadcrumb';
import { Loading } from '../../customUI/loading';
import { showErrorModal } from '../../components/modal/errorModal';
import { HibraryRootState } from '../../../redux/rootReducer';
import { UserAuthentication } from '../../redux/auth/authRedux';
import { HtiResponse, LoginResponse, Profile, UpdateProfileRequest } from '../../../interface';
import { useIntl } from 'react-intl';
import axios from 'axios';
import * as authRedux from '../../redux/auth/authRedux';
import successLoading from '../../assets/lotties/1127-success.json';
import Lottie from 'lottie-react';
import DeleteAccountModal from '../../components/modal/deleteAccountModal/deleteAccountModal';
import DeleteLibraryModal from '../../components/modal/deleteLibraryModal/deleteLibraryModal';
import ChangeEmailModal from '../../components/modal/authModal/changeEmailModal/ChangeEmailModal';

export const ProfilePage = () => {
    const intl = useIntl();
    const pageId = 'ProfilePage';
    const titleText = intl.formatMessage({ id: 'Profile.Title' });

    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [deleteLibraryModalVisible, setDeleteLibraryModalVisible] = useState<boolean>(false);
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState<boolean>(false);
    const [initContent, setContent] = useState<Profile | undefined>();
    const [visible, setVisible] = useState<boolean>(false);
    const [isChangeEmailModal, setChangeEmailModal] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { authen } = useSelector(
        (state: HibraryRootState) => ({
            authen: state.auth
        }),
        shallowEqual
    );
    useEffect(() => {
        setContent(undefined);
        setLoadingPage(false);
        fetchData();
    }, [dispatch]);

    const reqFunc = async () => {
        dispatch(action.setProcess(true, pageId));
        const { data } = await axios.get<HtiResponse<Profile>>('/member/profiles');
        if (data.status && data.data) {
            if (data.data) {
                setContent(data.data);
            }
        }
        dispatch(action.setProcess(false, pageId));
    };

    const fetchData = async () => {
        try {
            dispatch(removeSplash);
            reqFunc();
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const updateProfile = async (values: UpdateProfileRequest) => {
        try {
            setLoadingPage(true);
            const qString: UpdateProfileRequest = {
                name: values.name,
                surname: values.surname,
                staffID: values.staffID
                    ? checkStaffId(values.staffID!, authen.userInfo!.clientPrefix)
                    : undefined
            };

            removeUnchangedValue(qString, initContent);
            const {
                data: { data }
            } = await axios.patch<HtiResponse<LoginResponse>>('/member/profiles', qString);
            setVisible(true);
            dispatch(authRedux.actions.login(data!.userToken));
            setLoadingPage(false);
        } catch (error) {
            setLoadingPage(false);
            showErrorModal(error as any);
        }
    };

    const checkStaffId = (staffId: string, companyPrefix: string) => {
        const regex = new RegExp(`^${companyPrefix}([0-9]{4,15})$`);
        const match = staffId.match(regex);
        if (match) {
            return staffId;
        } else {
            return `${companyPrefix}${staffId}`;
        }
    };

    const removeUnchangedValue = (values: any, defaultValue: any) => {
        for (const key in values) {
            const currentValue = values[key];
            const getDefaultValue = defaultValue[key];
            if (currentValue === getDefaultValue) {
                console.log(delete values[key]);
            }
        }
    };

    const onOk = () => {
        setVisible(false);
    };

    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>
                        {window.location.hostname.match(/cu-elibrary.com/)
                            ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                            : 'Hibrary'}{' '}
                        | {titleText}
                    </title>
                </Helmet>
                <BreadCrumbView />
                <h1>{titleText}</h1>
                <Loading id={pageId} />
                {initContent ? (
                    <SignUpForm
                        initContent={initContent}
                        loadingPage={loadingPage}
                        setChangeEmail={setChangeEmailModal}
                        handleSubmit={updateProfile}
                        userAuthen={authen}
                        deleteAccountModal={() => setDeleteAccountModalVisible(true)}
                        deleteLibraryModal={() => setDeleteLibraryModalVisible(true)}
                    />
                ) : null}
            </div>

            <Modal
                className='rental-modal'
                visible={visible}
                footer={
                    <Button size='middle' type='primary' shape='round' key='back' onClick={onOk}>
                        {intl.formatMessage({ id: 'Action.OKBtn' })}
                    </Button>
                }
            >
                <div className='modal-detail'>
                    <Lottie animationData={successLoading} className='modal-lottie' />
                    <h3>เปลี่ยนข้อมูลเรียบร้อยแล้ว</h3>
                </div>
            </Modal>

            {/* <Modal
                destroyOnClose
                footer={null}
                visible={isChangeEmail}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <AuthPage
                    initType={AuthPageType.ChangeEmail}
                    verifyOtpType={SendEmailForOtpType.ChangeEmail}
                    onCloseModal={() => setChangeEmail(false)}
                />
            </Modal> */}
            <ChangeEmailModal
                visible={isChangeEmailModal}
                onClose={() => setChangeEmailModal(false)}
            />

            <DeleteAccountModal
                visible={deleteAccountModalVisible}
                onCancel={() => setDeleteAccountModalVisible(false)}
            />

            <DeleteLibraryModal
                visible={deleteLibraryModalVisible}
                onCancel={() => setDeleteLibraryModalVisible(false)}
            />
        </>
    );
};

const SignUpForm = (props: {
    initContent: Profile;
    loadingPage: boolean;
    userAuthen: UserAuthentication;
    setChangeEmail: (e: any) => void;
    handleSubmit: (e: UpdateProfileRequest) => void;
    deleteAccountModal: () => void;
    deleteLibraryModal: () => void;
}) => {
    const intl = useIntl();
    const { authen } = useSelector(
        (state: HibraryRootState) => ({
            authen: state.auth
        }),
        shallowEqual
    );

    const formik = useFormik({
        initialValues: {
            name: props.initContent.name,
            surname: props.initContent.surname,
            staffID: props.initContent.staffID,
            email: props.initContent.email
        },
        onSubmit: (values: Profile) => {
            props.handleSubmit(values);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} className='profile-form'>
            <div>
                <h2>{intl.formatMessage({ id: 'Profile.UserAccount' })} </h2>
                <Row>
                    <Col>
                        <label>{intl.formatMessage({ id: 'Profile.Name' })}</label>
                        <input
                            id='name'
                            name='name'
                            type='text'
                            placeholder={`กรอกชื่อ`}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            disabled={authen.userInfo?.isSsoLogin}
                        />
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <label>{intl.formatMessage({ id: 'Profile.LastName' })}</label>
                        <input
                            id='surname'
                            name='surname'
                            type='text'
                            placeholder={`กรอกนามสกุล`}
                            onChange={formik.handleChange}
                            value={formik.values.surname}
                            disabled={authen.userInfo?.isSsoLogin}
                        />
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <label>
                            {intl.formatMessage({ id: 'Profile.ID' })} (
                            {props.userAuthen.userInfo?.clientPrefix})
                        </label>
                        <input
                            id='staffID'
                            name='staffID'
                            type='text'
                            placeholder={`-`}
                            onChange={formik.handleChange}
                            value={formik.values.staffID}
                            disabled={authen.userInfo?.isSsoLogin}
                        />
                    </Col>
                </Row>
            </div>

            {!authen.userInfo?.isSsoLogin && (
                <Space size={16} className='flex center'>
                    <Button
                        type='default'
                        shape='round'
                        disabled={
                            formik.values['name'] !== '' &&
                            formik.values['surname'] !== '' &&
                            (props.initContent?.name !== formik.values['name'] ||
                                props.initContent?.surname !== formik.values['surname'] ||
                                props.initContent?.staffID !== formik.values['staffID'])
                                ? false
                                : true
                        }
                        className='accept-button'
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                        loading={props.loadingPage}
                    >
                        {intl.formatMessage({ id: 'Action.Save' })}
                    </Button>

                    <Button
                        type='primary'
                        shape='round'
                        className='errors-button'
                        onClick={props.deleteAccountModal}
                        danger
                    >
                        {intl.formatMessage({ id: 'Profile.DeleteAccountBtn' })}
                    </Button>
                    <Button
                        type='primary'
                        shape='round'
                        className='errors-button'
                        onClick={props.deleteLibraryModal}
                        danger
                    >
                        {intl.formatMessage({ id: 'Profile.DeleteLibraryBtn' })}
                    </Button>
                </Space>
            )}

            <div>
                <h2>{intl.formatMessage({ id: 'Profile.SignIn' })}</h2>
                <Row gutter={[16, 16]}>
                    <Col>
                        <label>{intl.formatMessage({ id: 'Profile.Email' })}</label>
                        <label>{props.userAuthen.userInfo?.email ?? '-'}</label>
                    </Col>
                    <Col>
                        <label>{intl.formatMessage({ id: 'Profile.Tel' })}</label>
                        <label>{props.userAuthen.userInfo?.phoneNumber ?? '-'}</label>
                    </Col>
                </Row>
            </div>

            {!authen.userInfo?.isSsoLogin ? (
                <Button
                    shape='round'
                    className='accept-button'
                    onClick={() => props.setChangeEmail(true)}
                >
                    {intl.formatMessage({ id: 'Profile.ChangeEmail' })}
                </Button>
            ) : null}
        </form>
    );
};
