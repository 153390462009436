import { Progress } from 'antd';
import dayjs from 'dayjs';
import { ReadingTime } from '../../../../interface';
import { useIntl } from 'react-intl';

interface ReadingListProps {
    data: ReadingTime[];
}

const ReadingList = (props: ReadingListProps) => {
    const intl = useIntl();

    const FormatTime = (time: number) => {
        let timeString = '';

        if (time >= 3600) {
            const hours = Math.floor(time / 3600);
            timeString += `${hours} ${intl.formatMessage({
                id: 'Unit.Hour'
            })}`;
        }
        const remainingMinutes = Math.floor((time % 3600) / 60);
        if (remainingMinutes > 0) {
            timeString += ` ${remainingMinutes} ${intl.formatMessage({
                id: 'Unit.Minute'
            })}`;
        } else if (time < 60) {
            timeString += `${time} ${intl.formatMessage({
                id: 'Unit.Seconds'
            })}`;
        }

        return timeString;
    };

    return (
        <div style={{ width: '100%' }}>
            {props.data.map((el) => (
                <div
                    className='reading-list-box flex align-center justify-space-between'
                    key={el.licenseUID}
                >
                    <div>
                        <p className='reading-time'>
                            {intl.formatMessage({
                                id: 'ReadingStat.HistoryTime'
                            })}
                            {' ' + FormatTime(el.readingTime ?? 0)}
                        </p>
                        <p className='reading-date'>
                            {intl.formatMessage({
                                id: 'ReadingStat.HistoryDateRead'
                            }) + ' : '}
                            {dayjs.unix(el.timeStamp).format('DD/MM/YYYY')}
                        </p>
                        <p className='date-text'>
                            {intl.formatMessage({
                                id: 'ReadingStat.HistoryBorrowedDate'
                            }) + ' : '}
                            {dayjs.unix(el.startDate).format('DD/MM/YYYY เวลา HH:mm น.')}
                        </p>
                        <p className='date-text'>
                            {intl.formatMessage({
                                id: 'ReadingStat.HistoryReturnDate'
                            }) + ' : '}
                            {dayjs.unix(el.expireDate).format('DD/MM/YYYY เวลา HH:mm น.')}
                        </p>
                    </div>
                    <div
                        className='flex flex-col progress-day-reading align-center justify-center'
                        style={{ width: 'fit-content' }}
                    >
                        <Progress
                            type='circle'
                            size='small'
                            percent={Math.ceil(el.readingTimePercent * 100)}
                            strokeWidth={12}
                            trailColor='#ffff'
                            width={65}
                            format={(percent) => (percent ?? 0 < 100 ? `${percent}%` : '100%')}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ReadingList;
