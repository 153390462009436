import { Radio, Space } from 'antd';
import { QuestionComponentProps } from '../../../../interface/questionForm';

const QuestionMostCategory = (props: QuestionComponentProps) => {
   return (
      <Radio.Group
         id={props.data.questionUID}
         name={props.data.questionUID}
         onChange={props.onChange}
         value={props.formikValue}
      >
         <Space direction='vertical'>
            {props.data.answerPreset?.map((el, index) => {
               if (el.value === 0) {
                  return (
                     <Radio value={el.value} key={index}>
                        อื่นๆ
                     </Radio>
                  );
               } else {
                  return (
                     <Radio value={el.value} key={index}>
                        {el.display}
                     </Radio>
                  );
               }
            })}
         </Space>
      </Radio.Group>
   );
};

export default QuestionMostCategory;
