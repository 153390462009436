import { Space } from 'antd';

const RequestPersonalRight = () => {
    return (
        <div className='notification-measures'>
            <div>
                <h1>ขั้นตอนการดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย</h1>
                <p>
                    หากพบว่ามีผลงานที่เข้าข่ายผิดกฎหมาย บริษัทฯ
                    จะดำเนินการระงับการเผยแพร่และแจ้งให้เจ้าของผลงานทราบ เพื่อดำเนินการแก้ไขต่อไป
                    และหากพบว่าเจ้าของผลงานมีการส่งผลงานที่ผิดกฎหมายเข้ามาซ้ำซาก บริษัทฯ
                    จะดำเนินการระงับบัญชีผู้ใช้ของเจ้าของผลงานต่อไปอย่างไรก็ดี บริษัทฯ
                    ได้พัฒนาระบบเพื่อตรวจสอบและ ป้องกันการนำ
                    ภาพและเนื้อหาที่มีลิขสิทธิ์หรือไม่เหมาะสมมาวางขายบนแพลตฟอร์ม และบริษัทฯ
                    ได้แต่งตั้งทีมงานที่มีประสบการณ์เชี่ยวชาญด้านเนื้อหาและรูปภาพเพื่อที่จะคอยช่วยดูแล
                    ตรวจจับ และป้องกัน ไม่ให้มีเนื้อหาที่ละเมิดลิขสิทธิ์ หรือผิดกฎหมาย นอกจากนี้
                    บริษัทฯ ได้จัดให้มีระบบในการร้องเรียน จากสมาชิกให้ทีมงานบริษัทฯ โดยตรง
                    ผู้ที่ประสงค์จะให้บริษัทฯ ในฐานะผู้ให้บริการดำเนินการต่อเนื้อหาที่เข้าข่าย
                    ผิดกฎหมาย สามารถดำเนินการแจ้งบริษัทฯ ได้พร้อมเอกสารหลักฐานประกอบแล้วส่งมาที่
                </p>
            </div>
            <div>
                <h4>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด (Hytexts)</h4>
                <Space direction='vertical' size={16}>
                    <p>
                        3 อาคารพร้อมพันธุ์ 3 ชั้น 7 ห้อง 701-702 ซอย ลาดพร้าว 3 แขวงจอมพล เขตจตุจักร
                        กรุงเทพมหานคร 10900
                    </p>
                    <p>โทร: 089-134-7470 (ทุกวัน 9.00-17.30 น.)</p>
                    <p>อีเมล : contact@hytexts.com</p>
                    <p>บริษัทจะดำเนินการและตอบกลับภายใน 7 วันทำการ</p>
                </Space>
            </div>
            <div>
                <h4>การจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม</h4>
                <Space size={16} direction='vertical'>
                    <p>
                        บริษัทฯ
                        มีหลักเกณฑ์ในการจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม
                        โดยคำนึงถึง ปัจจัยหลักในการจัดอันดับขายดี คือ ยอดขาย และ
                        ปัจจัยหลักในการจัดระดับหนังสือที่คุณน่าจะสนใจ
                        ขึ้นกับความเกี่ยวข้องของหนังสือที่ผู้ใช้บริการมี
                        ซึ่งแพลตฟอร์มจะแบ่งเนื้อหาออกเป็น 3 ประเภท โดยคำนึงถึง
                    </p>
                    <ul>
                        <li>ประเภทแรก คือ เนื้อหาทั่วไปที่สามารถเข้าถึงได้บนหน้าเว็บไซต์</li>
                        <li>
                            ประเภทสอง คือ เนื้อหาที่เหมาะกับผู้ใช้งานที่มีอายุ 18 ปีขึ้นไป
                            โดยจะมีสัญลักษณ์ 18+ หรือ 18 ปีขึ้นไป
                        </li>
                        <li>
                            ประเภทสาม คือ เนื้อหาที่เหมาะกับผู้ใช้งานบางกลุ่ม
                            ซึ่งจะจำกัดการแสดงผลให้เฉพาะผู้ใช้บางกลุ่มเท่านั้น เช่น หนังสือรุ่น
                            เป็นต้น
                        </li>
                    </ul>
                    <p>
                        ทั้งนี้ ผลงานใดจะอยู่ในระดับ/ประเภทไหน
                        ขึ้นกับการตรวจสอบและพิจารณาของเจ้าหน้าที่
                        โดยจะพิจารณาจากเนื้อหาของผลงานนั้นๆ
                    </p>
                    <p>
                        การจัดอันดับกลุ่มหนังสือที่แสดงบนหน้าเว็บไซต์นั้น บริษัทฯ
                        มีหลักเกณฑ์ในการจัดอันดับการนำเสนอ โดยคำนึงถึงปัจจัยหลักในการจัดอันดับขายดี
                        คือ ยอดขาย และ ปัจจัยหลักในการจัดระดับหนังสือที่คุณน่าจะสนใจ
                        ขึ้นกับความเกี่ยวข้องของหนังสือที่ผู้ใช้บริการมี ซึ่งแพลตฟอร์มจะแบ่งกลุ่มไว้
                        6 กลุ่มประเภทดังนี้
                    </p>
                    <ul>
                        <li>
                            โปรโมชั่น - เฉพาะหนังสือที่มีการจัดโปรโมชั่น
                            โดยเรียงตามลำดับของวันที่สิ้นสุดโปรโมชั่นก่อน
                        </li>
                        <li>มาใหม่ - เรียงตามลำดับการขายหนังสือ จากล่าสุดไปเก่าสุด</li>
                        <li>ขายดี - จัดอันดับจากยอดขาย ณ กรอบเวลาปัจจุบันเป็นปัจจัยหลัก</li>
                        <li>ละครฮิต - เฉพาะหนังสือที่ติดป้าย TV คือหนังสือที่ถูกทำเป็นละคร</li>
                        <li>
                            ฟรี - เฉพาะหนังสือที่ดาวน์โหลดได้ฟรี โดยใช้ความนิยม ณ
                            กรอบเวลาปัจุบันเป็นปัจจัยหลัก
                        </li>
                    </ul>
                </Space>
            </div>
            <div>
                <h4>มาตรการดูแลผู้ใช้บริการ</h4>
                <p>
                    มาตรการดูแลผู้ใช้บริการซึ่งได้รับความเสียหายจากการใช้บริการเป็นไปตามนโยบายของบริษัทฯ
                    ถ้าท่านมีปัญหาใด ๆเกี่ยวกับการให้บริการแพลตฟอร์มดิจิทัล โปรดติดต่อ
                </p>
            </div>
            <div>
                <h4>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด (Hytexts)</h4>
                <Space direction='vertical' size={16}>
                    <p>
                        3 อาคารพร้อมพันธุ์ 3 ชั้น 7 ห้อง 701-702 ซอย ลาดพร้าว 3 แขวงจอมพล เขตจตุจักร
                        กรุงเทพมหานคร 10900
                    </p>
                    <p>โทร: 089-134-7470 (ทุกวัน 9.00-17.30 น.)</p>
                    <p>อีเมล : contact@hytexts.com</p>
                    <p>บริษัทจะดำเนินการและตอบกลับภายใน 7 วันทำการ</p>
                    <p>
                        ทั้งนี้ หากยังไม่ได้รับการแก้ไขภายในกรอบระยะเวลาที่กำหนดไว้
                        ท่านสามารถติดต่อศูนย์ช่วยเหลือและจัดการปัญหาออนไลน์ (1212ETDA) สายด่วน 1212
                        (24 ชั่วโมง) อีเมล 1212@mdes.go.th เพื่อให้คำแนะนำและรับเรื่อง
                        ร้องเรียนดังกล่าว
                    </p>
                </Space>
            </div>
        </div>
    );
};

export default RequestPersonalRight;
