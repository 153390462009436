import { SetStateAction, useState } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { Button, Input, Tooltip } from 'antd';
import {
    AuthPageType,
    AxiosError,
    ClientInfo,
    OtpPassword,
    OtpRegister
} from '../../../../interface';
import SVG from 'react-inlinesvg';
import * as Yup from 'yup';
import './index.scss';
import { resetPassword } from '../../../redux/auth/authCrud';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { VerifyOTPAction } from '../../../redux/verifyOTP';

const initialValues = {
    newPassword: '',
    confirmNewPassword: ''
};

interface NewPasswordPayload {
    newPassword?: string;
    confirmNewPassword?: string;
}

export interface NewPasswordProps {
    clientInfo: ClientInfo;
    otp: OtpRegister | OtpPassword;
    verifyOTP: string;
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
    onBack: () => void;
}

export const NewPassword = (props: NewPasswordProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const passwordRegex = /^(?=.*[0-9A-Za-z])[A-Za-z0-9!@#$%^&*_+]{6,20}$/;
    const [loading, setLoading] = useState<boolean>(false);
    const [seeNewPW, setSeeNewPW] = useState<boolean>(false);
    const [seeConfirmNewPW, setSeeConfirmNewPW] = useState<boolean>(false);
    const [status, setStatus] = useState<string>();

    const { authen } = useSelector(
        (state: HibraryRootState) => ({
            authen: state.auth
        }),
        shallowEqual
    );

    const NewPasswordSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(6, 'อย่างน้อย 6 ตัวอักษร')
            .max(20, 'ไม่เกิน 20 ตัวอักษร')
            .matches(passwordRegex, 'ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น')
            .required(),
        confirmNewPassword: Yup.string()
            .min(6, 'อย่างน้อย 6 ตัวอักษร')
            .max(20, 'ไม่เกิน 20 ตัวอักษร')
            .matches(passwordRegex, 'ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น')
            .required()
    });

    const onChangePassword = async (values: NewPasswordPayload) => {
        if (values.confirmNewPassword === values.newPassword) {
            try {
                setLoading(true);
                const response = await resetPassword(
                    values.newPassword ?? '',
                    props.otp.uid!,
                    props.verifyOTP ?? '',
                    props.otp.referenceCode!
                );
                if (response) {
                    if (authen.isLogin) {
                        props.setPageType(AuthPageType.SuccessChangePassword);
                        dispatch(VerifyOTPAction.resetChangePasswordOTP());
                    } else {
                        props.setPageType(AuthPageType.SuccessForgotPassword);
                        dispatch(VerifyOTPAction.resetForgotPasswordOTP());
                    }
                }
            } catch (error) {
                const getError = error as AxiosError;
                setStatus(getError.response.data.message);
            } finally {
                setLoading(false);
            }
        } else {
            setStatus('รหัสผ่านไม่ตรงกัน');
        }
    };

    const formik = useFormik<NewPasswordPayload>({
        initialValues,
        validationSchema: NewPasswordSchema,
        onSubmit: (values) => onChangePassword(values)
    });

    return (
        <>
            <div className='newPasswordContainer'>
                <h1>{intl.formatMessage({ id: 'ChangePassword.Title' })}</h1>
                <form onSubmit={formik.handleSubmit}>
                    <div className='inputItem'>
                        <Input
                            value={formik.values.newPassword}
                            placeholder={intl.formatMessage({
                                id: 'ChangePassword.Placeholder'
                            })}
                            type={seeNewPW ? 'text' : 'password'}
                            className='formInput'
                            autoComplete='new-password'
                            onChange={(e) => formik.setFieldValue('newPassword', e.target.value)}
                            suffix={
                                <Tooltip
                                    id='quick-user-tooltip'
                                    title={seeNewPW ? 'ซ่อนรหัสผ่าน' : 'แสดงรหัสผ่าน'}
                                >
                                    <SVG
                                        src={seeNewPW ? `/images/eye-slash.svg` : `/images/eye.svg`}
                                        onClick={() => setSeeNewPW(!seeNewPW)}
                                    />
                                </Tooltip>
                            }
                        />

                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className='error-text'>{formik.errors.newPassword}</div>
                        ) : null}
                    </div>
                    <div className='inputItem'>
                        <Input
                            value={formik.values.confirmNewPassword}
                            placeholder={intl.formatMessage({
                                id: 'ChangePassword.ConfirmPlaceholder'
                            })}
                            type={seeConfirmNewPW ? 'text' : 'password'}
                            onChange={(e) =>
                                formik.setFieldValue('confirmNewPassword', e.target.value)
                            }
                            className='formInput'
                            autoComplete='new-password'
                            suffix={
                                <Tooltip
                                    id='quick-user-tooltip'
                                    title={seeConfirmNewPW ? 'ซ่อนรหัสผ่าน' : 'แสดงรหัสผ่าน'}
                                >
                                    <SVG
                                        src={
                                            seeConfirmNewPW
                                                ? `/images/eye-slash.svg`
                                                : `/images/eye.svg`
                                        }
                                        onClick={() => setSeeConfirmNewPW(!seeConfirmNewPW)}
                                    />
                                </Tooltip>
                            }
                        />
                        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                            <div className='error-text'>{formik.errors.confirmNewPassword}</div>
                        ) : null}
                    </div>
                    <div className='errorField'>{status && <div>{status}</div>}</div>
                    <div className='buttonContain'>
                        <Button
                            className='acceptButton'
                            loading={loading}
                            onClick={() => formik.handleSubmit()}
                        >
                            {intl.formatMessage({ id: 'Action.ConfirmBtn' })}
                        </Button>
                        <span className='back' onClick={props.onBack}>
                            {intl.formatMessage({ id: 'Action.Back' })}
                        </span>
                    </div>
                </form>
            </div>
        </>
    );
};

export default NewPassword;
