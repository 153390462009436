import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

const CardListEbook = () => {
    const intl = useIntl();
    const history = useHistory();

    return (
        <div className='list-card'>
            <div
                className='card-of-ebook card-publisher'
                onClick={() => history.push('/listEbook/publisher')}
            >
                <img src='/images/card-of-ebook-publisher.png' />
                <div className='label'>
                    {intl.formatMessage({
                        id: 'Catalogue.PublisherCard'
                    })}
                </div>
            </div>
            <div className='card-of-ebook card-cat' onClick={() => history.push('/listEbook/cat')}>
                <img src='/images/card-of-ebook-cat.png' />
                <div className='label'>
                    {intl.formatMessage({
                        id: 'Catalogue.CategoryCard'
                    })}
                </div>
            </div>
            <div
                className='card-of-ebook card-newest'
                onClick={() => history.push('/listEbook/newest')}
            >
                <img src='/images/card-of-ebook-newest.png' />
                <div className='label'>
                    {intl.formatMessage({
                        id: 'Catalogue.NewestCard'
                    })}
                </div>
            </div>
        </div>
    );
};

export default CardListEbook;
