import React from 'react';
import { Empty } from 'antd';

interface NodataViewProps {
   text?: string;
   image?: string;
   isSearch?: boolean;
}
export const NoData = (props: NodataViewProps) => {
   const val = props.text ? props.text : props.isSearch ? 'ไม่พบรายการที่ค้นหา' : 'ไม่มีข้อมูล';
   const img = props.image ? (
      props.image
   ) : props.isSearch ? (
      <img src='/media/members/no-search-result.png' alt='' />
   ) : (
      <Empty description={false} />
   );
   return (
      <div className='nodata'>
         {img}
         <p>{val}</p>
      </div>
   );
};

export default NoData;
