import { Button, Input, InputRef, Modal, ModalProps } from 'antd';
import { ClientRefactor } from '../../../../../interface';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

interface ConfirmPasswordModalProps {
    clientNameConfirm?: ClientRefactor;
    setClientNameConfirm: React.Dispatch<React.SetStateAction<ClientRefactor | undefined>>;
    onRemove: (password: string, clientUID: string) => void;
    loading: boolean;
}
export const ConfirmPasswordModal = (props: ConfirmPasswordModalProps) => {
    const refPassword = useRef<InputRef>(null);
    const { clientNameConfirm, setClientNameConfirm, onRemove } = props;
    const intl = useIntl();

    const modalFooter = (
        <>
            <Button
                shape='round'
                className='cancel-button'
                onClick={() => setClientNameConfirm(undefined)}
            >
                {intl.formatMessage({ id: 'AddLibrary.ModalCancelBtn' })}
            </Button>
            <Button
                shape='round'
                className='accept-button'
                loading={props.loading}
                onClick={() => {
                    if (
                        refPassword.current !== null &&
                        refPassword.current.input !== null &&
                        clientNameConfirm?.clientUID
                    )
                        onRemove(refPassword.current.input.value, clientNameConfirm.clientUID);
                }}
            >
                {intl.formatMessage({ id: 'AddLibrary.ModalDeleteBtn' })}
            </Button>
        </>
    );

    const modalProps: ModalProps = {
        open: clientNameConfirm !== undefined,
        onCancel: () => setClientNameConfirm(undefined),
        wrapClassName: 'confirmRemoveModal',
        width: 400,
        footer: modalFooter
    };

    return (
        <Modal {...modalProps}>
            <div className='confirmRemoveClient'>
                <h3>{intl.formatMessage({ id: 'DeleteLibrary.ModalTitle' })}</h3>
                <div className='removeText' style={{ marginBottom: '1rem' }}>
                    {intl.formatMessage(
                        { id: 'AddLibrary.ModalSubTitle' },
                        { 0: clientNameConfirm?.clientName }
                    )}
                </div>
                <Input.Password
                    ref={refPassword}
                    placeholder='password'
                    autoComplete='new-password'
                />
            </div>
        </Modal>
    );
};
