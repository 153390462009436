import { Swiper, SwiperSlide } from 'swiper/react';
import { Category, ContentType } from '../../../interface';
import { CategoryView } from '../category';

interface ContentProps {
    contents: Category[];
}

const CategorySlideView = (props: ContentProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 1.5,
            slidesPerGroup: 1.5
        },
        576.98: {
            slidesPerView: 3.3,
            slidesPerGroup: 3.3
        },
        768.98: {
            slidesPerView: 5.3,
            slidesPerGroup: 5.3
        }
    };

    return (
        <Swiper spaceBetween={16} breakpoints={breakpoint}>
            {props.contents.map((el, index) => (
                <SwiperSlide key={index}>
                    <CategoryView
                        key={index}
                        category={el}
                        licenseType={el.licenseType}
                        contentType={el.contentType || ContentType.ebook}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default CategorySlideView;
