export enum VoteAction {
   Vote = 'Vote',
   UnVote = 'UnVote'
}
export interface CatalogsResponse {
   catalogsPoll: CatalogMetadata[];
}

export interface CatalogMetadata {
   uid: string;
   title: string;
   coverImage: string;
   coverImageWebp?: string;
   bgColor?: string;
   isVoted: boolean;
   voteStartDate: number;
   voteEndDate: number;
}

export interface CatalogContentResponse {
   title: string;
   concurrent: number;
   activeConcurrent: number;
   contents: CatalogContent[];
   endDate: number;
   startDate: number;
   lastEvaluatedKey: string;
}

export interface CatalogContent {
   authorName: string;
   contentUID: string;
   coverImage: string;
   coverImageWebp?: string;
   description: string;
   isVoted: boolean;
   publisherName: string;
   tags: [];
   title: string;
}

export interface CatalogVotePayload {
   catalogUID: string;
   listContents: ListVoteContents[];
}

export interface ListVoteContents {
   contentUID: string;
   voteAction: VoteAction;
}

export interface CatalogContentMetaData {
   publicationDate: number;
   status: number;
   fileSize: number;
   pageCount: number;
   description: string;
   creatorUID: string;
   title: string;
   publisherName: string;
   catName: string;
   subcatName: string;
   writerID: number;
   authorName: string;
   coverImage: string;
   samplePath: string;
   fileType: string;
   contentUID: string;
   purchasedConcurrent: number;
   orderAmount: number;
}
