import { Button, Modal, ModalProps } from 'antd';
import { useIntl } from 'react-intl';

interface LimitVoteContentModalProps {
    open: boolean;
    onClose: () => void;
    width?: number;
    title?: string;
    description: JSX.Element;
}

const AlertVoteContentModal = (props: LimitVoteContentModalProps) => {
    const intl = useIntl();
    const modalProps: ModalProps = {
        open: props.open,
        footer: null,
        onCancel: props.onClose,
        className: `AlertVoteContentModal`,
        centered: true,
        closable: false,
        width: props.width ? props.width : 600
    };

    return (
        <Modal {...modalProps}>
            {props.title && <h1>{props.title}</h1>}
            <div>{props.description}</div>
            <Button shape='round' className='accept' onClick={props.onClose}>
                {intl.formatMessage({ id: 'VoteActivity.AgreeBtn' })}
            </Button>
        </Modal>
    );
};

export default AlertVoteContentModal;
