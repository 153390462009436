import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormTypeResponse } from '../../../interface/questionForm';
import { removeSplash } from '../../helpers/removeSplash';
import { fetchQuestionForm } from '../../redux/questionForm';
import { useFormik } from 'formik';

//?css
import './form.scss';
import HeaderSectionBox from './header-box';
import QuestionBox from './questionComponent/question-box';

const QuestionnaireForm = () => {
   const dispatch = useDispatch();
   const initialValues = {};
   const [form, setForm] = useState<FormTypeResponse>();

   useEffect(() => {
      fetchForm();
   }, []);

   useEffect(() => {
      setInitialValues();
   }, [form]);

   const fetchForm = async () => {
      try {
         const response = await fetchQuestionForm();
         setForm(response!);
         removeSplash(dispatch);
      } catch (error) {
         console.log(error);
      }
   };

   const setInitialValues = () => {
      const data = form?.question.section;
      if (data) {
         data.map((el) =>
            el.question.map((el) => Object.assign(initialValues, { [el.questionUID]: undefined }))
         );
      }
   };

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (values) => {
         alert(JSON.stringify(values, null, 2));
      }
   });

   console.log(formik.values);

   return (
      <div className='form-wrapper'>
         <div className='form-container'>
            <div className='image-cover' />

            {form && (
               <>
                  <div className='form-title'>
                     <h1>{form.question.title}</h1>
                     <p dangerouslySetInnerHTML={{ __html: form.question.subtitle }} />
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                     {form.question.section.map((el) => (
                        <>
                           <HeaderSectionBox title={el.title} />
                           <QuestionBox
                              data={el.question}
                              onChange={formik.handleChange}
                              value={formik.values}
                           />
                        </>
                     ))}
                  </form>
               </>
            )}
         </div>
      </div>
   );
};

export default QuestionnaireForm;
