interface HeaderBoxProps {
  title: string;
  subTitle?: string;
}

const HeaderSectionBox = (props: HeaderBoxProps) => {
  return (
    <div className="section-title">
      <h1>{props.title}</h1>
      {props.subTitle && <p>{props.subTitle}</p>}
    </div>
  );
};

export default HeaderSectionBox;
