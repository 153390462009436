import { SegmentHeader } from './segmentHeader';
import { Sponsor, SponsorBannerMetaData } from '../../../interface/sponsor';
import {
    getAllContentRoute,
    getCustomContentRoute,
    getListCategoryRoute,
    getListSubInCategoryRoute,
    getSetContentRoute
} from '../../routes/generatePath';
import {
    DisplayType,
    LicenseType,
    Content,
    Category,
    CustomHomeBanner,
    SegmentType,
    SegmentMetaData,
    SubscriptionMetaData,
    SubscriptionType
} from '../../../interface';
import ContentsView from '../../components/segment/contentsView';
import CategorySlideView from '../../components/segment/categorySlideView';
import ContentListWithRankView from '../../components/segment/contentListWithRankView';
import ContentWithDetailView from '../../components/segment/contentWithDetailView';
import BannerRectangleView from '../../components/segment/bannerRectangle';
import BannerSquareView from '../../components/segment/bannerSquare';
import ContentWithRankTitleView from '../../components/segment/contentWithRankTitleView';
import SponsorBanner from '../../components/segment/SponsorBanner';
import SponsorView from '../../components/segment/SponsorView';
import SubScriptionView from '../../components/segment/subscriptionView';
// import ContentBestsellerView from '../../components/segment/contentBestSeller';
// import ContentListView from '../../components/segment/contentListView';
// import ContentListWithBackgroundView from '../../components/segment/contentListWithBackgroundView';
// import ContentListWithCoverBackgroundView from '../../components/segment/contentListWithCoverBackgroundView';
// import ContentWithButtonView from '../../components/segment/contentWithButtonView';
// import ContentWithRankTitleView from '../../components/segment/contentWithRankTitleView';
// import ContentWithTitleButtonView from '../../components/segment/contentWithTitleButtonView';

interface HomeSegmentProps {
    segment: SegmentMetaData;
    indexSegment: number;
    licenseType: LicenseType;
}

const HomeSegment = (props: HomeSegmentProps) => {
    const renderSegment = () => {
        switch (props.segment.displayType) {
            case DisplayType.Content:
                return <ContentsView contents={props.segment.segmentContents as Content[]} />;
            case DisplayType.ContentWithTitle:
                return (
                    <ContentWithDetailView contents={props.segment.segmentContents as Content[]} />
                );
            case DisplayType.ContentListWithRank:
                return (
                    <ContentListWithRankView
                        contents={props.segment.segmentContents as Content[]}
                    />
                );
            case DisplayType.ContentWithRankTitle:
                return (
                    <ContentWithRankTitleView
                        contents={props.segment.segmentContents as Content[]}
                    />
                );
            case DisplayType.BannerSquare:
                return (
                    <BannerSquareView
                        banners={props.segment.segmentContents as CustomHomeBanner[]}
                    />
                );
            case DisplayType.BannerRectangle:
                if (props.segment.segmentType === SegmentType.RecommendContent) {
                    return (
                        <SponsorBanner
                            banners={props.segment.segmentContents as SponsorBannerMetaData[]}
                        />
                    );
                } else {
                    return (
                        <BannerRectangleView
                            banners={props.segment.segmentContents as CustomHomeBanner[]}
                        />
                    );
                }
            case DisplayType.ContentCategory:
            case DisplayType.CategoryContent:
                if (props.segment.segmentType === SegmentType.SubscriptionContent) {
                    return (
                        <SubScriptionView
                            contents={props.segment.segmentContents as SubscriptionMetaData[]}
                            licenseType={props.licenseType}
                        />
                    );
                } else {
                    return (
                        <CategorySlideView contents={props.segment.segmentContents as Category[]} />
                    );
                }
            case DisplayType.ContentMainSponsored:
            case DisplayType.ContentSecondarySponsored:
                return <SponsorView contents={props.segment.segmentContents as Sponsor[]} />;

            default:
                return <></>;
        }
    };

    const handleViewAll = () => {
        switch (props.segment.segmentType) {
            case SegmentType.CategoryContent:
                return getListSubInCategoryRoute(
                    props.segment.categoryUID ?? '',
                    props.licenseType,
                    props.segment.contentType
                );
            case SegmentType.Category:
                return getListCategoryRoute(props.licenseType, props.segment.contentType);
            case SegmentType.RentableContent:
                return `/ebook/all/RentableContent`;
            case SegmentType.AllContent:
                return getAllContentRoute(props.licenseType, props.segment.contentType);
            case SegmentType.ContentMainSponsored:
            case SegmentType.SecondarySponsor:
                return '/viewAllSponsor';
            case SegmentType.RecommendContent:
                return '/viewAllSponsorBanner';
            case SegmentType.CustomContent:
                return getCustomContentRoute(
                    props.segment.segmentUID,
                    props.licenseType,
                    props.segment.contentType
                );
            case SegmentType.SubscriptionContent:
                return getSetContentRoute(
                    props.segment.subscriptionType ?? SubscriptionType.eBook,
                    props.licenseType
                );
            default:
                return '/';
        }
    };

    const renderViewAllButton = () => {
        switch (props.segment.displayType) {
            case DisplayType.BannerRectangle:
            case DisplayType.BannerSquare: {
                return false;
            }
            case DisplayType.Content: {
                if (props.segment.segmentContents.length > 14) return true;
                else return false;
            }
            case DisplayType.ContentListWithRank:
            case DisplayType.ContentWithRankTitle: {
                return false;
            }
            case DisplayType.ContentCategory: {
                if (props.segment.segmentContents.length > 6) return true;
                else return false;
            }
            default: {
                if (
                    props.segment.segmentContents.length > 7 &&
                    props.segment.segmentType !== SegmentType.PopularContent
                )
                    return true;
                else return false;
            }
        }
    };

    return (
        <div className='homeContainer'>
            <SegmentHeader
                title={props.segment.title}
                description={props.segment.description}
                path={handleViewAll()}
                right={renderViewAllButton()}
            />
            {renderSegment()}
        </div>
    );
};

export default HomeSegment;
