import { ContentType, SubscriptionType, LicenseType } from '../../interface';

export const getListCategoryRoute = (
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    return `/${licenseType.toLowerCase()}/categories/${contentType}/all`;
};

export const getListSubInCategoryRoute = (
    categoryUID: number | string,
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    return `/${licenseType.toLowerCase()}/categories/${categoryUID}/${contentType}/list`;
};

export const getCustomContentRoute = (
    segmentUID: string,
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    return `${licenseType}/contents/set/${segmentUID}/${contentType}`;
};

export const getSetContentRoute = (
    setType: SubscriptionType,
    licenseType: LicenseType = LicenseType.Rent
) => {
    return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}`;
};

export const getSetContentListRoute = (
    setUID: string,
    setType: SubscriptionType,
    licenseType: LicenseType = LicenseType.Rent
) => {
    return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}/${setUID}`;
};

export const getListSubCategoryRoute = (
    categoryUID: number | string,
    subcategoryUID: number | string,
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    return `/${licenseType.toLowerCase()}/categories/${categoryUID}/subcategories/${subcategoryUID}/${contentType}/all`;
};

export const getListSetRoute = (
    setUID: string,
    setType: SubscriptionType,
    licenseType: LicenseType = LicenseType.Rent
) => {
    return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}/${setUID}`;
};

export const getAllContentRoute = (
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    return `/${licenseType.toLowerCase()}/all/${contentType}`;
};

export const getPublisherRoute = (
    publisherUID: string,
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    return `/${licenseType.toLowerCase()}/publisher/${publisherUID}/${contentType}`;
};

export const getDetailRoute = (
    contentUID: string,
    contentType: ContentType = ContentType.ebook,
    licenseType: LicenseType = LicenseType.Rent
) => {
    return `/${licenseType.toLowerCase()}/${contentType}/detail/${contentUID}`;
};

export const getHomeRoute = (
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    return `/${licenseType.toLowerCase()}/home/${contentType}`;
};

export const getSearchRoute = (keyword: string) => {
    return `/search/${keyword?.trim()}`;
};

export const getWriterRoute = (
    writerUID: string,
    creatorUID?: string,
    licenseType: LicenseType = LicenseType.Rent,
    contentType: ContentType = ContentType.ebook
) => {
    if (creatorUID)
        return `/${licenseType.toLowerCase()}/writer/${writerUID}/${contentType}/${creatorUID}`;
    else return `/${licenseType.toLowerCase()}/writer/${writerUID}/${contentType}`;
};

export const convertSetType = (setType: string): SubscriptionType => {
    switch (setType) {
        case 'magazine':
            return SubscriptionType.Magazine;
        case 'newspaper':
            return SubscriptionType.Newspaper;
        default:
            return SubscriptionType.eBook;
    }
};
