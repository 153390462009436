/* eslint-disable @typescript-eslint/ban-types */
import { AnswerType, QuestionType } from '../../../../interface/questionForm';

import QuestionCheckBox from './question-checkbox';
import QuestionInput from './question-input';
import QuestionRadioGroup from './question-radio-group';
import QuestionRating from './question-rating';
import QuestionMostCategory from './question-moast-category';

interface QuestionBoxProps {
   data: QuestionType[];
   onChange: (value: any) => void;
   value: object;
}

const QuestionBox = (props: QuestionBoxProps) => {
   const trailmodal = '';

   console.log(trailmodal);

   const getClassName = (answerType: AnswerType) => {
      switch (answerType) {
         case AnswerType.Radio:
         case AnswerType.CheckBox:
            return 'radio-button-box';
         case AnswerType.Rating:
            return 'rating-box';
         default:
            return 'radio-button-box';
      }
   };

   const renderAnswerComponent = (question: QuestionType) => {
      switch (question.answerType) {
         case AnswerType.Radio:
            return (
               <QuestionRadioGroup
                  data={question}
                  onChange={props.onChange}
                  formikValue={
                     Object.keys(props.value).length > 0
                        ? props.value[question.questionUID as keyof Object]
                        : null
                  }
               />
            );
         case AnswerType.CheckBox:
            return (
               <QuestionCheckBox
                  data={question}
                  onChange={props.onChange}
                  formikValue={
                     Object.keys(props.value).length > 0
                        ? props.value[question.questionUID as keyof Object]
                        : null
                  }
               />
            );
         case AnswerType.Rating:
            return (
               <QuestionRating
                  data={question}
                  onChange={props.onChange}
                  formikValue={
                     Object.keys(props.value).length > 0
                        ? props.value[question.questionUID as keyof Object]
                        : null
                  }
               />
            );
         case AnswerType.Input:
            return (
               <QuestionInput
                  data={question}
                  onChange={props.onChange}
                  formikValue={
                     Object.keys(props.value).length > 0
                        ? props.value[question.questionUID as keyof Object]
                        : null
                  }
               />
            );
         case AnswerType.MostCategoryRent:
            return (
               <QuestionMostCategory
                  data={question}
                  onChange={props.onChange}
                  formikValue={
                     Object.keys(props.value).length > 0
                        ? props.value[question.questionUID as keyof Object]
                        : null
                  }
               />
            );
      }
   };

   return (
      <>
         {props.data.map((el) => (
            <div className={getClassName(el.answerType)} key={el.questionUID}>
               <p className='ask-text'>{el.title}</p>
               {renderAnswerComponent(el)}
            </div>
         ))}
      </>
   );
};

export default QuestionBox;
