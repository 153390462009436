import { Link } from 'react-router-dom';
import { DetailViewParam } from '../../../interface/detailPage';
import { getWriterRoute } from '../../routes/generatePath';
import { useIntl } from 'react-intl';
import { ContentType, LicenseType } from '../../../interface';

export const WriterView = (props: DetailViewParam) => {
    const content = props.content;
    const intl = useIntl();

    const renderWriterName = () => {
        if (
            (content.licenseType === LicenseType.Private && content.authorName === 'ไม่พบข้อมูล') ||
            content.contentType === ContentType.magazine ||
            content.contentType === ContentType.newspaper
        )
            return <span>{content.writerName || content.authorName}</span>;
        else
            return (
                <Link
                    to={{
                        pathname: getWriterRoute(
                            content.writerUID,
                            content.creatorUID,
                            content.licenseType,
                            content.contentType
                        )
                    }}
                >
                    {content.writerName || content.authorName}
                </Link>
            );
    };

    return (
        <>
            {intl.formatMessage({ id: 'Book.Writer' })} : {renderWriterName()}
        </>
    );
};
