import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { useEffect } from 'react';
import { action } from '../../../redux/environment';
import { isNull } from 'lodash';
import { TermsOfServicePage } from '../../../redux/termsOfService/request';
import { isMobile, isTablet } from 'react-device-detect';
import dayjs from 'dayjs';
import './index.scss';

const CookieTab = () => {
    const dispatch = useDispatch();
    const { environment } = useSelector((state: HibraryRootState) => ({
        environment: state.environment
    }));

    useEffect(() => {
        const value = localStorage.getItem('cookie_date');
        console.log(value);

        if (!isNull(value)) {
            const currentAction = value.split('_')[0];
            const currentDate = parseInt(value.split('_')[1]);
            const timeDifferenceInDay = dayjs().diff(dayjs.unix(currentDate), 'day');

            if (
                (currentAction === 'accept' && timeDifferenceInDay < 30) ||
                (currentAction === 'reject' && timeDifferenceInDay < 7)
            ) {
                dispatch(action.hideCookieTab());
            }
        }
    }, []);

    const handleClickButton = (buttonAction: string) => {
        dispatch(action.hideCookieTab());
        localStorage.setItem('cookie_date', `${buttonAction}_${dayjs().unix()}`);
    };

    return (
        <>
            <div
                className='overlayContainer'
                style={
                    (isMobile || isTablet) && environment.cookieTab
                        ? {
                              position: 'fixed',
                              background: 'rgba(0, 0, 0, 0.8)',
                              width: '100%',
                              height: '100%',
                              zIndex: 99
                          }
                        : {}
                }
            />
            <div className={environment.cookieTab ? `cookieTab` : `cookieTab fadeOut`}>
                <div>
                    <p className='title'>ความยินยอมข้อมูลส่วนบุคคลของคุณ (Cookies Policy)</p>
                    <p>
                        <span>เว็บไซต์ Hytexts ใช้คุกกี้ซึ่งทำให้เว็บไซต์ใช้งานง่ายขึ้น </span>
                        <Link
                            to={`/terms-of-service/${TermsOfServicePage.CookiePolicy}`}
                            className='link'
                            target='_blank'
                        >
                            เรียนรู้เกี่ยวกับคุกกี้ของเบราว์เซอร์เพิ่มเติม{' '}
                        </Link>
                    </p>
                </div>
                <div>
                    <Button
                        shape='round'
                        size='large'
                        className='cancelButton'
                        onClick={() => handleClickButton('reject')}
                    >
                        ไม่ยอมรับ
                    </Button>
                    <Button
                        shape='round'
                        size='large'
                        className='acceptButton'
                        onClick={() => handleClickButton('accept')}
                    >
                        ยอมรับ
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CookieTab;
