import { Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReadingStatResponse } from '../../../../interface';
import { Loading } from '../../../customUI/loading';
import { getReadingStat } from '../../../redux/bookshelf/request';
import { action } from '../../../redux/request';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { convertFormatReadingGoal } from '../../../pages/bookshelf';
import { toJpeg } from 'html-to-image';
import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';
import ReadingTimeBox from './reading-time-box';
import ReadingPercentBox from './reading-percent-box';

import '../index.scss';
import ReadTimePerDay from './reading-time-perday';
import ReadingList from './reading-list';
import { EmptyDataView } from '../../nodata';
import { useIntl } from 'react-intl';

interface ModalProps {
    visible: boolean;
    onClose: () => void;
    licenseUID: string;
    readingGoal?: number;
}

const ReadingStatModal = (props: ModalProps) => {
    const intl = useIntl();
    const [readingStat, setReadingStat] = useState<ReadingStatResponse>();
    const statRef = useRef<any>();
    const pageId = `readingStatModal`;
    const dispatch = useDispatch();
    const dateFormat = 'DD:MM:YYYY';

    const { readingGoal, request } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch,
            readingGoal: state.clientInfo.readingGoal
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchReadingStat();
    }, []);

    const fetchReadingStat = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getReadingStat(props.licenseUID);
            if (response.data) {
                setReadingStat(response.data);
                dispatch(action.setProcess(false, pageId));
            }
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
        }
    };

    const exportImage = () => {
        if (statRef.current) {
            statRef.current.style.display = 'block';
            toJpeg(statRef.current).then((dataUrl: any) => {
                const link = document.createElement('a');
                link.download = `${
                    readingStat?.contentDetail.title + `_${dayjs().format(dateFormat)}`
                }.jpeg`;
                link.href = dataUrl;
                link.click();
                statRef.current.style.display = 'none';
            });
        }
    };

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={props.onClose}
                title={null}
                footer={null}
                className='reading-stat-modal'
            >
                <h1 className='title'>{intl.formatMessage({ id: 'ReadingStat.Title' })}</h1>
                {readingStat && (
                    <>
                        <div className='modal-container'>
                            <div className='left-column'>
                                <div className='book-group'>
                                    <img
                                        src={
                                            readingStat?.contentDetail.coverImageWebp ??
                                            readingStat?.contentDetail.coverImage
                                        }
                                        className='cover'
                                    />
                                    <div className='description'>
                                        <div className='title-content'>
                                            {readingStat?.contentDetail.title}
                                        </div>
                                        <div className='author'>
                                            {intl.formatMessage({
                                                id: 'ReadingStat.By'
                                            }) + ' '}
                                            :{' ' + readingStat?.contentDetail.writerName}
                                        </div>
                                        <div className='publisher'>
                                            {intl.formatMessage({
                                                id: 'ReadingStat.Publisher'
                                            }) + ' '}
                                            :{' ' + readingStat?.contentDetail.publisherName}
                                        </div>
                                    </div>
                                </div>

                                <div className='license-detail-group'>
                                    <div className='text-center'>
                                        <div className='label-value'>
                                            {readingStat?.contentDetail.pageCount ?? '-'}
                                        </div>
                                        <label>
                                            {intl.formatMessage({
                                                id: 'ReadingStat.NoOfPages'
                                            })}
                                        </label>
                                    </div>
                                    <div className='text-center'>
                                        <div className='label-value'>
                                            {readingStat?.licenseDetail.startDate
                                                ? dayjs
                                                      .unix(readingStat.licenseDetail.startDate)
                                                      .format('DD/MM/YYYY')
                                                : '-'}
                                        </div>
                                        <label>
                                            {intl.formatMessage({
                                                id: 'ReadingStat.HistoryBorrowedDate'
                                            })}
                                        </label>
                                    </div>
                                    <div className='text-center'>
                                        <div className='label-value'>
                                            {readingStat?.licenseDetail.expireDate
                                                ? dayjs
                                                      .unix(readingStat.licenseDetail.expireDate)
                                                      .format('DD/MM/YYYY')
                                                : '-'}
                                        </div>
                                        <label>
                                            {' '}
                                            {intl.formatMessage({
                                                id: 'ReadingStat.HistoryReturnDate'
                                            })}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='right-column'>
                                <div className='flex read-time-graph-container'>
                                    <ReadingTimeBox
                                        data={readingStat?.readingTimePerDay ?? []}
                                        totalReadingTime={readingStat?.totalTime ?? 0}
                                    />
                                    <ReadingPercentBox
                                        percent={readingStat?.licenseDetail.totalProgression ?? 0}
                                    />
                                </div>

                                <div
                                    className='flex flex-col align-center'
                                    style={{ marginTop: '2rem' }}
                                >
                                    <h4 className='title'>
                                        {intl.formatMessage(
                                            {
                                                id: 'ReadingStat.ReadingGoal'
                                            },
                                            {
                                                0:
                                                    convertFormatReadingGoal(
                                                        (readingGoal ?? 1) / 60
                                                    ) +
                                                    `/${intl.formatMessage({
                                                        id: 'Unit.Day'
                                                    })}`
                                            }
                                        )}
                                    </h4>
                                    <div
                                        className='flex align-start justify-between'
                                        style={{ width: '100%' }}
                                    >
                                        <ReadTimePerDay
                                            data={readingStat?.readingTimePerDay ?? []}
                                        />
                                    </div>
                                </div>

                                {readingStat?.readingTime && (
                                    <div
                                        className='flex flex-col align-center gap-8'
                                        style={{
                                            width: '100%',
                                            marginTop: '2rem'
                                        }}
                                    >
                                        <h4 className='title'>
                                            {intl.formatMessage({
                                                id: 'ReadingStat.History'
                                            })}
                                        </h4>
                                        <ReadingList data={readingStat?.readingTime} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='reading-stat-footer'>
                            <div className='reading-stat-action'>
                                <SVG
                                    src='/images/reading-stat/button-1.svg'
                                    onClick={() => exportImage()}
                                />
                                {/* <SVG src="/images/reading-stat/button-2.svg" /> */}
                            </div>
                        </div>
                    </>
                )}
                {!readingStat && !request.loading && <EmptyDataView />}
                <Loading id={pageId} absolute={true} />
            </Modal>
            <div className='reading-stat-modal stat-download-curtain'>
                <DownloadStat innerRef={statRef} readingStat={readingStat} />
            </div>
        </>
    );
};

export default ReadingStatModal;

interface DownloadStatRef {
    innerRef: any;
    readingStat?: ReadingStatResponse;
}

export const DownloadStat = (props: DownloadStatRef) => {
    const { innerRef } = props;
    const intl = useIntl();
    const imageRef = useRef<any>();
    // const [base64URL, setBase64URL] = useState('');
    const { readingGoal } = useSelector(
        (state: HibraryRootState) => ({
            readingGoal: state.clientInfo.readingGoal
        }),
        shallowEqual
    );

    useEffect(() => {
        props.innerRef.current.style.display = 'none';
    }, [props.readingStat]);

    return (
        <>
            <div
                className='download-stat'
                ref={innerRef}
                style={{
                    backgroundImage: `url(${props.readingStat?.image.bodyBase64})`
                }}
            >
                <div className='download-stat-content-container'>
                    <div className='download-stat-content'>
                        <h1 className='download-stat-title text-center'>สถิติการอ่าน</h1>
                        <h3 className='download-stat-title text-center'>
                            ผู้อ่าน : {props.readingStat?.memberName}
                        </h3>
                        <div className='stat-content'>
                            <div className='left-column'>
                                <img
                                    ref={imageRef}
                                    src={props.readingStat?.contentDetail.coverImageBase64}
                                    className='cover'
                                />
                                <div className='description'>
                                    <div className='title-content limit-two-line'>
                                        {props.readingStat?.contentDetail.title}
                                    </div>
                                    <div className='author'>
                                        {intl.formatMessage({
                                            id: 'ReadingStat.By'
                                        }) + ' '}
                                        :{' ' + props.readingStat?.contentDetail.writerName}
                                    </div>
                                    <div className='publisher'>
                                        {intl.formatMessage({
                                            id: 'ReadingStat.Publisher'
                                        }) + ' '}
                                        :{' ' + props.readingStat?.contentDetail.publisherName}
                                    </div>
                                </div>
                            </div>
                            <div className='modal-container'>
                                <div className='right-column'>
                                    <div className='flex read-time-graph-container'>
                                        <ReadingTimeBox
                                            data={props.readingStat?.readingTimePerDay ?? []}
                                            totalReadingTime={props.readingStat?.totalTime ?? 0}
                                        />
                                        <ReadingPercentBox
                                            percent={
                                                props.readingStat?.licenseDetail.totalProgression ??
                                                0
                                            }
                                        />
                                    </div>
                                    <h4 className='download-stat-title text-center goal-label'>
                                        {}
                                        {convertFormatReadingGoal((readingGoal ?? 1) / 60) +
                                            `/วัน `}
                                    </h4>
                                    <div
                                        className='flex align-start justify-between gap-8'
                                        style={{ marginBottom: '1rem' }}
                                    >
                                        <ReadTimePerDay
                                            data={props.readingStat?.readingTimePerDay ?? []}
                                        />
                                    </div>
                                    <div>
                                        <h3 className='download-app-label text-center'>
                                            {intl.formatMessage({
                                                id: 'ReadingStat.DownloadApplicationAt'
                                            })}
                                        </h3>
                                        <div className='download-app'>
                                            <div>
                                                <img
                                                    src='/images/appstore-footer.png'
                                                    className='app-store'
                                                />
                                                <img
                                                    src='/images/appstoreQRCode.png'
                                                    className='qrcode-download'
                                                />
                                            </div>
                                            <div>
                                                <img
                                                    src='/images/playstore-footer.png'
                                                    className='play-store'
                                                />
                                                <img
                                                    src='/images/playstoreQRCode.png'
                                                    className='qrcode-download'
                                                />
                                            </div>
                                        </div>

                                        <div className='link-download-section'>
                                            <p className='scan-text'>
                                                {intl.formatMessage({
                                                    id: 'ReadingStat.ScanToDownloadApplication'
                                                })}
                                            </p>
                                            <p className='link-text' style={{ fontWeight: 400 }}>
                                                หรือใช้งานห้องสมุดผ่านเว็บไซต์
                                            </p>
                                            <p className='link-text'>
                                                https://elibrary-wwa.hibrary.me
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
