// import { RenderHomeSegment } from '.';
import {
    SegmentMetaData,
    LicenseType,
    PrivateSegmentMetaData,
    ContentType
} from '../../../interface';
import { EmptyDataView } from '../../components/nodata';
import { Loading } from '../../customUI/loading';
import HomeSegment from './homeSegment';
import { PrivateHomeSegment } from './privateHomeSegment';
interface StoreHomeListProps {
    homes?: SegmentMetaData[] | PrivateSegmentMetaData[];
    licenseType: LicenseType;
    contentType: ContentType;
}

const StoreHomeList = (props: StoreHomeListProps) => {
    const pageId = 'StoreHomeList';

    const renderSegment = (
        key: string,
        index: number,
        data: SegmentMetaData | PrivateSegmentMetaData
    ) => {
        switch (props.licenseType) {
            case LicenseType.Private:
                return (
                    <PrivateHomeSegment
                        key={key}
                        segment={data as PrivateSegmentMetaData}
                        indexSegment={index}
                        contentType={props.contentType}
                        licenseType={props.licenseType}
                    />
                );
            default:
                return (
                    <HomeSegment
                        key={key}
                        segment={data as SegmentMetaData}
                        indexSegment={index}
                        licenseType={props.licenseType}
                    />
                );
        }
    };

    return (
        <>
            {props.homes ? (
                props.homes.length > 0 ? (
                    <div className='segment-list'>
                        {props.homes.map((el, index) => {
                            const key = `${props.licenseType}${index}`;
                            return <>{renderSegment(key, index, el)}</>;
                        })}
                    </div>
                ) : (
                    <EmptyDataView />
                )
            ) : null}
            <Loading id={pageId} />
        </>
    );
};

export default StoreHomeList;
