export interface EnvState {
    endOfScreen: boolean;
    scrollToTop: boolean;
    openAppTab: boolean;
    cookieTab: boolean;
}

const initialData: EnvState = {
    endOfScreen: false,
    scrollToTop: false,
    openAppTab: true,
    cookieTab: true
};

export enum EnvActionType {
    SetEndOffScreen = 'Env[SetEndOffScreen]',
    ShowScrollToTop = 'Scroll[SetShowScrollToTop]',
    HideScrollToTop = 'Scroll[SetHideScrollToTop]',
    ShowOpenAppTab = 'App[SetShowOpenAppTab]',
    HideOpenAppTab = 'App[SetHideOpenAppTab]',
    ShowCookieTab = 'Cookie[SetShowCookieTab]',
    HideCookieTab = 'Cookie[SetHideCookieTab]'
}
export interface EnvAction<T = any> {
    payload?: T;
    type: EnvActionType;
}
export const reducer = (state = initialData, action: EnvAction): EnvState => {
    switch (action.type) {
        case EnvActionType.SetEndOffScreen:
            return {
                ...state,
                endOfScreen: action.payload
            };
        case EnvActionType.ShowScrollToTop:
            return {
                ...state,
                scrollToTop: true
            };
        case EnvActionType.HideScrollToTop:
            return {
                ...state,
                scrollToTop: false
            };
        case EnvActionType.ShowOpenAppTab:
            return {
                ...state,
                openAppTab: true
            };
        case EnvActionType.HideOpenAppTab:
            return {
                ...state,
                openAppTab: false
            };
        case EnvActionType.ShowCookieTab:
            return {
                ...state,
                cookieTab: true
            };
        case EnvActionType.HideCookieTab:
            return {
                ...state,
                cookieTab: false
            };
        default:
            return state;
    }
};

const setEndOfScreen = (isEndOfScreen: boolean): EnvAction<boolean> => {
    return {
        type: EnvActionType.SetEndOffScreen,
        payload: isEndOfScreen
    };
};

const showScrollToTop = () => {
    return {
        type: EnvActionType.ShowScrollToTop
    };
};

const hideScrollToTop = () => {
    return {
        type: EnvActionType.HideScrollToTop
    };
};

const showOpenAppTab = () => {
    return {
        type: EnvActionType.ShowOpenAppTab
    };
};

const hideOpenAppTab = () => {
    return {
        type: EnvActionType.HideOpenAppTab
    };
};

const showCookieTab = () => {
    return {
        type: EnvActionType.ShowCookieTab
    };
};

const hideCookieTab = () => {
    return {
        type: EnvActionType.HideCookieTab
    };
};

export const action = {
    setEndOfScreen,
    showScrollToTop,
    hideScrollToTop,
    showOpenAppTab,
    hideOpenAppTab,
    showCookieTab,
    hideCookieTab
};
