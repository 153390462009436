const HibraryTerms = () => {
    return (
        <>
            <div>
                <p>
                    โปรดอ่านทำความเข้าใจตามเงื่อนไขและข้อตกลงก่อนทำการลงทะเบียน
                    หลังจากลงทะเบียนเสร็จสมบูรณ์แล้ว
                    ถือว่าท่านยอมรับเงื่อนไขและข้อตกลงระหว่างผู้ใช้งานกับ บริษัท ไฮเท็คซ์
                    อินเตอร์แอคทีฟ จำกัด หรือ Hibrary
                </p>
                <p>โดยทั้งสองฝ่ายตกลงทำข้อตกลงกัน ซึ่งมีข้อความดังต่อไปนี้</p>
            </div>

            <div>
                <h1>เงื่อนไขและข้อตกลงในการใช้บริการ Hibrary</h1>
                <p>
                    โปรดอ่านทำความเข้าใจตามเงื่อนไขและข้อตกลงก่อนทำการลงทะเบียน
                    หลังจากที่การลงทะเบียนเสร็จสมบูรณ์แล้ว
                    ถือว่าท่านยอมรับเงื่อนไขและข้อตกลงระหว่างสมาชิก กับ บริษัท ไฮเท็คซ์
                    อินเตอร์แอคทีฟ จำกัด ผู้ให้บริการ Hibrary
                </p>
                <p>ทั้งสองฝ่ายตกลงทำข้อตกลงกันดังมีข้อความดังต่อไปนี้</p>
            </div>

            <div>
                <h1>หนังสืออิเล็กทรอนิกส์ (E-BOOK)</h1>
                <ol>
                    <li>
                        หนังสืออิเล็กทรอนิกส์ที่เผยแพร่ใน Hibrary จะถูกเข้ารหัสเพื่อจัดการลิขสิทธ์
                        (Digital Right Management)
                    </li>
                    <li>สมาชิกสามารถลงชื่อใช้งานพร้อมกัน ได้สูงสุด 2 อุปกรณ์ ต่อบัญชี</li>
                    <li>
                        หนังสืออิเล็กทรอนิกส์ และ ตัวอย่างหนังสืออิเล็กทรอนิกส์ ที่ถูกให้บริการใน
                        Hibrary ห้ามทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ จัดจำหน่าย โดยไม่ได้รับอนุญาตจาก
                        Hibrary
                    </li>
                </ol>
            </div>

            <div>
                <h1>บัญชีผู้ใช้งาน</h1>
                <ol>
                    <li>
                        ผู้ที่ต้องการสมัครสมาชิก ต้องกรอกข้อมูลของตนเองที่เป็นจริงให้แก่ Hibrary
                        ให้ครบทุกข้อ
                    </li>
                    <li>
                        ผู้ใดแอบอ้าง หรือกระทำการใดๆ อันเป็นการละเมิดสิทธิ์ส่วนบุคคล
                        โดยใช้ข้อมูลของผู้อื่นมาแอบอ้างสมัครสมาชิกเพื่อให้ได้สิทธิ์การสมาชิก
                        ถือเป็นความผิด ต้องรับโทษตามที่กฎหมายกำหนดไว้
                    </li>
                    <li>
                        เพื่อความเป็นส่วนตัวและความปลอดภัยในข้อมูลของท่านเอง Hibrary
                        ขอแจ้งให้ทราบว่า เป็นหน้าที่ของสมาชิกในการรักษาข้อมูลชื่อ Login และ Password
                        โดยไม่บอกให้ผู้อื่นทราบ
                    </li>
                    <li>
                        สมาชิก สามารถเปลี่ยนแปลงรหัสผ่าน (Password)
                        ด้วยตนเองได้ตลอดระยะเวลาการใช้งาน โดยไม่ต้องแจ้งให้ Hibrary ทราบ
                        ทั้งนี้สมาชิก ตกลงให้รหัสผ่าน (Password) ที่เปลี่ยนแปลงไปในแต่ละขณะดังกล่าว
                        เป็นรหัสผ่านการใช้บริการของ Hibrary ที่มีผลผูกพันสมาชิก
                        ตามข้อกำหนดและเงื่อนไขการใช้บริการนี้ทุกประการ
                    </li>
                    <li>
                        การกระทำการใดๆ โดยผ่านบริการ ไม่ว่ากรณีใดๆก็ตาม
                        หากกระทำโดยใช้รหัสสมาชิกและรหัสผ่านของสมาชิกและ/หรือตรงตามวิธีการหรือเงื่อนไขที่บริษัทกำหนดไว้เกี่ยวกับการใช้บริการ
                        Hibrary แล้ว สมาชิกตกลงให้ถือว่าการกระทำนั้นมีผลสมบูรณ์
                        โดยสมาชิกไม่ต้องลงลายมือชื่อเป็นหลักฐานในเอกสารใดๆ อีก
                        และยินยอมรับผิดชอบในการกระทำดังกล่าวเสมือนหนึ่งว่าสมาชิกเป็นผู้กระทำด้วยตนเอง
                        ทั้งนี้ไม่ว่าจะเกิดขึ้นด้วยความทุจริตของบุคคลภายนอกหรือไม่ก็ตาม
                        สมาชิกจะไม่เรียกร้องให้บริษัทต้องรับผิดชอบแต่อย่างใด
                    </li>
                    <li>
                        รหัสผ่าน (Password)
                        ถือเป็นความลับเฉพาะตัวของสมาชิกที่ต้องเก็บรักษาและปกปิดมิให้ผู้อื่นล่วงรู้
                        สมาชิกต้องเก็บรักษาไว้ด้วยตนเอง หากมีการใช้บริการรายการใดๆโดยใช้รหัสผ่าน
                        (Password) ของสมาชิกแล้ว
                        ให้ถือว่าถูกต้องสมบูรณ์มีผลผูกพันสมาชิกเสมือนสมาชิกใช้บริการในครั้งนั้นๆ
                        ด้วยตนเอง ทั้งนี้ บริษัทไม่สามารถล่วงรู้รหัสผ่าน (Password) ของสมาชิกได้
                        ดังนั้น บริษัทจึงไม่ต้องรับผิดชอบในความเสียหายใดๆที่เกิดขึ้นในการใช้บริการ
                        Hibrary โดยรหัสผ่าน (Password) ของสมาชิก
                    </li>
                    <li>
                        สมาชิกจำเป็นต้องตรวจสอบความถูกต้องในการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์ว่าถูกต้องหรือไม่
                        ก่อนที่จะทำการตกลงชำระเงิน
                        ซึ่งถ้าหากเกิดข้อผิดพลาดจากการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์อันเนื่องมาจากความไม่ถูกต้องของรายการเนื่องมาจากความผิดพลาดของสมาชิกเอง
                        ทาง Hibrary จะไม่รับผิดชอบทั้งสิ้น
                    </li>
                    <li>
                        ถ้า Hibrary
                        พบว่าสมาชิกนำหนังสืออิเล็กทรอนิกส์และตัวอย่างหนังสืออิเล็กทรอนิกส์ของ
                        Hibrary ไปทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ หรือจัดจำหน่าย โดยไม่ได้รับอนุญาตจาก
                        Hibrary ทีมงานมีสิทธิ์ยกเลิกความเป็นสมาชิกที่ใช้บริการ Hibraryได้โดยทันที
                    </li>
                    <li>
                        หนังสืออิเล็กทรอนิกส์ที่สมาชิกสั่งใช้บริการ ห้ามทำซ้ำหรือดัดแปลง
                        ห้ามให้เช่าต้นฉบับหรือสำเนางาน ห้ามเผยแพร่งานต่อสาธารณชน
                        หากผู้ใดกระทำการดังกล่าว ถือเป็นการละเมิดลิขสิทธิ์
                        ต้องรับโทษอาญาและจ่ายค่าเสียหายแก่เจ้าของลิขสิทธิ์และ Hibrary ด้วย
                        หากผู้ใดรู้อยู่แล้วหรือมีเหตุอันควรรู้ว่างานใดได้ทำขึ้นโดยละเมิดลิขสิทธิ์ของผู้อื่น
                        กระทำการต่อไปนี้แก่งานนั้นเพื่อหากำไร
                        ให้ถือว่าผู้นั้นกระทำละเมิดลิขสิทธิ์ด้วย ได้แก่
                        <ol>
                            <li>
                                ขาย มีไว้เพื่อขาย เสนอขาย ให้เช่า เสนอให้เช่า ให้เช่าซื้อ
                                หรือเสนอให้เช่าซื้อ
                            </li>
                            <li>เผยแพร่ต่อสาธารณชน</li>
                            <li>แจกจ่ายในลักษณะที่อาจก่อให้เกิดความเสียหายแก่เจ้าของลิขสิทธิ์</li>
                        </ol>
                    </li>
                    <li>
                        สมาชิกจะต้องไม่โพสต์ข้อความที่มีเนื้อหาดังต่อไปนี้ที่ Hibrary
                        <ol>
                            <li>ไม่โพสต์ข้อความซึ่งพาดพิงหรือลบหลู่ สถาบันพระมหากษัตริย์</li>
                            <li>
                                ไม่โพสต์ข้อมูลหยาบคาบ หมิ่นประมาณ
                                หรือสร้างความเสียหายต่อบุคคลหนึ่งบุคคลใด
                            </li>
                        </ol>
                    </li>
                    <li>
                        Hibrary จะไม่รับผิดชอบต่อความสูญเสียในด้านข้อมูลสมาชิก ข้อมูลการสั่งซื้อ
                        และข้อมูลทุกๆอย่างภายในเว็บไซต์หากมีการสูญหาย
                        จากการถูกจารกรรมความผิดพลาดจากระบบของเครื่องแม่ข่ายที่เก็บข้อมูลหรือความผิดพลาดที่เกิดจากระบบเว็บไซต์ทั้งสิ้น
                    </li>
                    <li>
                        หากพบว่าสมาชิกกระทำการที่ผิดเงื่อนไขและข้อตกลงกับ Hibrary
                        ทีมงานมีสิทธิ์ที่จะยกเลิกความเป็นสมาชิกได้โดยทันที
                    </li>
                </ol>
            </div>

            <div>
                <h1>ข้อกำหนดเนื้อหาของการใช้งานสื่อองค์กร</h1>
                <p>
                    Hibrary ไม่มีหน้าที่ตรวจสอบ เนื้อหา เนื้อเรื่อง ภาพประกอบ ลิขสิทธิ์
                    หรือส่วนหนึ่งส่วนใด ของหนังสืออิเล็กทรอนิกส์ ไฟล์เสียง หรือไฟล์วีดีโอ
                    ที่ผู้ใช้บริการนำส่งข้อมูล
                    ดังนั้นผู้ใช้บริการตกลงว่าจะไม่นำงานวรรณกรรมที่มีเนื้อหา เนื้อเรื่อง
                    ภาพประกอบหรือส่วนหนึ่งส่วนใดดังต่อไปนี้ให้ Hibrary จัดจำหน่าย โฆษณาและเผยแพร่ ใน
                    Hibrary
                </p>
                <ol>
                    <li>
                        งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาท หรือแสดงความอาฆาตมาดร้าย
                        พาดพิงสถาบันพระมหากษัตริย์ รัชทายาทราชวงศ์ หรือผู้สำเร็จราชการแทนพระองค์
                        เป็นอันขาด
                    </li>
                    <li>
                        งานวรรณกรรมที่กล่าวโจมตี หรือวิพากษ์วิจารณ์ในทางเสียหายต่อศาสนา
                        หรือคำสอนของศาสนาใดๆ ทุกศาสนา
                    </li>
                    <li>
                        งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคลที่มีต่อบุคคลอื่นหรือหน่วยงานใด
                    </li>
                    <li>งานวรรณกรรมที่คัดลอกผลงาน หรือบทความบนเว็บไซต์ของบุคคลอื่น</li>
                    <li>งานวรรณกรรมที่มีเนื้อหาละเมิดกฎหมาย หรือสนับสนุนกิจกรรมที่ผิดกฎหมาย</li>
                    <li>
                        งานวรรณกรรมที่มีเนื้อหามีภาพประกอบหรือภาพบุคคลอื่นที่มีลักษณะหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคล
                    </li>
                    <li>
                        งานวรรณกรรมที่มีเนื้อหามีการโฆษณาในวัตถุประสงค์
                        เพื่อใช้ในการโฆษณางานวรรณกรรมเดียวกันที่จัดจำหน่ายในที่อื่นนอกเหนือจาก
                        Hibrary
                    </li>
                    <li>
                        งานวรรณกรรมที่มี ตัวอักษร หน้าปก หรือ
                        ภาพประกอบที่ละเมิดลิขสิทธิ์ของบุคคลอื่น
                    </li>
                </ol>
            </div>

            <div>
                <h1>ขั้นตอนการดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย</h1>
                <p>
                    หากพบว่ามีผลงานที่เข้าข่ายผิดกฎหมาย
                    สำนักงานจะดำเนินการระงับการเผยแพร่และแจ้งให้เจ้าของผลงานทราบ
                    เพื่อดำเนินการแก้ไขต่อไป
                    และหากพบว่าเจ้าของผลงานมีการส่งผลงานที่ผิดกฎหมายเข้ามาซ้ำซาก
                    สำนักงานจะดำเนินการระงับบัญชีผู้ใช้ของเจ้าของผลงานต่อไป
                    ผู้ที่ประสงค์จะให้สำนักงานในฐานะผู้ให้บริการดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย
                    สามารถดำเนินการแจ้งสำนักงานได้พร้อมเอกสารหลักฐานประกอบแล้วส่งมาที่
                </p>
                <p>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด เลขที่ 3</p>
                <ol>
                    <li>อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702 ซอยลาดพร้าว 3</li>
                    <li>ถนนลาดพร้าว แขวงจอมพล เขตจตุจักร กทม 10900 โทร. 02-024-6690</li>
                    <li>Customer Service : 089-134-7470</li>
                    <li>E-mail : contact@hytexts.com</li>
                    <li>ช่วงเวลาทำการ: จันทร์-ศุกร์ 09:00-18:00 น.</li>
                </ol>
                <p>บริษัทจะดำเนินการและตอบกลับภายใน 7 วันทำการ</p>
            </div>

            <div>
                <h1>การจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม</h1>
                <p>
                    บริษัทฯ
                    มีหลักเกณฑ์ในการจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม
                    โดยคำนึงถึง ปัจจัยหลักในการจัดอันดับขายดี คือ ยอดขาย และ
                    ปัจจัยหลักในการจัดระดับหนังสือที่คุณน่าจะสนใจ
                    ขึ้นกับความเกี่ยวข้องของหนังสือที่ผู้ใช้บริการมี
                    ซึ่งแพลตฟอร์มจะแบ่งเนื้อหาออกเป็น 3 ประเภท โดย
                </p>
                <ol style={{ listStyle: 'none', paddingLeft: '0', marginBottom: '0' }}>
                    <li>- ประเภทแรก คือ เนื้อหาทั่วไปที่สามารถเข้าถึงได้บนหน้าเว็บไซต์</li>
                    <li>
                        - ประเภทสอง คือ เนื้อหาที่เหมาะกับผู้ใช้งานที่มีอายุ 18 ปีขึ้นไป
                        โดยจะมีสัญลักษณ์ 18+ หรือ 18 ปีขึ้นไป
                    </li>
                    <li>
                        - ประเภทสาม คือ เนื้อหาที่เหมาะกับผู้ใช้งานบางกลุ่ม
                        ซึ่งจะจำกัดการแสดงผลให้เฉพาะผู้ใช้บางกลุ่มเท่านั้น เช่น หนังสือรุ่น เป็นต้น
                    </li>
                </ol>
                <p>
                    ทั้งนี้ ผลงานใดจะอยู่ในระดับ/ประเภทไหน ขึ้นกับการตรวจสอบและพิจารณาของเจ้าหน้าที่
                    โดยจะพิจารณาจากเนื้อหาของผลงานนั้นๆ
                </p>
            </div>

            <div>
                <h1>มาตรการดูแลผู้ใช้บริการ</h1>
                <p>
                    มาตรการดูแลผู้ใช้บริการซึ่งได้รับความเสียหายจากการใช้บริการเป็นไปตามนโยบายของบริษัทฯ
                </p>
            </div>
        </>
    );
};

export default HibraryTerms;
