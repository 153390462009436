import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { NotificationAction, NotificationActionType } from '.';
import { action } from '../request';
import { getNotification } from './request';
import { Device } from '../../../interface';

export const fetchNotificationData =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(action.setProcess(true));
      const response = await getNotification();
      if (response) {
        dispatch({
          type: NotificationActionType.fetchNotificationData,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch(action.setProcess(false));
    } finally {
      dispatch(action.setProcess(false));
    }
  };

export const notificationAction = {
  setDevicesNotification: (
    devices?: Device[],
    memberUID?: string
  ): NotificationAction => ({
    type: NotificationActionType.setDevicesNotification,
    payload: { deviceErr: { devices: devices ?? [], memberUID } }
  })
};
