const HibraryPolicy = () => {
    return (
        <>
            <div>
                <p>
                    Hibrary
                    คือระบบห้องสมุดหนังสืออิเล็กทรอนิกส์ที่ได้รับสิทธิ์อย่างถูกต้องในการโฆษณา
                    จัดจำหน่ายหนังสืออิเล็กทรอนิกส์(E-book) จากสำนักพิมพ์ที่เข้าร่วม
                </p>
                <p>ทรัพยากรอิเล็กทรอนิกส์ หมายถึง</p>
                <ul>
                    <li>หนังสืออิเล็กทรอนิกส์ (E-book ) เป็นรูปแบบไฟล์ PDF หรือ ePub</li>
                    <li> นิตยสารอิเล็กทริอนิกส์ (E-Magazine ) เป็นรูปแบบไฟล์ PDF หรือ ePub</li>
                    <li>หนังสือพิมพ์อิเล็กทริอนิกส์ (E-Newspaper) เป็นรูปแบบไฟล์ PDF หรือ ePub</li>
                    <li> วีดีโอ อยู่ในรูปแบบไฟล์สตรีมมิ่ง </li>
                    <li> พอดแคสต์ หรือ หนังสือเสียง อยู่ในรูปแบบไฟล์สตรีมมิ่ง</li>
                </ul>
                <p>
                    ทรัพยากรอิเล็กทรอนิกส์ ที่เผยแพร่ใน Hibrary จะเป็นรูปแบบไฟล์ PDF หรือ ePub
                    ซึ่งจะถูกเข้ารหัส เพื่อควบคุมลิขสิทธิ์ (Digital Right Management) ทุกไฟล์
                    สมาชิกสามารถนำ ทรัพยากรอิเล็กทรอนิกส์ ของ Hibrary ไปอ่านในอุปกรณ์ที่รองรับได้
                    เช่น iPhone, iPad, Android, E-Reader และเว็บไชต์
                    เมื่อผู้ใช้บริการได้เข้าหรือออกจาก Hibrary รวมถึงการเข้าชมหน้าต่าง ๆ ของเว็บไซต์
                    Hibrary จะมีการจัดเก็บค่า IP Address รวมถึงชนิดเว็บเบราว์เซอร์ของสมาชิก
                    ทั้งนี้เพื่อนำข้อมูลเหล่านั้นมาวิเคราะห์และนำไปพัฒนาบริการของ Hibrary
                    เพื่อตอบสนองความต้องการของสมาชิกในอนาคต
                </p>
            </div>

            <div>
                <h1>การรวบรวมข้อมูล</h1>
                <ul className='no-padding'>
                    <li>
                        ข้อมูลส่วนตัว และ ข้อมูลเพื่อการติดต่อ ในการสมัครสมาชิกของสมาชิก กับ Hibrary
                        ผู้ใช้บริการจะต้องกรอกข้อมูลชื่อ อีเมล
                        หรือรหัสพนักงานให้ถูกต้องและครบถ้วนเพื่อสิทธิประโยชน์ของผู้ใช้บริการในอนาคต
                    </li>
                    <li>
                        ข้อมูลทางสถิติสมาชิก (demographic) เฉพาะ location-base
                        จะถูกเก็บข้อมูลทุกที่มีการลงชื่อใช้งานผ่านทาง QR-Code
                        เพื่อใช้ในการยืนยันสิทธิ์การใช้งาน ตามที่องค์กรที่เป็นลูกค้าของ Hibrary
                        กำหนด
                    </li>
                    <li>
                        ข้อมูลประวัติการทำธุรกรรม ข้อมูลการยืม-คืน-จองคิว ทรัพยากรอิเล็กทรอนิกส์
                    </li>
                    <li>
                        ข้อมูลทางเทคนิค IP address คุกกี้ ระบบปฏิบัติการและแพลตฟอร์ม
                        ประเภทและเวอร์ชันของเบราว์เซอร์ ชื่ออุปกรณ์
                    </li>
                    <li>
                        ข้อมูลเกี่ยวกับพฤติกรรม เวลาการเข้าใช้งาน เวลาการอ่านทรัพยากรอิเล็กทรอนิกส์
                    </li>
                    <li>
                        ข้อมูลส่วนบุคคลที่สมาชิกได้ให้หรือใช้ผ่านการประมวลผลของเครื่องคอมพิวเตอร์ที่ควบคุมการทำงานของ
                        Hibrary ทั้งหมดนั้นสมาชิกยอมรับและตกลงว่าเป็นสิทธิและกรรมสิทธิ์ของบริษัท
                        Hibrary
                        จะรักษาข้อมูลเหล่านั้นไว้เป็นความลับตามเกณฑ์มาตรฐานสากลสำหรับการรักษาข้อมูลเป็นความลับและจะไม่อนุญาตให้ผู้อื่นเข้าถึงข้อมูลดังกล่าว
                        ได้นอกจากพนักงานผู้ที่ได้รับอนุญาตจาก Hibrary
                        อย่างไรก็ตามเพื่อความปลอดภัยในข้อมูลส่วนบุคคลของสมาชิก
                        สมาชิกควรปฏิบัติตามข้อกำหนดและเงื่อนไขการให้บริการของเว็บไซต์ของบริษัทแต่ละเว็บไซต์โดยเคร่งครัด
                    </li>
                </ul>
            </div>

            <div>
                <h1>วัตถุประสงค์ในการรวบรวมข้อมูล</h1>
                <ul>
                    <li>
                        การลงทะเบียนและการยืนยันตัวตนเพื่อลงทะเบียน ตรวจสอบ ยืนยัน ระบุและ/หรือ
                        รับรองหรือตัวตนของผู้ใช้งาน
                    </li>
                    <li>
                        การทำงานของเว็บไซต์ แอปพลิเคชันมือถือ และแพลตฟอร์มเพื่อดูแล ดำเนินงาน ติดตาม
                        สังเกตการณ์ และจัดการเว็บไซต์และแพลตฟอร์ม
                        เพื่อปรับปรุงให้มีประสิทธิภาพและปลอดภัย และใช้ในการพัฒนาฟีเจอร์ใหม่
                    </li>
                    <li>
                        การพัฒนาการให้บริการและการวัดผลความคุ้มค่าของทรัพยากรการวิเคราะห์ข้อมูลตามความสนใจหรือพฤติกรรมของการใช้งาน
                        และการวิเคราะข้อมูล เพื่อปรับปรุงในการให้บริการ
                        และการจัดซื้อทรัพยากรให้ตรงกับความต้องการของผู้ใช้งานให้ได้มากที่สุด
                    </li>
                </ul>
            </div>

            <div>
                <h1>การเปิดเผยข้อมูลแก่บุคคลที่สาม</h1>
                <ul>
                    <li>
                        Hibrary อาจเปิดเผยข้อมูลส่วนบุคคลเท่าที่จำเป็น การเปิดเผยข้อมูลนั้น ๆ
                        เป็นไปโดยถูกต้องตาม
                    </li>
                    <li>
                        กฎหมายหรือตามคำสั่งของหน่วยงานของรัฐที่เกี่ยวข้อง หรือ บริษัทในเครือ
                        หรือบริษัทในกลุ่ม คู่สัญญาหรือองค์กรที่ใช้บริการ Hibrary
                    </li>
                </ul>
            </div>

            <div>
                <h1>คุกกี้ (COOKIE)</h1>
                <p>
                    คุกกี้คือไฟล์ขนาดเล็กที่มีสตริงอักขระซึ่งส่งไปยังคอมพิวเตอร์ของสมาชิกเมื่อสมาชิกเข้าชมเว็บไซต์
                    เมื่อสมาชิกเข้าชมเว็บไซต์นั้นอีกครั้งคุกกี้จะทำให้ไซต์ดังกล่าวจดจำเบราว์เซอร์ของสมาชิก
                    คุกกี้อาจเก็บการตั้งค่าของผู้ใช้หรือข้อมูลอื่นๆสมาชิก
                    สามารถตั้งค่าเบราว์เซอร์ของสมาชิกใหม่ให้ปฏิเสธคุกกี้ทั้งหมดหรือแสดงให้รู้เมื่อกำลังมีการส่งคุกกี้
                    อย่างไรก็ตามลักษณะหรือบริการบางอย่างของเว็บไซต์อาจทำงานได้ไม่สมบูรณ์เมื่อไม่มีคุกกี้
                </p>
            </div>

            <div>
                <h1>ความรับผิดชอบ</h1>
                <p>
                    Hibrary
                    ขอสงวนสิทธิ์ในการปฏิเสธความรับผิดทั้งหมดในกรณีที่ข้อมูลส่วนบุคคลของสมาชิกเสียหายหรือสูญหายเนื่องจากความผิดพลาดใดๆ
                    ระหว่างส่งข้อมูลระหว่าง server กับผู้ใช้บริการเหตุสุดวิสัยใด ๆ
                    การถูกจารกรรมข้อมูลคอมพิวเตอร์ (Hack) และหรือการกระทำใด ๆ
                    ที่ขัดกฎหมายไม่ว่าการกระทำนั้นจะเกิดจากบุคคลใด ๆ ก็ตาม
                </p>
            </div>

            <div>
                <h1>ลิขสิทธิ์ (COPYRIGHTS)</h1>
                <p>
                    ลิขสิทธิ์ตามกฎหมาย (Copyrights) ข้อมูลทรัพยยากรอิเล็กทรอนิกส์
                    ข้อมูลตัวอย่างทรัพยากรอิเล็กทรอนิกส์, ข้อความ ภาพ เสียงเนื้อหา ส่วนประกอบใด ๆ
                    ทั้งหมดของ Hibrary แต่ไม่จำกัดเฉพาะเครื่องหมายการค้าเครื่องหมายบริการ
                    ชื่อทางการค้าที่ปรากฏบน Hibrary
                    เป็นงานอันได้รับความคุ้มครองตามกฎหมายทรัพย์สินทางปัญญาของไทยโดยชอบด้วยกฎหมายของ
                    Hibrary แต่เพียงผู้เดียว หากบุคคลใดลอกเลียน ปลอมแปลงทำซ้ำ ดัดแปลง
                    เผยแพร่ต่อสาธารณชนจำหน่าย
                    มีไว้ให้เช่าหรือกระทำการใดๆในลักษณะที่เป็นการแสวงหาผลประโยชน์ทางการค้าหรือประโยชน์โดยมิชอบไม่ว่าโดยประการใดๆ
                    จากทรัพย์สินทางปัญญาดังกล่าวข้างต้นโดยไม่ได้รับอนุญาตจาก Hibrary
                    จะดำเนินการตามกฎหมายกับผู้ละเมิดสิทธิ์ดังกล่าวโดยทันที
                </p>
            </div>

            <div>
                <h1>สิทธิ์ของเจ้าของข้อมูลส่วนบุคคล</h1>
                <p>
                    ภายใต้บทบัญญัติแห่งกฎหมายและข้อยกเว้นตามกฎหมายที่เกี่ยวข้อง
                    ผู้ใช้งานอาจมีสิทธิ์ตามที่ระบุไว้
                </p>
                <ul>
                    <li>
                        การเข้าถึง:
                        ผู้ใช้งานอาจมีสิทธิ์ในการขอเข้าถึงหรือขอรับสำเนาข้อมูลส่วนบุคคลที่เราเก็บรวบรวม
                        ใช้หรือเปิดเผยเกี่ยวกับผู้ใช้งาน ทั้งนี้
                        เพื่อความเป็นส่วนตัวและความปลอดภัยของผู้ใช้งาน
                        เราอาจขอให้ผู้ใช้งานพิสูจน์ตัวตนของผู้ใช้งานก่อนจะให้ข้อมูลตามที่ผู้ใช้งานขอ
                    </li>

                    <li>
                        การแก้ไขให้ถูกต้อง:
                        ผู้ใช้งานอาจมีสิทธิ์ขอให้มีการดำเนินการแก้ไขข้อมูลส่วนบุคคลที่เราได้เก็บรวบรวม
                        ใช้หรือเปิดเผยเกี่ยวกับผู้ใช้งาน ให้ ถูกต้อง เป็นปัจจุบัน สมบูรณ์
                        และไม่ก่อให้เกิดความเข้าใจผิด
                    </li>

                    <li>
                        การคัดค้าน: ผู้ใช้งานอาจมีสิทธิ์คัดค้านการเก็บรวบรวม
                        ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน เช่น การคัดค้านการตลาดแบบตรง
                    </li>

                    <li>
                        การขอให้ระงับการใช้:
                        ผู้ใช้งานอาจมีสิทธิ์ขอให้ระงับการใช้ข้อมูลส่วนบุคคลของผู้ใช้งานในบางกรณี
                    </li>

                    <li>
                        การถอนความยินยอม:
                        สำหรับวัตถุประสงค์ที่ผู้ใช้งานได้ให้ความยินยอมให้เราเก็บรวบรวม
                        ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน
                        ผู้ใช้งานมีสิทธิ์ที่จะถอนความยินยอมของผู้ใช้งาน ณ เวลาใด ก็ได้
                    </li>

                    <li>
                        การลบหรือทำลายข้อมูล: ผู้ใช้งานอาจมีสิทธิ์ขอให้เราดำเนินการลบหรือทำลาย
                        หรือทำให้ข้อมูลส่วนบุคคลของผู้ใช้งานที่เราเก็บรวบรวม ใช้หรือเปิดเผย
                        เป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูล เว้นเสียแต่ว่า
                        การเก็บรักษาข้อมูลดังกล่าวของเรานั้นเป็นไปเพื่อการปฏิบัติตามกฎหมาย
                        หรือเพื่อก่อตั้งสิทธิ์เรียกร้องตามกฎหมาย เพื่อการใช้
                        หรือการปกป้องสิทธิ์เรียกร้องตามกฎหมาย
                    </li>

                    <li>
                        การยื่นเรื่องร้องเรียน:
                        ผู้ใช้งานอาจมีสิทธิ์ยื่นเรื่องร้องเรียนไปยังหน่วยงานที่มีอำนาจในกรณีที่ผู้ใช้งานเชื่อว่าการเก็บรวบรวม
                        ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของเรานั้นไม่ชอบด้วยกฎหมายหรือไม่สอดคล้องกับกฎหมายคุ้มครองข้อมูลที่บังคับใช้
                    </li>
                </ul>
            </div>

            <div>
                <h1>ระยะเวลาในการที่เราเก็บข้อมูลส่วนบุคคล</h1>
                <p>
                    เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผล
                    เพื่อให้บรรลุตามวัตถุประสงค์ที่เราได้รับข้อมูลส่วนบุคคลนั้นมา
                    และเพื่อปฏิบัติตามภาระหน้าที่ทางกฎหมายและกฎข้อบังคับต่างๆ อย่างไรก็ตาม
                    เราอาจเก็บข้อมูลส่วนบุคคลของท่านนานขึ้นหากจำเป็นตามกฎหมาย ที่ใช้บังคับ
                </p>
            </div>

            <div>
                <h1>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h1>
                <p>
                    Hibrary สามารถเปลี่ยนแปลงนโยบายความเป็นส่วนตัวได้ตลอดเวลา
                    เมื่อมีการเปลี่ยนแปลงหรือเพิ่มเติมบริการหรือเมื่อเราได้รับข้อเสนอแนะจากสมาชิก
                    การเปลี่ยนแปลงใด ๆ ในนโยบายจะมีการแจ้งถึงสมาชิกผ่านทางไปรษณีย์อิเล็กทรอนิกส์
                </p>
            </div>

            <div>
                <h1>รายละเอียดการติดต่อเรา</h1>
                <p style={{ marginBottom: '1rem' }}>
                    รายละเอียดการติดต่อเรา
                    หากผู้ใช้งานมีความประสงค์ที่จะใช้สิทธิ์ของผู้ใช้งานที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของผู้ใช้งาน
                    หรือหากผู้ใช้งานมีข้อสงสัยเกี่ยวกับข้อมูลส่วนบุคคลของผู้ใช้งานภายใต้นโยบายความเป็นส่วนตัวฉบับนี้
                    โปรดติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเรา ที่
                </p>
                <p>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด</p>
                <p>
                    เลขที่ 3 อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702 ซอยลาดพร้าว 3
                    ถนนลาดพร้าว
                </p>
                <p style={{ marginBottom: '1rem' }}>แขวงจอมพล เขตจตุจักร กทม 10900</p>
                <p>โทร. 02-024-6690 ทุกวัน จันทร์-ศุกร์ 9.30-17.30 น.</p>
                <p>E-mail: contact@hytexts.com</p>
            </div>
        </>
    );
};

export default HibraryPolicy;
