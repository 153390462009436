import { SetStateAction, useEffect, useState } from 'react';
import {
    AuthPageType,
    AxiosError,
    ClientInfo,
    OtpPassword,
    OtpRegister,
    RegisterType
} from '../../../../../interface';
import { verifyRegister } from '../../../../redux/auth/authCrud';
import { Button, Checkbox, Input, Space } from 'antd';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { VerifyOTPAction } from '../../../../redux/verifyOTP';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { checkOTPExpire } from '../../../../Utilities/validateText';
import PrivacyPolicyModal from '../../privacyPolicyModal/PrivacyPolicyModal';
import TermsOfUseModal from '../../termsOfUseModal/TermsOfUseModal';
import * as Yup from 'yup';
import '../index.scss';

interface RegisterProps {
    location?: GeolocationCoordinates;
    clientInfo: ClientInfo;
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
    setOTPData: React.Dispatch<SetStateAction<OtpPassword | OtpRegister | undefined>>;
}

interface RegisterPayload {
    userID?: string;
}

const initialValues = {
    userID: ''
};

const Register = (props: RegisterProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
    const regexPhoneNumber = /^(?:\+|\d)[0-9]+$/g;
    const [checkPrivacy, setCheckPrivacy] = useState<boolean>(false);
    const [privacyPolicyModal, setPrivacyModal] = useState<boolean>(false);
    const [checkTermOfUse, setCheckTermOfuse] = useState<boolean>(false);
    const [termsOfUseModal, setTermsOfUseModal] = useState<boolean>(false);

    const [status, setStatus] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [registerType, setRegisterType] = useState<RegisterType>(RegisterType.Email);

    const { verifyOTP } = useSelector(
        (state: HibraryRootState) => ({
            verifyOTP: state.verifyOTP
        }),
        shallowEqual
    );

    const schema = Yup.object().shape({
        userID: Yup.string()
            .trim()
            .matches(
                registerType === RegisterType.Email ? regexEmail : regexPhoneNumber,
                intl.formatMessage({ id: 'Register.PleaseEnterValidEmailOrTel' })
            )
            .required(
                registerType === RegisterType.Email
                    ? intl.formatMessage({ id: 'Register.PleaseEnterEmail' })
                    : intl.formatMessage({ id: 'Alert.EnterPhoneNo' })
            )
    });

    const formik = useFormik<RegisterPayload>({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: (value) => {
            onRegisterMember(value);
        }
    });

    useEffect(() => {
        formik.setTouched({}, false);
        if (formik.values.userID) formik.setFieldValue('userID', '');
        if (verifyOTP.registerOTP && registerType === RegisterType.PhoneNumber) {
            if (checkOTPExpire(verifyOTP.registerOTP.expireDate!)) {
                dispatch(VerifyOTPAction.resetRegisterOTP());
            } else {
                props.setOTPData(verifyOTP.registerOTP);
                props.setPageType(AuthPageType.RegisterOTP);
            }
        }
    }, [registerType]);

    useEffect(() => {
        if (status) setStatus(undefined);
    }, [formik.values]);

    const onRegisterMember = async (value: RegisterPayload) => {
        try {
            setLoading(true);
            const response = await verifyRegister(
                props.clientInfo.companyPrefix,
                value.userID,
                props.clientInfo.geoLocation && props.location
                    ? {
                          latitude: props.location.latitude,
                          longitude: props.location.longitude
                      }
                    : undefined
            );

            if (response) {
                props.setOTPData(response);
                props.setPageType(AuthPageType.RegisterOTP);
                if (registerType === RegisterType.PhoneNumber)
                    dispatch(VerifyOTPAction.initRegisterOTP(response));
            }
        } catch (error) {
            const getError = error as AxiosError;
            setStatus(getError.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleRegisterType = () => {
        switch (registerType) {
            case RegisterType.Email:
                setRegisterType(RegisterType.PhoneNumber);
                break;
            case RegisterType.PhoneNumber:
                setRegisterType(RegisterType.Email);
                break;
        }
    };

    const checkTerms = () => {
        if (checkPrivacy && checkTermOfUse) return false;
        else return true;
    };

    return (
        <div className='registerContainer'>
            <h1>{intl.formatMessage({ id: 'Register.Title' })}</h1>
            <div className='clientCard'>
                <div className='coverImage'>
                    <img src={props.clientInfo?.logoImages} />
                </div>
                <div>
                    <p>{props.clientInfo?.displayName}</p>
                    <p>{props.clientInfo?.clientName}</p>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className='inputItem'>
                    <Input
                        className='formInput'
                        value={formik.values.userID}
                        placeholder={
                            registerType === RegisterType.Email
                                ? intl.formatMessage({
                                      id: 'Register.PleaseEnterEmail'
                                  })
                                : intl.formatMessage({
                                      id: 'Register.PhonePlaceholder'
                                  })
                        }
                        onChange={(e) => formik.setFieldValue('userID', e.target.value)}
                    />
                    <div>
                        {formik.errors.userID && formik.touched.userID ? (
                            <p className='error-text'>{formik.errors.userID}</p>
                        ) : null}
                        {props.clientInfo?.registerByPhoneNumber && (
                            <p onClick={() => handleRegisterType()} className='registerType'>
                                {registerType === RegisterType.Email
                                    ? intl.formatMessage({
                                          id: 'Register.PhoneBtn'
                                      })
                                    : intl.formatMessage({
                                          id: 'RegisterOther.EmailBtn'
                                      })}
                            </p>
                        )}
                    </div>
                    <Space direction='vertical' size={8} className='check-box-container'>
                        <Checkbox
                            checked={checkPrivacy}
                            onClick={() => {
                                if (checkPrivacy) {
                                    setCheckPrivacy(false);
                                } else {
                                    setPrivacyModal(true);
                                }
                            }}
                        >
                            ฉันได้อ่านและยอมรับ นโยบายความเป็นส่วนตัว
                        </Checkbox>
                        <Checkbox
                            checked={checkTermOfUse}
                            onClick={() => {
                                if (checkTermOfUse) {
                                    setCheckTermOfuse(false);
                                } else {
                                    setTermsOfUseModal(true);
                                }
                            }}
                        >
                            ฉันได้อ่านและยอมรับ เงื่อนไขการใช้บริการ
                        </Checkbox>
                    </Space>
                </div>
                <div
                    className='errorField'
                    style={status ? {} : { background: 'var(--modal-background)' }}
                >
                    {status && <p>{status}</p>}
                </div>

                <div className='buttonContain'>
                    {props.clientInfo?.registerDomain &&
                    props.clientInfo?.registerDomain.length > 0 &&
                    registerType === RegisterType.Email ? (
                        <div className='domainText'>
                            {intl.formatMessage(
                                { id: 'Register.Domain' },
                                {
                                    0: ` ${props.clientInfo.registerDomain?.join(', ')}`
                                }
                            )}
                        </div>
                    ) : null}
                    <Button
                        className='acceptButton'
                        htmlType='submit'
                        loading={loading}
                        disabled={checkTerms()}
                    >
                        {loading
                            ? intl.formatMessage({ id: 'Action.JustAMoment' })
                            : intl.formatMessage({ id: 'Register.RegisterBtn' })}
                    </Button>
                    <span className='back' onClick={() => props.setPageType(AuthPageType.Login)}>
                        {intl.formatMessage({ id: 'Action.Back' })}
                    </span>
                </div>
            </form>
            <PrivacyPolicyModal
                visible={privacyPolicyModal}
                handleCheckPrivacy={() => setCheckPrivacy(true)}
                handleVisible={(visible) => setPrivacyModal(visible)}
            />
            <TermsOfUseModal
                visible={termsOfUseModal}
                handleCheckTermsOfUse={() => setCheckTermOfuse(true)}
                handleVisible={(visible) => setTermsOfUseModal(visible)}
            />
        </div>
    );
};

export default Register;
