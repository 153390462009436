import { Modal, ModalProps } from 'antd';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import {
  AuthPageType,
  LoginResponse,
  OtpPassword,
  OtpRegister
} from '../../../../../interface';
import VerifyOTP from '../VerifyOTP';
import Success from '../Success';
import ChangeEmail from './ChangeEmail';
import './changeEmailModal.scss';

interface ChangeEmailModalProps {
  visible: boolean;
  onClose: () => void;
}

const ChangeEmailModal = (props: ChangeEmailModalProps) => {
  const [pageType, setPageType] = useState<AuthPageType>(
    AuthPageType.ChangeEmail
  );
  const [otpData, setOTPData] = useState<OtpPassword | OtpRegister>();
  const [userTokenRegister, setUserTokenRegister] = useState<LoginResponse>();

  const { clientInfo } = useSelector(
    (state: HibraryRootState) => ({
      clientInfo: state.clientInfo,
      verifyOTP: state.verifyOTP
    }),
    shallowEqual
  );

  const modalProps: ModalProps = {
    open: props.visible,
    onCancel: props.onClose,
    className: 'changeEmailModal',
    footer: null,
    width: 450,
    afterClose: () => setPageType(AuthPageType.ChangeEmail)
  };

  const renderPage = () => {
    switch (pageType) {
      case AuthPageType.ChangeEmail:
        return (
          <ChangeEmail
            clientInfo={clientInfo}
            setPageType={setPageType}
            setOTPData={setOTPData}
          />
        );
      case AuthPageType.ChangeEmailOTP:
        return (
          <VerifyOTP
            pageType={pageType}
            otp={otpData!}
            setPageType={setPageType}
            setOTPData={setOTPData}
            setUserTokenRegistered={setUserTokenRegister}
            onBack={() => setPageType(AuthPageType.ChangeEmail)}
            onClose={props.onClose}
          />
        );
      case AuthPageType.SuccessChangeEmail:
        return (
          <Success
            clientInfo={clientInfo}
            userTokenRegistered={userTokenRegister}
            onCloseModal={props.onClose}
          />
        );
    }
  };

  return (
    <Modal {...modalProps}>
      <img src={clientInfo.logoImages ?? 'images/login-sticker.svg'} alt="" />
      {renderPage()}
    </Modal>
  );
};
export default ChangeEmailModal;
