export enum StoreStatus {
    Trial = 'trial',
    Activate = 'activate'
}

export interface ClientInfo {
    clientName: string;
    logoImages: string;
    companyPrefix: string;
    emailDomainRegister: boolean;
    isPrivateLibrary: boolean;
    isPublicRegister: boolean;
    registerDomain?: string[];
    contactList?: ContactList;
    theme?: Themes;
    displayName: string;
    isPublicLibrary?: boolean; //! อาจจะไม่ได้ใช้
    storeType?: string;
    storeStatus?: StoreStatus;
    showClientLogo?: boolean;
    isAnonymous?: boolean;
    geoLocation?: limitArea[];
    requireEmployeeNumber?: boolean;
    registerByPhoneNumber?: boolean;
    registerable?: ClientRegisterable;
    clientExpireDate?: number;
    sso?: boolean;
    userSurveyUrl?: string;
    customTitle?: CustomTitleType;
    strictPdpa: boolean;
}

export interface limitArea {
    distance: number;
    latitude: number;
    longitude: number;
    name: string;
}

export interface ClientLocation {
    latitude: number;
    longitude: number;
}

export interface Themes {
    Primary: string;
    PrimaryVariant: string;
    Secondary: string;
    SecondaryVariant: string;
    Background: string;
    Surface: string;
    Surface2?: string;
    Error: string;
    OnPrimary: string;
    OnSecondary: string;
    OnBackground: string;
    OnSurface: string;
    OnError: string;
    Title: string;
    Header: string;
    SubHeader?: string;
    Body: string;
    grey1?: string;
    grey2?: string;
    grey3?: string;
    grey4?: string;
    grey5?: string;
    grey6?: string;
    grey7?: string;
    PrimaryDark: string;
    OnPrimaryDark: string;
    htiPrimaryLight: string;
    htiPrimaryDark: string;
    htiPrimaryVariant: string;
    htiSecondary: string;
    htiSecondaryVariant: string;
    modalBackground: string;
}

export interface ContactList {
    website?: string;
    address?: string;
    phoneNumber: string;
    mobilePhone: string;
    LineID?: string;
    companyName: string;
    Twitter?: string;
    Instagram?: string;
    Facebook?: string;
    email: string;
    YouTube?: string;
    Tiktok?: string;
}

// export interface newThemes {
//    Nav: string;
//    Surfaces: string;
//    OnError: string;
//    OnBackground: string;
//    OnPrimaryVariants: string;
//    OnPrimary: string;
//    Primary: string;
//    PrimaryVariants: string;
//    OnSurfaces: string;
//    Disable: string;
//    OnDisable: string;
//    Secondary: string;
//    Background: string;
//    OnNav: string;
//    Error: string;
//    OnSecondary: string;
//    GREY7: string;
//    GREY8: string;
//    GREY5: string;
//    GREY6: string;
//    GREY3: string;
//    GREY4: string;
//    GREY1: string;
//    GREY2: string;
// }

export interface Registerable {
    clients: ClientRegisterable[];
}

export interface ClientRegisterable {
    clientUID: string;
    clientName: string;
    displayName: string;
    companyPrefix: string;
    registerDomain: string[];
    logoImages: string;
    isPublic: boolean;
    registerByPhoneNumber: boolean;
    emailDomainRegister: boolean;
    requireEmployeeNumber: boolean;
}

export interface CustomTitleType {
    line1: string;
    line2?: string;
    line3?: string;
}
