import { Button, Modal, ModalProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { AddMemberStore, AuthPageType, PublicRegisterClient } from '../../../../../interface';
import { useIntl } from 'react-intl';
import AddLibrary from './AddLibrary';
import SelectLibrary from './SelectLibrary';
import AddLibraryForm from './AddLibraryForm';
import SuccessAddLibrary from './SuccessAddLibrary';
import './addLibraryModal.scss';

interface AddLibraryModalProps {
    visible: boolean;
    onClose: () => void;
}

const AddNewLibraryModal = (props: AddLibraryModalProps) => {
    const intl = useIntl();
    const [pageType, setPageType] = useState<AuthPageType>(AuthPageType.SelectLibrary);
    const [currentClient, setCurrentClient] = useState<PublicRegisterClient>();
    const [userToken, setUserToken] = useState<AddMemberStore>();

    const modalWidth = (): number => {
        switch (pageType) {
            case AuthPageType.AddNewLibrary:
            case AuthPageType.SelectLibrary:
                return 600;
            case AuthPageType.Success:
                return 300;
            default:
                return 450;
        }
    };

    const modalProps: ModalProps = {
        open: props.visible,
        onCancel: props.onClose,
        width: modalWidth(),
        footer: null,
        afterClose: () => setPageType(AuthPageType.SelectLibrary),
        className: 'addNewLibraryModal',
        destroyOnClose: true,
        closable: pageType === AuthPageType.Success ? false : true
    };

    const renderPage = () => {
        switch (pageType) {
            case AuthPageType.SelectLibrary:
                return <SelectLibrary />;
            case AuthPageType.AddNewLibraryForm:
                return (
                    <AddLibraryForm
                        setPageType={setPageType}
                        clientInfo={currentClient!}
                        setUserToken={setUserToken}
                    />
                );
            case AuthPageType.Success:
                return <SuccessAddLibrary currentClient={currentClient} userToken={userToken} />;
            case AuthPageType.AddNewLibrary:
                return (
                    <AddLibrary
                        setPageType={setPageType}
                        setUserToken={setUserToken}
                        setCurrentClient={setCurrentClient}
                    />
                );
            default:
                return (
                    <AddLibrary
                        setPageType={setPageType}
                        setUserToken={setUserToken}
                        setCurrentClient={setCurrentClient}
                    />
                );
        }
    };

    return (
        <Modal {...modalProps}>
            {renderPage()}
            {pageType === AuthPageType.SelectLibrary && (
                <div className='selectLibraryButton'>
                    <Button
                        type='dashed'
                        shape='round'
                        ghost
                        className='dash-radius-button'
                        onClick={() => setPageType(AuthPageType.AddNewLibrary)}
                    >
                        <PlusOutlined />
                        {intl.formatMessage({ id: 'AddLibrary.AddBtn' })}
                    </Button>
                </div>
            )}
        </Modal>
    );
};

export default AddNewLibraryModal;
