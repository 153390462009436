import axios from 'axios';
import { HtiResponse, ListDevicesResponse } from '../../../interface/request';

export const getDeviceList = async () => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListDevicesResponse>>('/member/devices');
   return data;
};

export const removeDeviceList = async (deviceToken: string, memberUID?: string) => {
   if (memberUID) {
      await axios.delete<HtiResponse<ListDevicesResponse>>('/signin/devices', {
         data: { deviceToken, memberUID }
      });
   } else {
      await axios.delete<HtiResponse<ListDevicesResponse>>('/member/devices', {
         data: { deviceToken }
      });
   }
};
