import { persistReducer } from 'redux-persist';
import { ClientInfo, StoreStatus } from '../../../interface/client';

import dayjs from 'dayjs';
import * as theme from '../theme';
import storage from 'redux-persist/lib/storage';

export interface ClientInfoState extends ClientInfo {
    expireDate: number;
    readingGoal?: number;
}

const initialState: ClientInfoState = {
    clientName: '',
    logoImages: '',
    companyPrefix: '',
    emailDomainRegister: false,
    isPrivateLibrary: false,
    isPublicRegister: true,
    registerDomain: [],
    contactList: undefined,
    theme: theme.normalTheme,
    displayName: '',
    storeStatus: StoreStatus.Trial,
    expireDate: dayjs().add(15, 'm').unix(),
    readingGoal: undefined,
    sso: false,
    strictPdpa: false
};

export enum ClientInfoActionType {
    SetClient = 'SetClient[Client]',
    SetClientForRegister = 'SetClientForRegister[Client]',
    SetReadingGoal = 'SetReadingGoal'
}

export interface ClientInfoAction {
    type: ClientInfoActionType;
    payload: ClientInfo | number;
}

export const reducer = persistReducer(
    {
        storage,
        key: 'hibrary-clientInfo'
    },
    (state: ClientInfoState = initialState, action: ClientInfoAction): ClientInfoState => {
        switch (action.type) {
            case ClientInfoActionType.SetClient:
                return {
                    ...(action.payload as ClientInfo),
                    expireDate: dayjs().add(30, 'm').unix()
                };
            case ClientInfoActionType.SetClientForRegister:
                return { ...state };
            case ClientInfoActionType.SetReadingGoal:
                return { ...state, readingGoal: action.payload as number };
            default:
                return state;
        }
    }
);
