import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as auth from '../../redux/auth/authRedux';
import { logout } from '../../redux/auth/authCrud';
import { showSplash, removeSplash } from '../../helpers/removeSplash';
import { HibraryRootState } from '../../../redux/rootReducer';
import { action } from '../../redux/home';
import { Loading } from '../../customUI/loading';
import * as request from '../../redux/request';
import jwtDecode from 'jwt-decode';
import { TatAccessToken } from '../../../interface';

const Logout = () => {
  const pageId = 'logout';
  const dispatch = useDispatch();
  const history = useHistory();
  const { authState, clientInfo } = useSelector(
    (state: HibraryRootState) => ({
      authState: state.auth,
      clientInfo: state.clientInfo
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(showSplash());
    console.log(pageId);
  }, []);

  const getTidFromHeader = async () => {
    const response = await fetch(window.location.href);
    const authHeader = response.headers.get('access-token');
    if (authHeader !== null && authHeader !== undefined) {
      const { tid } = jwtDecode(authHeader) as TatAccessToken;
      return tid;
    } else {
      return undefined;
    }
  };

  const singOutRedirect = async () => {
    const tid = await getTidFromHeader();

    if (clientInfo.companyPrefix === 'tat' && tid) {
      switch (process.env.REACT_APP_ENV) {
        case 'production':
        case 'staging': {
          window.location.href = `https://auth.tat.or.th/oauth2/sign_out?rd=https://login.windows.net/${tid}/oauth2/logout?post_logout_redirect_uri=${encodeURI(
            'https://elibrary-tat.hibrary.me?closeTab=true'
          )}`;
          break;
        }
        case 'development': {
          window.location.href = `https://time-auth.tat.or.th/oauth2/sign_out?rd=https://login.windows.net/${tid}/oauth2/logout?post_logout_redirect_uri=${encodeURI(
            'https://dev-elibrary-tat.hibrary.me?close-tab=true'
          )}`;
          break;
        }
        default:
          history.push(`/`);
          break;
      }
      return;
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    dispatch(request.action.setProcess(true, pageId));
    console.log('htk', authState.authToken !== undefined);

    if (authState.authToken !== undefined) {
      logout()
        .then(() => {
          dispatch(request.action.setProcess(false, pageId));
          removeSplash(dispatch);
          dispatch(auth.actions.logout());
          dispatch(action.resetData());
          singOutRedirect();
        })
        .catch(() => {
          dispatch(request.action.setProcess(false, pageId));
          dispatch(auth.actions.logout());
          dispatch(action.resetData());
          //  history.push(`/`);
          singOutRedirect();
        });
    } else {
      history.push(`/`);
    }
  }, [dispatch, history]);

  return (
    <div>
      <Loading id={'LogOut'} />
    </div>
  );
};
export default Logout;
