import { Button, Result } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { removeSplash } from '../../helpers/removeSplash';
import './index.scss';

const NotFoundPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(removeSplash);
    }, []);

    return (
        <div className='not-found-page'>
            <Result status='500' title='009' subTitle='Sorry, not found your organization.' />
            <Button onClick={() => history.push('/')} size='large' shape='round'>
                กลับหน้าแรก
            </Button>
        </div>
    );
};

export default NotFoundPage;
