import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Col } from 'antd';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbLink, BreadCrumbView } from '../../../components/breadcrumb';
import { SubscriptionType, LicenseType, SubscriptionResponse } from '../../../../interface/home';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { ContentSetView } from '../../../components/store';
import { removeSplash } from '../../../helpers/removeSplash';
import { getListSets, getListContentSets } from '../../../redux/store/storeCrud';
import { Loading } from '../../../customUI/loading';
import { action } from '../../../redux/request';
import { ContentCollectionView } from '../../../components/contentCover';
import { useIntl } from 'react-intl';
import { ListAllContentResponse } from '../../../../interface';
import { EmptyDataView } from '../../../components/nodata';
import './index.scss';

interface SetsPageProps {
    setType: SubscriptionType;
    licenseType: LicenseType;
}

export const SubscriptionListPage = (props: SetsPageProps) => {
    const pageId = 'SubscriptionListPage';
    const setType = props.setType;
    const setTypeName = getSetTypeName(setType);
    const dispatch = useDispatch();
    const [listSets, setListSet] = useState<SubscriptionResponse>();
    const { clientInfo, authen } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchListSets();
    }, []);

    const fetchListSets = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getListSets(authen.isLogin, clientInfo.companyPrefix, setType);
            if (response) {
                setListSet(response);
            }
        } finally {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    return (
        <div className='categories'>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}
                    | {setTypeName}
                </title>
            </Helmet>
            <BreadCrumbView />
            <>
                <h1>{setTypeName}</h1>
                <div className='content-list list-row-categories'>
                    {listSets?.subscriptions.map((el, index) => (
                        <ContentSetView
                            key={index}
                            set={el}
                            subscriptionName={setTypeName}
                            licenseType={LicenseType.Rent}
                        />
                    ))}
                </div>
            </>
            <Loading id={pageId} />
        </div>
    );
};

export const getSetTypeName = (type: SubscriptionType) => {
    const intl = useIntl();
    switch (type) {
        case SubscriptionType.Magazine:
            return intl.formatMessage({ id: 'ContentType.Magazine' });
        case SubscriptionType.Newspaper:
            return intl.formatMessage({ id: 'ContentType.Newspaper' });
        case SubscriptionType.eBook:
            return intl.formatMessage({ id: 'ContentType.Ebook' });
        default:
            return intl.formatMessage({ id: 'ContentType.Magazine' });
    }
};

interface SetsContentListPageProps {
    subscriptionType: SubscriptionType;
    subscriptionUID: string;
    licenseType: LicenseType;
}

export const SetsContentListPage = (props: SetsContentListPageProps) => {
    const pageId = 'SetsContentListPage';
    const dispatch = useDispatch();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
    const [title, setTitle] = useState<string>('');
    const [contentSet, setContentSet] = useState<ListAllContentResponse>();
    const { clientInfo, authen, environment, request } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchListContent(false);
    }, []);

    useEffect(() => {
        if (environment.endOfScreen && !request.loading) {
            if (contentSet?.lastEvaluatedKey !== undefined) {
                fetchListContent(true);
            }
        }
    }, [environment]);

    const fetchListContent = async (appendData: boolean) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = contentSet?.lastEvaluatedKey ?? undefined;
            const data = await getListContentSets(
                authen.isLogin,
                clientInfo.companyPrefix,
                props.subscriptionUID,
                lastEvaluatedKey as string
            );

            if (data) {
                if (appendData && contentSet) {
                    setListModel(data);
                } else {
                    setTitle((data?.title as string) ?? '');
                    setContentSet(data);
                    setBreadCrumbs([{ title: (data?.title as string) ?? '' }]);
                }
            }

            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const breadcrumbsMemo = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    const setListModel = (data: ListAllContentResponse) => {
        const current = contentSet as ListAllContentResponse;
        const currentData = data as ListAllContentResponse;
        if (contentSet) {
            setContentSet({
                ...contentSet,
                contents: current.contents.concat(currentData?.contents ?? []),
                lastEvaluatedKey: currentData?.lastEvaluatedKey
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}{' '}
                    | {title ?? 'Subscription'}
                </title>
            </Helmet>
            {breadcrumbsMemo}
            <div className='store view-all-content'>
                <>
                    <h1 className='viewAllTitle'>{title ?? ''}</h1>
                    {contentSet && contentSet.contents.length > 0 ? (
                        <div className='bookLayout collection' style={{ marginBottom: '2rem' }}>
                            {contentSet?.contents.map((el, index) => {
                                return (
                                    <Col key={`set_content_${index}`} className='col-ebook'>
                                        <ContentCollectionView
                                            content={el}
                                            licenseType={LicenseType.Rent}
                                        />
                                    </Col>
                                );
                            })}
                        </div>
                    ) : (
                        !request.loading && <EmptyDataView />
                    )}
                </>
                <Loading id={pageId} />
            </div>
        </>
    );
};
