import dayjs from 'dayjs';
import { useState, memo, useEffect } from 'react';
import { Device } from '../../../interface/device';
import { getDeviceList, removeDeviceList } from '../../redux/device';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Divider, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ComponentLoading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { EmptyDataView } from '../../components/nodata';
import { HibraryRootState } from '../../../redux/rootReducer';
import { useIntl } from 'react-intl';

import SVG from 'react-inlinesvg';
import trash from './trash.svg';
import './index.scss';

type RemoveDeviceCb = (index: number, deviceName: string) => void;

interface ManageDeviceProps {
    devices?: Device[];
    memberUID?: string;
}

export const ManageDevice: React.FC<ManageDeviceProps> = ({ devices, memberUID }) => {
    const pageId = 'ManageDevice';
    const intl = useIntl();
    const dispatch = useDispatch();
    const [deviceList, setDeviceList] = useState<Device[] | undefined>(devices);
    const [deleteDeviceUiD, setDeleteDeviceUiD] = useState<string>();

    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch
        }),
        shallowEqual
    );

    const fetchData = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getDeviceList();
            if (response) {
                response.devices = response.devices.sort((a, b) => b.regisDate - a.regisDate);
                setDeviceList(response.devices);
            }
        } finally {
            dispatch(action.setProcess(false, pageId));
        }
    };

    const removeDevice = async (listIndex: number) => {
        const getDevice = deviceList![listIndex];
        setDeleteDeviceUiD(getDevice?.deviceuid);
        try {
            await removeDeviceList(getDevice!.devicetoken, memberUID);
            const newDeviceList = deviceList!.filter((el, index) => listIndex !== index);
            setDeviceList(newDeviceList);
            setDeleteDeviceUiD(undefined);
        } catch (error) {
            setDeleteDeviceUiD(undefined);
        }
    };
    const onRemoveDevice = (index: number, deviceName: string) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'ManageDevice.ModalDeleteTitle' }),
            content: intl.formatMessage(
                { id: 'ManageDevice.ModalDeleteSubTitle' },
                { 0: deviceName }
            ),
            okText: intl.formatMessage({ id: 'ManageDevice.ModalAgreeBtn' }),
            cancelText: intl.formatMessage({ id: 'ManageDevice.ModalCancelBtn' }),
            onOk: () => removeDevice(index)
        });
    };
    useEffect(() => {
        if (deviceList === undefined) {
            fetchData();
        }
    }, []);

    return (
        <div className='device-list'>
            {deviceList && deviceList.length > 0 ? (
                <>
                    {deviceList.map((el, index) => (
                        <MemoDeviceItem
                            key={index}
                            device={el}
                            onRemoveDevice={(index, name) => onRemoveDevice(index, name)}
                            index={index}
                            isProcessing={el.deviceuid === deleteDeviceUiD ? true : false}
                        />
                    ))}
                </>
            ) : (
                <> {!request.loading && <EmptyDataView />}</>
            )}

            <ComponentLoading id={pageId} />
        </div>
    );
};

interface DeviceItemProps {
    onRemoveDevice: RemoveDeviceCb;
    device: Device;
    index: number;
    isProcessing: boolean;
}

const DeviceItem = (props: DeviceItemProps) => {
    const intl = useIntl();
    return (
        <>
            <div className='device-item'>
                <div className='device-information'>
                    <h3>{`${props.device.devicemodel}  ${props.device.osversion}`}</h3>
                    <p>
                        {props.device.isCurrentDevice
                            ? intl.formatMessage({ id: 'ManageDevice.ThisDevice' })
                            : `${intl.formatMessage({
                                  id: 'ManageDevice.LastSignIn'
                              })} : ${dayjs
                                  .unix(props.device.regisDate)
                                  .format('DD/MM/YYYY HH:mm')}`}
                    </p>
                </div>

                {props.device.isCurrentDevice ? null : (
                    <>
                        {!props.isProcessing ? (
                            <SVG
                                src={trash}
                                width={24}
                                height={24}
                                onClick={() =>
                                    props.onRemoveDevice(
                                        props.index,
                                        `${props.device.devicename}  ${props.device.osversion}`
                                    )
                                }
                            />
                        ) : (
                            <LoadingOutlined style={{ fontSize: 24 }} />
                        )}
                    </>
                )}
            </div>
            <Divider className='divider' />
        </>
    );
};
export const MemoDeviceItem = memo(DeviceItem);
