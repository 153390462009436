import { useFormik } from 'formik';
import { SetStateAction, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { Button, Input } from 'antd';
import { useIntl } from 'react-intl';
import { AddMemberClientStore } from '../../../../redux/auth/authCrud';
import {
    AddMemberStore,
    AddMemberStorePayload,
    AuthPageType,
    PublicRegisterClient
} from '../../../../../interface';

interface AddLibraryFormProps {
    clientInfo: PublicRegisterClient;
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
    setUserToken: React.Dispatch<SetStateAction<AddMemberStore | undefined>>;
}

interface AddLibraryFormPayload {
    name?: string;
    surname?: string;
    userID?: string;
    staffID?: string;
}

const AddLibraryForm = (props: AddLibraryFormProps) => {
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);

    const { authState } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth
        }),
        shallowEqual
    );

    const schema = Yup.object().shape({
        staffID: Yup.string()
            .max(50, 'ไม่เกิน 50 ตัวอักษร')
            .required(intl.formatMessage({ id: 'Register.PleaseEnterEmployeeID' }))
    });

    const formik = useFormik<AddLibraryFormPayload>({
        initialValues: {
            name: authState.userInfo?.name,
            surname: authState.userInfo?.surname,
            userID: authState.userInfo?.email ?? authState.userInfo?.phoneNumber
        },
        validationSchema: schema,
        onSubmit: (values) => {
            onAddClientStore(values);
        }
    });

    const onAddClientStore = async (values: AddLibraryFormPayload) => {
        try {
            setLoading(true);
            const payload: AddMemberStorePayload = {
                staffID: values.staffID,
                clientPrefix: props.clientInfo.companyPrefix,
                memberUID: authState.userInfo?.uid
            };
            const response = await AddMemberClientStore(payload);
            if (response) {
                props.setUserToken(response);
                props.setPageType(AuthPageType.Success);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='addLibraryFormContainer'>
            <div className='coverImage'>
                <img src={'images/login-sticker.svg'} alt='' />
            </div>
            <h1>เพิ่มห้องสมุด</h1>
            <form onSubmit={formik.handleSubmit}>
                <div className='formContainer'>
                    <Input className='formInput' value={formik.values.userID} disabled />
                    <Input className='formInput' value={formik.values.name} disabled />
                    <Input className='formInput' value={formik.values.surname} disabled />
                    <div className='inputItem'>
                        <Input
                            className='formInput'
                            placeholder={intl.formatMessage({ id: 'Register.IDPlaceholder' })}
                            value={formik.values.staffID}
                            onChange={(e) => formik.setFieldValue('staffID', e.target.value)}
                        />
                        {formik.touched.staffID && formik.errors.staffID && (
                            <div className='error-text'>{formik.errors.staffID}</div>
                        )}
                    </div>
                </div>

                <div className='buttonContain'>
                    <Button className='acceptButton' loading={loading} htmlType='submit'>
                        {loading
                            ? intl.formatMessage({ id: 'Action.JustAMoment' })
                            : intl.formatMessage({
                                  id: 'Action.ConfirmBtn'
                              })}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddLibraryForm;
