import { message } from 'antd';
import { osVersion, osName, mobileModel, browserName } from 'react-device-detect';
import { HtiErrorResponse } from '../interface';
import { showErrorModal } from '../app/components/modal/errorModal';
import { createBrowserHistory } from 'history';
import { publicIpv4 } from 'public-ip';
import { getDomainName } from '../app/Utilities/validateText';
import { notificationAction } from '../app/redux/notification/actions';
import store from '../redux/store';
import J from '../../package.json';
import axios from 'axios';

let requestMiddleware: any;
let responseMiddleware: any;
// export const ip = publicIp.v4();
export const ip = publicIpv4();
const history = createBrowserHistory();
// const regExCu = new RegExp(`${process.env.REACT_APP_API_CU_DOMAIN}`, 'i');
// const isCuDomain = window.location.hostname.match(regExCu);

axios.defaults.timeout = 120000;
export const setupAxiosByToken = (
    authToken?: string,
    BASE_URL: string = process.env.REACT_APP_API_END_POINT!
) => {
    // setup axios
    axios.defaults.timeout = 120000;
    const requestInterceptors = (config: any) => {
        if (config.url && !config.url.match(/^(https|http)/)) {
            config.url = BASE_URL + config.url;
        }
        const headers = setUpHeader();
        config.headers = headers;
        if (authToken && authToken !== '') {
            config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
    };
    const responseInterceptors = (response: any) => {
        return response;
    };
    if (requestMiddleware !== undefined) {
        axios.interceptors.request.eject(requestMiddleware);
    }
    requestMiddleware = axios.interceptors.request.use(requestInterceptors, (err) => {
        return Promise.reject(err);
    });
    if (responseMiddleware !== undefined) {
        axios.interceptors.response.eject(responseMiddleware);
    }
    responseMiddleware = axios.interceptors.response.use(responseInterceptors, (err) => {
        if (err && err.response && err.response.data) {
            if (err.response.config) {
                errorHandler(err.response.data);
            }
        }
        return Promise.reject(err);
    });
};

export const setupAxios = (store: any, BASE_URL: string = process.env.REACT_APP_API_END_POINT!) => {
    const { auth } = store.getState();

    const userToken = auth && auth.authToken ? auth.authToken : undefined;
    setupAxiosByToken(userToken, BASE_URL);
};

const setUpHeader = () => {
    const domain = getDomainName(window.location.hostname);
    const regexCUDomain = new RegExp(`${process.env.REACT_APP_HIBRARY_CU_ELIBRARY_ENDPOINT}`, 'i');

    let appname: string = process.env.REACT_APP_HIBRARY_APP_NAME!;
    let appID: string = process.env.REACT_APP_HIBRARY_APP_ID!;
    if (domain === process.env.REACT_APP_HIBRARY_CU_ELIBRARY_ENDPOINT) {
        appname = process.env.REACT_APP_CU_ELIBRARY_APP_NAME!;
        appID = process.env.REACT_APP_CU_ELIBRARY_APP_ID!;
    }
    const deviceuidSuffix = window.location.hostname.match(regexCUDomain) ? 'cu.' : 'hibrary.';
    return {
        appversion: J.version,
        deviceuid: deviceuidSuffix + localStorage.getItem('deviceuid'),
        devicemodel: mobileModel === 'none' ? browserName : mobileModel,
        devicename: osName,
        osversion: osVersion,
        appname: appname,
        appid: appID,
        os: osName,
        'x-accept-version': process.env.REACT_APP_API_VERSION
    };
};

const errorHandler = (error: HtiErrorResponse) => {
    if (!error.status) {
        switch (error.errorCode) {
            // force logout
            case '012':
            case '015':
            case '907':
            case '909': {
                if (!['/logout'].includes(history.location.pathname)) {
                    showErrorModal(error, () => {
                        history.push('/logout');
                        window.location.reload();
                    });
                }
                break;
            }
            // don't show message
            case '009':
            case '004': {
                //getError.response.data.data?.devices
                store.dispatch(
                    notificationAction.setDevicesNotification(
                        error.data?.devices ?? [],
                        error.data?.memberUID
                    )
                );
                break;
            }
            case '063':
            case '905':
            case '911':
            case '912':
            case '915':
            case '926':
            case '6004':
            case '929':
            case '8001': {
                break;
            }
            case undefined: {
                message.error('พบความผิดพลาดของระบบ');
                break;
            }
            default: {
                if (!['/bookshelf', '/profile'].includes(history.location.pathname)) {
                    message.error(error.message);
                }

                break;
            }
        }
    }
};
