import { SetStateAction, useState } from 'react';
import {
    AuthPageType,
    AxiosError,
    ClientInfo,
    OtpPassword,
    OtpRegister
} from '../../../../../interface';
import { requestChangeEmail } from '../../../../redux/auth/authCrud';
import { Button, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { validationDomain } from '../../../../Utilities/validateText';
import * as Yup from 'yup';
import '../index.scss';

interface ChangeEmailProps {
    clientInfo: ClientInfo;
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
    setOTPData: React.Dispatch<SetStateAction<OtpPassword | OtpRegister | undefined>>;
}

interface ChangeEmailPayload {
    userID?: string;
}

const initialValues = {
    userID: ''
};

const ChangeEmail = (props: ChangeEmailProps) => {
    const intl = useIntl();
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
    const [status, setStatus] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const schema = Yup.object().shape({
        userID: Yup.string()
            .matches(regexEmail, intl.formatMessage({ id: 'Register.PleaseEnterValidEmailOrTel' }))
            .required()
    });

    const formik = useFormik<ChangeEmailPayload>({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: () => showConfirm()
    });

    const ChangeEmail = async () => {
        if (
            validationDomain(formik.values.userID ?? '', props.clientInfo.registerDomain ?? []) ||
            props.clientInfo.emailDomainRegister
        )
            try {
                setLoading(true);
                const response = await requestChangeEmail(formik.values.userID ?? '');
                if (response) {
                    props.setOTPData(response);
                    props.setPageType(AuthPageType.ChangeEmailOTP);
                }
            } catch (error) {
                const getError = error as AxiosError;
                setStatus(getError.response.data.message);
            } finally {
                setLoading(false);
            }
        else {
            setStatus(
                `${intl.formatMessage(
                    { id: 'Register.Domain' },
                    {
                        0: ` ${props.clientInfo?.registerDomain?.join(', ')}`
                    }
                )}`
            );
        }
    };

    const showConfirm = () => {
        Modal.confirm({
            title:
                intl.formatMessage({
                    id: 'Profile.ChangeEmail'
                }) + '?',
            content: (
                <p
                    dangerouslySetInnerHTML={{
                        __html:
                            intl
                                .formatMessage(
                                    { id: 'RegisterModal.Description' },
                                    {
                                        0: formik.values.userID
                                    }
                                )
                                .replace(/\n/g, '<br/>') + '?'
                    }}
                />
            ),
            okText: intl.formatMessage({ id: 'RegisterModal.AgreeBtn' }),
            cancelText: intl.formatMessage({ id: 'RegisterModal.CancelBtn' }),
            onOk: () => ChangeEmail()
        });
    };

    return (
        <div className='registerContainer'>
            <h1>{intl.formatMessage({ id: 'Profile.ChangeEmail' })}</h1>
            <form onSubmit={formik.handleSubmit}>
                <div className='inputItem'>
                    <Input
                        className='formInput'
                        value={formik.values.userID}
                        placeholder={intl.formatMessage({
                            id: 'ChangeEmail.Placeholder'
                        })}
                        onChange={(e) => formik.setFieldValue('userID', e.target.value)}
                    />
                    <div>
                        {formik.errors.userID && formik.touched.userID ? (
                            <p className='error-text'>{formik.errors.userID}</p>
                        ) : null}
                    </div>
                </div>
                {props.clientInfo?.emailDomainRegister &&
                props.clientInfo.registerDomain &&
                props.clientInfo.registerDomain.length > 0 ? (
                    <div
                        className='info'
                        style={{
                            marginTop: '0.25rem',
                            padding: '0 1rem',
                            color: 'var(--hti-primary-light)'
                        }}
                    >
                        {intl.formatMessage(
                            { id: 'Register.Domain' },
                            {
                                0: ` ${props.clientInfo?.registerDomain?.join(', ')}`
                            }
                        )}
                    </div>
                ) : null}

                <div
                    className='errorField'
                    style={status ? {} : { background: 'var(--modal-background)' }}
                >
                    {status && <p>{status}</p>}
                </div>
                <div className='buttonContain'>
                    <Button className='acceptButton' htmlType='submit' loading={loading}>
                        {loading
                            ? intl.formatMessage({ id: 'Action.JustAMoment' })
                            : intl.formatMessage({ id: 'Action.ConfirmBtn' })}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ChangeEmail;
