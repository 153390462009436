// import { ebooksSlice } from './storeSlice';
import { ContentType, HomeResponse, LicenseType } from '../../../interface/home';
import { hideSplash } from '../../helpers/removeSplash';
import * as requestFromServer from './storeCrud';
import * as homeRedux from '../home';
import * as request from '../request';

// const { actions } = ebooksSlice;

export const fetchStoreHome =
    (
        id: string,
        isLogin: boolean,
        clientPrefix: string,
        licenseType: LicenseType,
        contentType?: ContentType
    ) =>
    async (dispatch: (arg0: homeRedux.HomeAction | any) => void) => {
        dispatch(request.action.setProcess(true, id));
        try {
            let home: HomeResponse | undefined;
            switch (licenseType) {
                case LicenseType.Rent:
                    if (isLogin) {
                        home = await requestFromServer.getStoreHome();
                    } else {
                        home = await requestFromServer.getPublicHome(clientPrefix);
                    }
                    if (!contentType || contentType === ContentType.content) {
                        dispatch(homeRedux.action.setHomeHibrary(home?.home ?? []));
                    } else {
                        dispatch(homeRedux.action.setHomeContent(home?.home ?? []));
                    }
                    break;
                case LicenseType.Free:
                    home = await requestFromServer.getFreeHome(clientPrefix, isLogin);
                    if (!contentType || contentType === ContentType.content) {
                        dispatch(homeRedux.action.setHomeFree(home?.home ?? []));
                    } else {
                        dispatch(homeRedux.action.setHomeContent(home?.home ?? []));
                    }
                    break;
                case LicenseType.Private:
                    home = await requestFromServer.getPrivateHome(
                        clientPrefix,
                        contentType,
                        !isLogin
                    );
                    if (!contentType || contentType === ContentType.content) {
                        dispatch(homeRedux.action.setHomePrivate(home?.home ?? []));
                    } else {
                        dispatch(homeRedux.action.setHomeContent(home?.home ?? []));
                    }
                    break;
                default:
                    break;
            }

            dispatch(homeRedux.action.setBanner(home?.banners ?? []));
            dispatch(hideSplash());
            dispatch(request.action.setProcess(false, id));
        } catch (error) {
            dispatch(hideSplash());
            dispatch(request.action.setProcess(false, id));
        }
    };
