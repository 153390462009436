import axios from 'axios';
import { HtiResponse } from '../../../interface';
import { NotificationResponse } from '../../../interface/notification';

export const getNotification = async () => {
   try {
      const { data } = await axios.get<HtiResponse<NotificationResponse>>(`/menu/notification`);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};
