import { useMemo } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { BookshelfPage } from '../bookshelf';
import { QueuePage } from '../queueNhistory/QueuePage';
import { HistoryPage } from '../queueNhistory/HistoryPage';
import { ProfilePage } from '../auth/ProfilePage';
import { ListEbook } from '../listEbook';
import { SubscriptionListPage, SetsContentListPage } from '../set/setEbook';
import { DetailPage } from '../store/DetailPage';
import { v4 as uuidv4 } from 'uuid';
import { SearchResult } from '../search';
import { convertSetType } from '../../routes/generatePath';
import { HibraryRootState } from '../../../redux/rootReducer';
import { ViewAllContent } from '../content/viewAllContent';
import { ContentType, getLicenseType, LicenseType } from '../../../interface/home';
import { isMobile, isTablet } from 'react-device-detect';
import { getDynamicLink } from '../../Utilities/dynamicLink';
import { Platform } from '../../../interface/dynamicLink';
import { TermsOfServicePage } from '../../redux/termsOfService/request';
import Logout from '../auth/Logout';
import QuestionnaireForm from '../questionnaire/questionnaire';
import LogoutSSO from '../auth/LogoutSSO';
import NotFoundPage from './NotFoundPage';
import ViewAllSponsor from '../viewAllSponsor/ViewAllSponsor';
import SponsorSetEbook from '../set/sponsorSetEbook';
import ViewAllSponsorBanner from '../viewAllSponsorBanner';
import VoteEbook from '../voteEbook';
import CatalogContents from '../voteEbook/catalogContents';
import ScrollToTop from '../../helpers/scrollToTop';
import RentableContents from '../content/rentableContent';
import OpenAppTab from '../../components/popup/openAppPopup/OpenAppPopup';
import QrCode from '../qrCode';
import TermsOfService from '../termsOfService';
import CategoryContentPage from '../content/categoryContent';
import CategoryPage from '../content/categoryPage';
import CustomContent from '../content/customContent';
import { StoreHomePage } from '../home';
import PrivateCategoryContentPage from '../content/privateCategoryContentPage';

const BasePage = () => {
    const location = useLocation();
    const { authState, clientInfo, environment } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            clientInfo: state.clientInfo,
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    const scrollToTop = (
        <img
            src='/images/goToTop.png'
            alt=''
            className={
                environment.scrollToTop && !environment.cookieTab
                    ? 'gotoTop isShow'
                    : 'gotoTop isClose'
            }
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        />
    );

    const storeHomePageMemoPrivate = useMemo(
        () => <StoreHomePage licenseType={LicenseType.Private} key={uuidv4()} />,
        [authState, clientInfo]
    );

    const storeHomePageMemoRent = useMemo(
        () => (
            <StoreHomePage
                licenseType={LicenseType.Rent}
                key={uuidv4()}
                contentType={ContentType.content}
            />
        ),
        [authState, clientInfo]
    );

    const storeHomePageMemoFree = useMemo(
        () => (
            <StoreHomePage
                licenseType={LicenseType.Free}
                key={uuidv4()}
                contentType={ContentType.content}
            />
        ),
        [authState, clientInfo]
    );

    const OpenAppTabMemo = useMemo(() => {
        const url = getDynamicLink(
            window.location.href,
            authState.isCuDomain ? Platform.CuElibrary : Platform.Hibrary
        );
        if ((isMobile || isTablet) && !environment.cookieTab) return <OpenAppTab url={url} />;
    }, [location.pathname, environment.cookieTab, authState]);

    return (
        <div className='base-page'>
            {OpenAppTabMemo}
            <ScrollToTop>
                {scrollToTop}
                <Switch>
                    <Route path='/question-form'>
                        <QuestionnaireForm />
                    </Route>
                    <Route exact path='/viewAllSponsor' render={() => <ViewAllSponsor />} />
                    <Route
                        path='/terms-of-service/:page'
                        exact
                        render={(props) => (
                            <TermsOfService
                                currentPage={props.match.params.page as TermsOfServicePage}
                            />
                        )}
                    />
                    <Route path='/' exact render={() => storeHomePageMemoRent} />
                    <Route path='/free' exact render={() => storeHomePageMemoFree} />
                    <Route path='/private' exact render={() => storeHomePageMemoPrivate} />
                    <Route path='/listEbook' exact render={() => <ListEbook />} />
                    <Route
                        path='/listEbook/:type'
                        exact
                        render={(props) => <ListEbook type={props.match.params.type} />}
                    />
                    <Route
                        path='/listEbook/:type/:id'
                        exact
                        render={(props) => (
                            <ListEbook type={props.match.params.type} id={props.match.params.id} />
                        )}
                    />
                    <Route
                        path='/listEbook/:type/:id/:subId'
                        exact
                        render={(props) => (
                            <ListEbook
                                type={props.match.params.type}
                                id={props.match.params.id}
                                subId={props.match.params.subId}
                            />
                        )}
                    />
                    <Route
                        path='/:contentType/all/rentableContent'
                        exact
                        render={(props) => (
                            <RentableContents
                                contentType={props.match.params.contentType as ContentType}
                            />
                        )}
                    />
                    <Route
                        path='/search/:searchValue'
                        render={(props) => (
                            <SearchResult keyword={props.match.params.searchValue} />
                        )}
                    />
                    <Route
                        path='/:license/categories/:content/all'
                        render={(props) => (
                            <CategoryPage
                                contentType={props.match.params.content as ContentType}
                                licenseType={getLicenseType(props.match.params.license)}
                            />
                        )}
                    />
                    <Route
                        path='/:license/categories/:categoryUID/:content/list'
                        render={(props) => {
                            if (
                                getLicenseType(props.match.params.license) === LicenseType.Private
                            ) {
                                return (
                                    <PrivateCategoryContentPage
                                        categoryUID={props.match.params.categoryUID}
                                        contentType={props.match.params.content as ContentType}
                                        licenseType={getLicenseType(props.match.params.license)}
                                    />
                                );
                            } else {
                                return (
                                    <CategoryContentPage
                                        categoryUID={props.match.params.categoryUID}
                                        contentType={props.match.params.content as ContentType}
                                        licenseType={getLicenseType(props.match.params.license)}
                                    />
                                );
                            }
                        }}
                    />
                    <Route
                        path='/:licenseType/:contentType/detail/:contentUID'
                        render={(props) => (
                            <DetailPage
                                {...props}
                                contentUID={props.match.params.contentUID}
                                licenseType={getLicenseType(props.match.params.licenseType)}
                            />
                        )}
                    />
                    <Route
                        path='/:licenseType/detail/:contentUID'
                        render={(props) => (
                            <DetailPage
                                {...props}
                                contentUID={props.match.params.contentUID}
                                licenseType={getLicenseType(props.match.params.licenseType)}
                            />
                        )}
                    />
                    <Route
                        exact
                        path='/:licenseType/categories/:categoryUID/subcategories/:subcategoryUID/:content/all'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.content as ContentType}
                                licenseType={getLicenseType(props.match.params.licenseType)}
                                categoryUID={props.match.params.categoryUID}
                                subcategoryUID={props.match.params.subcategoryUID}
                            />
                        )}
                    />
                    <Route
                        exact
                        path='/:license/publisher/:publisherUID/:contentType'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                licenseType={getLicenseType(props.match.params.license)}
                                publisherUID={props.match.params.publisherUID}
                            />
                        )}
                    />
                    //? writer private
                    <Route
                        exact
                        path='/:license/writer/:writerUID/:contentType/:creatorUID'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                licenseType={getLicenseType(props.match.params.license)}
                                writerUID={props.match.params.writerUID}
                                creatorUID={props.match.params.creatorUID}
                            />
                        )}
                    />
                    //? writer rent and free2
                    <Route
                        exact
                        path='/:license/writer/:writerUID/:contentType'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                licenseType={getLicenseType(props.match.params.license ?? '')}
                                writerUID={props.match.params.writerUID}
                            />
                        )}
                    />
                    <Route
                        path='/:license/keyword/:keyword/:content'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.content as ContentType}
                                licenseType={getLicenseType(props.match.params.license)}
                                // keyword={props.match.params.keyword}
                            />
                        )}
                    />
                    <Route
                        path='/rent/all/:contentType'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                licenseType={LicenseType.Rent}
                            />
                        )}
                    />
                    <Route
                        path='/free/all/:contentType'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                licenseType={LicenseType.Free}
                            />
                        )}
                    />
                    <Route
                        path='/private/all/:contentType'
                        render={(props) => (
                            <ViewAllContent
                                contentType={props.match.params.contentType as ContentType}
                                licenseType={LicenseType.Private}
                            />
                        )}
                    />
                    <Route
                        path='/:license/home/:contentType'
                        exact
                        render={(props) => (
                            <StoreHomePage
                                licenseType={getLicenseType(props.match.params.license)}
                                contentType={props.match.params.contentType as ContentType}
                            />
                        )}
                    />
                    <Route
                        exact
                        path='/:licenseType/sets/:subscriptionType/:subscriptionUID'
                        render={(props) => (
                            <SetsContentListPage
                                licenseType={getLicenseType(props.match.params.licenseType)}
                                subscriptionType={convertSetType(
                                    props.match.params.subscriptionType
                                )}
                                subscriptionUID={props.match.params.subscriptionUID}
                            />
                        )}
                    />
                    <Route
                        exact
                        path='/:licenseType/contents/set/:segmentUID/:contentType'
                        render={(props) => (
                            <CustomContent
                                licenseType={getLicenseType(props.match.params.licenseType)}
                                contentType={props.match.params.contentType as ContentType}
                                segmentUID={props.match.params.segmentUID}
                            />
                        )}
                    />
                    <Route
                        exact
                        path='/sponsor/sets/:setUID'
                        render={(props) => <SponsorSetEbook setUID={props.match.params.setUID} />}
                    />
                    <Route
                        exact
                        path='/viewAllSponsorBanner'
                        render={() => <ViewAllSponsorBanner />}
                    />
                    <Route
                        exact
                        path='/:license/sets/:setType'
                        render={(props) => (
                            <SubscriptionListPage
                                licenseType={getLicenseType(props.match.params.license)}
                                setType={convertSetType(props.match.params.setType)}
                            />
                        )}
                    />
                    <Route path='/queue' exact render={() => <QueuePage />} />
                    <Route path='/history' exact render={() => <HistoryPage />} />
                    <Route path='/profile' exact render={() => <ProfilePage />} />
                    <Route path='/voteEbook' exact render={() => <VoteEbook />} />
                    <Route
                        path='/voteEbook/:catalogUID'
                        exact
                        render={(props) => (
                            <CatalogContents catalogUID={props.match.params.catalogUID} />
                        )}
                    />
                    <Route path='/bookshelf' exact render={() => <BookshelfPage />} />
                    <Route path='/qrCode/:qrCodeUID' exact render={() => <QrCode />} />
                    <Route path='/logout' exact render={() => <Logout />} />
                    <Route path='/logout-sso' exact render={() => <LogoutSSO />} />
                    <Route path='/error/not-found' exact render={() => <NotFoundPage />} />
                    <Route
                        path='/error/not-found-organization'
                        exact
                        render={() => <NotFoundPage />}
                    />
                    <Redirect to='/error/not-found' />
                </Switch>
            </ScrollToTop>
        </div>
    );
};
export default BasePage;

//https://login.microsoftonline.com/organizations/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fwww.hibrary.me%2Fredirect%3Fredirect%3Dhttps%3A%2F%2Fdev-elibrary.tat.or.th%2Flogout-sso%3FcurrentPath%3D%2F&client-request-id=4c680fdc-2d97-4e60-a8ea-cbf66766972e
