/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Category, SubCategory } from '../../../interface';

export interface SegmentHeaderParam {
    title: string;
    right: boolean;
    path?: string;
    description?: string;
    params?: Category | SubCategory;
}

export const SegmentHeader = (props: SegmentHeaderParam) => {
    const intl = useIntl();

    return (
        <div className='title-segment'>
            <div>
                <h2>{props.title || 'title'}</h2>
                <h3>{props.description}</h3>
            </div>
            {props.right && (
                <Link
                    to={{
                        pathname: props.path ?? '',
                        state: props.params
                    }}
                >
                    {`${intl.formatMessage({
                        id: intl.formatMessage({ id: 'Library.SeeAllBtn' })
                    })} >`}
                </Link>
            )}
        </div>
    );
};

// <div className='segmentHeader'>
//     <div className='titleSegment'>
//         <h1>{props.left}</h1>
//         {props.description && <h3>{props.description}</h3>}
//     </div>
//     {props.right ? (
//         <Link
//             to={{
//                 pathname: props.path ?? '',
//                 state: props.params
//             }}
//         >
//             {`${intl.formatMessage({
//                 id: intl.formatMessage({ id: 'Library.SeeAllBtn' })
//             })} >`}
//         </Link>
//     ) : null}
// </div>
