import { Input } from 'antd';
import { QuestionComponentProps } from '../../../../interface/questionForm';

const QuestionInput = (props: QuestionComponentProps) => {
   return (
      <Input
         id={props.data.questionUID}
         name={props.data.questionUID}
         onChange={props.onChange}
         value={props.formikValue}
      />
   );
};

export default QuestionInput;
