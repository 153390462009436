import { DetailViewParam } from '../../../interface/detailPage';
import { getListSubCategoryRoute, getListSubInCategoryRoute } from '../../routes/generatePath';
import TagStyle from '../tag/Tags';

export const TagsView = (props: DetailViewParam) => {
    const content = props.content;
    return (
        <div className='tagContainer'>
            <TagStyle
                text={content.categoryName || content.catName}
                path={getListSubInCategoryRoute(
                    content.categoryUID || content.catId,
                    content.licenseType,
                    content.contentType
                )}
            />
            {content.subcategoryName || content.subcatName ? (
                <TagStyle
                    text={props.content.subcategoryName || props.content.subcatName}
                    path={getListSubCategoryRoute(
                        content.categoryUID || content.catId,
                        content.subcategoryUID || content.subcatId,
                        content.licenseType,
                        content.contentType
                    )}
                />
            ) : null}
        </div>
    );
};
