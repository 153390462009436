import axios from 'axios';
import { HtiResponse } from '../../../interface';
import { UploadFile } from 'antd';

export interface TermsOfUseResponse {
    termOfUse: string;
}

export interface PrivacyPolicyResponse {
    privacy: string;
}

export enum TermsOfServicePage {
    TermsOfUse = 'terms-and-conditions',
    PrivacyPolicy = 'privacy-policy',
    PersonalRights = 'personal-rights-form',
    NotificationMeasures = 'notification-measures',
    IllegalContent = 'illegal-content',
    CookiePolicy = 'cookie-policy'
}

export enum DataSubjectRight {
    RightToWithdrawConsent = 'rightToWithdrawConsent',
    RightOfAccess = 'rightOfAccess',
    RightToDataPortability = 'rightToDataPortability',
    RightToObject = 'rightToObject',
    RightToErasure = 'rightToErasure',
    RightToRestrictProcessing = 'rightToRestrictProcessing',
    RightOfRectification = 'rightOfRectification'
}

export interface PDPAPresignResponse {
    url: string;
    fields: Fields;
    fileName: string;
}

export interface RightSubjectFormPayload extends RightSubjectPayload {
    fileList: UploadFile[];
}

export interface RightSubjectPayload {
    email?: string;
    phoneNumber?: string;
    detail?: string;
    subjectRight?: DataSubjectRight[];
    fileNames?: string[];
}

export interface Fields {
    key: string;
    acl: string;
    ContentType: string;
    bucket: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    Policy: string;
    'X-Amz-Signature': string;
}

export const getTermsOfUse = async () => {
    try {
        const path = '/application/term';
        const {
            data: { data }
        } = await axios.get<HtiResponse<TermsOfUseResponse>>(path);
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getPrivacyPolicy = async () => {
    try {
        const path = '/application/privacy';
        const {
            data: { data }
        } = await axios.get<HtiResponse<PrivacyPolicyResponse>>(path);
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const saveRightSubject = async (clientPrefix: string, payload: RightSubjectPayload) => {
    try {
        const path = `/public/${clientPrefix}/pdpa/submit`;
        const { data } = await axios.post(path, payload);
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getPresignPDPA = async (clientPrefix: string, fileTypes: string[]) => {
    try {
        const path = `/public/${clientPrefix}/pdpa/presigned`;
        const {
            data: { data }
        } = await axios.post<HtiResponse<PDPAPresignResponse[]>>(path, { fileTypes: fileTypes });
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
};
