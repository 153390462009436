import { Swiper, SwiperSlide } from 'swiper/react';
import { CustomHomeBanner } from '../../../interface';
import './index.scss';
import { onErrorImage } from '../../Utilities/imageUtilities';

interface BannerProps {
    banners: CustomHomeBanner[];
}

const BannerSquareView = (props: BannerProps) => {
    const breakpoint = {
        1: {
            slidesPerView: 1.5,
            slidesPerGroup: 1.5
        },
        576.98: {
            slidesPerView: 2.5,
            slidesPerGroup: 2.5
        },
        768.98: {
            slidesPerView: 3.5,
            slidesPerGroup: 3.5
        }
    };

    return (
        <Swiper
            breakpoints={breakpoint}
            spaceBetween={16}
            // autoplay={{ delay: 5000, disableOnInteraction: false }}
            className='banner-square'
        >
            {props.banners.map((el, index) => (
                <SwiperSlide key={index}>
                    {el.value ? (
                        <a href={el.value} target='_blank'>
                            <img src={el.imageUrl} loading='lazy' onError={onErrorImage} />
                        </a>
                    ) : (
                        <img src={el.imageUrl} loading='lazy' onError={onErrorImage} />
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default BannerSquareView;
