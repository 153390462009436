import { SetStateAction, useEffect, useState } from 'react';
import {
    AuthPageType,
    AxiosError,
    ClientInfo,
    OtpPassword,
    OtpRegister
} from '../../../../../interface';
import { requestChangePassword } from '../../../../redux/auth/authCrud';
import { Button, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import '../index.scss';
import { checkValueIsPhoneNumber } from '../../../../Utilities/validateText';
import { VerifyOTPAction } from '../../../../redux/verifyOTP';

interface ChangePasswordProps {
    clientInfo: ClientInfo;
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
    setOTPData: React.Dispatch<SetStateAction<OtpPassword | OtpRegister | undefined>>;
}

interface ChangePasswordPayload {
    userID?: string;
}

const initialValues = {
    userID: ''
};

const ChangePassword = (props: ChangePasswordProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const regex = /^((?:\+|\d)[0-9])+|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$/;
    const [status, setStatus] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const { authState, verifyOTP } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            verifyOTP: state.verifyOTP
        }),
        shallowEqual
    );

    useEffect(() => {
        if (authState.isLogin)
            formik.setFieldValue(
                'userID',
                authState.userInfo?.phoneNumber ?? authState.userInfo?.email
            );
    }, []);

    const schema = Yup.object().shape({
        userID: Yup.string()
            .matches(regex, intl.formatMessage({ id: 'Register.PleaseEnterValidEmailOrTel' }))
            .required()
    });

    const formik = useFormik<ChangePasswordPayload>({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: () => showConfirm()
    });

    const onChangePassword = async () => {
        try {
            setLoading(true);
            const response = await requestChangePassword();
            if (response) {
                props.setOTPData(response);
                props.setPageType(AuthPageType.ChangePasswordOTP);
                if (checkValueIsPhoneNumber(formik.values.userID ?? ''))
                    dispatch(VerifyOTPAction.initChangePasswordOTP(response));
            }
        } catch (error) {
            const getError = error as AxiosError;
            setStatus(getError.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const showConfirm = () => {
        Modal.confirm({
            title: intl.formatMessage({
                id: 'ChangePassword.ModalChangePassword'
            }),
            content: (
                <p
                    dangerouslySetInnerHTML={{
                        __html:
                            intl
                                .formatMessage(
                                    { id: 'RegisterModal.Description' },
                                    {
                                        0: formik.values.userID
                                    }
                                )
                                .replace(/\n/g, '<br/>') + '?'
                    }}
                />
            ),
            okText: intl.formatMessage({ id: 'RegisterModal.AgreeBtn' }),
            cancelText: intl.formatMessage({ id: 'RegisterModal.CancelBtn' }),
            onOk: () => {
                if (
                    verifyOTP.changePasswordOTP &&
                    checkValueIsPhoneNumber(formik.values.userID ?? '')
                ) {
                    props.setOTPData(verifyOTP.changePasswordOTP);
                    props.setPageType(AuthPageType.ChangePasswordOTP);
                } else {
                    onChangePassword();
                }
            }
        });
    };

    return (
        <div className='registerContainer'>
            <h1>{intl.formatMessage({ id: 'ChangePassword.Title' })}</h1>
            <form onSubmit={formik.handleSubmit}>
                <div className='inputItem'>
                    <Input
                        className='formInput'
                        value={formik.values.userID}
                        placeholder={intl.formatMessage({
                            id: 'ChangeEmail.Placeholder'
                        })}
                        disabled
                        onChange={(e) => formik.setFieldValue('userID', e.target.value)}
                    />
                    <div>
                        {formik.errors.userID && formik.touched.userID ? (
                            <p className='error-text'>{formik.errors.userID}</p>
                        ) : null}
                    </div>
                </div>

                <div
                    className='errorField'
                    style={status ? {} : { background: 'var(--modal-background)' }}
                >
                    {status && <p>{status}</p>}
                </div>
                <div className='buttonContain'>
                    <Button className='acceptButton' htmlType='submit' loading={loading}>
                        {loading
                            ? intl.formatMessage({ id: 'Action.JustAMoment' })
                            : intl.formatMessage({ id: 'Action.ConfirmBtn' })}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
