export const breakpointEbook = {
    // 1: {
    //     slidesPerView: 2.2
    // },
    // 576.98: {
    //     slidesPerView: 4.2
    // },
    // 768.98: {
    //     slidesPerView: 5.2
    // },
    // 1024.98: {
    //     slidesPerView: 6.2
    // },
    // 1376.98: {
    //     slidesPerView: 7
    // }
    1: {
        slidesPerView: 3.2,
        slidesPerGroup: 3.2
    },
    576.98: {
        slidesPerView: 5.2,
        slidesPerGroup: 5.2
    },
    768.98: {
        slidesPerView: 7.2,
        slidesPerGroup: 7.2
    }
};

export const breakpointVideo = {
    1: {
        slidesPerView: 2.2
    },
    768.98: {
        slidesPerView: 3.5
    },
    1024.98: {
        slidesPerView: 4
    }
};

export const breakpointPodcast = {
    1: {
        slidesPerView: 3.3
    },
    768.98: {
        slidesPerView: 4.3
    },
    1024.98: {
        slidesPerView: 6.3
    }
};
