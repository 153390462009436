import { TermsOfServicePage } from '../../redux/termsOfService/request';
import { Privacy, TermsOfUse } from './Terms';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { removeSplash } from '../../helpers/removeSplash';
import { Affix } from 'antd';
import PersonalRightsForm from './PersonalRightsForm';
import NotFoundPage from '../base/NotFoundPage';
import './termOfService.scss';
import NotificationMeasures from './NotificationMeasures';
import RequestPersonalRight from './RequestPersonalRight';
import { ComponentLoading } from '../../customUI/loading';
import CookiePolicy from './CookiePolicy';

interface TermsOfServiceProps {
    currentPage: TermsOfServicePage;
}

const TermOfService = (props: TermsOfServiceProps) => {
    const pageId = 'TermOfService';
    const dispatch = useDispatch();

    const renderPage = () => {
        switch (props.currentPage) {
            case TermsOfServicePage.TermsOfUse:
                return <TermsOfUse />;
            case TermsOfServicePage.PrivacyPolicy:
                return <Privacy />;
            case TermsOfServicePage.PersonalRights:
                return <PersonalRightsForm />;
            case TermsOfServicePage.NotificationMeasures:
                return <NotificationMeasures />;
            case TermsOfServicePage.IllegalContent:
                return <RequestPersonalRight />;
            case TermsOfServicePage.CookiePolicy:
                return <CookiePolicy />;
            default:
                return <NotFoundPage />;
        }
    };

    useEffect(() => {
        dispatch(removeSplash);
    }, []);

    return (
        <div className='term-of-service-container'>
            <Affix offsetTop={150} className='side-menu-box'>
                <div>
                    <h3>ข้อตกลงการใช้บริการ</h3>
                    <ul>
                        <li>
                            <Link to={`/terms-of-service/${TermsOfServicePage.PrivacyPolicy}`}>
                                นโยบายความเป็นส่วนตัว
                            </Link>
                        </li>
                        <li>
                            <Link to={`/terms-of-service/${TermsOfServicePage.TermsOfUse}`}>
                                เงื่อนไขการใช้บริการ
                            </Link>
                        </li>
                        <li>
                            <Link to={`/terms-of-service/${TermsOfServicePage.PersonalRights}`}>
                                ขอใช้สิทธิสำหรับเจ้าของข้อมูลส่วนบุคคล
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/terms-of-service/${TermsOfServicePage.NotificationMeasures}`}
                            >
                                มาตรการแจ้งเตือน
                            </Link>
                        </li>

                        <li>
                            <Link to={`/terms-of-service/${TermsOfServicePage.IllegalContent}`}>
                                ขั้นตอนการดำเนินการ ต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย
                            </Link>
                        </li>
                        <li>
                            <Link to={`/terms-of-service/${TermsOfServicePage.CookiePolicy}`}>
                                นโยบายการใช้คุกกี้
                            </Link>
                        </li>
                    </ul>
                </div>
            </Affix>
            <div className='content-container'>
                <>{renderPage()}</>
                <ComponentLoading id={pageId} />
            </div>
        </div>
    );
};

export default TermOfService;
