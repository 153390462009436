import { useState } from 'react';
import { Button, Modal, ModalProps } from 'antd';
import './index.scss';
import { useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import HibraryTerms from './HibraryTerms';
import CUTerms from './CUTerms';

interface TermsOfUseModalProps {
  visible: boolean;
  handleCheckTermsOfUse: () => void;
  handleVisible: (visible: boolean) => void;
}

const TermsOfUseModal = (props: TermsOfUseModalProps) => {
  const [isEndScroll, setIsEndScroll] = useState<boolean>(false);
  const { authen } = useSelector((state: HibraryRootState) => ({
    authen: state.auth
  }));
  const modalProps: ModalProps = {
    open: props.visible,
    onCancel: () => props.handleVisible(false),
    footer: null,
    className: 'privacy-policy-modal',
    width: 800
  };

  const handleEndScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      setIsEndScroll(true);
    } else {
      setIsEndScroll(false);
    }
  };

  const renderTerms = () => {
    if (authen.isCuDomain) return <CUTerms />;
    else return <HibraryTerms />;
  };

  return (
    <Modal {...modalProps}>
      <div className="title-container">
        <h1>เงื่อนไขการใช้บริการ</h1>
        <p>กรุณาเลื่อนลงเพื่ออ่านข้อมูลทั้งหมด ก่อนกดยอมรับ</p>
      </div>
      <div className="content-container" onScroll={handleEndScroll}>
        {renderTerms()}
      </div>
      <div className="button-container">
        <Button
          shape="round"
          size="large"
          disabled={!isEndScroll}
          onClick={() => {
            props.handleCheckTermsOfUse();
            props.handleVisible(false);
          }}
        >
          ยอมรับ
        </Button>
      </div>
    </Modal>
  );
};

export default TermsOfUseModal;
