import axios from 'axios';
import { ClientInfo } from '../../../interface/client';

export const fetchClientInfo = async (
    prefix: string,
    position?: GeolocationCoordinates
): Promise<ClientInfo> => {
    const response = await axios.get(`/public/${prefix}/theme`, {
        params: {
            latitude: position?.latitude,
            longitude: position?.longitude
        }
    });
    return response.data.data;
};
