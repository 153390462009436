import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Main } from './pages/main';
import { I18nProvider } from './i18n';

export default function App({
  store,
  basename
}: {
  store: any;
  basename: any;
}) {
  return (
    <Provider store={store}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <Main />
        </I18nProvider>
      </BrowserRouter>
    </Provider>
  );
}
