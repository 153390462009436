import { Button, Checkbox, Input, Upload, UploadFile, message } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { UploadChangeParam } from 'antd/lib/upload';
import {
    DataSubjectRight,
    RightSubjectFormPayload,
    RightSubjectPayload,
    getPresignPDPA,
    saveRightSubject
} from '../../redux/termsOfService/request';
import * as Yup from 'yup';
import axios from 'axios';
import SuccessModal from '../../components/modal/successRightSubjectModal/Success';

const PersonalRightsForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const regexPhoneNumber = /^(?:\+|\d)[0-9]+$/g;

    const { clientPrefix } = useSelector((state: HibraryRootState) => ({
        clientPrefix: state.clientInfo.companyPrefix
    }));

    const schema = Yup.object().shape({
        email: Yup.string().required('กรุณากรอกอีเมล'),
        phoneNumber: Yup.string()
            .required('กรุณากรอกเบอร์โทรศัพท์')
            .matches(regexPhoneNumber, 'เบอร์โทรศัพท์ไม่ถูกต้อง'),
        rights: Yup.array().min(1, 'กรุณาเลือกความประสงค์'),
        detail: Yup.string().required('กรุณากรอกรายละเอียด')
    });

    const formik = useFormik<RightSubjectFormPayload>({
        initialValues: {
            email: '',
            phoneNumber: '',
            detail: '',
            subjectRight: [],
            fileList: []
        },
        validationSchema: schema,
        onSubmit: () => onGetPresign()
    });

    const checkboxOptions = [
        {
            label: 'สิทธิขอถอนความยินยอม',
            value: DataSubjectRight.RightToWithdrawConsent
        },
        {
            label: 'สิทธิขอเข้าถึงข้อมูลส่วนบุคคล',
            value: DataSubjectRight.RightOfAccess
        },
        {
            label: 'สิทธิขอโอนย้ายข้อมูลส่วนบุคคลสมบูรณ์',
            value: DataSubjectRight.RightToDataPortability
        },
        {
            label: 'สิทธิขอคัดค้านการเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล',
            value: DataSubjectRight.RightToObject
        },
        {
            label: 'สิทธิขอลบ หรือทำลายข้อมูลส่วนบุคคล',
            value: DataSubjectRight.RightToErasure
        },
        {
            label: 'สิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคล',
            value: DataSubjectRight.RightToRestrictProcessing
        },
        {
            label: 'สิทธิขอให้แก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง',
            value: DataSubjectRight.RightOfRectification
        }
    ];

    const dummyRequest = (componentsData: any) => {
        setTimeout(() => {
            componentsData.onSuccess('ok');
        }, 0);
    };

    const handleChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
        formik.setFieldValue('subjectRight', checkedValues);
    };

    const handleChangeUpload = (uploadPayload: UploadChangeParam<UploadFile<any>>) => {
        if (uploadPayload.file.status === 'done')
            formik.setFieldValue('fileList', [...formik.values.fileList, uploadPayload.file]);
    };

    const onRemoveFile = (file: UploadFile) => {
        const index = formik.values.fileList.indexOf(file);
        const newFileList = formik.values.fileList.slice();
        newFileList.splice(index, 1);
        formik.setFieldValue('fileList', newFileList);
    };

    const onGetPresign = async () => {
        try {
            setLoading(true);
            const fileNames: string[] = [];
            const fileList = formik.values.fileList;
            const fileTypes = fileList.map((el) => el.type?.split('/')[1] || '');
            const presignResponse = await getPresignPDPA(clientPrefix, fileTypes);

            if (presignResponse) {
                const formDataArray = presignResponse.map((presign, index) => {
                    const formData = new FormData();
                    const file = fileList[index];
                    if (file.originFileObj) {
                        for (const [key, value] of Object.entries(presign.fields)) {
                            formData.append(key, value);
                        }
                        formData.append('file', file.originFileObj);
                        fileNames.push(presign.fileName);
                    }

                    return formData;
                });

                const axiosRequests = formDataArray.map((formData, index) => {
                    const presign = presignResponse[index];
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    return axios({
                        method: 'POST',
                        url: presign.url,
                        data: formData,
                        ...axiosConfig
                    });
                });

                await Promise.all(axiosRequests);
                sendRightFormData(fileNames);
            }
        } catch (error) {
            console.error('Error during upload:', error);
            message.error('การอัปโหลดมีข้อผิดพลาด');
        } finally {
            setLoading(false);
        }
    };

    const sendRightFormData = async (fileNames: string[]) => {
        try {
            const payload: RightSubjectPayload = {
                email: formik.values.email,
                phoneNumber: formik.values.phoneNumber,
                fileNames: fileNames,
                subjectRight: formik.values.subjectRight,
                detail: formik.values.detail
            };
            const response = await saveRightSubject(clientPrefix, payload);
            if (response) setSuccessModal(true);
        } catch (error) {
            message.error('พบข้อผิดพลาด');
        }
    };

    return (
        <form onSubmit={formik.handleSubmit} className='personal-rights-form'>
            <h1>ขอใช้สิทธิสำหรับเจ้าของข้อมูลส่วนบุคคล</h1>
            <div>
                <h4>ข้อมูลติดต่อของเจ้าของข้อมูลส่วนบุคคล *</h4>
                <div className='input-row'>
                    <Input
                        placeholder='กรอกอีเมล'
                        value={formik.values.email}
                        onChange={(e) => formik.setFieldValue('email', e.target.value)}
                        type='email'
                    />
                    {formik.errors.email && formik.touched.email ? (
                        <label className='error'>{formik.errors.email}</label>
                    ) : null}
                </div>
                <div className='input-row'>
                    <Input
                        value={formik.values.phoneNumber}
                        placeholder='กรอกเบอร์โทรศัพท์'
                        onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value)}
                    />
                    {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                        <label className='error'>{formik.errors.phoneNumber}</label>
                    ) : null}
                </div>
            </div>

            <div>
                <h4>มีความประสงค์จะขอใช้สิทธิดังต่อไปนี้ *</h4>
                <div className='input-row'>
                    <Checkbox.Group
                        value={formik.values.subjectRight}
                        options={checkboxOptions}
                        onChange={handleChangeCheckbox}
                    />
                    {formik.errors.subjectRight && formik.touched.subjectRight ? (
                        <label className='error'>{formik.errors.subjectRight}</label>
                    ) : null}
                </div>
            </div>

            <div>
                <h4>รายละเอียด *</h4>
                <div className='input-row'>
                    <Input.TextArea
                        rows={6}
                        placeholder='กรอกรายละเอียด'
                        onChange={(e) => formik.setFieldValue('detail', e.target.value)}
                        value={formik.values.detail}
                    />
                    {formik.errors.detail && formik.touched.detail ? (
                        <label className='error'>{formik.errors.detail}</label>
                    ) : null}
                </div>
            </div>
            <div className='upload-container'>
                <div className='upload-title'>
                    <div>
                        <h2>อัปโหลดไฟล์ภาพประกอบ *</h2>
                        <p>จำนวนไฟล์ที่อัปโหลด : {formik.values.fileList.length}</p>
                    </div>
                    <Upload
                        customRequest={dummyRequest}
                        onChange={handleChangeUpload}
                        showUploadList={false}
                    >
                        <Button shape='round' size='large' className='primary'>
                            อัปโหลดไฟล์
                        </Button>
                    </Upload>
                </div>
                <Upload fileList={formik.values.fileList} onRemove={onRemoveFile} />
            </div>
            <Button shape='round' htmlType='submit' className='submit' loading={loading}>
                ส่งข้อมูล
            </Button>
            <div className='notice-container'>
                <p> หมายเหตุ : บริษัทอาจปฏิเสธคำร้องของท่านหากพบว่า</p>
                <p>(1) คำขอดังกล่าวไม่สมเหตุสมผล</p>
                <p>(2) คำขอฟุ่มเฟือย</p>
                <p>(3) จำเป็นต่อการปฏิบัติตามสัญญาหรือการเข้าสัญญา</p>
                <p>(4) จำเป็นต่อการปฏิบัติตามกฏหมาย หรือคำสั่งศาล</p>
                <p>
                    (5) การประมวลผลข้อมูลส่วนบุคคลดังกล่าว
                    มีความจำเป็นสำหรับผลประโยชน์ที่ชอบด้วยกฏหมาย
                </p>
                <p>(6) คำร้องขออาจส่งผลเสียและเป็นอันตรายต่อสิทธิและเสรีภาพของผู้อื่น</p>
                <p>
                    (7) จำเป็นต่อการก่อตั้ง ใช้ หรือป้องกันสิทธิทางกฏหมาย ทั้งนี้
                    เมื่อบริษัทได้รับแบบฟอร์มและเอกสารหลักฐานที่เกี่ยวข้อง เมื่อได้ตรวจสอบเอกสารแล้ว
                    บริษัท
                    จะดำเนินการแจ้งให้บุคคลที่เกี่ยวข้องซึ่งอยู่ในความควบคุมดูแลของบริษัททราบโดย
                    ทันที หาก ไม่มีเหตุแห่งการปฏิเสธคำร้องขอบริษัทจะดำเนินการตามคำขอของท่านภายใน 30
                    วันนับแต่วันที่ ได้รับคำขอและเอกสารประกอบโดยครบถ้วน
                </p>
            </div>
            <SuccessModal
                visible={successModal}
                onClose={() => setSuccessModal(false)}
                onClearForm={() => formik.resetForm()}
            />
        </form>
    );
};

export default PersonalRightsForm;
