import { ClientInfo, StoreStatus } from '../../interface';

export const clientInfoMock: ClientInfo = {
    clientName: 'ไม่พบองค์กร',
    displayName: 'ไม่พบองค์กร',
    logoImages:
        'https://s3-ap-southeast-1.amazonaws.com/hti.elibrary/public/clients/a32b5f03-2931-40a7-99a6-4a2e0af4a839/logo/hibrarylogo.png',
    companyPrefix: 'hti',
    contactList: {
        companyName: '',
        address: 'ไม่พบที่อยู่',
        email: 'ไม่พบที่อยู่อีเมล',
        Instagram: 'https://www.instagram.com/',
        LineID: 'http://line.me/',
        mobilePhone: 'ไม่พบเบอร์ติดต่อ',
        phoneNumber: 'ไม่พบเบอร์ติดต่อ',
        Twitter: 'https://twitter.com/',
        website: '',
        Facebook: 'https://www.facebook.com/'
    },
    isPrivateLibrary: false,
    isPublicLibrary: true,
    isPublicRegister: false,
    storeType: 'Concurrent',
    storeStatus: StoreStatus.Activate,
    showClientLogo: true,
    isAnonymous: false,
    emailDomainRegister: false,
    strictPdpa: false,
    theme: {
        Primary: '#012875',
        PrimaryVariant: '#001b51',
        Secondary: '#fd6b53',
        SecondaryVariant: '#ff5337',
        Background: '#f2f2f2',
        Surface: '#ffffff',
        Error: '#d7042b',
        OnPrimary: '#ffffff',
        OnSecondary: '#ffffff',
        OnBackground: '#012875',
        OnSurface: '#012875',
        OnError: '#ffffff',
        Title: '#12875',
        Body: '#203657',
        Header: '#012875',
        SubHeader: '#f2f2f2',
        PrimaryDark: '#012875',
        OnPrimaryDark: '#ffffff',
        htiPrimaryLight: '#012875',
        htiPrimaryDark: '#012875',
        htiPrimaryVariant: '#001b51',
        htiSecondary: '#fd6b53',
        htiSecondaryVariant: '#ff5337',
        modalBackground: '#ffffff'
    }
};
