const CUPolicy = () => {
    return (
        <>
            <p>
                CU-eLibrary เคารพสิทธิความเป็นส่วนตัวของผู้ใช้ทุกคนที่เข้าเยี่ยมชม Application
                หรือเว็บไซต์ของเรา ดังนั้น
                เราจึงต้องการที่จะชี้แจงให้ท่านทราบเกี่ยวกับการใช้งานข้อมูลส่วนบุคคลของท่าน
                เราขอแนะนำให้ท่านอ่านนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
                เพื่อที่จะได้เรียนรู้และเข้าใจหลักปฏิบัติที่เรายึดถือในการใช้ข้อมูลส่วนตัวของท่าน
                เมื่อท่านส่งข้อมูลส่วนตัวมาให้เรา
                เราจะถือว่าท่านยินยอมให้เราเปิดเผยข้อมูลเหล่านี้ได้ในกรณีที่จำเป็นและสมควร
                ดังที่ได้กล่าวไว้ในนโยบายฉบับนี้
            </p>

            <p>
                CU-eLibrary
                คือระบบห้องสมุดหนังสืออิเล็กทรอนิกส์ที่เป็นตัวแทนจัดจำหน่ายหนังสืออิเล็กทรอนิกส์(E-book)ที่เป็นตำราวิชาการ
                และหนังสือทั่วไป จากหลากหลายสำนักพิมพ์ที่เข้าร่วม หนังสืออิเล็กทรอนิกส์(E-book)
                สามารถรองรับรูปแบบไฟล์ PDF หรือ ePub ซึ่งจะถูกเข้ารหัสเพื่อควบคุมลิขสิทธิ์(Digital
                Right Management) ทุกไฟล์ การอ่าน สำหรับสมาชิกจะสามารถอ่านผ่านอุปกรณ์ต่างๆดังนี้
                iPhone, iPad, Android และเว็บไชต์ โดยระบบจะมีการจัดเก็บค่า IP Address
                รวมถึงชนิดเว็บเบราเซอร์ของสมาชิกไว้
                ทั้งนี้ก็เพื่อนำข้อมูลเหล่านั้นมาวิเคราะห์และนำไปพัฒนาบริการของ CU-eLibrary
                เพื่อตอบสนองความต้องการของสมาชิกในอนาคต
            </p>

            <div>
                <h1>การรวบรวมและการเก็บรักษาข้อมูลส่วนบุคคล</h1>
                <ol>
                    <li>
                        ข้อมูลสำหรับการเป็นสมาชิก ของ CU-eLibrary จำเป็นต้องกรอกข้อมูลชื่อ อีเมล
                        หรือรหัสพนักงานให้ถูกต้องและครบถ้วนเพื่อสิทธิประโยชน์ของผู้ใช้บริการในอนาคต
                    </li>
                    <li>
                        ข้อมูลส่วนบุคคลที่สมาชิกได้ให้หรือใช้ผ่านการประมวลผลของเครื่องคอมพิวเตอร์ที่ควบคุมการทำงาน
                        ในระบบCU-eLibrary สมาชิกยอมรับและตกลงว่าเป็นสิทธิและกรรมสิทธิ์ของ
                        CU-eLibrary
                        ทางเราจะรักษาข้อมูลเหล่านั้นไว้เป็นความลับตามเกณฑ์มาตรฐานสากลสำหรับการรักษาข้อมูลเป็นความลับและจะไม่อนุญาตให้ผู้อื่นเข้าถึงข้อมูลดังกล่าวได้นอกจากพนักงานผู้ที่ได้รับอนุญาตจาก
                        CU-eLibrary เพื่อความปลอดภัยในข้อมูลส่วนบุคคลของสมาชิก
                        สมาชิกควรปฏิบัติตามข้อกำหนดและเงื่อนไขการให้บริการของเว็บไซต์ของบริษัทแต่ละเว็บไซต์โดยเคร่งครัด
                    </li>
                    <li>
                        ข้อมูลทางสถิติสมาชิก(demographic)เฉพาะ location base
                        จะถูกเก็บข้อมูลทุกที่มีารลงชื่อใช้งานผ่านทาง QR-Code
                        เพื่อใช้ในการยืนยันสิทธิ์การใช้งาน ตามที่องค์กรที่เป็นลูกค้าของ CU-eLibrary
                        กำหนด
                    </li>
                </ol>
            </div>

            <div>
                <h1>การเปิดเผยข้อมูลส่วนบุคคล</h1>
                <p>
                    เว็บไซต์ www.chulabook.com
                    จะไม่ส่งต่อหรือขายข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกอื่น
                    เพื่อวัตถุประสงค์ในการนำไปใช้โดยไม่ได้รับอนุญาต
                    นอกจากนี้ทางเราจะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่องค์กรอื่นใด เว้นแต่
                    ท่านได้ร้องขอและให้อำนาจแก่เว็บไซต์ของเราในการดำเนินการ หรือ
                    ข้อมูลที่ส่งต่อนั้นเป็นไปเพื่อช่วยให้การเริ่มต้นธุรกรรมของท่านสำเร็จลุล่วงลง
                    หรือ การเปิดเผยข้อมูลนั้นเป็นไปตามที่กฎหมายอนุญาต หรือเป็นไปตามที่กฎหมายต้องการ
                    ตัวอย่างเช่นเว็บไซต์ CU-eLibrary
                    อาจได้รับการร้องขอให้ทำการเปิดเผยข้อมูลเกี่ยวกับลูกค้าของเราตามคำสั่งศาล
                    หรือตามหมายศาล หน่วยงานอื่นๆ ที่มีอำนาจตามกฎหมายในการณ์นี้ทางเว็บไซต์
                    CU-eLibrary จะทำการติดต่อแจ้งท่านล่วงหน้าเสมอ เว็บไซต์ CU-eLibrary
                    อาจทำความตกลงไว้กับผู้ให้บริการภายนอก (Third Party)
                    ในการที่จะพัฒนาและดูแลรักษาระบบพาณิชย์อิเล็กทรอนิกส์ (e-commerce)
                    ของเราและการจัดสรรทรัพยากรหรือบริการในนามของ CU-eLibrary ซึ่งบุคคลภายนอก
                    ที่เข้ามาดำเนินงานให้กับเราหรือเข้ามาดำเนินการในนามของบริษัทฯ
                    นั้นจะต้องตกลงที่จะรักษาข้อมูลลูกค้าของเว็บไซต์ CU-eLibrary
                    ไว้เป็นความลับด้วยเช่นกัน อีกทั้งยังต้องผูกพันต่อกฎหมายที่มีผลใช้บังคับด้วย
                    เมื่อใดก็ตามที่เว็บไซต์
                    CU-eLibraryได้ว่าจ้างองค์กรอื่นใดเข้ามาให้การสนับสนุนการบริการของเรา
                    องค์กรเหล่านั้นจะต้องปฎิบัติตามมาตรฐานการรักษาข้อมูลเป็นความลับของCU-eLibrary
                    ด้วยเช่นกัน
                </p>
            </div>

            <div>
                <h1>คุกกี้ (COOKIE)</h1>
                <p>
                    คุกกี้ (COOKIE)
                    คือข้อมูลที่ได้ส่งจากเว็บไซต์ไปยังเครื่องคอมพิวเตอร์ของสมาชิกผู้เข้าเยี่ยมชมเว็บไซต์
                    คุกกี้จะทำให้ไซต์ดังกล่าวจดจำเบราว์เซอร์ของท่าน
                    คุกกี้อาจเก็บการตั้งค่าของผู้ใช้หรือข้อมูลอื่นๆสมาชิกผู้เข้าเยี่ยมชม
                    ซึ่งอาจจะเก็บข้อมูลของท่านดังต่อไปนี้
                </p>
                <ol>
                    <li>หมาย IP คอมพิวเตอร์ของท่าน</li>
                    <li>ชนิดของ Browser ที่ใช้ในการเข้าชม</li>
                    <li>หน้าเว็บไซต์ที่ท่านเข้าเยี่ยมชม</li>
                    <li>วันและเวลาที่ท่านเยี่ยมชมเว็บไซต์</li>
                    <li>ข้อมูลที่ท่านค้นหาภายในเว็บไซต์ รวมไปถึงสถิติอื่น ๆ</li>
                </ol>
                <p>
                    **
                    โดยท่านสามารถตั้งค่าเบราว์เซอร์ให้ปฏิเสธคุกกี้ทั้งหมดหรือแสดงให้รู้เมื่อกำลังมีการส่งคุกกี้
                    อย่างไรก็ตามลักษณะหรือบริการบางอย่างของเว็บไซต์อาจทำงานได้ไม่สมบูรณ์เมื่อไม่มีคุกกี้
                </p>
                <p>
                    CU-eLibrary
                    ขอสงวนสิทธิ์ในการปฏิเสธความรับผิดทั้งหมดในกรณีที่ข้อมูลส่วนบุคคลของสมาชิกเสียหายหรือสูญหายเนื่องจากความผิดพลาดใดๆ
                    ระหว่างส่งข้อมูลระหว่าง server
                    กับผู้ใช้บริการเหตุสุดวิสัยใดๆการถูกจารกรรมข้อมูลคอมพิวเตอร์(Hack)
                    และ/หรือการกระทำใดๆที่ขัดกฎหมายไม่ว่าการกระทำนั้นจะเกิดจากบุคคลใด ๆ ก็ตาม
                </p>
            </div>

            <div>
                <h1>ลิขสิทธิ์ (COPYRIGHTS)</h1>
                <p>
                    ลิขสิทธิ์ตามกฎหมาย(Copyrights) ข้อมูลหนังสืออิเล็กทรอนิกส์(E-book)
                    ข้อมูลตัวอย่างหนังสืออิเล็กทรอนิกส์(E-book),ข้อความ ภาพ เสียงเนื้อหา
                    ส่วนประกอบใด ๆ ของ CU-eLibrary
                    แต่ไม่จำกัดเฉพาะเครื่องหมายการค้าเครื่องหมายบริการ ชื่อทางการค้าที่ปรากฏบน
                    CU-eLibrary
                    เป็นงานอันได้รับความคุ้มครองตามกฎหมายทรัพย์สินทางปัญญาของไทยโดยชอบด้วยกฎหมายของ
                    CU-eLibrary หากบุคคลใดลอกเลียน ปลอมแปลงทำซ้ำ ดัดแปลง เผยแพร่ต่อสาธารณชนจำหน่าย
                    มีไว้ให้เช่าหรือกระทำการใดๆในลักษณะที่เป็นการแสวงหาผลประโยชน์ทางการค้าหรือประโยชน์โดยมิชอบไม่ว่าโดยประการใดๆ
                    จากทรัพย์สินทางปัญญาดังกล่าวข้างต้นโดยไม่ได้รับอนุญาตจาก CU-eLibrary
                    ทางเราจะดำเนินการตามกฎหมายกับผู้ละเมิดสิทธิดังกล่าวโดยทันที
                </p>
            </div>

            <div>
                <h1>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h1>
                <p>
                    CU-eLibrary สามารถเปลียนแปลงนโยบายความเป็นส่วนตัวได้ตลอดเวลา
                    เมื่อมีการเปลี่ยนแปลงหรือเพิ่มเติมบริการหรือเมื่อเราได้รับข้อเสนอแนะจากสมาชิก
                    การเปลี่ยนแปลงใดๆในนโยบายจะมีการแจ้งถึงสมาชิกผ่านทางไปรษณีย์อิเล็กทรอนิกส์
                    หากสมาชิกมีคำถามที่เกี่ยวข้องกับนโยบายความเป็นส่วนตัวของเราสมาชิกสามารถติดต่อผ่านที่
                    cybersupport@cubook.chula.ac.th หรือผ่านหน้า“ติดต่อเรา”
                </p>
            </div>
        </>
    );
};

export default CUPolicy;
