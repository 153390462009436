import { Category } from '../categories/categoriesModel';

export const isNil = (val: any) => val == null;
export const convertLicenseType = (str: string | any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
export enum CallType {
    list = 'list',
    action = 'action',
    detail = 'detail'
}

export enum EbookFileType {
    pdf = 'pdf',
    epub = 'epub'
}

export enum LicenseType {
    sample = 'Sample',
    rent = 'Rent',
    buffet = 'Buffet',
    private = 'Private',
    free = 'Free'
}
export enum ContentType {
    content = 'content',
    ebook = 'ebook',
    podcast = 'podcast',
    video = 'video'
}
export enum RentalStatus {
    Rent = 'Rent',
    Rented = 'Rented',
    WaitingList = 'WaitingList',
    WaitingListed = 'WaitingListed',
    Returned = 'Returned',
    ReturnAble = 'ReturnAble'
}
export enum RentBtnTitle {
    Rent = 'ยืมสื่อ',
    Rented = 'ยืมแล้ว',
    WaitingList = 'จองคิว',
    WaitingListed = 'ยกเลิก'
    // Returned = "Returned",
    // ReturnAble = "ReturnAble",
}

export const RentalStatusClass = {
    Rent: {
        title: 'ยืมสื่อ',
        classname: 'Rent'
    },
    Rented: {
        title: 'ยืมแล้ว',
        classname: 'Rented'
    },
    WaitingList: {
        title: 'จองคิว',
        classname: 'WaitingList'
    },
    WaitingListed: {
        title: 'รอคิว',
        classname: 'WaitingListed'
    },
    Returned: {
        title: 'คืนสื่อ',
        classname: 'Returned'
    },
    ReturnAble: {
        title: '',
        classname: 'ReturnAble'
    }
};

export const RentalpodcastStatusClass = {
    Rent: {
        title: 'ยืมสื่อ',
        classname: 'Rent'
    },
    Rented: {
        title: 'ยืมสื่อแล้ว',
        classname: 'Rented'
    },
    WaitingList: {
        title: 'จองคิว',
        classname: 'WaitingList'
    },
    WaitingListed: {
        title: 'รอคิว',
        classname: 'WaitingListed'
    },
    Returned: {
        title: 'คืนสื่อ',
        classname: 'Returned'
    },
    ReturnAble: {
        title: '',
        classname: 'ReturnAble'
    }
};

export enum BannerType {
    ebook = 'ebook',
    podcast = 'podcast',
    video = 'video',
    link = 'link',
    creator = 'creator',
    author = 'author',
    category = 'category',
    subcategory = 'subcategory',
    search = 'search'
}

export enum HomeListType {
    CategoryEbook = 'CategoryEbook',
    Categories = 'Categories',
    BestSeller = 'BestSeller',
    NewEbook = 'NewEbook',
    NewContent = 'NewContent',
    AllContent = 'AllContent',
    NewVideo = 'NewVideo',
    NewPodcast = 'NewPodcast',
    ContentSet = 'ContentSet'
}

export enum SetType {
    Magazine = 'Magazine',
    Newspaper = 'Newspaper',
    eBook = 'eBook'
}

export interface StoreBookDetail {
    uploadDate: any;
    duration: any;
    contentUID: string;
    subcatId: string;
    subcatName?: string;
    concurrent: number;
    catId: string;
    catName: string;
    creatorUID: string;
    title: string;
    authorName: string;
    publisherName: string;
    publisherProfileImage: string;
    channelName: string;
    channelImage: string;
    coverImage: string;
    fileSize: number;
    fileType: EbookFileType;
    licenseType: LicenseType;
    licenseUID?: string;
    contentType: any;
    pageCount: number;
    description: string;
    language: string;
    rentalStatus: RentalStatus;
    // queueNumber: number;
    activeLicense?: number;
    rentalPeriod?: number;
    remainConcurrent?: number;
    totalRent?: number;
    waitingAmount: number; // รอคิว
    waitingQueue?: number; // จองคิวแล้ว (number)
    relatedEbooks?: StoreBookDetail[];
    relatedVideos?: StoreBookDetail[];
    relatedContents?: StoreBookDetail[];
    playlist: string;
    view?: number;
    rentBtn?: any;
    prefix?: any;
    fromBookshelf?: any;
    rentBtnPlay?: any;
    writerID?: any;
    writerUID?: any;
}
export interface StorePodcastDetail extends StoreBookDetail {
    channelName: string;
    channelImage: string;
    pageCount: number;
    language: string;

    relatedContents?: StorePodcastDetail[];
    playlist: string;
    episodes: any[];
    rentBtnPodcast: any;
    rentBtnPlayPodcast: any;
    episodeAmount: number;
    newRental: string;
    checkstatus: any;
    episodeUID: string;
    contentUID: string;
    rentBtnPlayPlayer: any;
}
export interface ContentSet {
    uid: string;
    title: string;
    imagePath: string;
}

export interface StoreBookState {
    listLoading: boolean;
    actionsLoading: boolean;
    entities?: any;
    error: any;
}

export interface StoreHomePage {
    banners: Banner[];
    home: StoreHomeSegment[];
}

export interface Banner {
    imageUrl: string;
    value: string;
    licenseType: LicenseType;
    type: BannerType;
    title?: string;
    description?: string;
}

export interface StoreHomeSegment {
    title?: string;
    description?: string;
    type?: HomeListType;
    ebooks?: StoreBookDetail[];
    videos?: StoreBookDetail[];
    contents?: StoreBookDetail[];
    category?: Category[];
    catId?: string;
    contentType?: string;
    sets?: ContentSet[];
    setType?: SetType;
}
